import { graphConfig } from "../authConfig";

export async function callMsGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => {
            console.log(error);
            throw error;
        });
}

export async function getGroupsFromGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json")

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({"securityEnabledOnly": false})
    };

    return fetch(graphConfig.graphGroupEndPoint, options)
        .then(response => {return response.json()})
        .catch(error => {
            console.log(error);
            throw error;
        });
}

export async function getDirectReportsFromGraph(accessToken: string, userId: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`

    headers.append("Authorization", bearer)
    headers.append("Content-Type", "application/json")

    const options = {
        method: "GET",
        headers: headers
    }

    return fetch(graphConfig.graphDirectReportsEndpoint.replace("{userId}", userId), options)
        .then(response => response.json())
        .catch(error => {
            console.log(error)
            throw error
        });
    
}
