<template>
  <div>
    <!-- Recommended Actions -->
    <div>
      <div v-if="showEmailModal" style="display: flex; justify-content: center">
        <EmailComponent
          :emailItem="getEmailItems()"
          @closeEmailModal="showEmailModal = false"
        />
      </div>
      <div class="overlay" v-if="showMandationPopup">
        <div
          class="mandation-confirmation card"
          v-show="showMandationPopup && !mandationCancel"
          id="mandation-popup"
        >
          <div class="row mb-2">
            <h6 class="popup-header" style="text-align: center">
              I confirm that to my knowledge mandation is appropriate for
              {{ getCustomer.firstName }}
            </h6>
            <div class="wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style="height: 2rem"
              >
                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm32 224c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z"
                />
              </svg>
              <div class="tooltip">
                <ul>
                  <li>
                    Has customer had opportunity to complete activity
                    voluntarily?
                  </li>
                  <li>
                    Is the activity reasonable and achievable? (tailored work
                    requirements)
                  </li>
                  <li>
                    Have all known complex needs and challenges been taken into
                    account?
                  </li>
                  <li>
                    Is the Customer in a Labour Market Regime which enables
                    Mandation?
                  </li>
                  <li>
                    Does the Customer have any easements in place that have
                    resulted in conditionality being switched off for now?
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              class="button-5 bg-gradient-dark"
              role="button"
              @click="confirmMandationChange()"
            >
              Submit
            </button>
            <button
              class="button-5 bg-gradient-dark"
              role="button"
              @click="mandationPopupCancel()"
            >
              Cancel
            </button>
          </div>
        </div>

        <div
          class="mandation-confirmation card"
          v-show="showMandationPopup && mandationCancel"
          id="mandation-popupCancel"
        >
          <h6 style="text-align: center">
            Are you sure you want to cancel the MAN? The MAN will not be saved,
            the action will not be mandated
          </h6>
          <button
            class="button-5"
            role="button"
            @click="mandationPopupCancel()"
          >
            Cancel</button
          ><button
            class="button-5"
            role="button"
            @click="confirmMandationChange()"
          >
            Submit
          </button>
        </div>
      </div>
      <div class="overlay" v-if="invalidMemId">
        <div
          class="invalid-memId card"
          v-show="invalidMemId"
          id="invalid-memId"
        >
          <h6 class="popup-header" style="text-align: center">
            The account ID {{ this.getCustomer.memorableId }} does not have a
            programme assigned
          </h6>
          <button
            class="button-5 bg-gradient-dark"
            role="button"
            @click="invalidMemIdClosePopup()"
          >
            Close
          </button>
        </div>
      </div>
      <!-- Desktop View -->
      <div
        class="table-responsive card desktop-logic"
        v-if="tableType == 'Recommended'"
        :id="id"
        style="padding: 0 1rem"
      >
        <div class="overflow-x-scroll-l840">
          <div
            @click="
              () => {
                show = !show;
              }
            "
            style="
              font-size: 1.5rem;
              padding: 0.7rem;
              font-weight: 600;
              display: flex;
              align-items: center;
            "
          >
            Recommended Actions
            <svg
              v-show="!show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
              />
            </svg>
            <svg
              v-show="show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
              />
            </svg>
          </div>
          <div class="fit-content-l840 overflow-x-scroll-l840" v-show="show">
            <div style="display: flex; flex-direction: row; margin-left: 1rem">
              <label style="font-size: medium; margin-right: 1rem"
                >Items Per Page:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <input
                    class="page-amount-input"
                    :value="currentPageLength"
                    @change="changePageLength($event.target.value)"
                  />
                  <svg
                    v-show="!showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="page-num-options" v-show="showPageAmountDrpDwn">
                  <div
                    v-for="index in Math.ceil(getActionAmount / pageIncrements)"
                    :key="index"
                    @click="
                      () => {
                        changePageLength(index * pageIncrements);
                        showPageAmountDrpDwn = false;
                      }
                    "
                  >
                    {{ index * pageIncrements }}
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 1vh; display: flex; flex-direction: row">
              <table
                style="height: fit-content; table-layout: fixed; width: 100%"
                id="datatable-basic"
                class="table table-flush"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      style="width: 8%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-if="ea"
                    >
                      Options
                    </th>
                    <th
                      style="width: 24.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                    <th
                      style="width: 24.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      What I will get from doing this?
                    </th>
                    <th
                      style="width: 11.25%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      More Info
                    </th>
                    <th
                      style="width: 11.25%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Due
                    </th>
                    <!-- <th
                      style="width: 9.5%"
                      v-if="ea"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Mandation
                    </th> -->
                    <th
                      style="width: 9.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Tasks
                    </th>
                    <th
                      style="width: 9.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-if="ea"
                    >
                      Edit Action
                    </th>
                  </tr>
                </thead>
                <tbody
                  style="border-top: 1px solid #e9ecef"
                  v-for="item in this.items"
                  :key="item.id"
                >
                  <tr
                    title="Right click for More Options"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :id="item.id"
                    class="action"
                    @mousedown="
                      ($event) => {
                        showContextMenu(
                          $event,
                          item,
                          false,
                          'recommended',
                          false
                        );
                      }
                    "
                  >
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      <svg
                        @click="
                          ($event) => {
                            showContextMenu(
                              $event,
                              item,
                              false,
                              'recommended',
                              true
                            );
                          }
                        "
                        class="open-context-Menu-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 128 512"
                      >
                        <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                        />
                      </svg>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          !item.actionUrl.includes(currentLocation) &&
                          !item.actionUrl.includes(localLocation) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="item.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          (item.actionUrl.includes(currentLocation) ||
                            item.actionUrl.includes(localLocation)) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(item.actionUrl, item.id)"
                      >
                        Visit Here
                      </router-link>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; color: limegreen"
                      class="text-sm font-weight-normal"
                    >
                      {{ formatDate(new Date(item.timeline.scheduledEnd)) }}
                    </td>
                    <td style="padding: 0.75rem 1.5rem">
                      <svg
                        v-if="item.tasks.length > 0"
                        class="show-tasks"
                        @click="toggleTaskDisplay(item.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M152.1 38.16C161.9 47.03 162.7 62.2 153.8 72.06L81.84 152.1C77.43 156.9 71.21 159.8 64.63 159.1C58.05 160.2 51.69 157.6 47.03 152.1L7.029 112.1C-2.343 103.6-2.343 88.4 7.029 79.03C16.4 69.66 31.6 69.66 40.97 79.03L63.08 101.1L118.2 39.94C127 30.09 142.2 29.29 152.1 38.16V38.16zM152.1 198.2C161.9 207 162.7 222.2 153.8 232.1L81.84 312.1C77.43 316.9 71.21 319.8 64.63 319.1C58.05 320.2 51.69 317.6 47.03 312.1L7.029 272.1C-2.343 263.6-2.343 248.4 7.029 239C16.4 229.7 31.6 229.7 40.97 239L63.08 261.1L118.2 199.9C127 190.1 142.2 189.3 152.1 198.2V198.2zM224 96C224 78.33 238.3 64 256 64H480C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H256C238.3 128 224 113.7 224 96V96zM224 256C224 238.3 238.3 224 256 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H256C238.3 288 224 273.7 224 256zM160 416C160 398.3 174.3 384 192 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416zM0 416C0 389.5 21.49 368 48 368C74.51 368 96 389.5 96 416C96 442.5 74.51 464 48 464C21.49 464 0 442.5 0 416z"
                        />
                      </svg>
                      <span
                        v-if="item.tasks.length == 0"
                        style="font-size: 0.9rem"
                        >No Tasks</span
                      >
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <svg
                        class="edit-action"
                        @click="openEditAction(item.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr
                    v-if="item.tasks.length == 0"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td colspan="8" style="padding: 0.75rem 1.5rem">
                      <span style="margin-left: 2rem">No Tasks</span>
                    </td>
                  </tr>
                  <tr
                    v-for="task in item.tasks"
                    :key="task.id"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td></td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      stye="padding: 0.75rem 1.5rem;"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          !task.actionUrl.includes(currentLocation) &&
                          !task.actionUrl.includes(localLocation) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="task.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          (task.actionUrl.includes(currentLocation) ||
                            task.actionUrl.includes(localLocation)) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(task.actionUrl, task.id)"
                      >
                        Visit Here
                      </router-link>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; color: limegreen"
                      class="text-sm font-weight-normal"
                    >
                      {{ formatDate(new Date(task.timeline.scheduledEnd)) }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <svg
                        class="edit-action"
                        @click="openEditTask(task.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- This uses 'page-change' and 'interface' events to communicate with the desktop pagination -->
            <TablePaginationVue
              :type="'Recommended'"
              :items="getNextActions"
              :buttons="4"
              @page-change="interfaces.tablePaginationMobile.setPage"
              @interface="this.setDesktopPaginationInterface"
              v-model="this.items"
            />
          </div>
        </div>
      </div>

      <!-- Mobile View -->
      <div
        class="card open-action-cont open-actions-card mobile-logic"
        v-if="tableType == 'Recommended'"
      >
        <!-- Table Header -->
        <div
          @click="
            () => {
              show = !show;
            }
          "
          style="
            font-size: 1.5rem;
            padding: 0.7rem;
            font-weight: 600;
            display: flex;
            align-items: center;
          "
        >
          Recommended Actions
          <svg
            v-show="!show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
            />
          </svg>
          <svg
            v-show="show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
            />
          </svg>
        </div>

        <!-- Table content (drops down) -->
        <div v-show="show">
          <!-- Search Filters -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              padding: 0 0.7rem 0.3rem 0.7rem;
            "
          >
            <div style="margin-right: 1.5rem; display: block">
              <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <label
                  style="font-size: medium; margin-right: 1rem; width: 8rem"
                  >Items Per Page:</label
                >
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; flex-direction: row">
                    <input
                      class="page-amount-input"
                      :value="currentPageLength"
                      @change="changePageLength($event.target.value)"
                    />
                    <svg
                      v-show="!showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = true"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      :id="`${id}-showSvg`"
                    >
                      <path
                        d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                      />
                    </svg>
                    <svg
                      v-show="showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = false"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      :id="`${id}-hideSvg`"
                    >
                      <path
                        d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                      />
                    </svg>
                  </div>
                  <div class="page-num-options" v-show="showPageAmountDrpDwn">
                    <div
                      v-for="index in Math.ceil(
                        getActionAmount / pageIncrements
                      )"
                      :key="index"
                      @click="
                        () => {
                          changePageLength(index * pageIncrements);
                          showPageAmountDrpDwn = false;
                        }
                      "
                    >
                      {{ index * pageIncrements }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Audit Item -->
          <div
            class="card h-100 open-action"
            v-for="(item, index) in this.items"
            :key="index"
            title="Right click for More Options"
            @mousedown="
              ($event) => {
                showContextMenu($event, item, false, 'recommended', false);
              }
            "
          >
            <div class="p-3 card-header" :style="'padding-bottom: 0;'">
              <div style="display: flex; flex-direction: row">
                <div style="margin-right: 1.5rem">
                  <svg
                    @click="
                      ($event) => {
                        showContextMenu(
                          $event,
                          item,
                          false,
                          'recommended',
                          true
                        );
                      }
                    "
                    class="open-context-Menu-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                    />
                  </svg>
                </div>

                <div style="overflow: hidden; display: block; width: 100%">
                  <h6 class="subject" style="width: 100%">
                    {{ item.subject }}
                  </h6>
                  <h6 style="width: 100%; color: #67748e"></h6>
                </div>

                <div style="width: 24px; height: 100%">
                  <svg
                    @click="onActionSelect(item.id)"
                    v-show="!extendedActions.includes(item.id)"
                    class="extend-action"
                    style="width: 100%"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onActionDeselect(item.id)"
                    style="width: 100%"
                    v-show="extendedActions.includes(item.id)"
                    class="extend-action"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Audit Item Content (drops down) -->
            <div
              class="card-body"
              v-show="extendedActions.includes(item.id)"
              style="padding: 0 1rem 1rem 1rem"
            >
              <ul class="list-group">
                <li
                  style="cursor: pointer; display: block; margin-bottom: 1rem"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >What Will I Get From Doing This?</strong
                  >
                  &nbsp;
                  <div>
                    {{ item.motivation }}
                  </div>
                </li>

                <li
                  v-if="item.actionUrl != null && item.actionUrl != ''"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >More Info:</strong
                  >
                  &nbsp;
                  <div>
                    <a
                      v-if="
                        !item.actionUrl.includes(currentLocation) &&
                        !item.actionUrl.includes(localLocation)
                      "
                      target="__blank"
                      style="text-decoration: underline"
                      :href="item.actionUrl"
                    >
                      Visit Here
                    </a>
                    <router-link
                      v-if="
                        item.actionUrl.includes(currentLocation) ||
                        item.actionUrl.includes(localLocation)
                      "
                      style="text-decoration: underline"
                      :to="formatLocalUrl(item.actionUrl, item.id)"
                    >
                      Visit Here
                    </router-link>
                  </div>
                </li>
                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Due:</strong
                  >
                  &nbsp;
                  <div style="color: limegreen">
                    {{ formatDate(new Date(item.timeline.scheduledEnd)) }}
                  </div>
                </li>
                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Edit:
                  </strong>
                  &nbsp;
                  <svg
                    class="edit-action"
                    @click="openEditAction(item.id)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                    />
                  </svg>
                </li>
              </ul>
              <div
                class="card tasks-card"
                v-if="item.tasks.length > 0"
                :style="
                  extendedTaskCollections.includes(item.id)
                    ? 'padding-bottom: 1rem;'
                    : ''
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem;
                  "
                  :style="
                    extendedTaskCollections.includes(item.id)
                      ? 'margin-bottom: 0.5rem;'
                      : ''
                  "
                >
                  Tasks
                  <svg
                    @click="onTaskCollectionSelect(item.id)"
                    v-show="!extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onTaskCollectionDeselect(item.id)"
                    v-show="extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
                <div v-show="extendedTaskCollections.includes(item.id)">
                  <div
                    class="card h-100 open-action"
                    v-for="task in item.tasks"
                    :key="task.id"
                  >
                    <div
                      class="p-3 card-header"
                      :style="
                        extendedTasks.includes(task.id)
                          ? 'padding-bottom: 0;'
                          : 'border-radius: 1rem; padding-bottom: 1rem;'
                      "
                    >
                      <div
                        class="row"
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          flex-direction: row;
                        "
                      >
                        <h6 class="subject" style="width: fit-content">
                          {{ task.subject }}
                        </h6>
                        <svg
                          @click="onTaskSelect(task.id)"
                          v-show="!extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                          />
                        </svg>
                        <svg
                          @click="onTaskDeselect(task.id)"
                          v-show="extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      v-show="extendedTasks.includes(task.id)"
                      style="padding: 0 1rem 1rem 1rem"
                    >
                      <ul class="list-group">
                        <li
                          style="
                            cursor: pointer;
                            display: block;
                            margin-bottom: 1rem;
                          "
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >What Will I Get From Doing This?</strong
                          >
                          &nbsp;
                          <div>
                            {{ task.motivation }}
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >More Info:</strong
                          >
                          &nbsp;
                          <div>
                            <a
                              v-if="
                                !task.actionUrl.includes(currentLocation) &&
                                !task.actionUrl.includes(localLocation) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              target="__blank"
                              style="text-decoration: underline"
                              :href="task.actionUrl"
                            >
                              Visit Here
                            </a>
                            <router-link
                              v-if="
                                (task.actionUrl.includes(currentLocation) ||
                                  task.actionUrl.includes(localLocation)) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              style="text-decoration: underline"
                              :to="formatLocalUrl(task.actionUrl, task.id)"
                            >
                              Visit Here
                            </router-link>
                          </div>
                        </li>
                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Due:</strong
                          >
                          &nbsp;
                          <div style="color: limegreen">
                            {{
                              formatDate(new Date(task.timeline.scheduledEnd))
                            }}
                          </div>
                        </li>
                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Edit:
                          </strong>
                          &nbsp;
                          <svg
                            class="edit-action"
                            @click="openEditTask(task.id)"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                            />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- This uses 'page-change' and 'interface' events to communicate with the desktop pagination -->
          <TablePaginationVue
            :type="'Recommended'"
            :items="getNextActions"
            :buttons="4"
            @page-change="interfaces.tablePaginationDesktop.setPage"
            @interface="this.setMobilePaginationInterface"
            v-model="this.items"
          />
        </div>
      </div>
    </div>

    <!-- Open Actions -->
    <div>
      <!-- Desktop View -->
      <div
        class="table-responsive card desktop-logic"
        v-if="tableType == 'Current'"
        :id="id"
        style="padding: 0px 1rem"
      >
        <div class="overflow-x-scroll-l840">
          <div
            @click="
              () => {
                show = !show;
              }
            "
            style="
              font-size: 1.5rem;
              padding: 0.7rem;
              font-weight: 600;
              display: flex;
              align-items: center;
            "
          >
            Open Actions
            <svg
              v-show="!show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
              />
            </svg>
            <svg
              v-show="show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
              />
            </svg>
          </div>
          <div class="fit-content-l840" v-show="show">
            <div style="display: flex; flex-direction: row; margin-left: 1rem">
              <label style="font-size: medium; margin-right: 1rem"
                >Items Per Page:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <input
                    class="page-amount-input"
                    :value="currentPageLength"
                    @change="changePageLength($event.target.value)"
                  />
                  <svg
                    v-show="!showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="page-num-options" v-show="showPageAmountDrpDwn">
                  <div
                    v-for="index in Math.ceil(getActionAmount / pageIncrements)"
                    :key="index"
                    @click="
                      () => {
                        changePageLength(index * pageIncrements);
                        showPageAmountDrpDwn = false;
                      }
                    "
                  >
                    {{ index * pageIncrements }}
                  </div>
                </div>
              </div>
              <div style="position: absolute; right: 1rem">
                <button
                  v-if="ea && getIsEmailEnabled"
                  class="btn"
                  style="
                    margin: 0 1rem 0 auto;
                    background-color: white;
                    padding: 0.23rem 0.8rem;
                    font-size: 0.9rem;
                  "
                  @click="showEmailModal = true"
                >
                  <svg
                    style="width: 1.2rem; height: 1rem; margin-right: 0.2rem"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                    />
                  </svg>
                  Email selected action(s)
                </button>
                <button
                  class="btn"
                  style="
                    margin-bottom: 0;
                    background-color: white;
                    padding: 0.23rem 0.8rem;
                    font-size: 0.9rem;
                  "
                  @click="openCreateNewAction()"
                >
                  <svg
                    style="width: 1rem; margin-right: 0.2rem"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                    />
                  </svg>
                  Create new action
                </button>
              </div>
            </div>
            <div style="margin-bottom: 1vh; display: flex; flex-direction: row">
              <table
                style="height: fit-content; table-layout: fixed; width: 100%"
                id="datatable-basic"
                class="table table-flush"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      v-if="getIsEmailEnabled"
                      style="width: 4%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Select
                    </th>
                    <th
                      style="width: 8%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-if="ea"
                    >
                      Options
                    </th>
                    <th
                      style="width: 14%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                    <th
                      style="width: 18%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      What I will get from doing this?
                    </th>
                    <th
                      style="width: 8.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      More Info
                    </th>

                    <th
                      v-if="getIsCreatedOnEnabled"
                      style="width: 8.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Created on
                    </th>
                    <th
                      style="width: 8.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Due
                    </th>
                    <th
                      style="width: 9%"
                      v-if="ea"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Mandation
                    </th>
                    <th
                      style="width: 8.5%"
                      v-if="ea && getIsCompletedByCustomerEnabled"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Completed By<br />
                      Customer
                    </th>
                    <th
                      style="width: 8.5%"
                      v-if="!ea"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Completed
                    </th>
                    <th
                      :style="!ea ? 'width: 8.5%;' : '7%'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Tasks
                    </th>
                    <th
                      :style="!ea ? 'width: 9.5%;' : '7%'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-if="ea"
                    >
                      Edit Action
                    </th>
                    <th
                      :style="!ea ? 'width: 9.5%;' : '7%'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Progress Updates
                    </th>
                  </tr>
                </thead>
                <tbody
                  style="border-top: 1px solid #e9ecef"
                  v-for="item in this.items"
                  :key="item.id"
                >
                  <tr
                    title="Right click for More Options"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :id="item.id"
                    class="action"
                    @mousedown="
                      ($event) => {
                        showContextMenu($event, item, false, 'current', false);
                      }
                    "
                  >
                    <td
                      v-if="getIsEmailEnabled && item.tasks.length == 0"
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                    >
                      <input
                        type="checkbox"
                        name="selectedAction"
                        :value="item"
                        v-model="checkedRows"
                        @click="getCheckedItems()"
                      />
                    </td>
                    <td v-else-if="getIsEmailEnabled"></td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                      v-if="
                        item.classification.ItemTypeCode ==
                        'FedcapCARES Group Appointment'
                      "
                    ></td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                      v-if="
                        item.classification.ItemTypeCode !=
                        'FedcapCARES Group Appointment'
                      "
                    >
                      <svg
                        @click="
                          ($event) => {
                            showContextMenu(
                              $event,
                              item,
                              false,
                              'current',
                              true
                            );
                          }
                        "
                        class="open-context-Menu-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 128 512"
                      >
                        <path
                          d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                        />
                      </svg>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      stye="padding: 0.75rem 1.5rem;"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          !item.actionUrl.includes(currentLocation) &&
                          !item.actionUrl.includes(localLocation) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="item.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          (item.actionUrl.includes(currentLocation) ||
                            item.actionUrl.includes(localLocation)) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(item.actionUrl, item.id)"
                      >
                        Visit Here
                      </router-link>
                    </td>
                    <td
                      v-if="getIsCreatedOnEnabled"
                      style="padding: 0.75rem 1.5rem; color: limegreen"
                      class="text-sm font-weight-normal"
                    >
                      {{ formatDate(new Date(item.audit.createdOn)) }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; color: limegreen"
                      class="text-sm font-weight-normal"
                    >
                      {{ formatDate(new Date(item.timeline.scheduledEnd)) }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea && !getIsMandationEnabled"
                    >
                      <label
                        class="switch mandation-switch"
                        v-if="item.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch mandation-switch"
                        v-if="!item.isMandated"
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                      <!-- <label class="switch mandation-switch" v-if="!item.isMandated">
                        <input type="checkbox" @change="UpdateActionMandated(item.id, $event.target.checked, true)">
                        <span class="slider round"></span>
                      </label> -->
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; border-bottom: none"
                      class="text-sm font-weight-normal d-flex flex-column"
                      v-if="ea && getIsMandationEnabled"
                    >
                      <label
                        class="switch unactive-madated-switch"
                        v-if="item.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch mandation-switch"
                        @click="
                          displayMandationPopup(
                            true,
                            item,
                            item.timeline.scheduledEnd
                          )
                        "
                        v-show="!item.isMandated"
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                      <a
                        v-if="item.isMandated"
                        :href="
                          (getIsCustomerUC
                            ? getUCManLink
                            : getJSAManLink
                          ).replace('{actionId}', item.id)
                        "
                      >
                        <svg
                          class="show-saved-man"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                          />
                        </svg>
                        <p style="display: flex">View Mandation</p>
                      </a>
                      <!-- <svg
                        v-if="
                          item.state.stateCode == 'PendingComplete' ||
                          item.state.stateCode == 'Complete'
                        "
                        class="show-saved-man"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        @click="openRelated603Form(item)"
                      >
                        <path
                          d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                        />
                      </svg> -->
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea && getIsCompletedByCustomerEnabled"
                    >
                      <label
                        class="switch switch-unactive"
                        v-if="
                          item.state.stateCode == 'PendingComplete' ||
                          item.state.stateCode == 'Complete'
                        "
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch switch-unactive"
                        v-if="
                          item.state.stateCode != 'PendingComplete' &&
                          item.state.stateCode != 'Complete'
                        "
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="!ea && getIsCompletedByCustomerEnabled"
                    >
                      <label
                        class="switch"
                        v-if="
                          item.state.stateCode == 'PendingComplete' ||
                          item.state.stateCode == 'Complete'
                        "
                        @click="UpdateCompletedByCustomer(item.id, null, false)"
                      >
                        <input
                          type="checkbox"
                          :id="'pendingComplete' + item.id"
                          disabled
                          checked
                        />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch"
                        v-if="
                          item.state.stateCode != 'PendingComplete' &&
                          item.state.stateCode != 'Complete'
                        "
                        @click="UpdateCompletedByCustomer(item.id, null, false)"
                      >
                        <input
                          type="checkbox"
                          :id="'pendingComplete' + item.id"
                          disabled
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td style="padding: 0.75rem 1.5rem">
                      <svg
                        v-if="item.tasks.length > 0"
                        class="show-tasks"
                        @click="toggleTaskDisplay(item.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M152.1 38.16C161.9 47.03 162.7 62.2 153.8 72.06L81.84 152.1C77.43 156.9 71.21 159.8 64.63 159.1C58.05 160.2 51.69 157.6 47.03 152.1L7.029 112.1C-2.343 103.6-2.343 88.4 7.029 79.03C16.4 69.66 31.6 69.66 40.97 79.03L63.08 101.1L118.2 39.94C127 30.09 142.2 29.29 152.1 38.16V38.16zM152.1 198.2C161.9 207 162.7 222.2 153.8 232.1L81.84 312.1C77.43 316.9 71.21 319.8 64.63 319.1C58.05 320.2 51.69 317.6 47.03 312.1L7.029 272.1C-2.343 263.6-2.343 248.4 7.029 239C16.4 229.7 31.6 229.7 40.97 239L63.08 261.1L118.2 199.9C127 190.1 142.2 189.3 152.1 198.2V198.2zM224 96C224 78.33 238.3 64 256 64H480C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H256C238.3 128 224 113.7 224 96V96zM224 256C224 238.3 238.3 224 256 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H256C238.3 288 224 273.7 224 256zM160 416C160 398.3 174.3 384 192 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416zM0 416C0 389.5 21.49 368 48 368C74.51 368 96 389.5 96 416C96 442.5 74.51 464 48 464C21.49 464 0 442.5 0 416z"
                        />
                      </svg>
                      <span
                        v-if="item.tasks.length == 0"
                        style="font-size: 0.9rem"
                        >No Tasks</span
                      >
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                      v-if="
                        item.classification.ItemTypeCode ==
                        'FedcapCARES Group Appointment'
                      "
                    ></td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="
                        ea &&
                        item.classification.ItemTypeCode !=
                          'FedcapCARES Group Appointment'
                      "
                    >
                      <svg
                        class="edit-action"
                        @click="openEditAction(item.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                        />
                      </svg>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <svg
                        class="open-memos"
                        @click="
                          () => {
                            setIdValues(item.id, '');
                            selectTableRow(item.id, 'Current');
                            createMemo('Current');
                          }
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          :style="{
                            fill: item.memos.length > 0 ? 'limeGreen' : '',
                          }"
                          d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr
                    v-if="item.tasks.length == 0"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td
                      :colspan="ea ? '9' : '8'"
                      style="padding: 0.75rem 1.5rem"
                    >
                      <span style="margin-left: 2rem">No Tasks</span>
                    </td>
                  </tr>
                  <tr
                    v-for="task in item.tasks"
                    :key="task.id"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td
                      v-if="getIsEmailEnabled && item.tasks.length > 0"
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                    >
                      <input
                        type="checkbox"
                        name="selectedAction"
                        :value="task"
                        v-model="checkedRows"
                        :onclick="this.getCheckedItems()"
                      />
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    ></td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          !task.actionUrl.includes(currentLocation) &&
                          !task.actionUrl.includes(localLocation) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="task.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          (task.actionUrl.includes(currentLocation) ||
                            task.actionUrl.includes(localLocation)) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(task.actionUrl, task.id)"
                      >
                        Visit Here
                      </router-link>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; color: limegreen"
                      class="text-sm font-weight-normal"
                    >
                      {{ formatDate(new Date(task.audit.createdOn)) }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; color: limegreen"
                      class="text-sm font-weight-normal"
                    >
                      {{ formatDate(new Date(task.timeline.scheduledEnd)) }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <label
                        class="switch unactive-madated-switch"
                        v-if="task.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch unactive-madated-switch"
                        v-if="!task.isMandated"
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <label
                        class="switch switch-unactive"
                        v-if="
                          task.state.stateCode == 'PendingComplete' ||
                          task.state.stateCode == 'Complete'
                        "
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch switch-unactive"
                        v-if="
                          task.state.stateCode != 'PendingComplete' &&
                          task.state.stateCode != 'Complete'
                        "
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="!ea"
                    >
                      <label
                        class="switch"
                        v-if="
                          task.state.stateCode == 'PendingComplete' ||
                          task.state.stateCode == 'Complete'
                        "
                        @click="
                          UpdateCompletedByCustomer(task.id, item.id, true)
                        "
                      >
                        <input
                          type="checkbox"
                          :id="'pendingComplete' + task.id"
                          disabled
                          checked
                        />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch"
                        v-if="
                          task.state.stateCode != 'PendingComplete' &&
                          task.state.stateCode != 'Complete'
                        "
                        @click="
                          UpdateCompletedByCustomer(task.id, item.id, true)
                        "
                      >
                        <input
                          type="checkbox"
                          :id="'pendingComplete' + task.id"
                          disabled
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td></td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <svg
                        class="edit-action"
                        @click="openEditTask(task.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                        />
                      </svg>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <svg
                        class="open-memos"
                        @click="
                          () => {
                            setIdValues(item.id, task.id);
                            selectTableRow(task.id, 'Current');
                            createMemo('Current');
                          }
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
            <TablePaginationVue
              :type="'Open'"
              :items="getNextActions"
              :buttons="4"
              @page-change="interfaces.tablePaginationMobile.setPage"
              @interface="this.setDesktopPaginationInterface"
              v-model="this.items"
            />
          </div>
        </div>
      </div>

      <!-- Mobile View -->
      <div
        class="card open-action-cont open-actions-card mobile-logic"
        v-if="tableType == 'Current'"
      >
        <!-- Table Header -->
        <div
          @click="
            () => {
              show = !show;
            }
          "
          style="
            font-size: 1.5rem;
            padding: 0.7rem;
            font-weight: 600;
            display: flex;
            align-items: center;
          "
        >
          Open Actions
          <svg
            v-show="!show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
            />
          </svg>
          <svg
            v-show="show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
            />
          </svg>
        </div>

        <!-- Table content (drops down) -->
        <div v-show="show">
          <!-- Search Filters -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              padding: 0 0.7rem 0.3rem 0.7rem;
            "
          >
            <div style="margin-right: 1.5rem; display: block">
              <button
                v-if="ea"
                style="
                  margin: 0 1rem 1rem auto;
                  background-color: white;
                  padding: 0.23rem 0.8rem;
                  font-size: 0.9rem;
                "
                class="btn"
                @click="openCreateNewAction()"
              >
                <svg
                  style="width: 1rem; margin-right: 0.2rem"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                  />
                </svg>
                Create new action
              </button>

              <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <label
                  style="font-size: medium; margin-right: 1rem; width: 8rem"
                  >Items Per Page:</label
                >
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; flex-direction: row">
                    <input
                      class="page-amount-input"
                      :value="currentPageLength"
                      @change="changePageLength($event.target.value)"
                    />
                    <svg
                      v-show="!showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = true"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                      />
                    </svg>
                    <svg
                      v-show="showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = false"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                      />
                    </svg>
                  </div>
                  <div class="page-num-options" v-show="showPageAmountDrpDwn">
                    <div
                      v-for="index in Math.ceil(
                        getActionAmount / pageIncrements
                      )"
                      :key="index"
                      @click="
                        () => {
                          changePageLength(index * pageIncrements);
                          showPageAmountDrpDwn = false;
                        }
                      "
                    >
                      {{ index * pageIncrements }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Audit Item -->
          <div
            class="card h-100 open-action"
            v-for="(item, index) in this.items"
            :key="index"
            title="Right click for More Options"
            @mousedown="
              ($event) => {
                showContextMenu($event, item, false, 'current', false);
              }
            "
          >
            <div class="p-3 card-header" :style="'padding-bottom: 0;'">
              <div style="display: flex; flex-direction: row">
                <div style="margin-right: 1.5rem">
                  <svg
                    @click="
                      ($event) => {
                        showContextMenu($event, item, false, 'current', true);
                      }
                    "
                    class="open-context-Menu-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                    />
                  </svg>
                </div>

                <div style="overflow: hidden; display: block; width: 100%">
                  <h6 class="subject" style="width: 100%">
                    {{ item.subject }}
                  </h6>
                  <h6 style="width: 100%; color: #67748e"></h6>
                </div>

                <div style="width: 24px; height: 100%">
                  <svg
                    @click="onActionSelect(item.id)"
                    v-show="!extendedActions.includes(item.id)"
                    class="extend-action"
                    style="width: 100%"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onActionDeselect(item.id)"
                    style="width: 100%"
                    v-show="extendedActions.includes(item.id)"
                    class="extend-action"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Audit Item Content (drops down) -->
            <div
              class="card-body"
              v-show="extendedActions.includes(item.id)"
              style="padding: 0 1rem 1rem 1rem"
            >
              <ul class="list-group">
                <li
                  style="cursor: pointer; display: block; margin-bottom: 1rem"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >What Will I Get From Doing This?</strong
                  >
                  &nbsp;
                  <div>
                    {{ item.motivation }}
                  </div>
                </li>

                <li
                  v-if="item.actionUrl != null && item.actionUrl != ''"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >More Info:</strong
                  >
                  &nbsp;
                  <div>
                    <a
                      v-if="
                        !item.actionUrl.includes(currentLocation) &&
                        !item.actionUrl.includes(localLocation)
                      "
                      target="__blank"
                      style="text-decoration: underline"
                      :href="item.actionUrl"
                    >
                      Visit Here
                    </a>
                    <router-link
                      v-if="
                        item.actionUrl.includes(currentLocation) ||
                        item.actionUrl.includes(localLocation)
                      "
                      style="text-decoration: underline"
                      :to="formatLocalUrl(item.actionUrl, item.id)"
                    >
                      Visit Here
                    </router-link>
                  </div>
                </li>

                <li
                  v-if="getIsCreatedOnEnabled"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Created:</strong
                  >
                  &nbsp;
                  <div style="color: limegreen">
                    {{ formatDate(new Date(item.audit.createdOn)) }}
                  </div>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Due:</strong
                  >
                  &nbsp;
                  <div style="color: limegreen">
                    {{ formatDate(new Date(item.timeline.scheduledEnd)) }}
                  </div>
                </li>

                <li
                  v-if="!getIsMandationEnabled"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Mandation:</strong
                  >
                  &nbsp;

                  <label
                    class="switch mandation-switch"
                    style="margin-bottom: 0px"
                    v-if="item.isMandated"
                  >
                    <input type="checkbox" disabled checked />
                    <span class="slider round"></span>
                  </label>
                  <label
                    class="switch mandation-switch"
                    style="margin-bottom: 0px"
                    v-if="!item.isMandated"
                  >
                    <input type="checkbox" disabled />
                    <span class="slider round"></span>
                  </label>
                </li>

                <li
                  v-if="getIsMandationEnabled"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Mandation:</strong
                  >
                  &nbsp;

                  <label
                    class="switch unactive-madated-switch"
                    style="margin-bottom: 0px"
                    v-if="item.isMandated"
                  >
                    <input type="checkbox" disabled checked />
                    <span class="slider round"></span>
                  </label>
                  <label
                    class="switch mandation-switch"
                    @click="
                      displayMandationPopup(
                        true,
                        item,
                        item.timeline.scheduledEnd
                      )
                    "
                    v-show="!item.isMandated"
                  >
                    <input type="checkbox" disabled />
                    <span class="slider round"></span>
                  </label>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Completed By Customer:</strong
                  >
                  &nbsp;

                  <label
                    class="switch switch-unactive"
                    v-if="
                      item.state.stateCode == 'PendingComplete' ||
                      item.state.stateCode == 'Complete'
                    "
                  >
                    <input type="checkbox" disabled checked />
                    <span class="slider round"></span>
                  </label>
                  <label
                    class="switch switch-unactive"
                    v-if="
                      item.state.stateCode != 'PendingComplete' &&
                      item.state.stateCode != 'Complete'
                    "
                  >
                    <input type="checkbox" disabled />
                    <span class="slider round"></span>
                  </label>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Edit:
                  </strong>
                  &nbsp;
                  <svg
                    class="edit-action"
                    @click="openEditAction(item.id)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                    />
                  </svg>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Progress Updates:
                  </strong>
                  &nbsp;
                  <svg
                    class="open-memos"
                    @click="
                      () => {
                        setIdValues(item.id, '');
                        selectTableRow(item.id, 'Current');
                        createMemo('Current');
                      }
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                    />
                  </svg>
                </li>
              </ul>
              <div
                class="card tasks-card"
                v-if="item.tasks.length > 0"
                :style="
                  extendedTaskCollections.includes(item.id)
                    ? 'padding-bottom: 1rem;'
                    : ''
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem;
                  "
                  :style="
                    extendedTaskCollections.includes(item.id)
                      ? 'margin-bottom: 0.5rem;'
                      : ''
                  "
                >
                  Tasks
                  <svg
                    @click="onTaskCollectionSelect(item.id)"
                    v-show="!extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onTaskCollectionDeselect(item.id)"
                    v-show="extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
                <div v-show="extendedTaskCollections.includes(item.id)">
                  <div
                    class="card h-100 open-action"
                    v-for="task in item.tasks"
                    :key="task.id"
                  >
                    <div
                      class="p-3 card-header"
                      :style="
                        extendedTasks.includes(task.id)
                          ? 'padding-bottom: 0;'
                          : 'border-radius: 1rem; padding-bottom: 1rem;'
                      "
                    >
                      <div
                        class="row"
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          flex-direction: row;
                        "
                      >
                        <h6 class="subject" style="width: fit-content">
                          {{ task.subject }}
                        </h6>
                        <svg
                          @click="onTaskSelect(task.id)"
                          v-show="!extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                          />
                        </svg>
                        <svg
                          @click="onTaskDeselect(task.id)"
                          v-show="extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      v-show="extendedTasks.includes(task.id)"
                      style="padding: 0 1rem 1rem 1rem"
                    >
                      <ul class="list-group">
                        <li
                          style="
                            cursor: pointer;
                            display: block;
                            margin-bottom: 1rem;
                          "
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >What Will I Get From Doing This?</strong
                          >
                          &nbsp;
                          <div>
                            {{ task.motivation }}
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >More Info:</strong
                          >
                          &nbsp;
                          <div>
                            <a
                              v-if="
                                !task.actionUrl.includes(currentLocation) &&
                                !task.actionUrl.includes(localLocation) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              target="__blank"
                              style="text-decoration: underline"
                              :href="task.actionUrl"
                            >
                              Visit Here
                            </a>
                            <router-link
                              v-if="
                                (task.actionUrl.includes(currentLocation) ||
                                  task.actionUrl.includes(localLocation)) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              style="text-decoration: underline"
                              :to="formatLocalUrl(task.actionUrl, task.id)"
                            >
                              Visit Here
                            </router-link>
                          </div>
                        </li>
                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Created:</strong
                          >
                          &nbsp;
                          <div style="color: limegreen">
                            {{ formatDate(new Date(task.audit.createdOn)) }}
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Due:</strong
                          >
                          &nbsp;
                          <div style="color: limegreen">
                            {{
                              formatDate(new Date(task.timeline.scheduledEnd))
                            }}
                          </div>
                        </li>
                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Mandation:</strong
                          >
                          &nbsp;

                          <label
                            class="switch unactive-madated-switch"
                            style="margin-bottom: 0px"
                            v-if="task.isMandated"
                          >
                            <input type="checkbox" disabled checked />
                            <span class="slider round"></span>
                          </label>
                          <label
                            class="switch mandation-switch"
                            style="margin-bottom: 0px"
                            v-if="!task.isMandated"
                          >
                            <input type="checkbox" disabled />
                            <span class="slider round"></span>
                          </label>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Completed By Customer:</strong
                          >
                          &nbsp;

                          <label
                            class="switch switch-unactive"
                            v-if="
                              task.state.stateCode == 'PendingComplete' ||
                              task.state.stateCode == 'Complete'
                            "
                          >
                            <input type="checkbox" disabled checked />
                            <span class="slider round"></span>
                          </label>
                          <label
                            class="switch switch-unactive"
                            v-if="
                              task.state.stateCode != 'PendingComplete' &&
                              task.state.stateCode != 'Complete'
                            "
                          >
                            <input type="checkbox" disabled />
                            <span class="slider round"></span>
                          </label>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Edit:
                          </strong>
                          &nbsp;
                          <svg
                            class="edit-action"
                            @click="openEditTask(task.id)"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                            />
                          </svg>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Progress Updates:
                          </strong>
                          &nbsp;
                          <svg
                            class="open-memos"
                            @click="
                              () => {
                                setIdValues(item.id, task.id);
                                selectTableRow(task.id, 'Current');
                                createMemo('Current');
                              }
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                            />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- This uses 'page-change' and 'interface' events to communicate with the desktop pagination -->
          <TablePaginationVue
            :type="'Open'"
            :items="getNextActions"
            :buttons="4"
            @page-change="interfaces.tablePaginationDesktop.setPage"
            @interface="this.setMobilePaginationInterface"
            v-model="this.items"
          />
        </div>
      </div>
    </div>

    <!-- Completed Actions -->
    <div>
      <!-- Desktop View -->
      <div
        class="table-responsive card desktop-logic"
        v-if="tableType == 'Completed'"
        :id="id"
        style="padding: 0px 1rem"
      >
        <div class="overflow-x-scroll-l840">
          <div
            @click="
              () => {
                show = !show;
              }
            "
            style="
              font-size: 1.5rem;
              padding: 0.7rem;
              font-weight: 600;
              display: flex;
              align-items: center;
            "
          >
            Completed Actions
            <svg
              v-show="!show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
              />
            </svg>
            <svg
              v-show="show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
              />
            </svg>
          </div>
          <div class="fit-content-l840" v-show="show">
            <div style="display: flex; flex-direction: row; margin-left: 1rem">
              <label style="font-size: medium; margin-right: 1rem"
                >Items Per Page:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <input
                    class="page-amount-input"
                    :value="currentPageLength"
                    @change="changePageLength($event.target.value)"
                  />
                  <svg
                    v-show="!showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="page-num-options" v-show="showPageAmountDrpDwn">
                  <div
                    v-for="index in Math.ceil(getActionAmount / pageIncrements)"
                    :key="index"
                    @click="
                      () => {
                        changePageLength(index * pageIncrements);
                        showPageAmountDrpDwn = false;
                      }
                    "
                  >
                    {{ index * pageIncrements }}
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 1vh; display: flex; flex-direction: row">
              <table
                style="height: fit-content; table-layout: fixed; width: 100%"
                id="datatable-basic"
                class="table table-flush"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      :style="ea ? 'width: 24.75%;' : 'width: 29%;'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                    <th
                      :style="ea ? 'width: 24.75%;' : 'width: 29%;'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      What I will get from doing this?
                    </th>
                    <th
                      :style="ea ? 'width: 11%;' : 'width: 11%;'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      More Info
                    </th>
                    <th
                      v-if="getIsCreatedOnEnabled"
                      :style="ea ? 'width: 11%;' : 'width: 11%;'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Created On
                    </th>
                    <th
                      :style="ea ? 'width: 11%;' : 'width: 11%;'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Completed
                    </th>
                    <th
                      style="width: 9.5%"
                      v-if="ea"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Mandation
                    </th>
                    <th
                      :style="ea ? 'width: 9.5%;' : 'width: 10%;'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Tasks
                    </th>
                    <th
                      :style="ea ? 'width: 9.5%;' : 'width: 10%;'"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Progress Updates
                    </th>
                  </tr>
                </thead>
                <tbody
                  style="border-top: 1px solid #e9ecef"
                  v-for="item in this.items"
                  :key="item.id"
                >
                  <tr
                    title="Right click for More Options"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :id="item.id"
                    class="action"
                    @mousedown="
                      ($event) => {
                        showContextMenu($event, item, true, 'completed', false);
                      }
                    "
                  >
                    <td
                      style="padding: 0.75rem 1.5rem; word-break: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-break: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      stye="padding: 0.75rem 1.5rem;"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          item.creationContext != undefinded &&
                          item.creationContext.action != 'themedAssessment' &&
                          !item.actionUrl.includes(currentLocation) &&
                          !item.actionUrl.includes(localLocation) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="item.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          item.creationContext != undefinded &&
                          item.creationContext.action != 'themedAssessment' &&
                          (item.actionUrl.includes(currentLocation) ||
                            item.actionUrl.includes(localLocation)) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(item.actionUrl, item.id)"
                      >
                        Visit Here
                      </router-link>
                      <div v-if="item.isMandated && getIs603Enabled">
                        <svg
                          class="fill-603"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          @click="openRelated603Form(item)"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"
                          />
                        </svg>
                        <p>Open 603 Form</p>
                        <a
                          v-if="
                            getIs603Enabled &&
                            item.isMandated &&
                            item.mandationRecord != null &&
                            item.mandationRecord.dwp603Records.length > 0
                          "
                          style="width: fit-content"
                          :href="
                            (getIsCustomerUC
                              ? getViewUC603Link
                              : getViewJSA603Link
                            ).replace('{actionId}', item.id)
                          "
                        >
                          <svg
                            style="margin-top: none"
                            class="view-603-doc"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          >
                            <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                              d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
                            />
                          </svg>
                          <p style="display: flex">View 603</p>
                        </a>
                      </div>
                      <div
                        v-if="item.isMandated && getIs604Enabled"
                        class="d-flex flex-column"
                        style="padding-top: 8px"
                      >
                        <svg
                          v-if="
                            getIs603Enabled &&
                            item.isMandated &&
                            item.mandationRecord != null &&
                            item.mandationRecord.dwp603Records.length > 0
                          "
                          class="fill-604"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          @click="openRelated604Form(item)"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
                          />
                        </svg>
                        <p style="margin-bottom: 4px">Open 604 Form</p>
                        <a
                          v-if="
                            getIs604Enabled &&
                            item.isMandated &&
                            item.mandationRecord != null &&
                            item.mandationRecord.dwp604Records.length > 0
                          "
                          style="width: fit-content"
                          :href="getView604Link.replace('{actionId}', item.id)"
                        >
                          <svg
                            class="view-604-doc"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          >
                            <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                              d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
                            />
                          </svg>
                          <p style="display: flex">View 604</p>
                        </a>
                      </div>
                    </td>
                    <td
                      v-if="getIsCreatedOnEnabled"
                      style="padding: 0.75rem 1.5rem; color: limegreen"
                      class="text-sm font-weight-normal"
                    >
                      {{ formatDate(new Date(item.audit.createdOn)) }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; color: limegreen"
                      class="text-sm font-weight-normal"
                      v-if="
                        item.timeline.actualEnd != '' &&
                        item.timeline.actualEnd != null
                      "
                    >
                      {{ formatDate(new Date(item.timeline.actualEnd)) }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      v-if="
                        item.timeline.actualEnd == '' ||
                        item.timeline.actualEnd == null
                      "
                      class="text-sm font-weight-normal"
                    >
                      N/A
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; border-bottom: none"
                      class="text-sm font-weight-normal d-flex flex-column"
                      v-if="ea"
                    >
                      <label
                        class="switch unactive-madated-switch"
                        v-if="item.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch unactive-madated-switch"
                        v-if="!item.isMandated"
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                      <svg
                        v-if="item.isMandated && getIs603Enabled"
                        class="fill-603"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        @click="openRelated603Form(item)"
                      >
                        <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                        />
                      </svg>
                      <a
                        v-if="
                          getIs603Enabled &&
                          item.isMandated &&
                          item.mandationRecord != null &&
                          item.mandationRecord.dwp603Records.length > 0
                        "
                        style="width: fit-content"
                        :href="
                          (getIsCustomerUC
                            ? getViewUC603Link
                            : getViewJSA603Link
                          ).replace('{actionId}', item.id)
                        "
                      >
                        <svg
                          class="view-mandation"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                        >
                          <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
                          />
                        </svg>
                        <p style="display: flex">View 603</p>
                      </a>
                      <a
                        v-if="item.isMandated && getIsMandationEnabled"
                        :href="
                          (getIsCustomerUC
                            ? getUCManLink
                            : getJSAManLink
                          ).replace('{actionId}', item.id)
                        "
                      >
                        <svg
                          class="show-saved-man"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                          />
                        </svg>
                        <p style="display: flex">View Mandation</p>
                      </a>
                    </td>
                    <td style="padding: 0.75rem 1.5rem">
                      <svg
                        v-if="item.tasks.length > 0"
                        class="show-tasks"
                        @click="toggleTaskDisplay(item.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M152.1 38.16C161.9 47.03 162.7 62.2 153.8 72.06L81.84 152.1C77.43 156.9 71.21 159.8 64.63 159.1C58.05 160.2 51.69 157.6 47.03 152.1L7.029 112.1C-2.343 103.6-2.343 88.4 7.029 79.03C16.4 69.66 31.6 69.66 40.97 79.03L63.08 101.1L118.2 39.94C127 30.09 142.2 29.29 152.1 38.16V38.16zM152.1 198.2C161.9 207 162.7 222.2 153.8 232.1L81.84 312.1C77.43 316.9 71.21 319.8 64.63 319.1C58.05 320.2 51.69 317.6 47.03 312.1L7.029 272.1C-2.343 263.6-2.343 248.4 7.029 239C16.4 229.7 31.6 229.7 40.97 239L63.08 261.1L118.2 199.9C127 190.1 142.2 189.3 152.1 198.2V198.2zM224 96C224 78.33 238.3 64 256 64H480C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H256C238.3 128 224 113.7 224 96V96zM224 256C224 238.3 238.3 224 256 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H256C238.3 288 224 273.7 224 256zM160 416C160 398.3 174.3 384 192 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416zM0 416C0 389.5 21.49 368 48 368C74.51 368 96 389.5 96 416C96 442.5 74.51 464 48 464C21.49 464 0 442.5 0 416z"
                        />
                      </svg>
                      <span
                        v-if="item.tasks.length == 0"
                        style="font-size: 0.9rem"
                        >No Tasks</span
                      >
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <svg
                        class="open-memos"
                        @click="
                          () => {
                            setIdValues(item.id, '');
                            selectTableRow(item.id, 'Completed');
                            createMemo('Completed');
                          }
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr
                    v-if="item.tasks.length == 0"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td colspan="8" style="padding: 0.75rem 1.5rem">
                      <span>No Tasks</span>
                    </td>
                  </tr>
                  <tr
                    v-for="task in item.tasks"
                    :key="task.id"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          task.creationContext != undefinded &&
                          task.creationContext.action != 'themedAssessment' &&
                          !task.actionUrl.includes(currentLocation) &&
                          !task.actionUrl.includes(localLocation) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="task.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          task.creationContext != undefinded &&
                          task.creationContext.action != 'themedAssessment' &&
                          (task.actionUrl.includes(currentLocation) ||
                            task.actionUrl.includes(localLocation)) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(task.actionUrl, task.id)"
                      >
                        Visit Here
                      </router-link>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="
                        task.timeline.actualEnd != '' &&
                        task.timeline.actualEnd != null
                      "
                    >
                      {{ formatDate(new Date(task.timeline.actualEnd)) }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      v-if="
                        task.timeline.actualEnd == '' ||
                        task.timeline.actualEnd == null
                      "
                      class="text-sm font-weight-normal"
                    >
                      N/A
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <label
                        class="switch unactive-madated-switch"
                        v-if="task.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch unactive-madated-switch"
                        v-if="!task.isMandated"
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td></td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <svg
                        class="open-memos"
                        @click="
                          () => {
                            setIdValues(item.id, task.id);
                            selectTableRow(task.id, 'Completed');
                            createMemo('Completed');
                          }
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
            <TablePaginationVue
              :type="'Completed'"
              :items="getNextActions"
              :buttons="4"
              @page-change="interfaces.tablePaginationMobile.setPage"
              @interface="this.setDesktopPaginationInterface"
              v-model="this.items"
            />
          </div>
        </div>
      </div>

      <!-- Mobile View -->
      <div
        class="card open-action-cont open-actions-card mobile-logic"
        v-if="tableType == 'Completed'"
      >
        <!-- Table Header -->
        <div
          @click="
            () => {
              show = !show;
            }
          "
          style="
            font-size: 1.5rem;
            padding: 0.7rem;
            font-weight: 600;
            display: flex;
            align-items: center;
          "
        >
          Completed Actions
          <svg
            v-show="!show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
            />
          </svg>
          <svg
            v-show="show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
            />
          </svg>
        </div>

        <!-- Table content (drops down) -->
        <div v-show="show">
          <!-- Search Filters -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              padding: 0 0.7rem 0.3rem 0.7rem;
            "
          >
            <div>
              <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <label
                  style="font-size: medium; margin-right: 1rem; width: 8rem"
                  >Items Per Page:</label
                >
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; flex-direction: row">
                    <input
                      class="page-amount-input"
                      :value="currentPageLength"
                      @change="changePageLength($event.target.value)"
                    />
                    <svg
                      v-show="!showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = true"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                      />
                    </svg>
                    <svg
                      v-show="showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = false"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                      />
                    </svg>
                  </div>
                  <div class="page-num-options" v-show="showPageAmountDrpDwn">
                    <div
                      v-for="index in Math.ceil(
                        getActionAmount / pageIncrements
                      )"
                      :key="index"
                      @click="
                        () => {
                          changePageLength(index * pageIncrements);
                          showPageAmountDrpDwn = false;
                        }
                      "
                    >
                      {{ index * pageIncrements }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Audit Item -->
          <div
            class="card h-100 open-action"
            v-for="(item, index) in this.items"
            :key="index"
            @mousedown="
              ($event) => {
                showContextMenu($event, item, true, 'completed', false);
              }
            "
          >
            <div class="p-3 card-header" :style="'padding-bottom: 0;'">
              <div style="display: flex; flex-direction: row">
                <div style="overflow: hidden; display: block; width: 100%">
                  <h6 class="subject" style="width: 100%">
                    {{ item.subject }}
                  </h6>
                  <h6 style="width: 100%; color: #67748e"></h6>
                </div>

                <div style="width: 24px; height: 100%">
                  <svg
                    @click="onActionSelect(item.id)"
                    v-show="!extendedActions.includes(item.id)"
                    class="extend-action"
                    style="width: 100%"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onActionDeselect(item.id)"
                    style="width: 100%"
                    v-show="extendedActions.includes(item.id)"
                    class="extend-action"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Audit Item Content (drops down) -->
            <div
              class="card-body"
              v-show="extendedActions.includes(item.id)"
              style="padding: 0 1rem 1rem 1rem"
            >
              <ul class="list-group">
                <li
                  style="cursor: pointer; display: block; margin-bottom: 1rem"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >What Will I Get From Doing This?</strong
                  >
                  &nbsp;
                  <div>
                    {{ item.motivation }}
                  </div>
                </li>

                <li
                  v-if="item.actionUrl != null && item.actionUrl != ''"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >More Info:</strong
                  >
                  &nbsp;
                  <div>
                    <a
                      v-if="
                        !item.actionUrl.includes(currentLocation) &&
                        !item.actionUrl.includes(localLocation)
                      "
                      target="__blank"
                      style="text-decoration: underline"
                      :href="item.actionUrl"
                    >
                      Visit Here
                    </a>
                    <router-link
                      v-if="
                        item.actionUrl.includes(currentLocation) ||
                        item.actionUrl.includes(localLocation)
                      "
                      style="text-decoration: underline"
                      :to="formatLocalUrl(item.actionUrl, item.id)"
                    >
                      Visit Here
                    </router-link>
                  </div>
                </li>

                <li
                  v-if="item.actionUrl != null && item.actionUrl != ''"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Completed:</strong
                  >
                  &nbsp;

                  <div
                    v-if="
                      item.timeline.actualEnd != '' &&
                      item.timeline.actualEnd != null
                    "
                    style="color: limegreen"
                  >
                    {{ formatDate(new Date(item.timeline.actualEnd)) }}
                  </div>
                  <div v-else>N/A</div>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Mandation:</strong
                  >
                  &nbsp;
                  <label
                    class="switch unactive-madated-switch"
                    v-if="item.isMandated"
                  >
                    <input type="checkbox" disabled checked />
                    <span class="slider round"></span>
                  </label>
                  <label
                    class="switch unactive-madated-switch"
                    v-if="!item.isMandated"
                  >
                    <input type="checkbox" disabled />
                    <span class="slider round"></span>
                  </label>
                </li>
                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Progress Updates:
                  </strong>
                  &nbsp;
                  <svg
                    class="open-memos"
                    @click="
                      () => {
                        setIdValues(item.id, '');
                        selectTableRow(item.id, 'Current');
                        createMemo('Current');
                      }
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                    />
                  </svg>
                </li>
              </ul>
              <div
                class="card tasks-card"
                v-if="item.tasks.length > 0"
                :style="
                  extendedTaskCollections.includes(item.id)
                    ? 'padding-bottom: 1rem;'
                    : ''
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem;
                  "
                  :style="
                    extendedTaskCollections.includes(item.id)
                      ? 'margin-bottom: 0.5rem;'
                      : ''
                  "
                >
                  Tasks
                  <svg
                    @click="onTaskCollectionSelect(item.id)"
                    v-show="!extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onTaskCollectionDeselect(item.id)"
                    v-show="extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
                <div v-show="extendedTaskCollections.includes(item.id)">
                  <div
                    class="card h-100 open-action"
                    v-for="task in item.tasks"
                    :key="task.id"
                  >
                    <div
                      class="p-3 card-header"
                      :style="
                        extendedTasks.includes(task.id)
                          ? 'padding-bottom: 0;'
                          : 'border-radius: 1rem; padding-bottom: 1rem;'
                      "
                    >
                      <div
                        class="row"
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          flex-direction: row;
                        "
                      >
                        <h6 class="subject" style="width: fit-content">
                          {{ task.subject }}
                        </h6>
                        <svg
                          @click="onTaskSelect(task.id)"
                          v-show="!extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                          />
                        </svg>
                        <svg
                          @click="onTaskDeselect(task.id)"
                          v-show="extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      v-show="extendedTasks.includes(task.id)"
                      style="padding: 0 1rem 1rem 1rem"
                    >
                      <ul class="list-group">
                        <li
                          style="
                            cursor: pointer;
                            display: block;
                            margin-bottom: 1rem;
                          "
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >What Will I Get From Doing This?</strong
                          >
                          &nbsp;
                          <div>
                            {{ task.motivation }}
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >More Info:</strong
                          >
                          &nbsp;
                          <div>
                            <a
                              v-if="
                                !task.actionUrl.includes(currentLocation) &&
                                !task.actionUrl.includes(localLocation) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              target="__blank"
                              style="text-decoration: underline"
                              :href="task.actionUrl"
                            >
                              Visit Here
                            </a>
                            <router-link
                              v-if="
                                (task.actionUrl.includes(currentLocation) ||
                                  task.actionUrl.includes(localLocation)) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              style="text-decoration: underline"
                              :to="formatLocalUrl(task.actionUrl, task.id)"
                            >
                              Visit Here
                            </router-link>
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Mandation:</strong
                          >
                          &nbsp;

                          <label
                            class="switch unactive-madated-switch"
                            v-if="task.isMandated"
                          >
                            <input type="checkbox" disabled checked />
                            <span class="slider round"></span>
                          </label>
                          <label
                            class="switch unactive-madated-switch"
                            v-if="!task.isMandated"
                          >
                            <input type="checkbox" disabled />
                            <span class="slider round"></span>
                          </label>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Progress Updates:
                          </strong>
                          &nbsp;
                          <svg
                            class="open-memos"
                            @click="
                              () => {
                                setIdValues(item.id, task.id);
                                selectTableRow(task.id, 'Completed');
                                createMemo('Completed');
                              }
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                            />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- This uses 'page-change' and 'interface' events to communicate with the desktop pagination -->
          <TablePaginationVue
            :type="'Completed'"
            :items="getNextActions"
            :buttons="4"
            @page-change="interfaces.tablePaginationDesktop.setPage"
            @interface="this.setMobilePaginationInterface"
            v-model="this.items"
          />
        </div>
      </div>
    </div>

    <!-- Cancelled Actions -->
    <div>
      <!-- Desktop View -->
      <div
        class="table-responsive card desktop-logic"
        v-if="tableType == 'Archived'"
        :id="id"
        style="padding: 0px 1rem"
      >
        <div class="overflow-x-scroll-l840">
          <div
            @click="
              () => {
                show = !show;
              }
            "
            style="
              font-size: 1.5rem;
              padding: 0.7rem;
              font-weight: 600;
              display: flex;
              align-items: center;
            "
          >
            Cancelled Actions
            <svg
              v-show="!show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
              />
            </svg>
            <svg
              v-show="show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
              />
            </svg>
          </div>
          <div class="fit-content-l840" v-show="show">
            <div style="display: flex; flex-direction: row; margin-left: 1rem">
              <label style="font-size: medium; margin-right: 1rem"
                >Items Per Page:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <input
                    class="page-amount-input"
                    :value="currentPageLength"
                    @change="changePageLength($event.target.value)"
                  />
                  <svg
                    v-show="!showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="page-num-options" v-show="showPageAmountDrpDwn">
                  <div
                    v-for="index in Math.ceil(getActionAmount / pageIncrements)"
                    :key="index"
                    @click="
                      () => {
                        changePageLength(index * pageIncrements);
                        showPageAmountDrpDwn = false;
                      }
                    "
                  >
                    {{ index * pageIncrements }}
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 1vh; display: flex; flex-direction: row">
              <table
                style="height: fit-content; table-layout: fixed; width: 100%"
                id="datatable-basic"
                class="table table-flush"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7;width: 8%;"
                      v-if="ea"
                    >
                      Options
                    </th>
                    <th
                      style="width: 18.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                    <th
                      style="width: 18.5%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      What I will get from doing this?
                    </th>
                    <th
                      style="width: 11%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      More Info
                    </th>
                    <th
                      style="width: 11%"
                      v-if="ea"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Mandation
                    </th>
                    <th
                      style="width: 11%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Tasks
                    </th>
                    <th
                      style="width: 11%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-if="ea"
                    >
                      Edit Action
                    </th>
                    <th
                      style="width: 11%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Progress Updates
                    </th>
                  </tr>
                </thead>
                <tbody
                  style="border-top: 1px solid #e9ecef"
                  v-for="item in this.items"
                  :key="item.id"
                >
                  <tr
                    title="Right click for More Options"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :id="item.id"
                    class="action"
                    @mousedown="
                      ($event) => {
                        showContextMenu($event, item, false, 'archived', false);
                      }
                    "
                  >
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      <svg
                        @click="
                          ($event) => {
                            showContextMenu(
                              $event,
                              item,
                              false,
                              'archived',
                              true
                            );
                          }
                        "
                        class="open-context-Menu-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 128 512"
                      >
                        <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                        />
                      </svg>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      stye="padding: 0.75rem 1.5rem;"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          !item.actionUrl.includes(currentLocation) &&
                          !item.actionUrl.includes(localLocation) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="item.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          (item.actionUrl.includes(currentLocation) ||
                            item.actionUrl.includes(localLocation)) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(item.actionUrl, item.id)"
                      >
                        Visit Here
                      </router-link>
                      <div class="mb-2">
                        Created on:
                        {{ formatDate(new Date(item.audit.createdOn)) }}
                      </div>
                      <div>
                        Cancelled on:
                        {{
                          item.timeline.actualEnd
                            ? formatDate(new Date(item.timeline.actualEnd))
                            : "N/A"
                        }}
                      </div>
                    </td>
                    <!-- @click="loadSurvey()" -->
                    <td
                      style="padding: 0.75rem 1.5rem; border-bottom: none"
                      class="text-sm font-weight-normal d-flex flex-column"
                      v-if="ea && !getIsMandationEnabled"
                    >
                      <label
                        class="switch unactive-madated-switch"
                        v-if="item.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch unactive-madated-switch"
                        v-if="!item.isMandated"
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; border-bottom: none"
                      class="text-sm font-weight-normal d-flex flex-column"
                      v-if="ea && getIsMandationEnabled"
                    >
                      <label
                        class="switch mandation-switch"
                        v-if="item.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch mandation-switch"
                        v-if="!item.isMandated"
                      >
                        <input
                          type="checkbox"
                          @change="
                            UpdateActionMandated(
                              item.id,
                              $event.target.checked,
                              true
                            )
                          "
                        />
                        <span class="slider round"></span>
                      </label>
                      <a
                        v-if="item.isMandated"
                        :href="
                          (getIsCustomerUC
                            ? getUCManLink
                            : getJSAManLink
                          ).replace('{actionId}', item.id)
                        "
                      >
                        <svg
                          class="show-saved-man"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                          />
                        </svg>
                        <p>View Mandation</p>
                      </a>
                    </td>
                    <td style="padding: 0.75rem 1.5rem">
                      <svg
                        v-if="item.tasks.length > 0"
                        class="show-tasks"
                        @click="toggleTaskDisplay(item.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M152.1 38.16C161.9 47.03 162.7 62.2 153.8 72.06L81.84 152.1C77.43 156.9 71.21 159.8 64.63 159.1C58.05 160.2 51.69 157.6 47.03 152.1L7.029 112.1C-2.343 103.6-2.343 88.4 7.029 79.03C16.4 69.66 31.6 69.66 40.97 79.03L63.08 101.1L118.2 39.94C127 30.09 142.2 29.29 152.1 38.16V38.16zM152.1 198.2C161.9 207 162.7 222.2 153.8 232.1L81.84 312.1C77.43 316.9 71.21 319.8 64.63 319.1C58.05 320.2 51.69 317.6 47.03 312.1L7.029 272.1C-2.343 263.6-2.343 248.4 7.029 239C16.4 229.7 31.6 229.7 40.97 239L63.08 261.1L118.2 199.9C127 190.1 142.2 189.3 152.1 198.2V198.2zM224 96C224 78.33 238.3 64 256 64H480C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H256C238.3 128 224 113.7 224 96V96zM224 256C224 238.3 238.3 224 256 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H256C238.3 288 224 273.7 224 256zM160 416C160 398.3 174.3 384 192 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416zM0 416C0 389.5 21.49 368 48 368C74.51 368 96 389.5 96 416C96 442.5 74.51 464 48 464C21.49 464 0 442.5 0 416z"
                        />
                      </svg>
                      <span
                        v-if="item.tasks.length == 0"
                        style="font-size: 0.9rem"
                        >No Tasks</span
                      >
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <svg
                        class="edit-action"
                        @click="openEditAction(item.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                        />
                      </svg>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <svg
                        class="open-memos"
                        @click="
                          () => {
                            setIdValues(item.id, '');
                            selectTableRow(item.id, 'Archived');
                            createMemo('Archived');
                          }
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr
                    v-if="item.tasks.length == 0"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td colspan="8" style="padding: 0.75rem 1.5rem">
                      <span :style="ea ? 'margin-left: 2rem;' : ''"
                        >No Tasks</span
                      >
                    </td>
                  </tr>
                  <tr
                    v-for="task in item.tasks"
                    :key="task.id"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    ></td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          !task.actionUrl.includes(currentLocation) &&
                          !task.actionUrl.includes(localLocation) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="task.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          (task.actionUrl.includes(currentLocation) ||
                            task.actionUrl.includes(localLocation)) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(task.actionUrl, task.id)"
                      >
                        Visit Here
                      </router-link>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <label
                        class="switch mandation-switch"
                        v-if="task.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch mandation-switch"
                        @change="
                          UpdateTaskMandated(
                            item.id,
                            task.id,
                            $event.target.checked,
                            true
                          )
                        "
                        v-if="!task.isMandated"
                      >
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td></td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                      v-if="ea"
                    >
                      <svg
                        class="edit-action"
                        @click="openEditTask(task.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                        />
                      </svg>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <svg
                        class="open-memos"
                        @click="
                          () => {
                            setIdValues(item.id, task.id);
                            selectTableRow(task.id, 'Archived');
                            createMemo('Archived');
                          }
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
            <TablePaginationVue
              :type="'Archived'"
              :items="getNextActions"
              :buttons="4"
              @page-change="interfaces.tablePaginationMobile.setPage"
              @interface="this.setDesktopPaginationInterface"
              v-model="this.items"
            />
          </div>
        </div>
      </div>

      <!-- Mobile View -->
      <div
        class="card open-action-cont open-actions-card mobile-logic"
        v-if="tableType == 'Archived'"
      >
        <!-- Table Header -->
        <div
          @click="
            () => {
              show = !show;
            }
          "
          style="
            font-size: 1.5rem;
            padding: 0.7rem;
            font-weight: 600;
            display: flex;
            align-items: center;
          "
        >
          Cancelled Actions
          <svg
            v-show="!show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
            />
          </svg>
          <svg
            v-show="show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
            />
          </svg>
        </div>

        <!-- Table content (drops down) -->
        <div v-show="show">
          <!-- Search Filters -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              padding: 0 0.7rem 0.3rem 0.7rem;
            "
          >
            <div>
              <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <label
                  style="font-size: medium; margin-right: 1rem; width: 8rem"
                  >Items Per Page:</label
                >
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; flex-direction: row">
                    <input
                      class="page-amount-input"
                      :value="currentPageLength"
                      @change="changePageLength($event.target.value)"
                    />
                    <svg
                      v-show="!showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = true"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                      />
                    </svg>
                    <svg
                      v-show="showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = false"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                      />
                    </svg>
                  </div>
                  <div class="page-num-options" v-show="showPageAmountDrpDwn">
                    <div
                      v-for="index in Math.ceil(
                        getActionAmount / pageIncrements
                      )"
                      :key="index"
                      @click="
                        () => {
                          changePageLength(index * pageIncrements);
                          showPageAmountDrpDwn = false;
                        }
                      "
                    >
                      {{ index * pageIncrements }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Audit Item -->
          <div
            class="card h-100 open-action"
            v-for="(item, index) in this.items"
            :key="index"
            title="Right click for More Options"
            @mousedown="
              ($event) => {
                showContextMenu($event, item, false, 'archived', false);
              }
            "
          >
            <div class="p-3 card-header" :style="'padding-bottom: 0;'">
              <div style="display: flex; flex-direction: row">
                <div style="margin-right: 1.5rem">
                  <svg
                    @click="
                      ($event) => {
                        showContextMenu($event, item, false, 'archived', true);
                      }
                    "
                    class="open-context-Menu-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                    />
                  </svg>
                </div>

                <div style="overflow: hidden; display: block; width: 100%">
                  <h6 class="subject" style="width: 100%">
                    {{ item.subject }}
                  </h6>
                  <h6 style="width: 100%; color: #67748e"></h6>
                </div>

                <div style="width: 24px; height: 100%">
                  <svg
                    @click="onActionSelect(item.id)"
                    v-show="!extendedActions.includes(item.id)"
                    class="extend-action"
                    style="width: 100%"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onActionDeselect(item.id)"
                    style="width: 100%"
                    v-show="extendedActions.includes(item.id)"
                    class="extend-action"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Audit Item Content (drops down) -->
            <div
              class="card-body"
              v-show="extendedActions.includes(item.id)"
              style="padding: 0 1rem 1rem 1rem"
            >
              <ul class="list-group">
                <li
                  style="cursor: pointer; display: block; margin-bottom: 1rem"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >What Will I Get From Doing This?</strong
                  >
                  &nbsp;
                  <div>
                    {{ item.motivation }}
                  </div>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >More Info:</strong
                  >
                  &nbsp;
                  <div>
                    <a
                      v-if="
                        !item.actionUrl.includes(currentLocation) &&
                        !item.actionUrl.includes(localLocation) &&
                        item.actionUrl != null &&
                        item.actionUrl != ''
                      "
                      target="__blank"
                      style="text-decoration: underline"
                      :href="item.actionUrl"
                    >
                      Visit Here
                    </a>
                    <router-link
                      v-if="
                        (item.actionUrl.includes(currentLocation) ||
                          item.actionUrl.includes(localLocation)) &&
                        item.actionUrl != null &&
                        item.actionUrl != ''
                      "
                      style="text-decoration: underline"
                      :to="formatLocalUrl(item.actionUrl, item.id)"
                    >
                      Visit Here
                    </router-link>
                  </div>
                </li>

                <li
                  v-if="!getIsMandationEnabled"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Mandation:</strong
                  >
                  &nbsp;

                  <label
                    class="switch mandation-switch"
                    style="margin-bottom: 0px"
                    v-if="item.isMandated"
                  >
                    <input type="checkbox" disabled checked />
                    <span class="slider round"></span>
                  </label>
                  <label
                    class="switch mandation-switch"
                    style="margin-bottom: 0px"
                    v-if="!item.isMandated"
                  >
                    <input type="checkbox" disabled />
                    <span class="slider round"></span>
                  </label>
                </li>

                <li
                  v-if="getIsMandationEnabled"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Mandation:</strong
                  >
                  &nbsp;

                  <label
                    class="switch mandation-switch"
                    style="margin-bottom: 0px"
                    v-if="item.isMandated"
                  >
                    <input type="checkbox" disabled checked />
                    <span class="slider round"></span>
                  </label>
                  <label
                    class="switch mandation-switch"
                    style="margin-bottom: 0px"
                    v-if="!item.isMandated"
                  >
                    <input
                      type="checkbox"
                      @change="
                        UpdateActionMandated(
                          item.id,
                          $event.target.checked,
                          true
                        )
                      "
                    />
                    <span class="slider round"></span>
                  </label>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Edit:
                  </strong>
                  &nbsp;
                  <svg
                    class="edit-action"
                    @click="openEditAction(item.id)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                    />
                  </svg>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Progress Updates:
                  </strong>
                  &nbsp;
                  <svg
                    class="open-memos"
                    @click="
                      () => {
                        setIdValues(item.id, '');
                        selectTableRow(item.id, 'Current');
                        createMemo('Current');
                      }
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                    />
                  </svg>
                </li>
              </ul>

              <div
                class="card tasks-card"
                v-if="item.tasks.length > 0"
                :style="
                  extendedTaskCollections.includes(item.id)
                    ? 'padding-bottom: 1rem;'
                    : ''
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem;
                  "
                  :style="
                    extendedTaskCollections.includes(item.id)
                      ? 'margin-bottom: 0.5rem;'
                      : ''
                  "
                >
                  Tasks
                  <svg
                    @click="onTaskCollectionSelect(item.id)"
                    v-show="!extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onTaskCollectionDeselect(item.id)"
                    v-show="extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
                <div v-show="extendedTaskCollections.includes(item.id)">
                  <div
                    class="card h-100 open-action"
                    v-for="task in item.tasks"
                    :key="task.id"
                  >
                    <div
                      class="p-3 card-header"
                      :style="
                        extendedTasks.includes(task.id)
                          ? 'padding-bottom: 0;'
                          : 'border-radius: 1rem; padding-bottom: 1rem;'
                      "
                    >
                      <div
                        class="row"
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          flex-direction: row;
                        "
                      >
                        <h6 class="subject" style="width: fit-content">
                          {{ task.subject }}
                        </h6>
                        <svg
                          @click="onTaskSelect(task.id)"
                          v-show="!extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                          />
                        </svg>
                        <svg
                          @click="onTaskDeselect(task.id)"
                          v-show="extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      v-show="extendedTasks.includes(task.id)"
                      style="padding: 0 1rem 1rem 1rem"
                    >
                      <ul class="list-group">
                        <li
                          style="
                            cursor: pointer;
                            display: block;
                            margin-bottom: 1rem;
                          "
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >What Will I Get From Doing This?</strong
                          >
                          &nbsp;
                          <div>
                            {{ task.motivation }}
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >More Info:</strong
                          >
                          &nbsp;
                          <div>
                            <a
                              v-if="
                                !task.actionUrl.includes(currentLocation) &&
                                !task.actionUrl.includes(localLocation) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              target="__blank"
                              style="text-decoration: underline"
                              :href="task.actionUrl"
                            >
                              Visit Here
                            </a>
                            <router-link
                              v-if="
                                (task.actionUrl.includes(currentLocation) ||
                                  task.actionUrl.includes(localLocation)) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              style="text-decoration: underline"
                              :to="formatLocalUrl(task.actionUrl, task.id)"
                            >
                              Visit Here
                            </router-link>
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Mandation:</strong
                          >
                          &nbsp;

                          <label
                            class="switch mandation-switch"
                            style="margin-bottom: 0px"
                            v-if="task.isMandated"
                          >
                            <input type="checkbox" disabled checked />
                            <span class="slider round"></span>
                          </label>
                          <label
                            class="switch mandation-switch"
                            style="margin-bottom: 0px"
                            v-if="!task.isMandated"
                          >
                            <input
                              type="checkbox"
                              @change="
                                UpdateActionMandated(
                                  task.id,
                                  $event.target.checked,
                                  true
                                )
                              "
                            />
                            <span class="slider round"></span>
                          </label>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Edit:
                          </strong>
                          &nbsp;
                          <svg
                            class="edit-action"
                            @click="openEditTask(task.id)"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
                            />
                          </svg>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Progress Updates:
                          </strong>
                          &nbsp;
                          <svg
                            class="open-memos"
                            @click="
                              () => {
                                setIdValues(task.id, '');
                                selectTableRow(task.id, 'Current');
                                createMemo('Current');
                              }
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                            />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
          <TablePaginationVue
            :type="'Archived'"
            :items="getNextActions"
            :buttons="4"
            @page-change="interfaces.tablePaginationDesktop.setPage"
            @interface="this.setMobilePaginationInterface"
            v-model="this.items"
          />
        </div>
      </div>
    </div>

    <!-- Failed to Complete Actions -->
    <div>
      <!-- Desktop View -->
      <div
        class="table-responsive card desktop-logic"
        v-if="tableType == 'Failed'"
        :id="id"
        style="padding: 0px 1rem"
      >
        <div class="overflow-x-scroll-l840">
          <div
            @click="
              () => {
                show = !show;
              }
            "
            style="
              font-size: 1.5rem;
              padding: 0.7rem;
              font-weight: 600;
              display: flex;
              align-items: center;
            "
          >
            Failed to Complete Actions
            <svg
              v-show="!show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
              />
            </svg>
            <svg
              v-show="show"
              style="height: 1.5rem; margin-left: auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
              />
            </svg>
          </div>
          <div class="fit-content-l840" v-show="show">
            <div style="display: flex; flex-direction: row; margin-left: 1rem">
              <label style="font-size: medium; margin-right: 1rem"
                >Items Per Page:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <input
                    class="page-amount-input"
                    :value="currentPageLength"
                    @change="changePageLength($event.target.value)"
                  />
                  <svg
                    v-show="!showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="page-num-options" v-show="showPageAmountDrpDwn">
                  <div
                    v-for="index in Math.ceil(getActionAmount / pageIncrements)"
                    :key="index"
                    @click="
                      () => {
                        changePageLength(index * pageIncrements);
                        showPageAmountDrpDwn = false;
                      }
                    "
                  >
                    {{ index * pageIncrements }}
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 1vh; display: flex; flex-direction: row">
              <table
                style="height: fit-content; table-layout: fixed; width: 100%"
                id="datatable-basic"
                class="table table-flush"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      style="width: 20%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                    <th
                      style="width: 20%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      What I will get from doing this?
                    </th>
                    <th
                      style="width: 17%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      More Info
                    </th>
                    <th
                      style="width: 17%"
                      v-if="ea && getIsMandationEnabled"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Mandation
                    </th>
                    <th
                      style="width: 13%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Tasks
                    </th>
                    <th
                      v-if="getIs603Enabled"
                      style="width: 13%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Open 603 Form
                    </th>
                    <th
                      v-if="
                        this.getCustomer.enrolledProgramme ==
                          'Universal Credit' &&
                        getIs604Enabled &&
                        getIsMandationEnabled
                      "
                      style="width: 13%"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Open 604 Form
                    </th>
                  </tr>
                </thead>
                <tbody
                  style="border-top: 1px solid #e9ecef"
                  v-for="item in this.items"
                  :key="item.id"
                >
                  <tr
                    title="Right click for More Options"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :id="item.id"
                    class="action"
                  >
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ item.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      stye="padding: 0.75rem 1.5rem;"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          !item.actionUrl.includes(currentLocation) &&
                          !item.actionUrl.includes(localLocation) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="item.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          (item.actionUrl.includes(currentLocation) ||
                            item.actionUrl.includes(localLocation)) &&
                          item.actionUrl != null &&
                          item.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(item.actionUrl, item.id)"
                      >
                        Visit Here
                      </router-link>
                      <div class="mb-2">
                        Created on:
                        {{ formatDate(new Date(item.audit.createdOn)) }}
                      </div>
                      <div>
                        Failed to complete on:
                        {{
                          item.timeline.actualEnd
                            ? formatDate(new Date(item.timeline.actualEnd))
                            : "N/A"
                        }}
                      </div>
                    </td>
                    <td
                      v-if="getIsMandationEnabled"
                      style="padding: 0.75rem 1.5rem; border-bottom: none"
                      class="text-sm font-weight-normal d-flex flex-column"
                    >
                      <label
                        class="switch unactive-madated-switch"
                        v-if="item.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch unactive-madated-switch"
                        v-if="!item.isMandated"
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                      <a
                        v-if="item.isMandated"
                        :href="
                          (getIsCustomerUC
                            ? getUCManLink
                            : getJSAManLink
                          ).replace('{actionId}', item.id)
                        "
                      >
                        <svg
                          class="show-saved-man"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                          />
                        </svg>
                        <p>View Mandation</p>
                      </a>
                    </td>
                    <td style="padding: 0.75rem 1.5rem">
                      <svg
                        v-if="item.tasks.length > 0"
                        class="show-tasks"
                        @click="toggleTaskDisplay(item.id)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M152.1 38.16C161.9 47.03 162.7 62.2 153.8 72.06L81.84 152.1C77.43 156.9 71.21 159.8 64.63 159.1C58.05 160.2 51.69 157.6 47.03 152.1L7.029 112.1C-2.343 103.6-2.343 88.4 7.029 79.03C16.4 69.66 31.6 69.66 40.97 79.03L63.08 101.1L118.2 39.94C127 30.09 142.2 29.29 152.1 38.16V38.16zM152.1 198.2C161.9 207 162.7 222.2 153.8 232.1L81.84 312.1C77.43 316.9 71.21 319.8 64.63 319.1C58.05 320.2 51.69 317.6 47.03 312.1L7.029 272.1C-2.343 263.6-2.343 248.4 7.029 239C16.4 229.7 31.6 229.7 40.97 239L63.08 261.1L118.2 199.9C127 190.1 142.2 189.3 152.1 198.2V198.2zM224 96C224 78.33 238.3 64 256 64H480C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H256C238.3 128 224 113.7 224 96V96zM224 256C224 238.3 238.3 224 256 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H256C238.3 288 224 273.7 224 256zM160 416C160 398.3 174.3 384 192 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416zM0 416C0 389.5 21.49 368 48 368C74.51 368 96 389.5 96 416C96 442.5 74.51 464 48 464C21.49 464 0 442.5 0 416z"
                        />
                      </svg>
                      <span
                        v-if="item.tasks.length == 0"
                        style="font-size: 0.9rem"
                        >No Tasks</span
                      >
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <div class="d-flex flex-column">
                        <svg
                          id="open-603"
                          v-if="item.isMandated && getIs603Enabled"
                          class="fill-603"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          @click="openRelated603Form(item)"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"
                          />
                        </svg>
                        <a
                          v-if="
                            getIs603Enabled &&
                            item.isMandated &&
                            item.mandationRecord != null &&
                            item.mandationRecord.dwp603Records.length > 0
                          "
                          style="width: fit-content"
                          :href="
                            (getIsCustomerUC
                              ? getViewUC603Link
                              : getViewJSA603Link
                            ).replace('{actionId}', item.id)
                          "
                        >
                          <svg
                            class="view-603-doc"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          >
                            <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                              d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
                            />
                          </svg>
                          <p style="display: flex">View 603</p>
                        </a>
                      </div>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <div class="d-flex flex-column">
                        <svg
                          id="open-604"
                          v-show="
                            getIs603Enabled &&
                            item.isMandated &&
                            item.mandationRecord != null &&
                            item.mandationRecord.dwp603Records.length > 0
                          "
                          class="fill-604"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          @click="openRelated604Form(item)"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"
                          />
                        </svg>
                        <a
                          v-if="
                            getIs604Enabled &&
                            item.isMandated &&
                            item.mandationRecord != null &&
                            item.mandationRecord.dwp604Records.length > 0
                          "
                          style="width: fit-content"
                          :href="getView604Link.replace('{actionId}', item.id)"
                        >
                          <svg
                            class="view-604-doc"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          >
                            <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                              d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
                            />
                          </svg>
                          <p style="display: flex">View 604</p>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="task in item.tasks"
                    :key="task.id"
                    :id="task.id"
                    style="height: fit-content; padding: 0.5rem 0.5rem"
                    :class="item.id + '_task_'"
                    class="action task"
                    hidden
                  >
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.subject }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem; word-wrap: normal"
                      class="text-sm font-weight-normal"
                    >
                      {{ task.motivation }}
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      stye="padding: 0.75rem 1.5rem;"
                      class="text-sm font-weight-normal"
                    >
                      <a
                        v-if="
                          !task.actionUrl.includes(currentLocation) &&
                          !task.actionUrl.includes(localLocation) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        target="__blank"
                        style="text-decoration: underline"
                        :href="task.actionUrl"
                      >
                        Visit Here
                      </a>
                      <router-link
                        v-if="
                          (task.actionUrl.includes(currentLocation) ||
                            task.actionUrl.includes(localLocation)) &&
                          task.actionUrl != null &&
                          task.actionUrl != ''
                        "
                        style="text-decoration: underline"
                        :to="formatLocalUrl(task.actionUrl, task.id)"
                      >
                        Visit Here
                      </router-link>
                    </td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    >
                      <label
                        class="switch unactive-madated-switch"
                        v-if="task.isMandated"
                      >
                        <input type="checkbox" disabled checked />
                        <span class="slider round"></span>
                      </label>
                      <label
                        class="switch unactive-madated-switch"
                        v-if="!task.isMandated"
                      >
                        <input type="checkbox" disabled />
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td style="padding: 0.75rem 1.5rem"></td>
                    <td
                      style="padding: 0.75rem 1.5rem"
                      class="text-sm font-weight-normal"
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
            <TablePaginationVue
              :items="getNextActions"
              :buttons="4"
              @page-change="interfaces.tablePaginationMobile.setPage"
              @interface="this.setDesktopPaginationInterface"
              v-model="this.items"
            />
          </div>
        </div>
      </div>

      <!-- Mobile View -->
      <div
        class="card open-action-cont open-actions-card mobile-logic"
        v-if="tableType == 'Failed'"
      >
        <!-- Table Header -->
        <div
          @click="
            () => {
              show = !show;
            }
          "
          style="
            font-size: 1.5rem;
            padding: 0.7rem;
            font-weight: 600;
            display: flex;
            align-items: center;
          "
        >
          Failed to Complete Actions
          <svg
            v-show="!show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
            />
          </svg>
          <svg
            v-show="show"
            style="
              min-width: 24px;
              flex-basis: 24px;
              height: 1.5rem;
              margin-left: auto;
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
            />
          </svg>
        </div>

        <!-- Table content (drops down) -->
        <div v-show="show">
          <!-- Search Filters -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              padding: 0 0.7rem 0.3rem 0.7rem;
            "
          >
            <div>
              <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <label
                  style="font-size: medium; margin-right: 1rem; width: 8rem"
                  >Items Per Page:</label
                >
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; flex-direction: row">
                    <input
                      class="page-amount-input"
                      :value="currentPageLength"
                      @change="changePageLength($event.target.value)"
                    />
                    <svg
                      v-show="!showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = true"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                      />
                    </svg>
                    <svg
                      v-show="showPageAmountDrpDwn"
                      @click="showPageAmountDrpDwn = false"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                      />
                    </svg>
                  </div>
                  <div class="page-num-options" v-show="showPageAmountDrpDwn">
                    <div
                      v-for="index in Math.ceil(
                        getActionAmount / pageIncrements
                      )"
                      :key="index"
                      @click="
                        () => {
                          changePageLength(index * pageIncrements);
                          showPageAmountDrpDwn = false;
                        }
                      "
                    >
                      {{ index * pageIncrements }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Audit Item -->
          <div
            class="card h-100 open-action"
            v-for="(item, index) in this.items"
            :key="index"
            title="Right click for More Options"
            @mousedown="
              ($event) => {
                showContextMenu($event, item, false, 'archived', false);
              }
            "
          >
            <div class="p-3 card-header" :style="'padding-bottom: 0;'">
              <div style="display: flex; flex-direction: row">
                <div style="margin-right: 1.5rem">
                  <svg
                    @click="
                      ($event) => {
                        showContextMenu($event, item, false, 'archived', true);
                      }
                    "
                    class="open-context-Menu-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                    />
                  </svg>
                </div>

                <div style="overflow: hidden; display: block; width: 100%">
                  <h6 class="subject" style="width: 100%">
                    {{ item.subject }}
                  </h6>
                  <h6 style="width: 100%; color: #67748e"></h6>
                </div>

                <div style="width: 24px; height: 100%">
                  <svg
                    @click="onActionSelect(item.id)"
                    v-show="!extendedActions.includes(item.id)"
                    class="extend-action"
                    style="width: 100%"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onActionDeselect(item.id)"
                    style="width: 100%"
                    v-show="extendedActions.includes(item.id)"
                    class="extend-action"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Audit Item Content (drops down) -->
            <div
              class="card-body"
              v-show="extendedActions.includes(item.id)"
              style="padding: 0 1rem 1rem 1rem"
            >
              <ul class="list-group">
                <li
                  style="cursor: pointer; display: block; margin-bottom: 1rem"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >What Will I Get From Doing This?</strong
                  >
                  &nbsp;
                  <div>
                    {{ item.motivation }}
                  </div>
                </li>

                <li
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >More Info:</strong
                  >
                  &nbsp;
                  <div>
                    <a
                      v-if="
                        !item.actionUrl.includes(currentLocation) &&
                        !item.actionUrl.includes(localLocation) &&
                        item.actionUrl != null &&
                        item.actionUrl != ''
                      "
                      target="__blank"
                      style="text-decoration: underline"
                      :href="item.actionUrl"
                    >
                      Visit Here
                    </a>
                    <router-link
                      v-if="
                        (item.actionUrl.includes(currentLocation) ||
                          item.actionUrl.includes(localLocation)) &&
                        item.actionUrl != null &&
                        item.actionUrl != ''
                      "
                      style="text-decoration: underline"
                      :to="formatLocalUrl(item.actionUrl, item.id)"
                    >
                      Visit Here
                    </router-link>
                  </div>
                </li>

                <li
                  v-if="getIsMandationEnabled"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Mandation:</strong
                  >
                  &nbsp;

                  <label
                    class="switch unactive-madated-switch"
                    v-if="item.isMandated"
                  >
                    <input type="checkbox" disabled checked />
                    <span class="slider round"></span>
                  </label>
                  <label
                    class="switch unactive-madated-switch"
                    v-if="!item.isMandated"
                  >
                    <input type="checkbox" disabled />
                    <span class="slider round"></span>
                  </label>
                </li>
                <li
                  v-if="item.isMandated && getIs603Enabled"
                  style="cursor: pointer; display: block"
                  class="pt-0 text-sm border-0 list-group-item ps-0"
                >
                  <strong class="text-dark" style="text-overflow: "
                    >Complete 603 Form:
                  </strong>
                  &nbsp;
                  <svg
                    @click="openRelated603Form(item)"
                    class="fill-603"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                    />
                  </svg>
                </li>
              </ul>

              <div
                class="card tasks-card"
                v-if="item.tasks.length > 0"
                :style="
                  extendedTaskCollections.includes(item.id)
                    ? 'padding-bottom: 1rem;'
                    : ''
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem;
                  "
                  :style="
                    extendedTaskCollections.includes(item.id)
                      ? 'margin-bottom: 0.5rem;'
                      : ''
                  "
                >
                  Tasks
                  <svg
                    @click="onTaskCollectionSelect(item.id)"
                    v-show="!extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                    />
                  </svg>
                  <svg
                    @click="onTaskCollectionDeselect(item.id)"
                    v-show="extendedTaskCollections.includes(item.id)"
                    class="extend-tasks"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                    />
                  </svg>
                </div>
                <div v-show="extendedTaskCollections.includes(item.id)">
                  <div
                    class="card h-100 open-action"
                    v-for="task in item.tasks"
                    :key="task.id"
                  >
                    <div
                      class="p-3 card-header"
                      :style="
                        extendedTasks.includes(task.id)
                          ? 'padding-bottom: 0;'
                          : 'border-radius: 1rem; padding-bottom: 1rem;'
                      "
                    >
                      <div
                        class="row"
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          flex-direction: row;
                        "
                      >
                        <h6 class="subject" style="width: fit-content">
                          {{ task.subject }}
                        </h6>
                        <svg
                          @click="onTaskSelect(task.id)"
                          v-show="!extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                          />
                        </svg>
                        <svg
                          @click="onTaskDeselect(task.id)"
                          v-show="extendedTasks.includes(task.id)"
                          class="extend-action"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      v-show="extendedTasks.includes(task.id)"
                      style="padding: 0 1rem 1rem 1rem"
                    >
                      <ul class="list-group">
                        <li
                          style="
                            cursor: pointer;
                            display: block;
                            margin-bottom: 1rem;
                          "
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >What Will I Get From Doing This?</strong
                          >
                          &nbsp;
                          <div>
                            {{ task.motivation }}
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >More Info:</strong
                          >
                          &nbsp;
                          <div>
                            <a
                              v-if="
                                !task.actionUrl.includes(currentLocation) &&
                                !task.actionUrl.includes(localLocation) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              target="__blank"
                              style="text-decoration: underline"
                              :href="task.actionUrl"
                            >
                              Visit Here
                            </a>
                            <router-link
                              v-if="
                                (task.actionUrl.includes(currentLocation) ||
                                  task.actionUrl.includes(localLocation)) &&
                                task.actionUrl != null &&
                                task.actionUrl != ''
                              "
                              style="text-decoration: underline"
                              :to="formatLocalUrl(task.actionUrl, task.id)"
                            >
                              Visit Here
                            </router-link>
                          </div>
                        </li>

                        <li
                          style="cursor: pointer; display: block"
                          class="pt-0 text-sm border-0 list-group-item ps-0"
                        >
                          <strong class="text-dark" style="text-overflow: "
                            >Mandation:</strong
                          >
                          &nbsp;

                          <label
                            class="switch unactive-madated-switch"
                            v-if="item.isMandated"
                          >
                            <input type="checkbox" disabled checked />
                            <span class="slider round"></span>
                          </label>
                          <label
                            class="switch unactive-madated-switch"
                            v-if="!item.isMandated"
                          >
                            <input type="checkbox" disabled />
                            <span class="slider round"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
          <TablePaginationVue
            :type="'Archived'"
            :items="getNextActions"
            :buttons="4"
            @page-change="interfaces.tablePaginationDesktop.setPage"
            @interface="this.setMobilePaginationInterface"
            v-model="this.items"
          />
        </div>
      </div>
    </div>

    <div id="contextMenu" hidden>
      <ul
        id="contextMain"
        @click="
          () => {
            showContextMenuMove = true;
          }
        "
      >
        <li class="contextItem">Move to...</li>
      </ul>
      <ul id="contextMove" v-show="showContextMenuMove">
        <li
          class="contextItem"
          @click="
            clearSelectedRow(),
              closeContextMenu($event, true),
              //findActionAndMove('Recommended', getContextMenuActionId)
              UpdateActionStateCode(getContextMenuActionId, 'Available', true)
          "
        >
          Recommended
        </li>
        <li
          class="contextItem"
          @click="
            clearSelectedRow(),
              closeContextMenu($event, true),
              openUpdateStart(getContextMenuActionId)
            //findActionAndMove('Open', getContextMenuActionId)
            //UpdateActionStateCode(getContextMenuActionId, 'Open', true);
          "
        >
          Open
        </li>
        <li
          class="contextItem"
          @click="
            clearSelectedRow(),
              closeContextMenu($event, true),
              UpdateEndThenMoveToComplete(
                this.getUTCDateString(new Date()),
                getContextMenuActionId,
                true
              )
          "
        >
          Completed
        </li>
        <li
          class="contextItem"
          @click="
            clearSelectedRow(),
              closeContextMenu($event, true),
              openCancellationReason(getContextMenuActionId)
            //findActionAndMove('Archive', getContextMenuActionId)
          "
        >
          Cancelled
        </li>
      </ul>
    </div>
    <div>
      <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="2400"
        filename="UcPreview"
        :pdf-quality="2"
        :manual-pagination="false"
        :margin="10"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="1200px"
        ref="html2Pdf"
      >
        <template v-slot:pdf-content>
          <SavedUcDocument />
        </template>
      </vue3-html2pdf>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { useCommonStore } from "@/store/commonStore";
import { useMainStore } from "../store/mainStore";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
import { useEmployabilityActionsStore } from "@/store/employabilityActionsData";
import TablePaginationVue from "@/components/TablePagination.vue";
import Vue3Html2pdf from "vue3-html2pdf";
import SavedUcDocument from "@/components/SavedUcDocument.vue";
import EmailComponent from "../components/EmailComponent.vue";

const envVars = process.env;

export default {
  name: "Action-Table",
  components: {
    TablePaginationVue,
    Vue3Html2pdf,
    SavedUcDocument,
    EmailComponent,
  },

  data() {
    return {
      interfaces: {
        tablePaginationDesktop: {
          setPage: () => {},
          setPageSize: () => {},
        },

        tablePaginationMobile: {
          setPage: () => {},
          setPageSize: () => {},
        },
      },

      items: [],
      extendedActions: [],
      extendedTaskCollections: [],
      extendedTasks: [],

      show: false,
      showMemos: false,
      creatingMemo: false,
      newMemoText: "",
      newMemoVisibleToCustomer: false,
      tasks: false,
      selectedActionId: "",
      selectedTaskId: "",
      task: false,
      contextMenuTypeOpenedOn: "",
      showContextMenuMove: false,
      currentPageNum: 1,
      currentPageLength: this.pageLength,
      showPageAmountDrpDwn: false,
      // currentLocation: location.protocol+'//'+location.host
      //currentLocation: 'https://'+location.host
      currentLocation: location.origin,
      localLocation: "https://actionplanmanagementui.azurewebsites.net",
      isFirstLoad: 0,
      invalidMemId: false,
      showMandationPopup: false,
      mandationCancel: false,
      actionToMandate: null,
      dueDate: null,
      showSavedJsa: false,
      showSavedUc: false,
      mandationOptions: false,
      showEmailModal: false,
      checkedRows: [],
    };
  },
  props: {
    id: {
      type: String,
      default: "action-table",
    },
    contextMenu: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    tableType: {
      type: String,
      default: "",
    },
    pageLength: {
      type: Number,
      default: 5,
    },
    pageIncrements: {
      type: Number,
      default: 5,
    },
    uniqueId: {
      required: true,
      type: String,
    },
    ea: {
      type: Boolean,
      default: false,
    },
    createPassportActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useCommonStore, [
      "getMemosForUi",
      "getActions",
      "getCustomer",
      "getMandationForm",
      "GetSavedMandationForm",
      "getActionToMandate",
      "getPerformPassportCheck",
    ]),
    ...mapState(useAdvisorDashboardStore, [
      "getContextMenuActionId",
      "getSurvey",
      "getCreateActionView",
      "getUpdateActionView",
      "getCurrentActionToUpdate",
      "getCurrentTaskToUpdate",
      "getUpdateStartView",
      "getMemoView",
      "getCreateActionView",
      "getCancellationReasonView",
      "getActionIdToCancel",
    ]),
    ...mapState(useMainStore, [
      "getIsMandationEnabled",
      "getIs603Enabled",
      "getIs604Enabled",
      "getIsEmailEnabled",
      "getIsCreatedOnEnabled",
      "getIsCompletedByCustomerEnabled",
    ]),

    ...mapState(useEmployabilityActionsStore, ["getPathwayA"]),

    getViewUC603Link() {
      return process.env.VUE_APP_VIEW_UC603_LINK;
    },
    getViewJSA603Link() {
      return process.env.VUE_APP_VIEW_JSA603_LINK;
    },
    getView604Link() {
      return process.env.VUE_APP_VIEW_604_LINK;
    },
    getUCManLink() {
      return process.env.VUE_APP_UCMAN_LINK;
    },
    getJSAManLink() {
      return process.env.VUE_APP_JSAMAN_LINK;
    },
    getIsCustomerUC() {
      return this.getCustomer
        ? this.getCustomer.enrolledProgramme == "Universal Credit"
        : false;
    },
    getMaxPages() {
      return Math.ceil(this.getRelatedActions.length / this.currentPageLength);
    },
    getRelatedActions() {
      if (this.tableType == "Recommended") {
        return this.getActions.recommended;
      } else if (this.tableType == "Current") {
        return this.getActions.current;
      } else if (this.tableType == "Completed") {
        return this.getActions.completed;
      } else if (this.tableType == "Archived") {
        return this.getActions.archived;
      } else if (this.tableType == "Failed") {
        return this.getActions.failed;
      }

      return [];
    },
    getNextActions() {
      // if (this.getRelatedActions.length > 0) {
      //   return this.getRelatedActions.slice(
      //     (this.currentPageNum - 1) * this.currentPageLength,
      //     ((this.currentPageNum - 1) * this.currentPageLength) +
      //       this.currentPageLength
      //   );
      // }
      return this.getRelatedActions;
    },
    getActionAmount() {
      return this.getRelatedActions.length;
    },
    mobileUniqueId() {
      return this.uniqueId + "-mobile";
    },

    // isChecklistComplete() {
    //   const completedSubjects = new Set(
    //     this.getActions.completed?.map((action) => action.subject) || []
    //   );

    //   return this.getPathwayA.targetSubjects.every((subject) =>
    //     completedSubjects.has(subject)
    //   );
    // },
  },

  watch: {
    currentPageLength: function () {
      this.interfaces.tablePaginationDesktop.setPageSize(
        this.currentPageLength
      );
      this.interfaces.tablePaginationMobile.setPageSize(this.currentPageLength);
    },

    // isChecklistComplete(newValue) {
    //   if (newValue && this.getPerformPassportCheck) {
    //     this.logCompletion();
    //     alert("Do check now")
    //   }
    // },
  },
  methods: {
    ...mapActions(useAdvisorDashboardStore, [
      "toggleModal",
      "setContextMenuActionId",
      "setModalContent",
      "setModalTitle",
      "setCreateActionView",
      "setUpdateActionView",
      "setCurrentActionToUpdate",
      "setCurrentTaskToUpdate",
      "setIsActionUpdating",
      "setUpdateStartView",
      "setMemoView",
      "setCurrentActionToAddTask",
      "setCreateActionView",
      "setCancellationReasonView",
      "setActionIdToCancel",
    ]),
    ...mapActions(useCommonStore, [
      "UpdateTaskCompletedByCustomer",
      "UpdateActionCompletedByCustomer",
      "CreateActionPlanItemMemo",
      "CreateTaskItemMemo",
      "UpdateActionStateCode",
      "UpdateActionMandated",
      "UpdateTaskMandated",
      "findTask",
      "findAction",
      "getUTCDateString",
      "UpdateEndThenMoveToComplete",
      "setCreatingMemo",
      "fireConfirmCustomerCompleteAlert",

      "SetMemosForUI",
      "CreateNewActionPlanMemo",
      "createNewTaskMemo",
      "findActionAndMove",
      "SetActionToMandate",
      "setDueDate",
      "getCustomer",
      "setUcMandationFormVisability",
      "setJsaMandationFormVisability",
      "setUcSavedVisability",
      "setJsaSavedVisability",
      "setJsa603Visibility",
      "setUc603Visibility",
      "setUc604Visibility",

      "setPerformPassportCheck",
      "setHasCreatedActions",
    ]),

    setDesktopPaginationInterface(childInterface) {
      this.interfaces.tablePaginationDesktop = childInterface;
    },

    setMobilePaginationInterface(childInterface) {
      this.interfaces.tablePaginationMobile = childInterface;
    },

    onActionSelect(actionId) {
      this.extendedActions.push(actionId);
    },
    onActionDeselect(actionId) {
      this.extendedActions = this.extendedActions.filter((a) => a != actionId);
    },

    onTaskSelect(taskId) {
      this.extendedTasks.push(taskId);
    },

    onTaskDeselect(taskId) {
      this.extendedTasks = this.extendedTasks.filter((t) => t != taskId);
    },

    onTaskCollectionSelect(actionId) {
      this.extendedTaskCollections.push(actionId);
    },

    onTaskCollectionDeselect(actionId) {
      this.extendedTaskCollections = this.extendedTaskCollections.filter(
        (a) => a != actionId
      );
    },
    displayMandationPopup(newMandatedValue, action, dueDate) {
      const enrolledProgramme = this.getCustomer.enrolledProgramme;
      const validProgramme =
        enrolledProgramme ==
          envVars.VUE_APP_MANDATION_PROGRAM_UNIVERSAL_CREDIT ||
        enrolledProgramme ==
          envVars.VUE_APP_MANDATION_PROGRAM_JOB_SEEKERS_ALLOWANCE;

      if (!validProgramme) {
        this.invalidMemId = true;
        return;
      }

      if (newMandatedValue) {
        this.mandationCancel = false;
      } else {
        this.mandationCancel = true;
      }

      //prevents actions with the isMandatable property set to false from opening
      if (!action.isMandatable) {
        return;
      }
      this.showMandationPopup = true;
      this.actionToMandate = action;
      this.dueDate = dueDate;
    },
    confirmMandationChange() {
      this.SetActionToMandate(this.actionToMandate);
      this.setDueDate(this.dueDate);
      this.showMandationPopup = false;
      this.actionToMandate = null;
      this.dueDate = null;
      this.openRelatedForm();
    },
    openRelatedForm() {
      if (
        this.getCustomer.enrolledProgramme ==
        envVars.VUE_APP_MANDATION_PROGRAM_UNIVERSAL_CREDIT
      ) {
        this.setUcMandationFormVisability(true);
      } else if (
        this.getCustomer.enrolledProgramme ==
        envVars.VUE_APP_MANDATION_PROGRAM_JOB_SEEKERS_ALLOWANCE
      ) {
        this.setJsaMandationFormVisability(true);
      } else {
        this.invalidMemId = true;
      }
      //7 = uc, 4 = jsa
      // if (this.getCustomer.memorableId.startsWith(7)) {
      //   this.setUcMandationFormVisability(true);
      // } else {
      //   t;
      // }
    },
    openRelated603Form(action) {
      this.SetActionToMandate(action);
      this.setDueDate(action.timeline.scheduledEnd);
      this.GetSavedMandationForm(action.id);

      // -- Function to automatically move action to failed to complete
      //this.UpdateActionStateCode(action.id, 'Canceled', true)

      if (
        this.getCustomer.enrolledProgramme ==
        envVars.VUE_APP_MANDATION_PROGRAM_UNIVERSAL_CREDIT
      ) {
        this.setUc603Visibility(true);
      } else if (
        this.getCustomer.enrolledProgramme ==
        envVars.VUE_APP_MANDATION_PROGRAM_JOB_SEEKERS_ALLOWANCE
      ) {
        this.setJsa603Visibility(true);
      } else {
        this.invalidMemId = true;
      }

      this.mandationOptions = false;
      this.actionToMandate = null;
      this.dueDate = null;
    },

    openRelated604Form(action) {
      this.SetActionToMandate(action);
      this.setDueDate(action.timeline.scheduledEnd);
      this.GetSavedMandationForm(action.id);

      if (
        this.getCustomer.enrolledProgramme ==
        envVars.VUE_APP_MANDATION_PROGRAM_UNIVERSAL_CREDIT
      ) {
        this.setUc604Visibility(true);
      } else if (
        this.getCustomer.enrolledProgramme ==
        envVars.VUE_APP_MANDATION_PROGRAM_JOB_SEEKERS_ALLOWANCE
      ) {
        this.setUc603Visibility(true);
      } else {
        this.invalidMemId = true;
      }

      this.mandationOptions = false;
      this.actionToMandate = null;
      this.dueDate = null;
    },

    openRelatedSavedDocument(action) {
      this.mandationOptions = false;

      //this.$refs.html2Pdf.generatePdf();

      this.SetActionToMandate(action);
      this.setDueDate(action.timeline.scheduledEnd);

      this.GetSavedMandationForm(action.id);

      if (
        this.getCustomer.enrolledProgramme ==
        envVars.VUE_APP_MANDATION_PROGRAM_UNIVERSAL_CREDIT
      ) {
        this.setUcSavedVisability(true);
      } else if (
        this.getCustomer.enrolledProgramme ==
        envVars.VUE_APP_MANDATION_PROGRAM_JOB_SEEKERS_ALLOWANCE
      ) {
        this.setJsaSavedVisability(true);
      } else {
        this.invalidMemId = true;
      }
      this.actionToMandate = null;
      this.dueDate = null;

      this.mandationOptions = false;
    },
    mandationPopupCancel() {
      this.SetActionToMandate(null);
      this.setDueDate(null);
      this.showMandationPopup = false;
      this.actionToMandate = null;
      this.dueDate = null;
    },
    invalidMemIdClosePopup() {
      this.invalidMemId = false;
    },

    formatDate(date) {
      return [
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
        date.getFullYear(),
      ].join("/");
    },
    openCancellationReason(id) {
      this.setActionIdToCancel(id);
      if (this.getCancellationReasonView == null) {
        this.setCancellationReasonView(
          document.getElementById("cancellation-reason")
        );
      }
      this.setModalTitle("");
      this.setModalContent(this.getCancellationReasonView);
      this.toggleModal();
    },
    showContextMenu(event, item, completed, type, force) {
      if (item.classification.ItemTypeCode != "FedcapCARES Group Appointment") {
        let actionId = item.id;
        let isRequired = item.isRequired;
        let customerComplete =
          item.state.stateCode == "PendingComplete" ||
          item.state.stateCode == "Complete";
        let systemGenerated = item.creationContext.systemGenerated;
        this.contextMenuTypeOpenedOn = type;
        if (!completed) {
          if ((event.which == 3 || force) && this.contextMenu) {
            //checks right mouse clicked
            var contextMenu = document.getElementById("contextMenu"); //shows context menu and positions it where clicked
            var items = contextMenu.getElementsByClassName("contextItem");
            for (var i = 0; i < items.length; i++) {
              items[i].hidden = false;
              switch (items[i].innerHTML.replace(/\s/g, "")) {
                //DONT SHOW RECOMMENDED AS AN OPTION IF THE ACTION IS ALREADY IN RECOMMENDED, IS SYSTEM GENERATED OR IS MANDATED
                case "Recommended":
                  if (
                    type == "recommended" ||
                    systemGenerated ||
                    item.isMandated
                  ) {
                    items[i].hidden = true;
                  }
                  break;
                //DONT SHOW OPEN AS AN OPTION ID THE ACTION IS ALREADY OPEN
                case "Open":
                  if (type == "current") {
                    items[i].hidden = true;
                  }
                  break;
                case "Completed":
                  //DONT SHOW COMPLETED OPTION IF THE ACTION IS CURRENTLY A RECOMMENDED ACTION, IF THE ACTION IS REQUIRED BUT NOT COMPLETED BY THE CUSTOMER
                  //OR THE ACTION HAS TASKS AS THIS GETS MOVED TO COMPLETE AUTOMATICALLY
                  if (
                    type == "recommended" ||
                    (!customerComplete &&
                      isRequired &&
                      this.getIsCompletedByCustomerEnabled) ||
                    (item.tasks.length > 0 &&
                      item.state.stateCode != "PendingComplete")
                  ) {
                    items[i].hidden = true;
                  }
                  break;
                //DONT SHOW CANCELLED AS AN OPTION IF THE ACTION IS ALREADY CANCELLED
                case "Cancelled":
                  if (type == "archived") {
                    items[i].hidden = true;
                  }
                  break;
              }
            }
            contextMenu.hidden = false;
            var space =
              contextMenu.getElementsByClassName("contextItem").length *
                contextMenu.getElementsByClassName("contextItem")[0]
                  .clientHeight -
              contextMenu.getElementsByClassName("contextItem")[0]
                .clientHeight *
                2;
            contextMenu.style = `top:${event.pageY - space}px; left:${
              event.pageX
            }px;`;
            this.setContextMenuActionId(actionId);
            if (document.getElementsByClassName("contextOpenedOn").length > 0) {
              //removes class to change background color and adds it to new el
              document
                .getElementsByClassName("contextOpenedOn")[0]
                .classList.remove("contextOpenedOn");
            }
            document.getElementById(actionId).classList.add("contextOpenedOn");
            document.onmousedown = ($event) => {
              this.closeContextMenu($event, false);
            };
          }
        }
      }
    },
    closeContextMenu(event, force) {
      if (event.which != 3 || force) {
        //checks right click hasnt been pressed
        var el = event.target;
        if (!el.classList.contains("contextItem") || force) {
          //checks context menu item hasnt been pressed
          document.getElementById("contextMenu").hidden = true; //hides menu and removes listener
          this.showContextMenuMove = false;
          document
            .getElementsByClassName("contextOpenedOn")[0]
            .classList.remove("contextOpenedOn");
          document.onmousedown = null;
        }
      }
    },
    setIdValues(actionId, taskId) {
      this.selectedActionId = actionId;
      this.selectedTaskId = taskId;
    },
    selectTableRow(id, type) {
      if (this.selected) {
        if (document.getElementsByClassName("selectedAction").length > 0) {
          document
            .getElementsByClassName("selectedAction")[0]
            .classList.remove("selectedAction");
        }
        this.selected = false;
      }
      var row = document.getElementById(id);
      var count = 0;
      if (row.nodeName == "TD") {
        while (count < 10 && !row.classList.contains("action")) {
          row = row.parentNode;
        }
        if (count >= 10 && !row.classList.contains("action")) {
          return;
        }
      }
      row.classList.add("selectedAction");
      if (row.classList.contains("task")) {
        this.task = true;
        this.SetMemosForUI(this.selectedTaskId, type);
      } else {
        this.task = false;
        this.SetMemosForUI(this.selectedActionId, type);
      }
      this.selected = true;
    },
    clearSelectedRow() {
      this.selected = false;
      if (document.getElementsByClassName("selectedAction").length > 0) {
        document
          .getElementsByClassName("selectedAction")[0]
          .classList.remove("selectedAction");
      }
    },
    loadSurvey() {
      this.setModalTitle("Survey");
      this.setModalContent(this.getSurvey);

      if (this.showModal) {
        this.toggleModal();
      }
    },
    toggleTaskDisplay(id) {
      var tasks = document.getElementsByClassName(id + "_task_");
      for (var i = 0; i < tasks.length; i++) {
        tasks[i].hidden = !tasks[i].hidden;
      }
    },
    createNewMemo(memo, visibleToCustomer, type) {
      var visibility = "EA";
      if (visibleToCustomer) {
        visibility = "EA|Customer";
      }
      if (this.task) {
        this.CreateTaskItemMemo(
          this.selectedActionId,
          this.selectedTaskId,
          memo,
          visibility,
          type
        );
        // this.createNewTaskMemo(
        //   this.selectedActionId,
        //   this.selectedTaskId,
        //   memo,
        //   type
        // );
      } else {
        this.CreateActionPlanItemMemo(
          this.selectedActionId,
          memo,
          visibility,
          type
        );
      }
    },
    createMemo(type) {
      this.setCreatingMemo(
        type,
        this.ea,
        this.selectedActionId,
        this.task ? this.selectedTaskId : null
      );
      if (this.getMemoView == null) {
        this.setMemoView(document.getElementById("memo-view"));
      }
      this.setModalTitle("Progress Updates");
      this.setModalContent(this.getMemoView);
      this.toggleModal();
    },
    changePageLength(length) {
      length = parseInt(length);
      // if (this.getMaxPages > this.getRelatedActions.length / length) {
      //   this.changePage(Math.ceil(this.getRelatedActions.length / length));
      // }
      this.currentPageLength = length;
    },
    allowEditProg(event, id) {
      var el = event.target;
      var count = 0;
      while (el.nodeName != "svg" && count < 10) {
        el = el.parentNode;
        count++;
      }
      if (el.nodeName != "svg") {
        return;
      }
      if (el.classList.contains("edit" + this.uniqueId + id)) {
        el.parentNode.previousSibling.hidden = false;
        el.parentNode.previousSibling.previousSibling.hidden = true;
        el.children[0].setAttribute(
          "d",
          "M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"
        );
        el.classList.remove("edit" + this.uniqueId + id);
      } else {
        el.parentNode.previousSibling.hidden = true;
        el.parentNode.previousSibling.previousSibling.hidden = false;
        el.parentNode.previousSibling.previousSibling.innerHTML =
          el.parentNode.previousSibling.value;
        el.children[0].setAttribute(
          "d",
          "M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
        );
        el.classList.add("edit" + this.uniqueId + id);
      }
    },
    allowEditMandated(event, id, isTask, parentActionId) {
      var el = event.target;
      var count = 0;
      while (el.nodeName != "svg" && count < 10) {
        el = el.parentNode;
        count++;
      }
      if (el.nodeName != "svg") {
        return;
      }
      if (el.classList.contains("editIsMandated" + this.uniqueId + id)) {
        el.parentNode.getElementsByClassName(
          "cancelIsMandated" + this.uniqueId + id
        )[0].parentNode.hidden = false;
        el.parentNode.getElementsByClassName(
          "edit-isMandated-input"
        )[0].disabled = false;
        el.children[0].setAttribute(
          "d",
          "M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"
        );
        el.classList.remove("editIsMandated" + this.uniqueId + id);
      } else {
        if (isTask) {
          this.UpdateTaskMandated(
            parentActionId,
            id,
            el.parentNode.getElementsByClassName("edit-isMandated-input")[0]
              .checked,
            true
          );
        } else {
          this.UpdateActionMandated(
            id,
            el.parentNode.getElementsByClassName("edit-isMandated-input")[0]
              .checked,
            true
          );
        }
        el.parentNode.getElementsByClassName(
          "cancelIsMandated" + this.uniqueId + id
        )[0].parentNode.hidden = true;
        el.parentNode.getElementsByClassName(
          "edit-isMandated-input"
        )[0].disabled = true;
        el.children[0].setAttribute(
          "d",
          "M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
        );
        el.classList.add("editIsMandated" + this.uniqueId + id);
      }
    },
    cancelEditMandated(event, id) {
      var el = event.target;
      var count = 0;
      while (el.nodeName != "svg" && count < 10) {
        el = el.parentNode;
        count++;
      }
      if (el.nodeName != "svg") {
        return;
      }
      el.parentNode.hidden = true;
      el.parentNode.parentNode.getElementsByClassName(
        "edit-isMandated-input"
      )[0].disabled = true;
      el.parentNode.parentNode
        .getElementsByClassName("edit-isMandated")[0]
        .children[0].setAttribute(
          "d",
          "M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
        );
      el.parentNode.parentNode
        .getElementsByClassName("edit-isMandated")[0]
        .classList.add("editIsMandated" + this.uniqueId + id);
    },
    UpdateCompletedByCustomer(id, parentActionId, isTask) {
      var completed = !document.getElementById("pendingComplete" + id).checked;
      this.fireConfirmCustomerCompleteAlert(
        id,
        parentActionId,
        completed,
        isTask
      );
      // if (isTask) {
      //   this.UpdateTaskCompletedByCustomer(
      //     parentActionId,
      //     id,
      //     completed,
      //     true
      //   );
      // } else {
      //   this.UpdateActionCompletedByCustomer(
      //     id,
      //     completed,
      //     true
      //   );
      // }
    },
    allowEditCompleted(event, id, isTask, parentActionId) {
      var el = event.target;
      var count = 0;
      while (el.nodeName != "svg" && count < 10) {
        el = el.parentNode;
        count++;
      }
      if (el.nodeName != "svg") {
        return;
      }
      if (el.classList.contains("editCompleted" + this.uniqueId + id)) {
        el.parentNode.getElementsByClassName(
          "cancelCompleted" + this.uniqueId + id
        )[0].parentNode.hidden = false;
        el.parentNode.getElementsByClassName(
          "edit-completed-input"
        )[0].disabled = false;
        el.children[0].setAttribute(
          "d",
          "M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"
        );
        el.classList.remove("editCompleted" + this.uniqueId + id);
      } else {
        if (isTask) {
          this.UpdateTaskCompletedByCustomer(
            parentActionId,
            id,
            el.parentNode.getElementsByClassName("edit-completed-input")[0]
              .checked,
            true
          );
        } else {
          this.UpdateActionCompletedByCustomer(
            id,
            el.parentNode.getElementsByClassName("edit-completed-input")[0]
              .checked,
            true
          );
        }
        el.parentNode.getElementsByClassName(
          "cancelCompleted" + this.uniqueId + id
        )[0].parentNode.hidden = true;
        el.parentNode.getElementsByClassName(
          "edit-completed-input"
        )[0].disabled = true;
        el.children[0].setAttribute(
          "d",
          "M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
        );
        el.classList.add("editCompleted" + this.uniqueId + id);
      }
    },
    cancelEditCompleted(event, id) {
      var el = event.target;
      var count = 0;
      while (el.nodeName != "svg" && count < 10) {
        el = el.parentNode;
        count++;
      }
      if (el.nodeName != "svg") {
        return;
      }
      el.parentNode.hidden = true;
      el.parentNode.parentNode.getElementsByClassName(
        "edit-completed-input"
      )[0].disabled = true;
      el.parentNode.parentNode
        .getElementsByClassName("edit-completed")[0]
        .children[0].setAttribute(
          "d",
          "M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
        );
      el.parentNode.parentNode
        .getElementsByClassName("edit-completed")[0]
        .classList.add("editCompleted" + this.uniqueId + id);
    },
    openEditAction(actionId) {
      this.setCurrentActionToUpdate(this.findAction(actionId));
      this.setIsActionUpdating(true);
      if (this.getUpdateActionView == null) {
        this.setUpdateActionView(document.getElementById("update-action"));
      }
      this.setModalContent(this.getUpdateActionView);
      this.setModalTitle("Update Action");
      this.toggleModal();
    },
    openEditTask(taskId) {
      this.setCurrentTaskToUpdate(this.findTask(taskId));
      this.setIsActionUpdating(false);
      if (this.getUpdateActionView == null) {
        this.setUpdateActionView(document.getElementById("update-action"));
      }
      this.setModalContent(this.getUpdateActionView);
      this.setModalTitle("Update Task");
      this.toggleModal();
    },
    getDateInputString(date) {
      return [
        date.getFullYear(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : date.getMonth() + 1,
        date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate(),
      ].join("-");
    },
    openCreateNewAction() {
      if (this.getCreateActionView == null) {
        this.setCreateActionView(document.getElementById("create-action"));
      }
      this.setModalContent(this.getCreateActionView);
      this.setModalTitle("Create New Action");
      this.toggleModal();
    },
    showToolTip(actionId, type) {
      var tooltip;
      if (type == "") {
        tooltip = document.getElementById("subject-tip-" + actionId);
      } else if (type == "motivation") {
        tooltip = document.getElementById("motivation-tip-" + actionId);
      }
      if (tooltip != undefined) {
        tooltip.hidden = false;
      }
    },
    hideToolTip(actionId, type) {
      var tooltip;
      if (type == "subject") {
        tooltip = document.getElementById("subject-tip-" + actionId);
      } else if (type == "motivation") {
        tooltip = document.getElementById("motivation-tip-" + actionId);
      }
      if (tooltip != undefined) {
        tooltip.hidden = true;
      }
    },
    openUpdateStart(actionPlanItemId) {
      this.setCurrentActionToUpdate(this.findAction(actionPlanItemId));
      if (this.getUpdateStartView == null) {
        this.setUpdateStartView(document.getElementById("updt-act-start"));
      }
      this.setModalContent(this.getUpdateStartView);
      this.setModalTitle("");
      this.toggleModal();
    },
    goToCreateTask(parentActionId) {
      if (this.getCreateActionView == null) {
        this.setCreateActionView(document.getElementById("create-action"));
      }
      this.setModalContent(this.getCreateActionView);
      this.setModalTitle("Add New Task");
      this.setCurrentActionToAddTask(parentActionId);
      this.toggleModal();
    },

    popupCheck() {
      const checked =
        document.querySelector("#openActionMandationSlider:checked") !== null;

      if (checked == true) {
        const mandationSliderPopUp = document.getElementById("mandation-popup");
        mandationSliderPopUp.style.visibility = "visible";
      } else {
        const mandationSliderPopUp = document.getElementById(
          "mandation-popupCancel"
        );
        mandationSliderPopUp.style.visibility = "visible";
      }
    },

    CloseMandationPopup() {
      const mandationSliderPopUp = document.getElementById("mandation-popup");
      mandationSliderPopUp.style.visibility = "hidden";
    },

    CloseCancelMandationPopup() {
      document.getElementById("openActionMandationSlider").checked = false;
      const mandationSliderPopUp = document.getElementById(
        "mandation-popupCancel"
      );
      mandationSliderPopUp.style.visibility = "hidden";
    },

    selectMandationForm() {
      const ucManid = this.getCustomer.memorableId;
      if (ucManid.startsWith("7")) {
        // <JsaMandationFormVue/>
        alert("martin");
      } else {
        alert("show jsaMan form");
      }
    },

    formatLocalUrl(val, id) {
      var url = new URL(val);
      var currentUrl = new URL(window.location);
      url.searchParams.append("relatedActionId", id);
      if (currentUrl.searchParams.get("noSearch")) {
        url.searchParams.append("noSearch", "true");
      }
      url = url.toString();
      url = url
        .replace(this.currentLocation, "")
        .replace(this.localLocation, "");
      return url;
    },

    getCheckedItems() {},

    getEmailItems() {
      const checkedItems = this.checkedRows;

      let emailItems = "";
      for (const item of checkedItems) {
        const subjectWithDAMS =
          item.tasks == 0
            ? item.subject
            : `Complete Digital Activation Modules: ${item.subject}`;
        emailItems += `\n<b>${subjectWithDAMS}</b>`;
        emailItems += `is due on ${this.formatDate(
          new Date(item.timeline.scheduledEnd)
        )}\n`;
        emailItems += `${item.motivation}\n`;
        if (item.actionUrl) {
          const urls = item.actionUrl.split(" ");
          for (const url of urls) {
            const formattedUrl = this.formatLocalUrl(url, item.id);
            emailItems += `<a href="${formattedUrl}">View More</a> `;
          }
        }
      }

      return emailItems;
    },
  },

  mounted() {
    for (const el of document.getElementsByTagName("table")) {
      el.addEventListener("contextmenu", (e) => e.preventDefault());
    }
  },
};
</script>
<style>
.page-cont-active {
  background-color: #e1e1e1 !important;
}

.open-context-Menu-svg {
  height: 1.1rem;
  cursor: pointer;
}

.page-selector {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: absolute;
  z-index: 2;
  width: fit-content;
  max-width: 15.2rem;
  max-height: 6.1rem;
  overflow-y: auto;
  background-color: #f3f3f3;
  border-radius: 3px;
  border: 0.02rem solid #dbdbdb;
  margin-right: 1.1rem;
}

.page-selector div {
  width: 2rem;
  height: 2rem;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: inset 0px 1px 0px 0px #e8e8e8;
  background: linear-gradient(to bottom, #ededed 5%, #ebebeb 100%);
  background-color: #ededed;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  color: #7a7a7a;
  text-shadow: 0px 1px 0px #e6e6e6;
}

.page-selector div:hover {
  /* background: linear-gradient(to bottom, #ddd 5%, #dbdbdb 100%);
  box-shadow: inset 0px 1px 0px 0px #dbdbdb; */
  background: none;
  background-image: none;
  box-shadow: none;
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
  text-shadow: none;
  color: white;
}

.page-selctor-1 {
  margin-top: -2.2rem;
}

.page-selctor-2 {
  margin-top: -4.3rem;
}

.page-selctor-3 {
  margin-top: -6.4rem;
}

svg {
  fill: #344767;
}

#contextMenu {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  color: #5b5b5b;
  position: absolute;
  z-index: 2;
}

#contextMain {
  margin-bottom: auto;
}

#contextMain,
#contextMove {
  background-color: rgb(245, 245, 245);
  width: fit-content;
  height: fit-content;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.contextItem:first-child {
  width: fit-content;
  border-radius: 2px 2px 0 0;
}

.contextItem {
  width: 100%;
  height: fit-content;
  padding: 0 0.75rem;
  border: 1px solid #f8f8f8;
  border-bottom: none;
}

.contextItem:hover {
  background-color: rgb(236, 236, 236);
  cursor: pointer;
}

.contextItem:last-child {
  border-bottom: 1px solid #f8f8f8;
  border-radius: 0 0 2px 2px;
}

.task {
  background-color: #f6f6f6;
}

.selectedAction {
  background-color: #f0f0f0;
}

.action:hover {
  cursor: pointer;
}

.memo-tab {
  display: flex;
  flex-direction: column;
  width: 1.8rem;
  min-height: 35vh;
  max-height: 75vh;
  background-color: #efefef;
  margin: 0 0 2vh 2vh;
  justify-content: center;
  align-items: center;
}

.memo-tab {
  margin-top: 3vh;
  margin-bottom: 1vh;
}

.memo-tab:hover {
  cursor: pointer;
}

.memo-tab div:hover {
  cursor: pointer;
}

.memo-tab-arrow {
  width: 50%;
  margin-top: 0.6vh;
}

.memo-area {
  width: 25vw;
  min-height: 35vh;
  max-height: 75vh;
  overflow-y: scroll;
  margin-top: 3vh;
  margin-bottom: 1vh;
  background-color: lightgrey;
  padding: 1vh;
}

@media (min-width: 1600px) {
  .memo-area {
    width: 19vw;
  }
}

@media (max-width: 840px) {
  .memo-area {
    width: 70vw;
  }
}

@media (max-width: 840px) {
  .fit-content-l840 {
    width: fit-content;
  }

  .overflow-x-scroll-l840 {
    overflow-x: scroll;
  }
}

.page-btn-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: right;
  margin: 0 0 1rem 0;
  padding-right: 0.7rem;
}

.page-btn {
  width: 2.2rem;
  height: 2.2rem;
  font-size: larger;
  border-radius: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(222, 222, 222);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.page-btn:hover {
  cursor: pointer;
}

.back-page,
.forw-page {
  font-size: larger;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 0.1rem 0 0.1rem 0.4rem;
}

.unactive {
  background-color: rgb(235 235 235);
  border: 1px solid rgb(236 235 235);
  color: #b2b5bc;
}

.unactive:hover {
  cursor: default;
}

.edit-date,
.edit-isMandated,
.edit-action,
.cancel-isMandated,
.edit-completed,
.cancel-completed,
.open-memos,
.show-tasks,
.add-task {
  width: 0.8rem;
  fill: rgb(180, 180, 180);
  margin-left: 0.4rem;
}

.edit-action,
.open-memos,
.show-tasks,
.add-task {
  width: 1.1rem;
}

.edit-isMandated,
.cancel-isMandated,
.edit-completed,
.cancel-completed {
  margin-bottom: 0.4rem;
}

.edit-date:hover,
.edit-isMandated:hover,
.edit-action:hover,
.cancel-isMandated:hover,
.edit-completed:hover,
.cancel-completed:hover,
.open-memos:hover {
  fill: rgb(161, 161, 161);
  cursor: pointer;
}

.edit-date-input {
  color: limegreen;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  color: #67748e;
}
</style>
<style scoped>
.table td {
  white-space: normal;
}
</style>
<style scoped>
.popup-header {
  text-align: center;
  font-family: inherit;
  font-weight: 600;
  color: #595959;
  font-size: 1.2rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: calc(6rem / 3);
  height: calc(3.4rem / 3);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(226, 60, 60);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: calc(2.6rem / 3);
  width: calc(2.6rem / 3);
  left: calc(0.4rem / 3);
  bottom: calc(0.4rem / 3);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #47e441;
}

input:focus + .slider {
  box-shadow: 0 0 1px #47e441;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(2.6rem / 3));
  -ms-transform: translateX(calc(2.6rem / 3));
  transform: translateX(calc(2.6rem / 3));
}

/* Rounded sliders */
.slider.round {
  border-radius: calc(3.4rem / 3);
}

.slider.round:before {
  border-radius: 50%;
}

.switch-unactive .slider {
  background-color: rgb(221, 128, 128);
}

.switch-unactive input:checked + .slider {
  background-color: #91df8e;
}

.switch-unactive input:focus + .slider {
  box-shadow: 0 0 1px #91df8e;
}

.mandation-switch .slider {
  background-color: rgb(139, 139, 139);
}

.mandation-switch input:checked + .slider {
  background-color: rgb(226, 60, 60);
}

.mandation-switch input:focus + .slider {
  box-shadow: 0 0 1px rgb(226, 60, 60);
}

.unactive-madated-switch .slider {
  background-color: rgb(165, 164, 164);
}

.unactive-madated-switch input:checked + .slider {
  background-color: rgb(221, 128, 128);
}

.unactive-madated-switch input:focus + .slider {
  background-color: rgb(221, 128, 128);
}

.table th {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

@media (max-width: 900px) {
  .desktop-logic {
    display: none !important;
  }

  .mobile-logic {
    display: block !important;
  }
}

@media (min-width: 900px) {
  .desktop-logic {
    display: block !important;
  }

  .mobile-logic {
    display: none !important;
  }
}
</style>

<!-- Mobile Styling -->
<style scoped>
.header {
  font-size: 1.5rem;
  font-weight: 400;
}

.open-action {
  margin-bottom: 1rem;
}

.open-action:last-child {
  margin-bottom: 0;
}

.open-actions-card {
  border-radius: 1rem;
  padding: 1rem;
}

.extend-action {
  width: 6%;
  padding: 0;
  margin: 0.2rem 2.5% auto 2.5%;
  cursor: pointer;
  fill: #344767;
}

.extend-tasks {
  width: 6%;
  padding: 0;
  margin: 0rem 2.5%;
  cursor: pointer;
  fill: #344767;
}

.subject {
  max-width: 89%;
  word-wrap: normal;
  margin: 0;
}

.open-action-header {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.open-action-header svg {
  width: 1.9rem;
  height: 1.9rem;
  fill: #2c3e50;
  margin-right: 0.5rem;
}

.open-action-cont {
  padding: 0.6rem 1rem;
  margin-bottom: 0.5rem;
}

.prog-upt-btn {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.list-group li {
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .my-actions-main {
    padding: 0 20%;
  }

  .extend-action {
    width: 1.5rem;
  }

  .extend-tasks {
    width: 1.5rem;
  }

  #memo-modal {
    margin: 0 26%;
    width: 48%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: calc(6rem / 3);
  height: calc(3.4rem / 3);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="date"] {
  box-sizing: border-box;
  padding: 0 0.7rem;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(226, 60, 60);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.disabled-switch .slider {
  background-color: rgb(161, 73, 73);
}

.tasks-card {
  margin-top: 1rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: calc(2.6rem / 3);
  width: calc(2.6rem / 3);
  left: calc(0.4rem / 3);
  bottom: calc(0.4rem / 3);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #47e441;
}

input:focus + .slider {
  box-shadow: 0 0 1px #47e441;
}

.disabled-switch input:checked + .slider {
  background-color: #64be61;
}

.disabled-switch input:focus + .slider {
  box-shadow: 0 0 1px #64be61;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(2.6rem / 3));
  -ms-transform: translateX(calc(2.6rem / 3));
  transform: translateX(calc(2.6rem / 3));
}

/* Rounded sliders */
.slider.round {
  border-radius: calc(3.4rem / 3);
}

.slider.round:before {
  border-radius: 50%;
}

/* button styling */
.buttons {
  display: flex;
  justify-content: center;
}

.button-5 {
  align-items: center;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 1rem;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-5:hover,
.button-5:focus {
  background-color: #1a6d86;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  transform: translateY(-1px);
}

.button-5:active {
  background-color: #0b4b71;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

/* button styling */

/* tooltip */

.wrapper {
  text-transform: uppercase;
  cursor: help;
  font-size: 20px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 15px 15px;
  position: relative;
  text-align: center;
  width: 80%;
  -webkit-transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
}

.wrapper .tooltip {
  background: #344767;
  bottom: 100%;
  color: #fff;
  display: block;
  left: 0;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  border-radius: 0.5rem;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #344767 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* tooltip */

.loader {
  border: 0.7rem solid #f3f3f3;
  /* Light grey */
  border-top: 0.7rem solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  animation: spin 2s linear infinite;
}

.loader-container {
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .width-100-l600 {
    width: 100% !important;
  }

  .pd-t-l600 {
    padding: 0.75rem 0rem 0rem 0rem !important;
  }

  .pd-tb-l100 {
    padding: 0.75rem 0rem !important;
  }

  .pd-lr-l600 {
    padding: 0rem 0.75rem !important;
  }
}

.mandation-confirmation {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 32rem;
  max-width: 100%;
  height: fit-content;
  padding: 12px;
  border-radius: 1rem;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 100;
  opacity: 1;
  transition: 0.6s;
  font-family: inherit;
  font-size: 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black */
  z-index: 100;
  /* higher than any other element on the page */
}

.invalid-memId {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 32rem;
  max-width: 100%;
  height: fit-content;
  padding: 12px;
  border-radius: 1rem;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 100;
  opacity: 1;
  transition: 0.6s;
  font-family: inherit;
  font-size: 1rem;
}

.fill-603 {
  width: 1.2rem;
  cursor: pointer;
  fill: rgb(118, 146, 185);
}

.fill-604 {
  width: 1.2rem;
  cursor: pointer;
  fill: rgb(0, 7, 135);
}

.show-saved-man {
  height: 1.5rem;
  margin-top: 4px;
  margin-left: 2%;
  cursor: pointer;
  fill: rgb(155, 155, 155);
  padding: 2px;
}

.view-603-doc {
  fill: rgb(118, 146, 185);
  width: 1.5rem;
  margin-top: 0.6rem;
}

.view-604-doc {
  fill: rgb(0, 7, 135);
  width: 1.5rem;
  margin-top: 0.6rem;
}

.view-mandation {
  width: 1.5rem;
  margin-top: 0.6rem;
}

.preview {
  position: fixed;
  overflow-y: auto;
  right: 0;
  top: 0;
  bottom: 0;
  left: 28.5%;
  z-index: 20;
}

.preview svg {
  position: absolute;
  height: 4rem;
  padding-left: 65%;
}

.popup-modal {
  position: fixed;
  z-index: 10;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.326);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
