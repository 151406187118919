import axios from 'axios';
const envVars = process.env;

export default {
    async TriggerCTFASurvey(customerId){
        try{
            var ax = axios.create({
                headers: {}
              });

            const resp = await ax({
                method: 'post',
                url: envVars.VUE_APP_CREATE_SURVEY_EVENT_URL,
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type":"application/json"
                },
                data:[{
                    "eventTriggerId":envVars.VUE_APP_CFTA_EVENT_TRIGGER_ID,
                    "customerIds":[customerId]
                }]
              }); 
            return resp;
        }
        catch(error){
            return error;
        }
    },
}