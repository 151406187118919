<template>
  <div class="py-4 container-fluid mandation-modal">
    <div class="row" style="min-width: 100%">
      <div class="text-center col-12" ref="form">
        <div class="multisteps-form">
          <!--progress bar-->
          <div class="row mt-3">
            <div class="mx-auto my-2 col-12 col-lg-12">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn mb-4"
                  type="button"
                  title="UC603 Part 1"
                  @click="activeStep = 0"
                  :class="activeStep >= 0 ? activeClass : ''"
                >
                  <p>UC603 Part 1</p>
                </button>
                <button
                  class="multisteps-form__progress-btn mb-4"
                  type="button"
                  title="UC603 Part 2"
                  @click="activeStep = 1"
                  :disabled="!store1.meta.valid"
                  :class="activeStep >= 1 ? activeClass : ''"
                >
                  <p>UC603 Part 2</p>
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-12">
              <form class="multisteps-form__form">
                <!--single form panel-->
                <UC603Form
                  :class="activeClass"
                  @closeMandationWizard="$emit('closeMandationWizard')"
                  v-if="activeStep === 0"
                />
                <!--single form panel-->
                <UC603Form2
                  :class="activeClass"
                  @closeMandationWizard="$emit('closeMandationWizard')"
                  v-if="activeStep === 1"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UC603Form from "../UC603Wizard/Components/UC603Form.vue";
import UC603Form2 from "../UC603Wizard/Components/UC603Form2.vue";
import { uc603FormStore } from "../../store/UC603FormStore";

export default {
  name: "UC-Mandation_Wizard",
  components: { UC603Form, UC603Form2 },
  data() {
    return {
      activeClass: "js-active position-relative mb-4",
      activeStep: 0,
      formSteps: 3,

      // This seems like a bad idea but couldn't figure out a better way to do this
      // Having a separate <script setup> seems to hide the 'nextStep' method below, which is needed for the 'Next' button to function on the first page
      store1: uc603FormStore()
    };
  },
  methods: {
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
        this.$nextTick(() => {
          this.$refs.form.scrollIntoView();
          //using the ref form it scrolls to where that is situated
        });
      } else {
        this.activeStep -= 1;
        this.$nextTick(() => {
          this.$refs.form.scrollIntoView();
        });
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
  },
};
</script>

<style scoped>
.mandation-modal {
  position: fixed;
  min-width: 75%;
  z-index: 10;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  height: fit-content;
  width: fit-content;
  max-width: 1050px;
  background-color: rgb(111, 150, 0);
  overflow-y: hidden;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}
::-webkit-scrollbar {
  width: 0.5em;
  height: auto;
}
::-webkit-scrollbar-track-piece {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.174);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

button:disabled.multisteps-form__progress-btn.mb-4 {
  color: rgb(165, 165, 165);
  -webkit-text-fill-color: #dee2e6;
  cursor: default;
}
button:disabled.multisteps-form__progress-btn.mb-4:before {
  background-color: rgb(165, 165, 165);
}
</style>
