import axios from 'axios';
import { TryGetCachedToken } from "../router/TokenManger";
const baseUrl = process.env.VUE_APP_CONTACT_BASE_URL;
const envVars = process.env;
export default {
    async DownloadFileAsync(fileUrl, customerId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: baseUrl
                    + envVars.VUE_APP_GET_FILE_DATA
                        .replace("{fileUrl}", fileUrl)
                        .replace("{customerId}", customerId),
                responseType: 'application/json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            const base64Response = await fetch(`data:${resp.data.contentType}};base64,${resp.data.base64}`);
            const blob = await base64Response.blob();
            var url = window.URL.createObjectURL(blob);
            var anchorElement = document.getElementById("download-link-anchor");
            anchorElement.href = url;
            anchorElement.download = resp.data.fileName;
            anchorElement.target = "_blank";

            anchorElement.click();
            URL.revokeObjectURL(url);
            return true;
        }
        catch (error) {
            return error;
        }
    },

    async UploadFileAsync(fileName,customerId,base64) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }

            console.log(base64)

            const resp = await axios({
                method: 'POST',
                url: baseUrl
                    + envVars.VUE_APP_POST_FILE
                        .replace("{fileName}", fileName)
                        .replace("{customerId}", customerId),
                headers: {
                    'content-type': 'application/json'
                },
                data: base64,

            });

            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetAllCustomerFilesAsync(customerId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: baseUrl
                    + envVars.VUE_APP_GET_ALL_CUSTOMERS_FILES
                        .replace("{customerId}", customerId),
                responseType: 'application/json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    FileRequestObject(){
        return{
            
        }

    }
}