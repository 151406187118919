const BUSINESS_START_HOUR = parseInt(process.env.VUE_APP_BUSINESS_DAY_START_HOUR ?? "8")
const BUSINESS_END_HOUR = parseInt(process.env.VUE_APP_BUSINESS_DAY_END_HOUR ?? "18")


export function formatUkDate(date: Date) {
    return [
        date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate(),
        date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1).toString()
            : date.getMonth() + 1,
        date.getFullYear(),
        ].join("/");
}

export function formatTime(date: Date) {
    return [
        date.getHours() < 10
        ? "0" + date.getHours().toString()
        : date.getHours(),
        date.getMinutes() < 10
        ? "0" + date.getMinutes().toString()
        : date.getMinutes(),
    ].join(":");
}

export function clampToBusinessDay(date: string) {
    const asDate = new Date(date)

    if (asDate.getUTCHours() < BUSINESS_START_HOUR) {
        asDate.setHours(8)
        asDate.setMinutes(0)
        asDate.setSeconds(0)
        asDate.setMilliseconds(0)
    } else if (asDate.getUTCHours() > BUSINESS_END_HOUR) {
        asDate.setHours(BUSINESS_END_HOUR)
        asDate.setMinutes(0)
        asDate.setSeconds(0)
        asDate.setMilliseconds(0)
    }
         
    return asDate.toISOString();
}