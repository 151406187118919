<template>
  <div
    :id="id"
    class="overflow-y-scroll-create"
    style="max-height: 70vh; padding: 1rem"
  >
    <div>
      <div hidden>
        <button class="clear-values-btn" @click="clearValues()"></button>
      </div>
      <div><b>Recommended Action template*:</b></div>
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; flex-direction: row">
          <div class="activity-type-search" hidden>
            <input
              @input="filterPrompts($event)"
              :placeholder="currentSearchValue"
            />
          </div>
          <div class="activity-type-selector" style="overflow: hidden">
            Select a targeted activity...
          </div>
          <svg
            v-show="!showActivityTypeDrpDwn"
            @click="
              () => {
                showActivityTypeDrpDwn = true;
                hideScroll('activity');
              }
            "
            class="tgl-page-dropdown"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
            />
          </svg>
          <svg
            v-show="showActivityTypeDrpDwn"
            @click="
              () => {
                showActivityTypeDrpDwn = false;
                showScroll('activity');
              }
            "
            class="tgl-page-dropdown"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
            />
          </svg>
        </div>
        <div class="activity-type-options" v-show="showActivityTypeDrpDwn">
          <div
            v-for="item in getFilteredPrompts"
            :key="item.id"
            @click="
              () => {
                selectTargetedActivity(item.id);
                showActivityTypeDrpDwn = false;
              }
            "
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="margin-top: 1rem;">
        <div><b>Status*:</b></div>
     <div style="display: flex; flex-direction: column">
            <div style="display: flex; flex-direction: row">
              <div class="status-selector">{{currentSelectedStatus}}</div>
              <svg
                v-show="!showStateCodeDrpDwn"
                @click="()=>{showStateCodeDrpDwn = true; hideScroll('status');}"
                class="tgl-page-dropdown"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                />
              </svg>
              <svg
                v-show="showStateCodeDrpDwn"
                @click="()=>{showStateCodeDrpDwn = false; showScroll('status');}"
                class="tgl-page-dropdown"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                />
              </svg>
            </div>
            <div class="status-options" v-show="showStateCodeDrpDwn">
              <div
                v-for="item in statusCodes"
                :key="item"
                @click="
                  () => {
                    selectStatusCode(item);
                    showStateCodeDrpDwn = false;
                  }
                "
              >
                {{ item }}
              </div>
            </div>
        </div>
    </div> -->
    <div style="margin-top: 1rem" class="crte-act-datetime">
      <!-- <div style="margin-bottom: 1rem;">
            <div><b>Scheduled Start*:</b></div>
            <input type="date" class="crte-act-input"
            v-model="newAction.scheduledStart"
            :min="getDateInputString(new Date(new Date().toUTCString()))" disabled/>
            <div style="margin-top: 0.3rem;" v-show="errors.start != ''" 
            class="text-danger">{{errors.start}}</div>
        </div> -->
      <div style="margin-bottom: 1rem">
        <div><b>Due Date*:</b></div>
        <input
          type="date"
          class="crte-act-input"
          v-model="newAction.scheduledEnd"
          :min="
            getDateInputString(
              addDaysToDate(new Date(new Date().toUTCString()), 1)
            )
          "
          disabled
        />
        <div
          style="margin-top: 0.3rem"
          v-show="errors.end != ''"
          class="text-danger"
        >
          {{ errors.end }}
        </div>
      </div>
    </div>
    <div v-if="!getIsReadOnlyActionEnabled" class="crte-act-section">
      <div><b>Action*:</b></div>
      <input
        style="width: 22rem"
        class="crte-act-input"
        v-model="newAction.subject"
      />
      <div
        style="margin-top: 0.3rem"
        v-show="errors.subject != ''"
        class="text-danger"
      >
        {{ errors.subject }}
      </div>
    </div>
    <div v-if="getIsReadOnlyActionEnabled" class="crte-act-section">
      <div><b>Action*:</b></div>
      <input
        style="width: 22rem"
        class="crte-act-input"
        :readonly="newAction.recommendedActionCatalog != ''"
        v-model="newAction.subject"
      />
      <div
        style="margin-top: 0.3rem"
        v-show="errors.subject != ''"
        class="text-danger"
      >
        {{ errors.subject }}
      </div>
    </div>
    <div class="crte-act-section" hidden>
      <div><b>Url:</b></div>
      <input
        style="width: 22rem"
        class="crte-act-input act-url-input"
        disabled
        v-model="newAction.actionUrl"
      />
      <div class="text-danger" v-show="errors.url != ''">{{ errors.url }}</div>
    </div>
    <div class="crte-act-section">
      <div><b>What will I get from doing this?*:</b></div>
      <textarea
        style="width: 22rem; height: 7.5rem"
        class="crte-act-input"
        :readonly="newAction.recommendedActionCatalog != ''"
        v-model="newAction.motivation"
      />
      <div class="text-danger" v-show="errors.motivation != ''">
        {{ errors.motivation }}
      </div>
    </div>
    <!-- <div class="crte-act-section">
        <div><b>Description*:</b></div>
        <textarea style="width: 22rem; height: 7.5rem;" class="crte-act-input" 
        disabled v-model="newAction.description"/>
        <div class="text-danger" v-show="errors.description != ''">{{errors.description}}</div>
    </div> -->
    <!-- <div class="upd-act-section" style="display: flex; align-items: center;">
        <div><b>Estimated Effort*:</b></div>
        <input type="number" style="height: 1.5rem; width: 3.2rem; 
        margin-left: 0.6rem; padding: 0.2rem; padding-right: 0;" class="crte-act-input" 
        disabled v-model="newAction.estimatedEffort" min="0" max="100"/>
        <div class="text-danger" v-show="errors.effort != ''">{{errors.effort}}</div>
    </div> -->
    <!-- <div class="crte-act-section" style="display: flex; align-items: center;">
        <div><b>Is Mandated:</b></div>
        <input type="checkbox" style="height: 1rem; width: 1rem; margin-left: 0.6rem;" class="crte-act-input" 
        disabled v-model="newAction.isMandated"/>
    </div> -->
    <button
      style="margin: 0; margin-right: 0.75rem; background-color: #ededed"
      class="btn"
      @click="clearValues(), toggleModal()"
    >
      Cancel
    </button>
    <button
      style="margin: 0; margin-right: 0.75rem; background-color: #ededed"
      class="btn crte-act-input"
      @click="createAction()"
      disabled
    >
      Save
    </button>
  </div>
</template>
<script>
import { mapState, mapActions } from "pinia";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
import { useCommonStore } from "@/store/commonStore";
import { useMainStore } from "../store/mainStore";
import { useEmployabilityActionsStore } from "@/store/employabilityActionsData";

export default {
  name: "create-action",
  data() {
    return {
      activitySelected: false,
      showActivityTypeDrpDwn: false,
      showStateCodeDrpDwn: false,
      currentSearchValue: "Search...",
      currentSelectedStatus: "Select status...",
      currentlyExtended: [],
      filteredPrompts: [],
      statusCodes: [
        "Recommended",
        "Current",
        "Completed",
        "Archive",
        "Failed to Complete",
      ],
      newAction: {
        actionUrl: "",
        subject: "",
        description: "",
        motivation: "",
        isMandated: false,
        isRequired: false,
        priorityCode: 0,
        progress: 0,
        estimatedEffort: 1,
        statusCode: "",
        stateCode: "Open",
        scheduledStart: this.getUTCDateString(new Date(new Date())),
        scheduledEnd: null,
        recommendedActionCatalog: "",
        recommendedAction: "",
      },
      errors: {
        start: "",
        end: "",
        subject: "",
        url: "",
        motivation: "",
        description: "",
        effort: "",
      },

      pathway:null,
    };
  },
  props: {
    id: {
      type: String,
      default: "create-action",
    },
    shouldCreateActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useCommonStore, [
      "getRecommendedActionTemplates",
      "gethasCreatedActions",
      "getActions",
      "getPerformPassportCheck",
      "getUnfilteredAssessmentResults",
      "getPathway",
    ]),
    ...mapState(useAdvisorDashboardStore, [
      "getTargetedActivities",
      "getCurrentActionToAddTask",
    ]),
    ...mapState(useMainStore, ["getIsReadOnlyActionEnabled"]),

    ...mapState(useEmployabilityActionsStore, [
      "getPathwayA",
      "getPathwayB",
      "getPathwayC",
    ]),

    // arePassportActionsAlreadyCreated() {
    //   const allActionSubjects = new Set(
    //     Object.values(this.getActions) // Get all action arrays
    //       .flat() // Flatten into a single array
    //       .filter(Boolean) // Remove any null or undefined entries
    //       .map((action) => action.subject)
    //   );

    //   return this.getPathwayA.actions.every((requiredAction) =>
    //     allActionSubjects.has(requiredAction.subject)
    //   );
    // },

    



    arePassportActionsAlreadyCreated() {
      const allActionSubjects = new Set(
        Object.values(this.getActions)
          .flat()
          .filter(Boolean)
          .map((action) => action.subject)
      );

      // Determine which pathway actions to use
      let requiredActions;
      if (!this.getPathway) {
        console.warn("getPathway is null, using default pathway (Pathway A)");
        requiredActions = this.getPathwayA.actions; // Use Pathway A as default
      } else {
        switch (this.getPathway) {
          case "Pathway A":
            requiredActions = this.getPathwayA.actions;
            break;
          case "Pathway B":
            requiredActions = this.getPathwayB.actions; // Assuming getPathwayB exists
            break;
          case "Pathway C":
            requiredActions = this.getPathwayC.actions; // Assuming getPathwayC exists
            break;
          default:
            console.error("Invalid pathway value:", this.getPathway);
            return false; // Or throw an error
        }
      }

      // Check if all required actions are already created
      return requiredActions.every((requiredAction) =>
        allActionSubjects.has(requiredAction.subject)
      );
    },

    getFilteredPrompts() {
      if (
        this.filteredPrompts.length == 0 &&
        this.currentSearchValue == "Search..."
      ) {
        return this.getRecommendedActionTemplates;
      } else {
        return this.filteredPrompts;
      }
    },
  },
  mounted() {
    //  document.getElementById("modal-close").addEventListener("click", ()=>{
    //    this.clearValues();
    // })
  },
  methods: {
    ...mapActions(useCommonStore, [
      "CreateNewAction",
      "CreateNewTask",
      "getUTCDateString",
      "addDaysToDate",
      "setHasCreatedActions",
      "setPerformPassportCheck",
    ]),
    ...mapActions(useAdvisorDashboardStore, [
      "toggleModal",
      "setCurrentActionToAddTask",
    ]),


    findPathway() {
      //assessmentName = ThemeId
      const mindsetAssessment = this.getUnfilteredAssessmentResults?.assessments?.find(
        (assessment) => assessment.assessmentName === process.env.VUE_APP_SURVEY_ASSESSMENT_NAME || process.env.VUE_APP_SURVEY_ASSESSMENT_NAME_TAKE_INITIAL
      );
     
      if (!mindsetAssessment) {
        console.warn("No 'Mindset and Insight' assessment found.");
        return null;
      }
 
      const pathwaySet = mindsetAssessment.questionSets.find(
        (set) => set.category === process.env.VUE_APP_SURVEY_PATHWAY_CATEGORY
      );
 
      if (!pathwaySet) {
        console.warn("No 'PathwayForCustomerJourney' question set found.");
        return null;
      }
 
      const pathwayMatch = pathwaySet.questions[0]?.answer?.match(/Pathway [A-C]/);
 
      if (pathwayMatch) {
        this.pathway = pathwayMatch[0];
      } else {
        console.warn("No valid pathway answer found.");
      }
    },

    clearValues() {
      console.debug("called");
      if (
        document.getElementsByClassName("overflow-y-hidden-create").length > 0
      ) {
        document
          .getElementsByClassName("overflow-y-hidden-create")[0]
          .classList.add("overflow-y-scroll-create");
        document
          .getElementsByClassName("overflow-y-hidden-create")[0]
          .classList.remove("overflow-y-hidden-create");
      }
      document.getElementsByClassName("activity-type-selector")[0].innerHTML =
        "Select a targeted activity...";
      (this.activitySelected = false),
        (this.showActivityTypeDrpDwn = false),
        (this.showStateCodeDrpDwn = false),
        (this.currentSearchValue = "Search..."),
        (this.currentSelectedStatus = "Select status..."),
        (this.currentlyExtended = []),
        (this.filteredPrompts = []),
        (this.statusCodes = [
          "Recommended",
          "Current",
          "Completed",
          "Archive",
          "Failed to Complete",
        ]),
        (this.newAction = {
          actionUrl: "",
          subject: "",
          description: "",
          motivation: "",
          isMandated: false,
          priorityCode: 0,
          progress: 0,
          estimatedEffort: 1,
          statusCode: "",
          stateCode: "Open",
          scheduledStart: this.getUTCDateString(new Date(new Date())),
          scheduledEnd: null,
          recommendedActionCatalog: "",
          recommendedAction: "",
        }),
        (this.errors = {
          start: "",
          end: "",
          subject: "",
          url: "",
          motivation: "",
          description: "",
          effort: "",
        });
      this.setCurrentActionToAddTask(null);
    },
    getDateInputString(date) {
      return [
        date.getFullYear(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : date.getMonth() + 1,
        date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate(),
      ].join("-");
    },
    getDateFormatString(date) {
      return [
        [
          date.getFullYear(),
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1).toString()
            : date.getMonth() + 1,
          date.getDate() < 10
            ? "0" + date.getDate().toString()
            : date.getDate(),
        ].join("-"),
        [
          date.getHours() + 1 < 10
            ? "0" + (date.getHours() + 1).toString()
            : date.getHours() + 1,
          date.getMinutes() + 1 < 10
            ? "0" + (date.getMinutes() + 1).toString()
            : date.getMinutes() + 1,
        ].join(":"),
      ].join("T");
    },
    selectTargetedActivity(id) {
      var selected = this.getRecommendedActionTemplates.find(
        (ta) => ta.id == id
      );
      if (selected.name != "Custom") {
        this.activitySelected = true;
        this.newAction.recommendedAction = selected.id;
        this.newAction.recommendedActionCatalog = selected.catalogName;
        this.newAction.motivation = selected.motivation;
        this.newAction.subject = selected.name;
        this.newAction.actionUrl = selected.actionUrl;
        this.newAction.isRequired = selected.isRequired;
        this.newAction.isMandatable = selected.isMandatable;
        this.newAction.category = selected.category;
      } else {
        this.activitySelected = true;
        this.newAction.recommendedAction = "";
        this.newAction.recommendedActionCatalog = "";
        this.newAction.motivation = "";
        this.newAction.subject = "";
        this.newAction.actionUrl = "";
        this.newAction.category = "Activity";
        this.newAction.isMandatable = true;
      }
      document.getElementsByClassName("activity-type-selector")[0].innerHTML =
        selected.name;
      var enable = document.getElementsByClassName("crte-act-input");
      for (var i = 0; i < enable.length; i++) {
        enable[i].disabled = false;
      }
      if (selected.id.includes("evSurvey-")) {
        document.getElementsByClassName("act-url-input")[0].disabled = true;
      } else {
        document.getElementsByClassName("act-url-input")[0].disabled = false;
      }
      this.showScroll("activity");
    },
    selectStatusCode(selected) {
      document.getElementsByClassName("status-selector")[0].innerHTML =
        selected;
      this.currentSelectedStatus = selected;
      this.showScroll("status");
    },
    hideScroll(type) {
      if (!this.currentlyExtended.includes(type)) {
        this.currentlyExtended.push(type);
      }
      if (type == "activity") {
        document.getElementsByClassName(
          "activity-type-search"
        )[0].hidden = false;
        document.getElementsByClassName(
          "activity-type-selector"
        )[0].hidden = true;
      }
      if (
        document.getElementsByClassName("overflow-y-scroll-create").length >
          0 &&
        this.currentlyExtended.length > 0
      ) {
        document
          .getElementsByClassName("overflow-y-scroll-create")[0]
          .classList.add("overflow-y-hidden-create");
        document
          .getElementsByClassName("overflow-y-scroll-create")[0]
          .classList.remove("overflow-y-scroll-create");
      }
    },
    showScroll(type) {
      this.currentlyExtended = this.currentlyExtended.filter((i) => i != type);
      if (type == "activity") {
        this.filteredPrompts = [];
        document.getElementsByClassName(
          "activity-type-search"
        )[0].hidden = true;
        document.getElementsByClassName(
          "activity-type-selector"
        )[0].hidden = false;
        this.currentSearchValue = "Search...";
        document.getElementsByClassName(
          "activity-type-search"
        )[0].children[0].value = "";
      }
      if (
        document.getElementsByClassName("overflow-y-hidden-create").length >
          0 &&
        this.currentlyExtended.length == 0
      ) {
        document
          .getElementsByClassName("overflow-y-hidden-create")[0]
          .classList.add("overflow-y-scroll-create");
        document
          .getElementsByClassName("overflow-y-hidden-create")[0]
          .classList.remove("overflow-y-hidden-create");
      }
    },
    filterPrompts(event) {
      this.currentSearchValue = event.target.value;
      this.filteredPrompts = this.getRecommendedActionTemplates.filter((a) =>
        a.name.toLowerCase().includes(this.currentSearchValue.toLowerCase())
      );
    },
    createAction() {
      var isValid = true;
      this.errors = {
        start: "",
        end: "",
        subject: "",
        url: "",
        motivation: "",
        description: "",
        effort: "",
      };
      switch (this.currentSelectedStatus) {
        case "Recommended":
          this.newAction.stateCode = "Available";
          break;
        case "Current":
          this.newAction.stateCode = "Open";
          break;
        case "Completed":
          this.newAction.stateCode = "Complete";
          break;
        case "Archive":
          this.newAction.stateCode = "Deferred";
          break;
        case "Failed to Complete":
          this.newAction.stateCode = "Canceled";
          break;
      }
      // if(this.newAction.scheduledStart == null){
      //     this.errors.start = "*Required";
      //     isValid = false;
      // }
      if (this.newAction.scheduledEnd == null) {
        this.errors.end = "*Required";
        isValid = false;
      }
      // else if(this.newAction.scheduledStart != null){
      //     if(new Date(this.newAction.scheduledEnd) < new Date(this.newAction.scheduledStart)){
      //         this.errors.end = "End date should be later than start date";
      //         isValid = false;
      //     }
      // }
      if (this.newAction.subject == "") {
        this.errors.subject = "*Required";
        isValid = false;
      }
      if (this.newAction.motivation == "") {
        this.errors.motivation = "*Required";
        isValid = false;
      }
      // if(this.newAction.description == ""){
      //     this.errors.description = "*Required";
      //     isValid = false;
      // }
      if (
        this.newAction.estimatedEffort < 1 ||
        this.newAction.estimatedEffort > 10
      ) {
        this.errors.estimatedEffort = "Must be bewteen 1 & 10";
        isValid = false;
      }
      if (isValid) {
        this.newAction.scheduledEnd = this.getUTCDateString(
          new Date(this.newAction.scheduledEnd)
        );
        this.newAction.scheduledStart == null
          ? (this.newAction.scheduledStart = this.getUTCDateString(new Date()))
          : this.getUTCDateString(new Date(this.newAction.scheduledStart));
        if (this.getCurrentActionToAddTask == null) {
          this.CreateNewAction(this.newAction, true);
        } else {
          this.CreateNewTask(
            this.getCurrentActionToAddTask,
            this.newAction,
            true
          );
        }
        this.clearValues();
        this.toggleModal();
      }
    },

    // createPassportQuestions() {
    //   if (!this.arePassportActionsAlreadyCreated) {
    //     for (const data of this.getPathwayA.actions) {
    //       data.scheduledStart = this.getUTCDateString(new Date());
    //       (data.scheduledEnd = this.getUTCDateString(
    //         this.addDaysToDate(new Date(new Date().toUTCString()), 1)
    //       )),
    //       this.CreateNewAction(data, true);
    //     }
    //   }
    // },

    createPassportQuestions() {
      if (!this.arePassportActionsAlreadyCreated) {
        // Determine which pathway actions to use
        let actions;
        switch (this.pathway) {
          case "Pathway A":
            actions = this.getPathwayA.actions;
            break;
          case "Pathway B":
            actions = this.getPathwayB.actions; // Assuming getPathwayB exists
            break;
          case "Pathway C":
            actions = this.getPathwayC.actions; // Assuming getPathwayC exists
            break;
          default:
            console.error("Invalid pathway value:", this.pathway);
            return; // Or handle the error differently
        }

        // Create actions for the selected pathway
        for (const data of actions) {
          data.scheduledStart = this.getUTCDateString(new Date());
          data.scheduledEnd = this.getUTCDateString(
            this.addDaysToDate(new Date(new Date().toUTCString()), 1)
          );

          this.CreateNewAction(data, true);
        }
      }
    },

    areRequiredSubjectsCompleted() {
      const completedSubjects = new Set(
        this.getActions.completed?.map((action) => action.subject) || []
      );

      // Determine which pathway data to use
      let targetSubjects;
      if (!this.getPathway) {
        console.warn("getPathway is null, using default pathway (Pathway A)");
        targetSubjects = this.getPathwayA.targetSubjects; // Use Pathway A as default
      } else {
        switch (this.getPathway) {
          case "Pathway A":
            targetSubjects = this.getPathwayA.targetSubjects;
            break;
          case "Pathway B":
            targetSubjects = this.getPathwayB.targetSubjects;
            break;
          case "Pathway C":
            targetSubjects = this.getPathwayC.targetSubjects;
            break;
          default:
            console.error("Invalid pathway value:", this.getPathway);
            return false; // Or throw an error
        }
      }

      // Check if all required subjects are completed
      return targetSubjects.every((subject) => completedSubjects.has(subject));
    },
  },
  watch: {
    getPerformPassportCheck(newVal) {
      if (newVal) {
        this.findPathway();
        this.createPassportQuestions();
      }
    },

    // getUnfilteredAssessmentResults: {
    //   immediate: true, // Run the handler immediately when the component is created
    //   handler(newAssessmentResults) {
    //     this.assessmentResults = newAssessmentResults;

    //     if (newAssessmentResults) {
    //       // Check if results are actually available
    //       this.findPathway();
    //     } else {
    //       this.pathway = null; // Reset pathway if results become empty
    //     }
    //   },
    // },
  },
};
</script>
<style>
.overflow-y-scroll-create {
  overflow-y: scroll;
}

.overflow-y-hidden-create {
  overflow-y: hidden;
}

.crte-act-section {
  margin-bottom: 1rem;
}

.crte-act-section div {
  font-size: medium;
}

.crte-act-section input,
.crte-act-section textarea,
.crte-act-datetime input {
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  color: #67748e;
  font-size: medium;
  padding: 0.2rem 0.5rem;
}

.crte-act-datetime b {
  font-size: medium;
  margin-right: 0.4rem;
}

.crte-act-datetime input {
  font-size: medium;
}

.activity-type-selector,
.status-selector {
  width: 18rem;
  height: 1.7rem;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 0 0.7rem;
  color: #67748e;
}

.activity-type-search input {
  width: 18rem;
  height: 1.7rem;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 0 0.7rem;
  color: #67748e;
}

.tgl-activity-type-dropdown {
  width: 0.78rem;
  margin-left: -1.3rem;
}

.tgl-activity-type-dropdown:hover {
  cursor: pointer;
}

.activity-type-options,
.status-options {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  width: 18rem;
  max-width: 18rem;
  z-index: 20;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 2rem;
  max-height: 15rem;
  overflow-y: scroll;
}

.activity-type-options div,
.status-options div {
  background-color: rgb(243 243 243);
  padding: 0.2rem 0.7rem;
}

.activity-type-options div:first-child,
.status-options div:first-child {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.activity-type-options div:last-child,
status-options div:last-child {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.activity-type-options div:hover,
.status-options div:hover {
  cursor: pointer;
  background-color: rgb(235, 235, 235);
}
</style>
