<template>
  <div>
    <!-- Desktop View -->
    <div class="table-responsive card desktop-logic" style="padding: 0 1rem">
      <!-- Table Header -->
      <div
        @click="
          () => {
            show = !show;
          }
        "
        style="
          font-size: 1.5rem;
          padding: 0.7rem;
          font-weight: 600;
          display: flex;
          align-items: center;
        "
      >
        <div>
          <div>Action Plans Due</div>
          <div style="font-size: 0.9rem; display: flex; align-items: center">
            Action plans due (or overdue). View a snapshot of the action plan.
          </div>
        </div>

        <svg
          v-show="!show"
          style="
            min-width: 24px;
            flex-basis: 24px;
            height: 1.5rem;
            margin-left: auto;
          "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
          />
        </svg>
        <svg
          v-show="show"
          style="
            min-width: 24px;
            flex-basis: 24px;
            height: 1.5rem;
            margin-left: auto;
          "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
          />
        </svg>
      </div>

      <!-- Table content (drops down) -->
      <div class="fit-content-l840" v-show="show">
        <!-- Search Filters -->
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 0 1rem 0.3rem 1rem;
          "
        >
          <div style="display: flex; flex-direction: row; flex-wrap: wrap">
            <div style="display: flex; flex-direction: column">
              <div style="display: flex; flex-direction: row"></div>
              <div class="page-num-options"></div>
            </div>

            <div
              style="
                margin-left: 2rem;
                margin-right: 1.5rem;
                display: flex;
                flex-wrap: wrap;
              "
            >
              <label style="font-size: medium; margin-right: 1rem"
                >From Date:</label
              >
              <input
                type="date"
                style="margin-right: 1.4rem"
                class="date-range-input"
                :value="
                  searchFromDate != null && searchFromDate != ''
                    ? formatDateInputString(new Date(searchFromDate))
                    : null
                "
                :max="searchToDate"
                @change="searchFromDate = $event.target.value"
              />

              <label style="font-size: medium; margin-right: 1rem"
                >To Date:</label
              >
              <input
                type="date"
                style="margin-right: 1.4rem"
                class="date-range-input"
                :value="
                  searchToDate != null && searchToDate != ''
                    ? formatDateInputString(new Date(searchToDate))
                    : null
                "
                :min="searchFromDate"
                @change="searchToDate = $event.target.value"
              />

              <div style="display: flex">
                <label style="font-size: medium; margin-right: 1rem"
                  >Filter Advisor:</label
                >
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; flex-direction: row">
                    <div class="search-type-dis">{{ advisorFilter }}</div>
                    <svg
                      v-show="!showAdvisorDropDown"
                      @click="showAdvisorDropDown = true"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                      />
                    </svg>
                    <svg
                      v-show="showAdvisorDropDown"
                      @click="showAdvisorDropDown = false"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                      />
                    </svg>
                  </div>
                  <div class="search-type-options" v-show="showAdvisorDropDown">
                    <div
                      v-for="(advisor, index) in availableAdvisors"
                      :key="index"
                      @click="
                        () => {
                          this.advisorFilter = advisor;
                          this.showAdvisorDropDown = false;
                        }
                      "
                    >
                      {{ advisor }}
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex">
                <label style="font-size: medium; margin-right: 1rem"
                  >Customer Status:</label
                >
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex; flex-direction: row">
                    <div class="search-type-dis">
                      {{ customerStateFilter.statusDisplay }}
                    </div>
                    <svg
                      v-show="!showCustomerStateDropDown"
                      @click="showCustomerStateDropDown = true"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                      />
                    </svg>
                    <svg
                      v-show="showCustomerStateDropDown"
                      @click="showCustomerStateDropDown = false"
                      class="tgl-page-dropdown"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                      />
                    </svg>
                  </div>
                  <div
                    class="search-type-options"
                    v-show="showCustomerStateDropDown"
                  >
                    <div
                      v-for="state in availableCustomerStates"
                      :key="state.status"
                      @click="
                        () => {
                          this.customerStateFilter = state;
                          this.showCustomerStateDropDown = false;
                        }
                      "
                    >
                      {{ state.statusDisplay }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table
          id="datatable-basic"
          class="table table-flush"
          style="table-layout: fixed; width: 100%"
        >
          <thead class="thead-light">
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Due
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Reason
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Customer
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Preview
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Advisor
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="upcoming-audits"
              v-for="(audit, index) in this.items"
              :key="index"
            >
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm"
                :class="this.auditTextCssClass(audit)"
              >
                {{ formatDate(audit.nextAuditDue) }}
                <div v-if="getIsAuditTableFeaturesEnabled" class="appointment-tooltip">
                  <p>
                    {{
                      `Last appt: ${
                        audit.precedingAppointmet?.start
                          ? formatDateTooltip(
                              new Date(audit.precedingAppointmet?.start)
                            )
                          : "Unknown"
                      }`
                    }}
                  </p>
                  <p>
                    {{
                      `Next appt: ${
                        audit.succeedingAppointmet?.start
                          ? formatDateTooltip(
                              new Date(audit.succeedingAppointmet?.start)
                            )
                          : "Not booked"
                      }`
                    }}
                  </p>
                </div>
              </td>

              <td
                v-show="audit.auditPoint == null"
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
              >
                Initial Action Plan
              </td>
              <td
                v-show="audit.auditPoint != null"
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
              >
                4 Weekly Review
              </td>

              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
              >
                <a 
                v-if="!getIsAuditTableFeaturesEnabled"
                >
                  {{ audit.customer }}
                </a>
                <a 
                v-if="getIsAuditTableFeaturesEnabled" 
                :href="`AdvisorDashboard/${audit.customerId}`"
                >
                  {{ audit.customer }}
                </a>
              </td>
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
              >
                <router-link
                  class="view-contract"
                  style="text-decoration: underline"
                  :to="getPreviewContractHref(audit.customerId)"
                  >Preview PDF
                </router-link>
              </td>

              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
              >
                {{ audit.advisor }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
        <TablePaginationVue
          :items="projectedAudits"
          :buttons="4"
          @page-change="$options.interfaces.tablePaginationMobile.setPage"
          @interface="setDesktopPaginationInterface"
          v-model="this.items"
        />
      </div>
    </div>

    <!-- Mobile View -->
    <div class="card open-action-cont open-actions-card mobile-logic">
      <!-- Table Header -->
      <div
        @click="
          () => {
            show = !show;
          }
        "
        style="
          font-size: 1.5rem;
          padding: 0.7rem;
          font-weight: 600;
          display: flex;
          align-items: center;
        "
      >
        <div>
          <div>Action Plans Due</div>
          <div style="font-size: 0.9rem; display: flex; align-items: center">
            Action plans due (or overdue). View a snapshot of the action plan.
          </div>
        </div>

        <svg
          v-show="!show"
          style="
            min-width: 24px;
            flex-basis: 24px;
            height: 1.5rem;
            margin-left: auto;
          "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
          />
        </svg>
        <svg
          v-show="show"
          style="
            min-width: 24px;
            flex-basis: 24px;
            height: 1.5rem;
            margin-left: auto;
          "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
          />
        </svg>
      </div>

      <!-- Table content (drops down) -->
      <div v-show="show">
        <!-- Search Filters -->
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 0 0.7rem 0.3rem 0.7rem;
          "
        >
          <div>
            <div style="margin-right: 1.5rem; display: block">
              <div>
                <label
                  style="
                    font-size: medium;
                    margin-right: 1rem;
                    margin-left: 0px;
                    width: 6rem;
                    display: inline-block;
                  "
                  >From Date:</label
                >
                <input
                  type="date"
                  style="margin-right: 1.4rem; margin-left: 0px"
                  class="date-range-input"
                  :value="
                    searchFromDate != null && searchFromDate != ''
                      ? formatDateInputString(new Date(searchFromDate))
                      : null
                  "
                  :max="searchToDate"
                  @change="searchFromDate = $event.target.value"
                />
              </div>

              <div>
                <label
                  style="
                    font-size: medium;
                    margin-right: 1rem;
                    margin-left: 0px;
                    width: 6rem;
                    display: inline-block;
                  "
                  >To Date:</label
                >
                <input
                  type="date"
                  style="margin-right: 1.4rem"
                  class="date-range-input"
                  :value="
                    searchToDate != null && searchToDate != ''
                      ? formatDateInputString(new Date(searchToDate))
                      : null
                  "
                  :min="searchFromDate"
                  @change="searchToDate = $event.target.value"
                />
              </div>
            </div>

            <div style="display: flex; flex-wrap: wrap">
              <label
                style="
                  font-size: medium;
                  margin-right: 1rem;
                  margin-left: 0px;
                  width: 6rem;
                  display: inline-block;
                "
                >Advisor:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <div class="search-type-dis">{{ advisorFilter }}</div>
                  <svg
                    v-show="!showAdvisorDropDown"
                    @click="showAdvisorDropDown = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showAdvisorDropDown"
                    @click="showAdvisorDropDown = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="search-type-options" v-show="showAdvisorDropDown">
                  <div
                    v-for="(advisor, index) in availableAdvisors"
                    :key="index"
                    @click="
                      () => {
                        this.advisorFilter = advisor;
                        this.showAdvisorDropDown = false;
                      }
                    "
                  >
                    {{ advisor }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Audit Item -->
        <div
          class="card h-100 open-action"
          v-for="(audit, index) in this.items"
          :key="index"
        >
          <div class="p-3 card-header" :style="'padding-bottom: 0;'">
            <div style="display: flex; flex-direction: row">
              <div style="overflow: hidden; display: block; width: 100%">
                <h6
                  class="subject"
                  style="width: 100%"
                  :class="this.auditTextCssClass(audit)"
                >
                  {{ formatDate(audit.nextAuditDue) }}
                </h6>
                <h6 style="width: 100%; color: #67748e">
                  {{ audit.customer }}
                </h6>
              </div>

              <div style="width: 24px; height: 100%">
                <svg
                  @click="onAuditSelect(audit)"
                  style="width: 100%"
                  v-show="!extendedItems.includes(audit)"
                  class="extend-action"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
                <svg
                  @click="onAuditDeselect(audit)"
                  style="width: 100%"
                  v-show="extendedItems.includes(audit)"
                  class="extend-action"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <!-- Audit Item Content (drops down) -->
          <div
            class="card-body"
            v-show="extendedItems.includes(audit)"
            style="padding: 0 1rem 1rem 1rem"
          >
            <ul class="list-group">
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Reason:</strong> &nbsp;
                <div>
                  {{
                    audit.auditPoint == null
                      ? "Initial Action Plan"
                      : "4 Weekly Review"
                  }}
                </div>
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Advisor:</strong> &nbsp;
                <div>
                  {{ audit.advisor }}
                </div>
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <router-link
                  class="view-contract"
                  style="text-decoration: underline"
                  :to="getPreviewContractHref(audit.customerId)"
                  >Preview PDF
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <!-- This uses 'page-change' and 'interface' events to communicate with the desktop pagination -->
        <TablePaginationVue
          :items="projectedAudits"
          :buttons="4"
          @page-change="$options.interfaces.tablePaginationDesktop.setPage"
          @interface="this.setMobilePaginationInterface"
          v-model="this.items"
        />
      </div>
    </div>
  </div>
</template>

<script scoped>
import { mapActions, mapState } from "pinia";
import { useCommonStore } from "@/store/commonStore";
import { useMainStore } from "../store/mainStore";
import { loggedIn } from "@/router/Guard.ts";
import { GetDirectReports } from "@/router/Guard";
import { msalInstance } from "@/authConfig";

import TablePaginationVue from "./TablePagination.vue";

export default {
  name: "signed-audits-table",
  components: {
    TablePaginationVue,
  },

  interfaces: {
    tablePaginationDesktop: {
      setPage: () => {},
    },

    tablePaginationMobile: {
      setPage: () => {},
    },
  },

  data() {
    return {
      extendedItems: [],

      advisorFilter: "[All]",
      showAdvisorDropDown: false,
      customerStateFilter: { status: "[All]", statusDisplay: "[All]" },
      showCustomerStateDropDown: false,
      requested: false,
      directReports: [],
      // Emails for advisors to be checked
      directReportsEmails: [],
      items: [],
      show: false,
      searchFromDate: null,
      searchToDate: null,
      customerStatus: [],
    };
  },
  props: {
    manager: {
      type: Boolean,
      required: true,
    },
    ownerId: {
      type: String,
      required: true,
    },
  },
  watch: {
    searchFromDate: async function () {
      if (!this.requested) {
        this.requested = true;
        await this.search();
        this.requested = false;
      }
    },

    searchToDate: async function () {
      if (!this.requested) {
        this.requested = true;
        await this.search();
        this.requested = false;
      }
    },

    directReports: function () {
      this.directReportsEmails = [];
      this.directReportsEmails[0] = loggedIn.email;

      for (let i = 0; i < this.directReports.length; i++) {
        this.directReportsEmails[this.directReportsEmails.length] =
          this.directReports[i].mail;
      }
    },
  },
  computed: {
    ...mapState(useCommonStore, [
      "getCustomerProjectedAudits",
      "getCustomerStatus",
    ]),
    ...mapState(useMainStore, ["getIsAuditTableFeaturesEnabled"]),
    projectedAudits() {
      let audits = this.getCustomerProjectedAudits;
      if (this.advisorFilter != "[All]") {
        audits = audits.filter((a) => {
          return a.advisor == this.advisorFilter;
        });
      }
      if (this.customerStateFilter.status != "[All]") {
        audits = audits.filter((a) => {
          return a.caseStatus === this.customerStateFilter.status;
        });
      }

      return audits;
    },
    searchFromOpen() {
      return document.activeElement == this.$refs["search-from-date"];
    },
    normalizedSearchTo() {
      return this.formatDateInputString(
        this.addDaysToDate(new Date(this.searchToDate), 1)
      );
    },
    availableAdvisors() {
      let projectedAudits = this.getCustomerProjectedAudits;
      let advisors = [];
      advisors[0] = "[All]";

      for (let i = 0; i < projectedAudits.length; i++) {
        if (advisors.indexOf(projectedAudits[i].advisor) == -1) {
          advisors[advisors.length] = projectedAudits[i].advisor;
        }
      }

      return advisors;
    },
    availableCustomerStates() {
      const allStates = this.customerStatus;
      const states = [{ status: "[All]", statusDisplay: "[All]" }];

      for (let i = 0; i < allStates.length; i++) {
        states.push({
          status: allStates[i].id,
          statusDisplay: allStates[i].display,
        });
      }

      return states;
    },
  },
  methods: {
    ...mapActions(useCommonStore, [
      "GetCustomer",
      "SetCustomerId",
      "GetContract",
      "GetCustomerProjectedAudits",
      "GetGroupCustomerProjectedAudits",
      "addDaysToDate",
      "GetCustomerStatus",
    ]),
    onAuditSelect(audit) {
      this.extendedItems.push(audit);
    },

    onAuditDeselect(audit) {
      this.extendedItems = this.extendedItems.filter((i) => i != audit);
    },

    auditTextCssClass(audit) {
      var today = new Date().setHours(0, 0, 0, 0);
      var auditDate = new Date(audit.nextAuditDue).setHours(0, 0, 0, 0);

      if (auditDate < today) {
        return "overdue";
      } else if (auditDate == today) {
        return "due-today";
      }

      return "";
    },
    async search() {
      if (new Date(this.searchFromDate) > new Date(this.searchToDate)) {
        this.searchFromDate = this.searchToDate;
      }

      if (this.directReports.length > 0) {
        // This will refresh the results in the store
        await this.GetGroupCustomerProjectedAudits(
          this.directReportsEmails,
          this.searchFromDate,
          this.normalizedSearchTo
        );
      }
    },
    formatDate(date) {
      return new Date(date).toDateString();
    },
    formatDateInputString(date) {
      return [
        date.getFullYear(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
      ].join("-");
    },
    formatDateTooltip(date) {
      return [
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
        date.getFullYear(),
      ].join("/");
    },
    getPreviewContractHref(customerId) {
      return `/PreviewContract/${customerId}`;
    },

    setDesktopPaginationInterface(childInterface) {
      this.$options.interfaces.tablePaginationDesktop = childInterface;
    },

    setMobilePaginationInterface(childInterface) {
      this.$options.interfaces.tablePaginationMobile = childInterface;
    },
  },

  async mounted() {
    let currentDate = new Date();

    let results = await GetDirectReports(msalInstance, loggedIn.id);
    let list = [];

    for (let i = 0; i < results.value.length; i++) {
      if (list.indexOf(results.value[i]) == -1) {
        list[list.length] = results.value[i];
      }
    }

    this.directReports = list;
    this.searchFromDate = this.formatDateInputString(
      this.addDaysToDate(currentDate, -7)
    );
    this.searchToDate = this.formatDateInputString(
      this.addDaysToDate(currentDate, 7)
    );
  },
  async created() {
    await this.GetCustomerStatus();
    this.customerStatus = this.getCustomerStatus;
  },
};
</script>

<!-- Common Styling -->
<style scoped>
.contract-link {
  text-decoration: underline;
  letter-spacing: -0.025rem;
  color: #344767;
}

.contract-link:hover {
  text-decoration: underline;
}

.hover-tooltip {
  z-index: 2;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.hover-tooltip div:last-child {
  padding: 0.5rem;
  border: 1px solid #5b5b5b;
  color: #5b5b5b;
  background-color: rgb(245, 245, 245);
  font-size: smaller;
  max-width: 25rem;
  white-space: pre-wrap;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.hover-tooltip div:first-child {
  height: 1.8rem;
}

.upcoming-audits:hover .appointment-tooltip {
  display: block;
}

.appointment-tooltip {
  display: none;
  width: fit-content;
  background-color: #f5f5f5;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  margin-left: 50px;

  /* Position the tooltip  */
  position: absolute;
  z-index: 1000;
}

.appointment-tooltip p {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;
  font-size: 12px;
}

.page-amount-input,
.search-type-dis {
  width: 4.5rem;
  height: 1.7rem;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 0 0.7rem;
  color: #67748e;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 1.5rem;
}

.search-type-dis {
  width: 14rem;
}

.date-range-input {
  height: 1.7rem;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  color: #67748e;
}

.tgl-page-dropdown {
  width: 0.78rem;
  margin-left: -1.3rem;
}

.tgl-page-dropdown:hover {
  cursor: pointer;
}

.page-num-options,
.search-type-options {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  width: 4.5rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 2rem;
  max-height: 10rem;
  overflow-y: auto;
}

.search-type-options {
  width: 14rem;
}

.page-num-options div,
.search-type-options div {
  background-color: rgb(243 243 243);
  padding: 0.2rem 0.7rem;
  width: 100%;
}

.page-num-options div:first-child,
.search-type-options div:first-child {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.page-num-options div:last-child,
.search-type-options div:last-child {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.page-num-options div:hover,
.search-type-options div:hover {
  cursor: pointer;
  background-color: rgb(235, 235, 235);
}

.view-contract:hover {
  cursor: pointer;
}

.overdue {
  color: crimson;
  font-weight: bold;
}

.due-today {
  color: goldenrod;
  font-weight: bold;
}

.table td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}

.table th {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

@media (max-width: 900px) {
  .fit-content-l840 {
    width: fit-content;
  }
}

@media (max-width: 900px) {
  .desktop-logic {
    display: none;
  }

  .mobile-logic {
    display: block;
  }
}

@media (min-width: 900px) {
  .desktop-logic {
    display: block;
  }

  .mobile-logic {
    display: none;
  }
}
</style>

<!-- Mobile Styling -->
<style scoped>
.header {
  font-size: 1.5rem;
  font-weight: 400;
}

.memo-body {
  padding: 1rem;
  padding-top: 0.6rem;
}

.memo {
  padding: 0.8rem;
  margin-bottom: 0.8rem;
}

.memo:last-child {
  margin-bottom: 0;
}

#memo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  background-color: rgba(15, 15, 15, 0.527);
  width: 100%;
  height: 100%;
  padding-top: 20%;
}

#memo-modal {
  border-radius: 1rem;
  background-color: rgb(240, 240, 240);
}

.memo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
}

.memo-header span {
  font-size: x-large;
}

.memo-header svg {
  width: 1rem;
  fill: #2c3e50;
  cursor: pointer;
}

.memo div:first-child {
  font-size: 0.8rem;
  word-break: break-all;
}

@media (max-width: 600px) {
  #memo-modal {
    min-width: 95%;
    max-width: 95%;
    margin: 0 2.5%;
  }
}

.tasks-card {
  padding: 0.5rem 1rem;
  background-color: #c8d3d8;
}

.page-sel-btn {
  font-weight: 0;
  background-color: #fff;
  padding: 0.2rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 0.3rem;
  transition: 1687ms;
  transform: translateY(0);
  cursor: pointer;
  font-size: 0.8rem;
  margin-right: 0.3rem;
}

.page-sel-btn + .selected {
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
  color: white;
}

.page-sel-btn + .disabled {
  background-color: #d0d0d0;
}

.page-sel-btn:last-child {
  margin-right: 0;
}

.page-selector {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.page-arrow {
  width: 0.6rem;
  fill: #344767;
}

.page-sel-btn + .disabled .page-arrow {
  fill: #666d7a;
}

.open-action {
  margin-bottom: 1rem;
}

.open-action:last-child {
  margin-bottom: 0;
}

.open-actions-card {
  border-radius: 1rem;
  padding: 1rem;
  /* background-color: rgb(240, 240, 240); */
  /* background-color: rgba(159, 168, 184, 0.849);  
      backdrop-filter: blur(5px); */
}

.extend-action {
  width: 6%;
  padding: 0;
  margin: 0.2rem 2.5% auto 2.5%;
  cursor: pointer;
  fill: #344767;
}

.extend-tasks {
  width: 6%;
  padding: 0;
  margin: 0rem 2.5%;
  cursor: pointer;
  fill: #344767;
}

.subject {
  max-width: 89%;
  word-wrap: normal;
  margin: 0;
}

.open-action-header {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.open-action-header svg {
  width: 1.9rem;
  height: 1.9rem;
  fill: #2c3e50;
  margin-right: 0.5rem;
}

.open-action-cont {
  padding: 0.6rem 1rem;
  margin-bottom: 0.5rem;
}

.prog-upt-btn {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

@media (min-width: 600px) {
  .my-actions-main {
    padding: 0 20%;
  }

  .extend-action {
    width: 1.5rem;
  }

  .extend-tasks {
    width: 1.5rem;
  }

  #memo-modal {
    margin: 0 26%;
    width: 48%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: calc(6rem / 3);
  height: calc(3.4rem / 3);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="date"] {
  box-sizing: border-box;
  padding: 0 0.7rem;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(226, 60, 60);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.disabled-switch .slider {
  background-color: rgb(161, 73, 73);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: calc(2.6rem / 3);
  width: calc(2.6rem / 3);
  left: calc(0.4rem / 3);
  bottom: calc(0.4rem / 3);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #47e441;
}

input:focus + .slider {
  box-shadow: 0 0 1px #47e441;
}

.disabled-switch input:checked + .slider {
  background-color: #64be61;
}

.disabled-switch input:focus + .slider {
  box-shadow: 0 0 1px #64be61;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(2.6rem / 3));
  -ms-transform: translateX(calc(2.6rem / 3));
  transform: translateX(calc(2.6rem / 3));
}

/* Rounded sliders */
.slider.round {
  border-radius: calc(3.4rem / 3);
}

.slider.round:before {
  border-radius: 50%;
}

.loader {
  border: 0.7rem solid #f3f3f3;
  /* Light grey */
  border-top: 0.7rem solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  animation: spin 2s linear infinite;
}

.loader-container {
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .width-100-l600 {
    width: 100% !important;
  }
  .pd-t-l600 {
    padding: 0.75rem 0rem 0rem 0rem !important;
  }
  .pd-tb-l100 {
    padding: 0.75rem 0rem !important;
  }
  .pd-lr-l600 {
    padding: 0rem 0.75rem !important;
  }
}
</style>
