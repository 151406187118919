<template>
<div style="padding: 0.5rem 1.5rem;">
    <navBarVue style="background-color: #6F9600;"/>
    <div style="margin: 0.75rem 0;">
    <div class="leave-bar">
        <svg @click="navBack()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
    </div>
        <SignableContractVue class="card"/>
    </div>
</div>
</template>
<script>
import SignableContractVue from '@/components/SignableContract.vue';
import navBarVue from '@/components/navBar.vue';
import { NavBackwards } from "@/router/index";
export default {
    name: "contract-view",
    data(){
        return{
            customerId: null,
            surveryName: null,
            surveyVersion: null
        }
    },
    components:{
        SignableContractVue,
        navBarVue
    },
    methods:{
        navBack(){
            NavBackwards();
        }
    }
}
</script>
<style scoped>
.leave-bar{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: right;
    margin-bottom: -2.3rem;
    padding: 0.3rem 0.3rem 0 0;
    padding-right: 0.6rem;
}

.leave-bar svg{
    width: 2rem;
    height: 2rem;
    z-index: 1;
    cursor: pointer;
}

.leave-bar svg:hover{
    fill: #243147;
}
</style>