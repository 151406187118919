<template>
  <div>
    <!-- Desktop View -->
    <div class="table-responsive card desktop-logic" style="padding: 0 1rem">
    <div
      @click="
        () => {
          show = !show;
        }
      "
      style="
        font-size: 1.5rem;
        padding: 0.7rem;
        font-weight: 600;
        display: flex;
        align-items: center;
      "
    >
      External Activities
      <svg
        v-show="!show"
        style="height: 1.5rem; margin-left: auto"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
        />
      </svg>
      <svg
        v-show="show"
        style="height: 1.5rem; margin-left: auto"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
        />
      </svg>
    </div>
    <div class="fit-content-l840" v-show="show">
      <div
        style="display: flex;
          justify-content: space-between;
          flex-direction: row;
          padding: 0 1rem 0.3rem 1rem;
        "
      >
        <div style="display: flex; flex-direction: row;">
          <div style="display: flex; flex-direction: row">
            <label style="font-size: medium; margin-right: 1rem">Items Per Page:</label>
            <div style="display: flex; flex-direction: column">
              <div style="display: flex; flex-direction: row">
                <input
                  class="page-amount-input"
                  :value="currentPageLength"
                  @change="changePageLength($event.target.value)"
                />
                <svg
                  v-show="!showPageAmountDrpDwn"
                  @click="showPageAmountDrpDwn = true"
                  class="tgl-page-dropdown"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                  />
                </svg>
                <svg
                  v-show="showPageAmountDrpDwn"
                  @click="showPageAmountDrpDwn = false"
                  class="tgl-page-dropdown"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                  />
                </svg>
              </div>
              <div class="page-num-options" v-show="showPageAmountDrpDwn">
                <div
                  v-for="index in Math.ceil(getActivityAmount / pageIncrements)"
                  :key="index"
                  @click="
                    () => {
                      changePageLength(index * pageIncrements);
                      showPageAmountDrpDwn = false;
                    }
                  "
                >
                  {{ index * pageIncrements }}
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; margin-left: 1.2rem;">
            <label style="font-size: medium; margin-right: 1rem">Activity Type:</label>
            <div style="display: flex; flex-direction: column">
              <div style="display: flex; flex-direction: row">
                <div
                  class="selected-filter"
                >{{currentTypeCodeFilter}}</div>
                <svg
                  v-show="!showTypeCodeDrpDwn"
                  @click="showTypeCodeDrpDwn = true"
                  class="tgl-type-dropdown"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                  />
                </svg>
                <svg
                  v-show="showTypeCodeDrpDwn"
                  @click="showTypeCodeDrpDwn = false"
                  class="tgl-type-dropdown"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                  />
                </svg>
              </div>
              <div class="type-filter-options" v-show="showTypeCodeDrpDwn">
                <div
                  v-for="item in activityTypes"
                  :key="item"
                  @click="
                    () => {
                      currentTypeCodeFilter = item;
                      showTypeCodeDrpDwn = false;
                    }
                  "
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          font-size: 1.3rem;
        "
        v-if="getNextActivities.length == 0"
      >
        <svg
          style="width: 1.8rem; margin-right: 0.5rem"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <defs>
            <linearGradient id="gradient">
              <stop offset="0%" stop-color="#2dce89" />
              <stop offset="100%" stop-color="#2dcecc" />
            </linearGradient>
          </defs>
          <path
            fill="url(#gradient)"
            d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
          />
        </svg>
        No Activities
      </div>
      <table id="datatable-basic" class="table table-flush" style="table-layout: fixed; width: 100%"> 
        <thead class="thead-light">
          <tr>
            <th
              style="width: 0px"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Activity Type Code
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Scheduled Start
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Scheduled End
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Subject
            </th>
           
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Description
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Modified On
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="activity in this.items" :key="activity.id">
            <td
              style="padding: 0.2rem 0.4rem; max-width: 1.2rem;min-width: 1.2rem;"
              class="text-sm font-weight-normal"
            >
              <div title="Convert to Action">
                <svg @click="createAction(activity.id)" class="to-act" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg>
              </div>
            </td>
            <td
              style="padding: 0.75rem 1.5rem;"
              class="text-sm font-weight-normal"
            >
              <div :title="activity.classification.activityTypeCode" style="cursor: pointer; max-width: 8rem; overflow:hidden; text-overflow: ellipsis;">
                {{activity.classification.activityTypeCode}}
              </div>
            </td>
            <td
              style="padding: 0.75rem 1.5rem; color: limegreen"
              class="text-sm font-weight-normal"
            >
              {{
                activity.timeline.scheduledStart != null
                  ? formatDate(new Date(activity.timeline.scheduledStart)) + " - " + formatTime(new Date(activity.timeline.scheduledStart))
                  : ""
              }}
            </td>
            <td
              style="padding: 0.75rem 1.5rem; color: limegreen"
              class="text-sm font-weight-normal"
            >
              {{
                activity.timeline.scheduledEnd != null
                  ? formatDate(new Date(activity.timeline.scheduledEnd)) + " - " +  formatTime(new Date(activity.timeline.scheduledEnd))
                  : ""
              }}
            </td>
            <td
              style="padding: 0.75rem 1.5rem"
              class="text-sm font-weight-normal"
            >
              <div :title="activity.subject" style="cursor: pointer">
                {{
                  activity.subject != null
                    ? activity.subject.length > 20
                      ? activity.subject.substring(0, 20) + "..."
                      : activity.subject
                    : ""
                }}
              </div>
            </td>
            
            <td
              style="padding: 0.75rem 1.5rem"
              class="text-sm font-weight-normal"
            >
              <div :title="activity.description" style="cursor: pointer">
                {{
                  activity.description != null
                    ? activity.description.length > 20
                      ? activity.description.substring(0, 20) + "..."
                      : activity.description
                    : ""
                }}
              </div>
              <div class="showDescription"></div>
            </td>
            <td
              style="padding: 0.75rem 1.5rem"
              class="text-sm font-weight-normal"
            >
              {{
                activity.audit.modifiedOn != null
                  ? formatDate(new Date(activity.audit.modifiedOn))
                  : ""
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
      <TablePaginationVue 
        :items="getNextActivities"
        :buttons="this.currentPageLength" 
        @page-change="$options.interfaces.tablePaginationMobile.setPage"
        @interface="setDesktopPaginationInterface"
        v-model="this.items" />

      </div>
    </div>

    <!-- Mobile View -->
    <div class="card open-action-cont open-actions-card mobile-logic">
       <!-- Table Header -->
      <div 
        @click="
            () => {
              show = !show;
            }
          "
        style="font-size: 1.5rem; padding: 0.7rem; font-weight: 600; display: flex; align-items: center;">
        External Activities
        <svg
          v-show="!show"
          style="min-width: 24px; flex-basis: 24px; height: 1.5rem; margin-left: auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
          />
        </svg>
        <svg
          v-show="show"
          style="min-width: 24px; flex-basis: 24px; height: 1.5rem; margin-left: auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
          />
        </svg>
      </div>

      <!-- Table content (drops down) -->
      <div v-show="show">   
        <!-- Search Filters -->
        <div
          style="display: flex; justify-content: space-between; flex-direction: row; padding: 0 0.7rem 0.3rem 0.7rem;"
          >        
          <div>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
              <label style="font-size: medium; margin-right: 1rem; width: 8rem">Items Per Page:</label>
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <input
                    class="page-amount-input"
                    :value="currentPageLength"
                    @change="changePageLength($event.target.value)"
                  />
                  <svg
                    v-show="!showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="page-num-options" v-show="showPageAmountDrpDwn">
                  <div
                    v-for="index in Math.ceil(getActivityAmount / pageIncrements)"
                    :key="index"
                    @click="
                      () => {
                        changePageLength(index * pageIncrements);
                        showPageAmountDrpDwn = false;
                      }
                    "
                  >
                    {{ index * pageIncrements }}
                  </div>
                </div>
              </div>
            </div>

            <div style="display: flex; flex-direction: row;flex-wrap: wrap; ">
              <label style="font-size: medium; margin-right: 1rem; width: 8rem">Activity Type:</label>
              <div style="display: flex; flex-direction: column; width: 10rem">
                <div style="display: flex; flex-direction: row">
                  <div
                    class="selected-filter"
                  >{{currentTypeCodeFilter}}</div>
                  <svg
                    v-show="!showTypeCodeDrpDwn"
                    @click="showTypeCodeDrpDwn = true"
                    class="tgl-type-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showTypeCodeDrpDwn"
                    @click="showTypeCodeDrpDwn = false"
                    class="tgl-type-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="type-filter-options" v-show="showTypeCodeDrpDwn">
                  <div
                    v-for="item in activityTypes"
                    :key="item"
                    @click="
                      () => {
                        currentTypeCodeFilter = item;
                        showTypeCodeDrpDwn = false;
                      }
                    "
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Audit Item -->
        <div
          class="card h-100 open-action"
          v-for="(activity, index) in this.items" :key="index"
        >
          <div
            class="p-3 card-header"
            :style="'padding-bottom: 0;'"
          >
            <div
              style="display: flex; flex-direction: row;">
              

              <div style="overflow: hidden; display:block; width: 100%"> 
                <h6 
                  class="subject" 
                  style="width: 100%"
                  >
                    {{ activity.classification.activityTypeCode }}
                </h6>
                <h6 style="width: 100%; color: #67748E;">
                  {{
                    activity.timeline.scheduledStart != null
                      ? formatDate(new Date(activity.timeline.scheduledStart)) + " - " +  formatTime(new Date(activity.timeline.scheduledStart))
                      : "N/A"
                  }}
                </h6>
              </div>

              <div style="width: 24px; height: 100%">
                <svg
                  @click="onActivitySelect(activity)"
                  style="width: 100%"
                  v-show="!extendedItems.includes(activity)"
                  class="extend-action"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
                <svg
                  @click="onActivityDeselect(activity)"
                  style="width: 100%"
                  v-show="extendedItems.includes(activity)"
                  class="extend-action"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                  />
                </svg>
              </div>

            </div>
          </div>

          <!-- Audit Item Content (drops down) -->
          <div
            class="card-body"
            v-show="extendedItems.includes(activity)"
            style="padding: 0 1rem 1rem 1rem"
          >
            <ul class="list-group">     
              <li
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <strong class="text-dark">Scheduled Start:</strong> &nbsp;
                <div :style="activity.timeline.scheduledStart != null ? 'color: limegreen' : ''">
                  {{
                    activity.timeline.scheduledStart != null
                      ? formatDate(new Date(activity.timeline.scheduledStart)) + " - " +  formatTime(new Date(activity.timeline.scheduledStart))
                      : "N/A"
                  }}
                </div>

              </li>
              <li
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <strong class="text-dark">Scheduled End:</strong> &nbsp;
                <div :style="activity.timeline.scheduledEnd != null ? 'color: limegreen' : ''">
                  {{
                    activity.timeline.scheduledEnd != null
                      ? formatDate(new Date(activity.timeline.scheduledEnd)) + " - " +  formatTime(new Date(activity.timeline.scheduledEnd))
                      : "N/A"
                  }}
                </div>
              </li>
              <li :title="activity.subject" style="cursor: pointer"
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <strong class="text-dark">Subject:</strong> &nbsp;
                <div>
                  {{
                    activity.subject != null
                      ? activity.subject.length > 40
                        ? activity.subject.substring(0, 40) + "..."
                        : activity.subject
                      : ""
                  }}
                </div>
              </li>
              <li :title="activity.description" style="cursor: pointer;"
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <strong class="text-dark" style="text-overflow:">Description:</strong> &nbsp;
                <div>
                  {{ activity.description }}
                </div>
              </li>
              <!-- <li
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <strong class="text-dark">Work Coach Email:</strong> &nbsp;
                <div>
                  {{ activity.ownership.workCoach }}
                </div>
              </li> -->
              <li
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <strong class="text-dark">Modified On:</strong> &nbsp;
                <div>
                  {{
                    activity.audit.modifiedOn != null
                      ? formatDate(new Date(activity.audit.modifiedOn))
                      : ""
                  }}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- This uses 'page-change' and 'interface' events to communicate with the desktop pagination -->
        <TablePaginationVue 
            :items="getNextActivities"
            :buttons="this.currentPageLength" 
            @page-change="$options.interfaces.tablePaginationDesktop.setPage"
            @interface="this.setMobilePaginationInterface"
            v-model="this.items" />
      </div>
    </div>
  </div>
</template>
<script>
import { useCommonStore } from "@/store/commonStore";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
import { mapState, mapActions } from "pinia";
import TablePaginationVue from "@/components/TablePagination.vue"

export default {
  name: "audit-table",
  components: {
    TablePaginationVue
  },

  interfaces: {
    tablePaginationDesktop: {
      setPage: () => {},
      setPageSize: () => {}
    },

    tablePaginationMobile: {
      setPage: () => {},
      setPageSize: () => {}
    }
  },

  data() {
    return {
      extendedItems: [],
      items: [],

      currentPageLength: this.pageLength,
      showPageAmountDrpDwn: false,
      show: false,
      showTypeCodeDrpDwn: false,
      activityTypes:['All'],
      currentTypeCodeFilter: 'All',
      nextActivities:[],
      showOptions: false,
      currentItemToShowOptions: ''
    };
  },
  props: {
    pageLength: {
      type: Number,
      default: 5,
    },
    pageIncrements: {
      type: Number,
      default: 5,
    },
    uniqueId: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState(useCommonStore, ["getExternalActivites"]),
    ...mapState(useAdvisorDashboardStore, ["getConvertActivityView"]),
    getFilteredActivities(){
      var activities = [];
      if (this.getExternalActivites.length > 0) {
        if(this.currentTypeCodeFilter != "All"){
          activities = this.getExternalActivites.filter(a=>a.classification.activityTypeCode == this.currentTypeCodeFilter);
        }
        else{
          activities = this.getExternalActivites;
        }
      }
      return activities;
    },
    getNextActivities() {
      var activities = [];
      if (this.getFilteredActivities.length > 0) {
        if(this.currentTypeCodeFilter != "All"){
          activities = this.getFilteredActivities.filter(a=>a.classification.activityTypeCode == this.currentTypeCodeFilter);
        }
        else{
          activities = this.getFilteredActivities;
        }
      }
      return activities;
    },
    getActivityAmount() {
      return this.getExternalActivites.length;
    },
  },
  watch: {
    getExternalActivites() {
      var activityTypes = []
      for(var i = 0; i < this.getExternalActivites.length; i++){
        if(!activityTypes.includes(this.getExternalActivites[i].classification.activityTypeCode)){
          activityTypes.push(this.getExternalActivites[i].classification.activityTypeCode);
        }
      }
      activityTypes = activityTypes.sort();
      activityTypes.unshift("All")

      this.activityTypes = activityTypes
    },

    currentPageLength: function() {
        this.$options.interfaces.tablePaginationDesktop.setPageSize(this.currentPageLength)
        this.$options.interfaces.tablePaginationMobile.setPageSize(this.currentPageLength)
    }
  },
  methods: {
    ...mapActions(useCommonStore, [
      "GetContract",
      "GetAuditPoints",
      "GetCustomer",
      "GetAuditActions",
      "SetActivityToConvert"
    ]),
    ...mapActions(useAdvisorDashboardStore, [
      "toggleModal",
      "setModalContent",
      "setModalTitle",
      "setContractView",
      "generateContract",
      "setAuditDescView",
      "setConvertActivityView"
    ]),
    onActivitySelect(activity) {
      this.extendedItems.push(activity)
    },

    onActivityDeselect(activity) {
      this.extendedItems = this.extendedItems.filter((i) => i != activity)
    },
    formatDate(date) {
      return [
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        (date.getMonth()+1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1),
        date.getFullYear(),
      ].join("/");
    },
    formatTime(date) {
      return [
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
      ].join(":");
    },
    changePageLength(length) {
      length = parseInt(length);
      if (this.getMaxPages > this.getExternalActivites.length / length) {
        this.changePage(Math.ceil(this.getExternalActivites.length / length));
      }
      this.currentPageLength = length;
    },
    createAction(activityId){
      this.SetActivityToConvert(activityId);
      if(this.getConvertActivityView == null){
        this.setConvertActivityView(document.getElementById("convert-activity"));
      }
      this.setModalContent(this.getConvertActivityView);
      this.setModalTitle("Convert Activity");
      this.toggleModal();
      //this.fireConfirmCreateActionAlert(activityId, true);
    },

    setDesktopPaginationInterface(childInterface) {
      this.$options.interfaces.tablePaginationDesktop = childInterface;
    },

    setMobilePaginationInterface(childInterface) {
      this.$options.interfaces.tablePaginationMobile = childInterface;
    },
  },
};
</script>
<style scoped>
.page-cont-active {
  background-color: #e1e1e1 !important;
}

.page-selector {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: absolute;
  z-index: 2;
  width: fit-content;
  max-width: 15.2rem;
  max-height: 6.1rem;
  overflow-y: auto;
  background-color: #f3f3f3;
  border-radius: 3px;
  border: 0.02rem solid #dbdbdb;
  margin-right: 1.1rem;
}

.page-selector div {
  width: 2rem;
  height: 2rem;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: inset 0px 1px 0px 0px #e8e8e8;
  background: linear-gradient(to bottom, #ededed 5%, #ebebeb 100%);
  background-color: #ededed;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  color: #7a7a7a;
  text-shadow: 0px 1px 0px #e6e6e6;
}

.page-selector div:hover {
  /* background: linear-gradient(to bottom, #ddd 5%, #dbdbdb 100%);
  box-shadow: inset 0px 1px 0px 0px #dbdbdb; */
  background: none;
  background-image: none;
  box-shadow: none;
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
  text-shadow: none;
  color: white;
}

.page-selctor-1 {
  margin-top: -2.2rem;
}
.page-selctor-2 {
  margin-top: -4.3rem;
}
.page-selctor-3 {
  margin-top: -6.4rem;
}

/* @media(max-width: 500px){
  .page-selector{
    top: 91.5%;
  right: 14%;
  }
} */

.key-span {
  margin-right: 0.8rem;
}

.activity-item {
  background-color: rgb(235, 235, 235);
  padding: 1rem 2rem;
  margin: 0 1rem 1rem 1rem;
}

.activity-item:last-child {
  margin-bottom: 0;
}

.hover-tooltip {
  z-index: 2;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.hover-tooltip div:last-child {
  padding: 0.5rem;
  border: 1px solid #5b5b5b;
  color: #5b5b5b;
  background-color: rgb(245, 245, 245);
  font-size: smaller;
  max-width: 25rem;
  white-space: pre-wrap;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.hover-tooltip div:first-child {
  height: 1.8rem;
}

.page-amount-input, .selected-filter {
  width: 4.5rem;
  height: 1.7rem;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 0 0.7rem;
  color: #67748e;
}

.selected-filter{
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2rem;
}

.tgl-page-dropdown, .tgl-type-dropdown {
  width: 0.78rem;
  margin-left: -1.3rem;
}

.tgl-page-dropdown:hover, .tgl-type-dropdown:hover {
  cursor: pointer;
}

.page-num-options, .type-filter-options {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  width: 4.5rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 2rem;
  max-height: 10rem;
  overflow-y: auto;
}

.type-filter-options{
  width: 15rem;
}

.page-num-options div, .type-filter-options div {
  background-color: rgb(243 243 243);
  padding: 0.2rem 0.7rem;
  width: 100%;
}

.page-num-options div:first-child, .type-filter-options div:first-child {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.page-num-options div:last-child, .type-filter-options div:last-child {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.page-num-options div:hover, .type-filter-options div:hover {
  cursor: pointer;
  background-color: rgb(235, 235, 235);
}

.view-contract:hover {
  cursor: pointer;
}

.page-btn-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: right;
  margin: 0 0 1rem 0;
  padding-right: 0.7rem;
}

.page-btn {
  width: 2.2rem;
  height: 2.2rem;
  font-size: larger;
  border-radius: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(222, 222, 222);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.page-btn:hover {
  cursor: pointer;
}

.back-page,
.forw-page {
  font-size: larger;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 0.1rem 0 0.1rem 0.4rem;
}

.unactive {
  background-color: rgb(235 235 235);
  border: 1px solid rgb(236 235 235);
  color: #b2b5bc;
}

.unactive:hover {
  cursor: default;
}

.list-group li {
  margin-bottom: 1rem
}

.selected-page-btn {
  color: white;
  border: none;
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
}

.hovertext {
  position: relative;
  border-bottom: 1px dotted black;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}

.table td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}

.table th {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

@media (max-width: 840px) {
  .fit-content-l840 {
    width: fit-content;
  }
}

.to-act{
  width: 1rem;
  cursor: pointer;
}
@media (max-width: 900px) {
  .desktop-logic {
    display: none;
  }

  .mobile-logic {
    display: block
  }
}

@media (min-width: 900px) {
  .desktop-logic {
    display: block;
  }

  .mobile-logic {
    display: none
  }
}
</style>

<!-- Mobile Styling -->
<style scoped>
.header {
  font-size: 1.5rem;
  font-weight: 400;
}

.open-action {
  margin-bottom: 1rem;
}

.open-action:last-child {
  margin-bottom: 0;
}

.open-actions-card {
  border-radius: 1rem;
  padding: 1rem;
}

.extend-action {
  width: 6%;
  padding: 0;
  margin: 0.2rem 2.5% auto 2.5%;
  cursor: pointer;
  fill: #344767;
}

.subject {
  max-width: 89%;
  word-wrap: normal;
  margin: 0;
}

.open-action-header {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.open-action-header svg {
  width: 1.9rem;
  height: 1.9rem;
  fill: #2c3e50;
  margin-right: 0.5rem;
}

.open-action-cont {
  padding: 0.6rem 1rem;
  margin-bottom: 0.5rem;
}

.prog-upt-btn {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

@media (min-width: 600px) {
  .my-actions-main {
    padding: 0 20%;
  }

  .extend-action {
    width: 1.5rem;
  }

  .extend-tasks {
    width: 1.5rem;
  }

  #memo-modal {
    margin: 0 26%;
    width: 48%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: calc(6rem / 3);
  height: calc(3.4rem / 3);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="date"] {
    box-sizing: border-box;
    padding: 0 0.7rem;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(226, 60, 60);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.disabled-switch .slider {
  background-color: rgb(161, 73, 73);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: calc(2.6rem / 3);
  width: calc(2.6rem / 3);
  left: calc(0.4rem / 3);
  bottom: calc(0.4rem / 3);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}



input:checked + .slider {
  background-color: #47e441;
}

input:focus + .slider {
  box-shadow: 0 0 1px #47e441;
}

.disabled-switch input:checked + .slider {
  background-color: #64be61;
}

.disabled-switch input:focus + .slider {
  box-shadow: 0 0 1px #64be61;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(2.6rem / 3));
  -ms-transform: translateX(calc(2.6rem / 3));
  transform: translateX(calc(2.6rem / 3));
}

/* Rounded sliders */
.slider.round {
  border-radius: calc(3.4rem / 3);
}

.slider.round:before {
  border-radius: 50%;
}

.loader {
  border: 0.7rem solid #f3f3f3;
  /* Light grey */
  border-top: 0.7rem solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  animation: spin 2s linear infinite;
}

.loader-container {
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .width-100-l600 {
    width: 100% !important;
  }
  .pd-t-l600 {
    padding: 0.75rem 0rem 0rem 0rem !important;
  }
  .pd-tb-l100 {
    padding: 0.75rem 0rem !important;
  }
  .pd-lr-l600 {
    padding: 0rem 0.75rem !important;
  }
}
</style>
