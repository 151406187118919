<template>
    <div style="padding: 0.5rem 1.5rem;">
        <NavBarVue style="background-color: #6F9600;"/>
         <div class="boc-main card" style="margin: 1rem 0 0.75rem 0;">
            <div class="leave-bar">
                <svg @click="navBack()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
            </div>
            <div class="boc-cont">
                <div class="boc-info card">
                    <div v-if="pipCase.caseReference != ''" class="card boc-info-body">
                        <table class="boc-table">
                            <thead>
                                <tr>
                                    <td colspan="2"><b>Customer</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="col-left">Name</td>
                                    <td class="col-right">{{pipCase.customerDisplay}}</td>
                                </tr>
                                <tr>
                                    <td class="col-left">Memorable ID</td>
                                    <td class="col-right">{{pipCase.customerMemorableId}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="boc-table">
                            <thead>
                                <tr>
                                    <td colspan="2"><b>Case</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="col-left">Reference ID</td>
                                    <td class="col-right">{{pipCase.caseReference}}</td>
                                </tr>
                                <tr>
                                    <td class="col-left">URL</td>
                                    <td class="col-right"><a style="text-decoration: underline; color: inherit" target="__blank" :href="pipCase.caseUrl">Visit Here</a></td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="boc-table">
                            <thead>
                                <tr>
                                    <td colspan="2"><b>Inputs</b> <b v-if="pipCase.inputs.length <= 0">(Empty)</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in pipCase.inputs" :key="item.name">
                                    <td class="col-left">{{item.name}}</td>
                                    <td class="col-right">{{item.value}}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="boc-table">
                            <thead>
                                <tr>
                                    <td colspan="1"><b>Not Found</b> <b v-if="pipCase.notFound.length <= 0">(Empty)</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in pipCase.notFound" :key="item.name">
                                    <td>{{item}}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="boc-table">
                            <thead>
                                <tr>
                                    <td colspan="2"><b>Outputs</b> <b v-if="pipCase.outputs.length <= 0">(Empty)</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in pipCase.outputs" :key="item.name">
                                    <td class="col-left">{{item.name}}</td>
                                    <td class="col-right">{{item.value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else class="card boc-info-body">
                        <table class="boc-table">
                            <thead>
                                <tr>
                                    <td colspan="2"><b>No Results Found</b></td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <FooterVue />
    </div>
</template>

<script>
import NavBarVue from '@/components/navBar.vue';
import FooterVue from '@/components/Footer.vue';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/store/commonStore';
import { NavBackwards } from "@/router/index";
export default {
    name: "better-off-calculator-results",
    components:{
        NavBarVue,
        FooterVue
    },
    computed:{
        ...mapState(useCommonStore,["getPipCase"]),
        pipCase(){
            return this.getPipCase
        }
    },
    methods:{
        ...mapActions(useCommonStore,["GetCustomer", "SetCustomerId", "GetPipCase"]),
        navBack(){
            NavBackwards();
        }
    },
    mounted(){
        this.SetCustomerId(this.$route.params.id)
        Promise.all([
            this.GetCustomer(),
            this.GetPipCase()]);
    }
}
</script>

<style scoped>
    .boc-main{
        width: 100%;
        background-image: url('../assets/img/wave2.svg');
        background-repeat:no-repeat;
        justify-content: center;
        display: flex;
        font-size: 0.9rem;
    }

    .boc-cont{
        padding: 5%;
        padding-top:  calc(5%-1rem);
    }

    .boc-info{
        background-color: rgb(0 40 58 / 83%);
        padding: 2.3rem;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        max-width: 75rem;
        width: 70vw;
        margin: 0 auto;
    }

    .boc-info-body{
        width: 100%;
        padding: 2rem 3rem;
    }

    .boc-table{
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .small-overflow .boc-table{
        margin-bottom: 0;
    }

    .boc-table thead tr{
        background-color:#242e38;
        color: #fff;
    }

    .boc-table tbody tr:nth-child(even){
        background-color: #26313b;
        color: #fff;
    }

    .boc-table tbody tr:nth-child(odd){
        background-color: #293541;
        color: #fff;
    }

    .boc-table tr{
        /* border-top: 1px solid grey; */
        height: 3rem;
    }
    .boc-table tr td{
        padding: 0 1rem;
    }

    .boc-table tfoot tr{
        background-color:#242e38;
        color: #fff;
    }

    .col-left{
        width: 40%;
        border-right: 1px solid #232c36;
    }

    .col-right{
        width: 60%;
    }

    .border-right{
        border-right: 1px solid #232c36;
    }

    .boc-multi-tr td{
        border-right: 1px solid #575d64;
    }

    .boc-multi-tr td:last-child{
        border-right: 0px;
    }

    @media(max-width: 900px){
        .boc-info{
            width: 100%;
        }

        .boc-table{
            word-break: break-all;
        }

        .small-overflow{
            width: 100%;
            overflow-x: auto;
        }

        .small-overflow .boc-table{
            width: 60rem !important;
        }
    }

    .leave-bar{
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: right;
        padding: 0.3rem 0.3rem 0 0;
        padding-right: 0.6rem;
    }

    .leave-bar svg{
        width: 2rem;
        height: 2rem;
        z-index: 1;
        cursor: pointer;
    }

    .leave-bar svg:hover{
        fill: #243147;
    }
</style>