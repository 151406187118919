import { ref } from 'vue';
import { defineStore } from "pinia";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import { uc604FormStore } from "@/store/UC604FormStore";
import { createPreviewMandationDoc, createFinalMandationDoc } from "../components/UC604_Wizard/Components/CreateMandationDoc604";
import { useCommonStore } from "@/store/commonStore";

export const uc604FormStore2Schema = Yup.object({
  activityToldDoTo: Yup.string(),
  activityFailedToDo: Yup.string(),
  activityConsiderFailedReason: Yup.string(),
  activityConsiderCompleteReason: Yup.string(),
  uC604FinalConfirmStatement: Yup.boolean(),
  // uC604FinalConfirmStatement: Yup.boolean().required('Field must be checked').oneOf([true], 'Field must be checked'),
});

export const uc604FormStore2 = defineStore("uc604FormStore2", () => {
  const { errors, useFieldModel, handleSubmit, meta } = useForm({
    validationSchema: uc604FormStore2Schema,
  });

  const loadedSavedForm = ref(false)
  const recordId = ref(null)
  const previewDocumentId = ref("")
  const activityToldDoTo = useFieldModel("activityToldDoTo");
  const activityFailedToDo = useFieldModel("activityFailedToDo");
  const activityConsiderFailedReason = useFieldModel("activityConsiderFailedReason");
  const activityConsiderCompleteReason = useFieldModel("activityConsiderCompleteReason");
  const uC604FinalConfirmStatement = useFieldModel("uC604FinalConfirmStatement");

  const submitForm = handleSubmit(async () => {
  });

  const createPreview = async function() {
    const partOneStore = uc604FormStore();
    const commmonStore = useCommonStore();
    const partTwoStore = uc604FormStore2();
    const documentResult = await createPreviewMandationDoc(partOneStore, partTwoStore, commmonStore);
    partTwoStore.previewDocumentId = documentResult.documentId
  }

  const isRequired = function(){
    const store = uc604FormStore();
    return !(store.participantComplied || store.activityNoLongerAppropriate || store.alternativeActivityComplied)
  }

  const createFinal = handleSubmit(async (values) => {
    const partOneStore = uc604FormStore();
    const commmonStore = useCommonStore();
    //const partTwoStore = uc604FormStore2();

    await createFinalMandationDoc(partOneStore, values, commmonStore);
  });

  const loadFromSavedRecord = function(dwp604Record) {
    const ComplianceDisputedDetails = dwp604Record.complianceDisputedDetails
    
    activityToldDoTo.value = ComplianceDisputedDetails.activityToldDoTo
    activityFailedToDo.value = ComplianceDisputedDetails.activityFailedToDo
    activityConsiderFailedReason.value = ComplianceDisputedDetails.activityConsiderFailedReason
    activityConsiderCompleteReason.value = ComplianceDisputedDetails.activityConsiderCompleteReason
  
    loadedSavedForm.value = true
    recordId.value = dwp604Record.recordId
  }

  const resetData = function() {
    activityToldDoTo.value = "";
    activityFailedToDo.value = "";
    activityConsiderFailedReason.value = "";
    activityConsiderCompleteReason.value = "";
    uC604FinalConfirmStatement.value = false;
    loadedSavedForm.value = false;
    recordId.value = null
  }
  
  return {
    activityToldDoTo,
    activityFailedToDo,
    activityConsiderFailedReason,
    activityConsiderCompleteReason,
    uC604FinalConfirmStatement,
    previewDocumentId,
    loadedSavedForm,
    recordId,
    errors,
    submitForm,
    createPreview,
    isRequired,
    createFinal,
    loadFromSavedRecord,
    resetData,
    meta
  };

});
