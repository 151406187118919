import * as yup from "yup";
import type { uc604FormStoreSchema } from "../../../store/UC604FormStore";
import moment from "moment";
import { loggedIn } from "../../../router/Guard";
import { clampToBusinessDay } from "../../../utils/DateUtil";
import { uc604FormStore } from "@/store/UC604FormStore";
import { Store } from "pinia"

function tryClampToBusinessDay(date : string | undefined) {
  if (date == undefined || date == '') {
    return null
  }
  return clampToBusinessDay(date)
}

interface MandationDocPartOne extends yup.InferType<typeof uc604FormStoreSchema> { }

function convertRecordToMandationDocPartOne(
  request: Record<string, any>
): MandationDocPartOne {
  return {
    participantComplied: request["participantComplied"],
    activityNoLongerAppropriate: request["activityNoLongerAppropriate"],
    alternativeActivityComplied: request["alternativeActivityComplied"],
    considerNotFullyComplied: request["considerNotFullyComplied"],
    activityNoLongerApplicableDate: request["activityNoLongerApplicableDate"],
    sanctionDecisionDate: request["sanctionDecisionDate"],
    sanctionStartDate: request["sanctionStartDate"],
    sanctionEndDate: request["sanctionEndDate"],
    uC604ConfirmStatement: request["uC604ConfirmStatement"],
  } as MandationDocPartOne;
}

export const uc604FormStore2Schema = yup.object({
  activityToldDoTo: yup.string().required("This field is required*"),
  activityFailedToDo: yup.string().required("This field is required*"),
  activityConsiderFailedReason: yup.string().required("This field is required*"),
  activityConsiderCompleteReason: yup.string().required("This field is required*"),
  uC604FinalConfirmStatement: yup.boolean().required('Field must be checked').oneOf([true], 'Field must be checked'),
});

interface MandationDocPartTwo extends yup.InferType<typeof uc604FormStore2Schema> { }


function convertRecordToMandationDocPartTwo(
  request: Record<string, any>
): MandationDocPartTwo {
  return {
    activityToldDoTo: request["activityToldDoTo"],
    activityFailedToDo: request["activityFailedToDo"],
    activityConsiderFailedReason: request["activityConsiderFailedReason"],
    activityConsiderCompleteReason: request["activityConsiderCompleteReason"],
    uC604FinalConfirmStatement: request["uC604FinalConfirmStatement"],
  } as MandationDocPartTwo;
}

function formAsDTO(
  mandationPartOne : MandationDocPartOne, 
  mandationPartTwo : MandationDocPartTwo,
  originalManData: any) {

  const activityNoLongerApplicableDateCheck = tryClampToBusinessDay(mandationPartOne.activityNoLongerApplicableDate);
  const sanctionDecisionDateCheck = tryClampToBusinessDay(mandationPartOne.sanctionDecisionDate);
  const sanctionStartDateCheck = tryClampToBusinessDay(mandationPartOne.sanctionStartDate);
  const sanctionEndDateCheck = tryClampToBusinessDay(mandationPartOne.sanctionEndDate);


  function FormatCustomerAddress() {
    const postalCode =   originalManData.postalCode;
    const addressLine1 = originalManData.addressLine1;
    const addressLine2 = originalManData.addressLine2;
    const addressLine3 = originalManData.addressLine3;

    const address = [
        addressLine1, addressLine2, addressLine3, postalCode
    ]

    return address
        .filter(i => i != null && i != "")
        .join(", ")
  }

  return {
    destinationAddress: FormatCustomerAddress(),
    status: "",
    documentTypeVersion: "",
    statementConfirmed: true,

    sanctionDecisionReviewDetails:{
      participantComplied: mandationPartOne.participantComplied,
      activityNoLongerAppropriate: mandationPartOne.activityNoLongerAppropriate,
      alternativeActivityComplied: mandationPartOne.alternativeActivityComplied,
      considerNotFullyComplied: mandationPartOne.considerNotFullyComplied,
      activityNoLongerApplicableDate: activityNoLongerApplicableDateCheck,
      sanctionDecisionDate: sanctionDecisionDateCheck,
      sanctionStartDate: sanctionStartDateCheck,
      sanctionEndDate: sanctionEndDateCheck,
      //setting this to true now as mandationPartOne.uC604ConfirmStatement seems to have a bug
      uC604ConfirmStatement: true,
    },

    complianceDisputedDetails:{
      activityToldDoTo: mandationPartTwo.activityToldDoTo,
      activityFailedToDo: mandationPartTwo.activityFailedToDo,
      activityConsiderFailedReason: mandationPartTwo.activityConsiderFailedReason,
      activityConsiderCompleteReason: mandationPartTwo.activityConsiderCompleteReason,
      //mandationPartTwo.uC604FinalConfirmStatement
      uC604FinalConfirmStatement: true,
    },
  };
}



/**
 * Creates the preview version of a DWP-604 document.
 * @param partOne 
 * @param partTwo 
 * @param commonStore 
 * @returns 
 */
export async function createPreviewMandationDoc(partOne: Record<string, any>, partTwo: Record<string, any>, commonStore: any) {
  const mandationPartOne = convertRecordToMandationDocPartOne(partOne);
  const mandationPartTwo = convertRecordToMandationDocPartTwo(partTwo);

  const { 
    savedManData, 
    getActionToMandate, 
    createDwp604PreviewDocument
  } = commonStore;

  // The data to populate the document with
  const data604 = formAsDTO(mandationPartOne, mandationPartTwo, savedManData);
       
  // Generate the document server-side
  const result = await createDwp604PreviewDocument(getActionToMandate.id, data604)
  return result
}


/**
 * Creates the final version of a DWP-604 document.
 * @param partOne 
 * @param partTwo 
 * @param commonStore 
 * @returns 
 */
export async function createFinalMandationDoc(partOne: Record<string, any>, partTwo: Record<string, any>, commonStore: any) {
  const mandationPartOne = convertRecordToMandationDocPartOne(partOne);
  const mandationPartTwo = convertRecordToMandationDocPartTwo(partTwo);

  const { 
    savedManData, 
    getActionToMandate, 
    createDwp604Record
  } = commonStore;

  // The data to populate the document with
  const data604 = formAsDTO(mandationPartOne, mandationPartTwo, savedManData);
       
  // Generate the document server-side
  const result = await createDwp604Record(getActionToMandate.id, data604)

  return result
}

const getDateFormat = (date: Date): String => {
  return Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);

}
