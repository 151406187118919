<template>
    <div style="overflow-y: scroll; max-height: 75vh; padding: 1rem 2rem;">
        <!-- <div style="font-size: larger;">{{activity.activityTypeCode}}</div>
        <div>
            <span style="margin-right: 1rem;">Starts:</span><span>{{activity.scheduledStart}}</span>
        </div>
         <div>
            <span style="margin-right: 1rem;">Ends:</span><span>{{activity.scheduledEnd}}</span>
        </div>
         <div>
            <span style="margin-right: 1rem;">Description:</span><span>{{activity.description}}</span>
        </div> -->
        <div v-for="contract in getCurrentContract" :key="contract" class="card contract">
            <div style="font-size: x-large;">{{contract.title}}</div>
            <div class="contract-info-section prog">
                <div style="font-size: medium;"><b>Completed</b></div>
                <div class="prog-input">
                    <input type="checkbox" :checked="contract.progress" disabled/>
                    <svg class="edit" @click="allowEditProg($event)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg>
                </div>
            </div>
            <div class="contract-info-section">
                <div style="font-size: larger;"><b style="font-size: medium;">Complete By:</b> {{getDateString(new Date(contract.dueBy))}}</div>
            </div>
            <div class="contract-info-section">
                <div style="font-size: larger;"><b style="font-size: medium;">Motivation:</b> {{contract.motivation}}</div>
            </div>
            <div class="contract-info-section">
                <div style="font-size: large;"><a target="__blank" :href="contract.taskUrl">Visit Here</a></div>
            </div>
        </div>
    </div>
</template>
<script>
import { useCommonStore } from '@/store/commonStore';
import { mapState, mapActions } from 'pinia';

export default {
    name:"contract-view",
    computed:{
        ...mapState(useCommonStore, ["getCurrentContract"])
    },
    methods:{
        ...mapActions(useCommonStore, []),
        allowEditProg(event){
            var el = event.target;
            var count = 0;
            while(el.nodeName != "svg" && count < 10){
                el = el.parentNode;
                count++;
            }
            if(el.nodeName != "svg"){
                return;
            }
            if(el.classList.contains("edit")){
                el.previousSibling.disabled = false;
                el.children[0].setAttribute("d", "M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z");
                el.classList.remove("edit");
            }
            else{
                el.previousSibling.disabled = true;
                el.children[0].setAttribute("d", "M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z");
                el.classList.add("edit");
            }
        },
        getDateString(date){
        return [date.getDate() < 10 
                ? '0' + (date.getDate()).toString() 
                :date.getDate(),
                date.getMonth()+1 < 10 
                ? '0' + (date.getMonth()+1).toString() 
                : date.getMonth()+1,
                date.getFullYear()].join('/')+' '+
                [date.getHours()+1 < 10 
                ? '0' + (date.getHours()).toString() 
                : date.getHours(),
                date.getMinutes()+1 < 10 
                ? '0' + (date.getMinutes()).toString() 
                : date.getMinutes(),].join(':');
      },
    }
    // props:{
    //     activity:{
    //         type: Object,
    //         required: true
    //     }
    // }
}
</script>
<style>
.contract{
    padding: 0.8rem 2rem;
    margin-bottom: 1.5rem;
}

.contract-info-section, .prog-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}

.contract-info-section{
    margin-top: 0.2rem;
}

.prog-input input{
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.4rem;
}

.prog svg{
    width: 0.8rem;
    fill: rgb(180, 180, 180);
    margin-left: 0.4rem;
}

.prog svg:hover{
    fill: rgb(161, 161, 161);
    cursor: pointer;
}
</style>