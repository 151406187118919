import { ref } from 'vue';
import { defineStore } from "pinia";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import Dwp603Record from '../interfaces/Dwp603Record'

export const jsa603FormStoreSchema = Yup.object({
  selectedAdvisor: Yup.string().required("This field is required*"),
  satisfyRestrictionOnJSA: Yup.string().required("This field is required*"),
  restartSchemeMandatory: Yup.string().required("This field is required*"),
  reasonableConsideration: Yup.string().required("This field is required*"),
  attemptsMade: Yup.string().required("This field is required*"),
  face2Face: Yup.string().when("rearrangeMandateActivity", {
    is: "yes",
    then: Yup.string().required("This field is required*"),
  }),
});

export const jsa603FormStore = defineStore("jsa603FormStore", () => {
  const { errors, useFieldModel, handleSubmit, meta } = useForm({
    validationSchema: jsa603FormStoreSchema,
  });

  // Prevents overwriting changed form data when changing pages
  const loadedSavedForm = ref(false)

  const selectedAdvisor = useFieldModel("selectedAdvisor");
  const satisfyRestrictionOnJSA = useFieldModel("satisfyRestrictionOnJSA");
  const restartSchemeMandatory = useFieldModel("restartSchemeMandatory");
  const reasonableConsideration = useFieldModel("reasonableConsideration");
  const attemptsMade = useFieldModel("attemptsMade");
  const face2Face = useFieldModel("face2Face");

  const submitForm = handleSubmit(async (values, actions) => {
    console.log("submitted");
  });


  const loadFromSavedRecord = function(dwp603Record: Dwp603Record) {
    const mandatableDetails = dwp603Record.mandatableDetails
    
    selectedAdvisor.value = dwp603Record.completedBy
    satisfyRestrictionOnJSA.value = convertBool(mandatableDetails.jcpRestrictions)
    restartSchemeMandatory.value = convertBool(mandatableDetails.restartSchemeParticipation)
    reasonableConsideration.value = convertBool(mandatableDetails.reasonable)
    attemptsMade.value = convertBool(mandatableDetails.otherNonManAttemptsMade)
  
    if (mandatableDetails.otherNonManAttemptsMade) {
      face2Face.value = mandatableDetails.faceToFaceActionsTaken ?? ""
    }
    
    loadedSavedForm.value = true
  }

  const resetData = function() {
    satisfyRestrictionOnJSA.value = "";
    restartSchemeMandatory.value = "";
    reasonableConsideration.value = "";
    attemptsMade.value = "";
    face2Face.value = "";
    selectedAdvisor.value = null
    loadedSavedForm.value = false
  }

  function convertBool(value: boolean) {
    return value == true ? "yes" : value == false ? "no" : ""
  }
  
  return {
    loadedSavedForm,
    selectedAdvisor,
    satisfyRestrictionOnJSA,
    restartSchemeMandatory,
    reasonableConsideration,
    attemptsMade,
    face2Face,
    errors,
    handleSubmit,
    meta,
    loadFromSavedRecord,
    resetData
  };
});
