import { ref } from 'vue';
import { defineStore } from "pinia";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import { uc603FormStore } from "@/store/UC603FormStore";
import createMandationDoc from "../components/UC603Wizard/Components/CreateMandationDocUC";
import { useCommonStore } from "@/store/commonStore";

export const uc603FormStore2Schema = Yup.object({
  rearrangeMandateActivity: Yup.string().required("This field is required*"),
  originalDueDate: Yup.string().when("rearrangeMandateActivity", {
    is: "yes",
    then: Yup.string().required("Please Select a Date*"),
  }),
  originalMandatedAction: Yup.string().when("rearrangeMandateActivity", {
    is: "yes",
    then: Yup.string().required("This field is required*"),
  }),
  rearrangementDenial: Yup.string().when("rearrangeMandateActivity", {
    is: "no",
    then: Yup.string().required("This field is required*"),
  }),
  failActivity: Yup.string().required("This field is required*"),
  fullDetails: Yup.string().required("This field is required*"),
  volunteerInfo: Yup.string(),
  otherInfo: Yup.string(),
  confirmStatement: Yup.boolean()
    .required("Field must be checked")
    .oneOf([true], "Field must be checked"),
});

export const uc603FormStore2 = defineStore("uc603FormStore2", () => {
  const { errors, useFieldModel, handleSubmit, meta } = useForm({
    validationSchema: uc603FormStore2Schema,
  });

  // Prevents overwriting changed form data when changing pages
  const loadedSavedForm = ref(false)

  const rearrangeMandateActivity = useFieldModel("rearrangeMandateActivity");
  const originalDueDate = useFieldModel("originalDueDate");
  const originalMandatedAction = useFieldModel("originalMandatedAction");
  const rearrangementDenial = useFieldModel("rearrangementDenial");
  const failActivity = useFieldModel("failActivity");
  const fullDetails = useFieldModel("fullDetails");
  const volunteerInfo = useFieldModel("volunteerInfo");
  const otherInfo = useFieldModel("otherInfo");
  const confirmStatement = useFieldModel("confirmStatement");

  const submitForm = handleSubmit(async () => {});

  const preview = handleSubmit(async (values) => {
    const partOneStore = uc603FormStore();
    const commmonStore = useCommonStore();

    await createMandationDoc(partOneStore, values, commmonStore);
  });


  const loadFromSavedRecord = function(dwp603Record) {
    const nonParticipationDetails = dwp603Record.nonParticipationDetails
    const rearrangementDetails = dwp603Record.rearrangementDetails
  
    if (rearrangementDetails.originalManIssuedOn != undefined) {
      const date = new Date(rearrangementDetails.originalManIssuedOn)
      const asText = date.toISOString().split('T')[0];
  
      originalDueDate.value = asText
    }
  
    rearrangeMandateActivity.value = convertBool(rearrangementDetails.attemptToRearrangeMade)
    
    if (rearrangementDetails.attemptToRearrangeMade) {
      originalMandatedAction.value = rearrangementDetails.rearrangmentDecisionDetails
    } else {
      rearrangementDenial.value = rearrangementDetails.rearrangmentDecisionDetails
    }
    
    failActivity.value = nonParticipationDetails.failureReason
    fullDetails.value = nonParticipationDetails.failureReasonDetails
  
    volunteerInfo.value = dwp603Record.participantFailureReason
    otherInfo.value = dwp603Record.additionalInformation
  
    loadedSavedForm.value = true
  }

  const resetData = function() {
    rearrangeMandateActivity.value = "";
    originalMandatedAction.value = "";
    originalDueDate.value = ""
    rearrangementDenial.value = "";
    failActivity.value = "";
    fullDetails.value = "";
    volunteerInfo.value = "";
    otherInfo.value = "";
    confirmStatement.value = false;
    loadedSavedForm.value = false
  }

  function convertBool(value) {
    return value == true ? "yes" : value == false ? "no" : ""
  }

  
  return {
    loadedSavedForm,
    rearrangeMandateActivity,
    originalDueDate,
    originalMandatedAction,
    rearrangementDenial,
    failActivity,
    fullDetails,
    volunteerInfo,
    otherInfo,
    confirmStatement,
    errors,
    submitForm,
    preview,
    meta,
    loadFromSavedRecord,
    resetData
  };
});
