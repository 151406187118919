import axios from 'axios';
import { TryGetCachedToken } from "../router/TokenManger";
const envVars = process.env;
const BaseUrl = process.env.VUE_APP_ACTIONS_BASE_URL;

export default {

    /**
     * Gets all customer generated audits for an owner between a date range
     * @param {*} owner - the owning advisor/manager
     * @param {*} generatedFrom - Only include audits generated from this date (inclusive)
     * @param {*} generatedBefore - Only include audits generated before this date (exclusive)
     * @returns 
     */
    async GetCustomerGeneratedAudits(owner, generatedFrom, generatedBefore) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'GET',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_GENERATED_AUDITS
                        .replace("{owner}", owner)
                    + `?generatedFrom=${generatedFrom}&generatedBefore=${generatedBefore}`,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    /**
     * Gets all customer generated audits for a group of owners between a date range
     * @param {*} owners - the owning advisors/managers
     * @param {*} generatedFrom - Only include audits generated from this date (inclusive)
     * @param {*} generatedBefore - Only include audits generated before this date (exclusive)
     * @returns 
     */
    async GetGroupCustomerGeneratedAudits(owners, generatedFrom, generatedBefore) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'POST',
                url: BaseUrl
                    + envVars.VUE_APP_GET_GROUP_CUSTOMER_GENERATED_AUDITS
                    + `?generatedFrom=${generatedFrom}&generatedBefore=${generatedBefore}`,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                },
                data: owners
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },


    /**
     * Gets all customer due/projected audits for an owner between a date range
     * @param {*} owner - the owning advisor/manager
     * @param {*} dueFrom - Only include audits due from this date (inclusive)
     * @param {*} dueBefore - Only include audits due before this date (exclusive)
     * @returns 
     */
    async GetCustomerProjectedAudits(owner, dueFrom, dueBefore) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'GET',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_PROJECTED_AUDITS
                        .replace("{owner}", owner)
                    + `?dueFrom=${dueFrom}&dueBefore=${dueBefore}`,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    /**
     * Gets all customer due/projected audits for a group of owners between a date range
     * @param {*} owners - the owning advisors/managers
     * @param {*} dueFrom - Only include audits due from this date (inclusive)
     * @param {*} dueBefore - Only include audits due before this date (exclusive)
     * @returns 
     */
    async GetGroupCustomerProjectedAudits(owners, dueFrom, dueBefore) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'POST',
                url: BaseUrl
                    + envVars.VUE_APP_GET_GROUP_CUSTOMER_PROJECTED_AUDITS
                    + `?dueFrom=${dueFrom}&dueBefore=${dueBefore}`,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                },
                data: owners
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    /**
     * Gets all customer signed audits for an owner between a date range
     * @param {*} owner - the owning advisor/manager
     * @param {*} signedFrom - Only include audits signed from this date (inclusive)
     * @param {*} signedBefore - Only include audits signed before this date (exclusive)
     * @returns 
     */
    async GetCustomerSignedAudits(owner, signedFrom, signedBefore) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'GET',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_SIGNED_AUDITS
                        .replace("{owner}", owner)
                    + `?signedFrom=${signedFrom}&signedBefore=${signedBefore}`,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    /**
 * Gets all customer signed audits for a group of owners between a date range
 * @param {*} owners - the owning advisors/managers
 * @param {*} signedFrom - Only include audits signed from this date (inclusive)
 * @param {*} signedBefore - Only include audits signed before this date (exclusive)
 * @returns 
 */
    async GetGroupCustomerSignedAudits(owners, signedFrom, signedBefore) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'POST',
                url: BaseUrl
                    + envVars.VUE_APP_GET_GROUP_CUSTOMER_SIGNED_AUDITS
                    + `?signedFrom=${signedFrom}&signedBefore=${signedBefore}`,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                },
                data: owners
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetCustomerStatus() {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'GET',
                url: BaseUrl
                    + "/metadata/customerStatus",
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async UpdateAuditAcceptedDate(date, customerId, version) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'PUT',
                url: BaseUrl
                    + envVars.VUE_APP_UPDATE_AUDIT_ACCEPTED
                        .replace("{customerId}", customerId)
                        .replace("{version}", version)
                    + `?value=${date}`,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async UpdateAuditDescription(description, contentType, customerId, version, modifiedBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const body = { description: description, contentType: contentType };
            const resp = await axios({
                method: 'PUT',
                url: BaseUrl
                    + envVars.VUE_APP_UPDATE_AUDIT_DESCRIPTION
                        .replace("{customerId}", customerId)
                        .replace("{version}", version)
                    + `?modifiedBy=${modifiedBy}`,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                },
                data: body
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetMonthlyActionPlanData(customerId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                //url:"https://actionplanserviceoauthtest.azurewebsites.net"
                url: BaseUrl
                    //url:'https://localhost:5005'
                    + envVars.VUE_APP_MONTHLY_ACTION_PLAN_DATA
                        .replace("{customerId}", customerId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetLastLoggedInAbintegro(customerId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    //url:'https://localhost:5005'
                    + envVars.VUE_APP_GET_LAST_LOGGED_IN_ABINTEGRO
                        .replace("{customerId}", customerId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetPRaPOrder(customerId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_PRAP_ORDER
                        .replace("{customerId}", customerId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetActionPlan(customerId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_BY_CUSTOMER_ID.replace("{customerId}", customerId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetCustomerNotification(customerId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_NOTIFICATIONS.replace("{customerId}", customerId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async CreateActionPlanItemMemo(customerId, actionPlanItemId, memoContent, visibility, createdBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var json = { contentType: "text/plain", body: memoContent, visibility: visibility, stateCode: "Open", statusCode: "" };
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: json,
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_ITEM_MEMO
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                    + `?createdBy=${createdBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async EditActionPlanItemMemo(memoId, customerId, actionPlanItemId, memoContent, visibility, stateCode, statusCode, modifiedBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var json = { contentType: "text/plain", body: memoContent, visibility: visibility, stateCode: stateCode, statusCode: statusCode };
            var resp = await axios({
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                },
                data: json,
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_ITEM_MEMO_BY_ID
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{memoId}", memoId)
                    + `?modifiedBy=${modifiedBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async CreateTaskMemo(customerId, actionPlanItemId, taskId, memo, visibility, createdBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var json = { contentType: "text/plain", body: memo, visibility: visibility, stateCode: "Open", statusCode: "test" };
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: json,
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_TASK_MEMO
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{taskId}", taskId)
                    + `?createdBy=${createdBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async EditTaskMemo(memoId, customerId, actionPlanItemId, taskId, memoContent, visibility, stateCode, statusCode, modifiedBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var json = { contentType: "text/plain", body: memoContent, visibility: visibility, stateCode: stateCode, statusCode: statusCode };
            var resp = await axios({
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                },
                data: json,
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_TASK_MEMO_BY_ID
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{taskId}", taskId)
                        .replace("{memoId}", memoId)
                    + `?modifiedBy=${modifiedBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async CreateActionPlanItem(action, customerId, createdBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: action,
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_ITEM_POST
                        .replace("{customerId}", customerId)
                    + `?createdBy=${createdBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async CreateAppointmentPlanItem(actvityId, customerId, createdBy, payload) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: payload,
                url: BaseUrl
                    + envVars.VUE_APP_APPOINTMENT_PLAN_ITEM_POST
                        .replace("{customerId}", customerId)
                        .replace("{activityId}", actvityId)
                    + `?createdBy=${createdBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async CreateActionPlanTask(task, actionPlanItemId, customerId, createdBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: task,
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_TASK_POST
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                    + `?createdBy=${createdBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async UpdateActionPlanItem(actionPlanItemId, customerId, action, modifiedBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                },
                data: action,
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_ITEM_UPDATE
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                    + `?modifiedBy=${modifiedBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async UpdateActionPlanTask(actionPlanItemId, taskId, customerId, task, modifiedBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                },
                data: task,
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_TASK_UPDATE
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{actionPlanTaskId}", taskId)
                    + `?modifiedBy=${modifiedBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async UpdateActionProperty(actionPlanItemId, customerId, propertyName, value, modifiedBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'PUT',
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_ITEM_PROPERTY
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{propertyName}", propertyName)
                    + `?value=${value}`
                    + `&modifiedBy=${modifiedBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async UpdateTaskProperty(actionPlanItemId, taskId, customerId, propertyName, value, modifiedBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'PUT',
                url: BaseUrl
                    + envVars.VUE_APP_ACTION_PLAN_TASK_PROPERTY
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{actionPlanTaskId}", taskId)
                        .replace("{propertyName}", propertyName)
                    + `?value=${value}`
                    + `&modifiedBy=${modifiedBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetAuditPoints(customerId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_AUDIT_POINTS.replace("{customerId}", customerId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetAuditPointByVersion(customerId, version) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_AUDIT_POINT_BY_VERSION
                        .replace("{customerId}", customerId)
                        .replace("{version}", version),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async CreateNewAuditPoint(customerId, reason, desc, contentType, modeOfIntervention, ignoreOpenAssessmentWarning, completerData) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: { reason: reason, description: desc, contentType: contentType, modeOfIntervention: modeOfIntervention, ignoreOpenAssessmentWarning: ignoreOpenAssessmentWarning, completerData: completerData },
                url: BaseUrl
                    + envVars.VUE_APP_CREATE_AUDIT_POINT.replace("{customerId}", customerId)
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetAuditPointActions(customerId, Version) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_AUDIT_POINT_ACTIONS
                        .replace("{customerId}", customerId)
                        .replace("{version}", Version),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    getCalendarMeetings(meetings) {
        const calendarMeetingsArray = [];
        for (let i = 0; i < meetings.length; i++) {
            calendarMeetingsArray.push({
                title: meetings[i].type,
                start: meetings[i].start.toISOString().split('T')[0],
                end: meetings[i].end.toISOString().split('T')[0],
                className: 'bg-gradient-danger'
            });
        }
        return calendarMeetingsArray;
    },

    async PostMandationForm(customerId, actionPlanItemId, createdBy, formData) {
        try {

            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: formData,
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_MANDATION_SAVE_MAN_RECORD.replace("{customerId}", customerId).replace("{actionPlanItemId}", actionPlanItemId) + `?createdBy=${createdBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetSavedMandationForm(actionPlanItemId) {
        try {
            // let fakeUrl = "https://localhost:62695"
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_MANDATION_GET_MAN_RECORD
                        .replace("{actionPlanItemId}", actionPlanItemId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    async postMandation603(customerId, actionPlanItemId, createdBy, formData) {
        var resp = await axios({
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: formData,
            url: BaseUrl
                + envVars.VUE_APP_GET_CUSTOMER_MANDATION_SAVE_603.replace("{customerId}", customerId).replace("{actionPlanItemId}", actionPlanItemId) + `?createdBy=${createdBy}`
        });
        return resp;
    },

    async postMandation604(customerId, actionPlanItemId, createdBy, formData) {
        try {

            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: formData,
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_MANDATION_SAVE_604.replace("{customerId}", customerId).replace("{actionPlanItemId}", actionPlanItemId) + `?createdBy=${createdBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    async getSaved603Form(actionPlanItemId) {
        try {
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_MANDATION_GET_MAN_RECORD
                        .replace("{actionPlanItemId}", actionPlanItemId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    async getSaved604Form(actionPlanItemId) {
        try {
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_MANDATION_SAVE_604
                        .replace("{actionPlanItemId}", actionPlanItemId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    async post603ToLogicApp(customerId, actionPlanItemId, recordId, base64, sentBy) {
        try {
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: base64,
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_MANDATION_EMAIL_603
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{recordId}", recordId)
                    + `?sentBy=${sentBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async publishEmail604(customerId, actionPlanItemId, recordId, blob, sentBy) {
        try {
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: blob,
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_MANDATION_EMAIL_604
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{recordId}", recordId)
                    + `?sentBy=${sentBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    async postMandationFormToSharepoint(actionPlanItemId, memorableId, blob) {
        try {
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: blob,
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_MANDATION_UPLOAD
                        .replace("{actionPlanItemId}", actionPlanItemId)
                        .replace("{memorableId}", memorableId)
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    async getSPMandationForm(actionPlanItemId){
        try{
            const resp = await axios({
                method: 'GET',
                url: BaseUrl + envVars.VUE_APP_GET_CUSTOMER_MANDATIONFORM_EMBED
                    .replace("{documentId}", actionPlanItemId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async getSectorDefinitions(customerId) {
        try {
            const resp = await axios({
                method: "GET",
                url:
                    BaseUrl +
                    envVars.VUE_APP_GET_SECTOR_DEFINITIONS +
                    `?customerId=${customerId}`,
                responseType: "json",
                Headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            });
            return resp;
        } catch (error) {
            return error;
        }
    },
    async postJobGoals(customerId, selectedOptions) {
        try {
            const resp = await axios({
                method: "PUT",
                url:
                    BaseUrl +
                    envVars.VUE_APP_PUT_CUSTOMER_JOB_GOALS.replace(
                        "{contactId}",
                        customerId
                    ),
                responseType: "json",
                Headers: {
                    "Access-Control-Allow-Origin": "*",
                },
                data: {
                    goals: selectedOptions,
                },
            });
            return resp;
        } catch (error) {
            return error;
        }
    },
    async UpdateAssessmentItemDueDate(customerId, actionPlanItemId, reason, dueDate, modifiedBy) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var json = { reason: reason, dueDate: dueDate };
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: json,
                url: BaseUrl
                    + envVars.VUE_APP_UPDATE_ASSESSMENT_ITEM_DUEDATE
                        .replace("{customerId}", customerId)
                        .replace("{actionPlanItemId}", actionPlanItemId)
                    + `?modifiedBy=${modifiedBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async PostAuthTokenAsync() {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            var resp = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                url: BaseUrl
                    + envVars.VUE_APP_STORE_AUTH_TOKEN
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
}