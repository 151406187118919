export function waitForElementsByClassName(
    className: string,
    expectedElementCount: number = 1,
    parentElement: Document | HTMLElement = document,
    maxRetries: number = 5,
    interval: number = 100  
): Promise<HTMLCollectionOf<Element>> {
    return new Promise<HTMLCollectionOf<Element>>((resolve, reject) => {
        const checkElement = (): void => {
            const element = parentElement.getElementsByClassName(className);

            if (element && element.length >= expectedElementCount) {
                // Element found, resolve the promise
                resolve(element as HTMLCollectionOf<Element>);
            } else {
                // Element not found
                if (maxRetries > 0) {
                    // Retry after the specified interval
                    setTimeout(checkElement, interval);
                    maxRetries--;
                    console.log("Retrying...")
                } else {
                    // Maximum retries reached, resolve with null
                    reject(Error("The expected number of elements were not found within the specified retries."));
                }
            }
        };

        // Start checking for the element
        checkElement();
    });
}