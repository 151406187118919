<template>
<div style="padding: 0.5rem 1.5rem;">
    <navBarVue style="background-color: #6F9600;"/>
     <div class="prap-main card" style="margin: 1rem 0 0.75rem 0;">
        <div class="leave-bar">
            <svg @click="navBack()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
        </div>
        <div class="prap-cont">
            <div class="prap-info card">
                <div v-if="currentPrap !== null" class="card prap-info-body">
                    <table class="prap-table">
                        <thead>
                            <tr>
                                <td colspan="2"><b>Purchase Order Summary</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-left">Type</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.tye : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Order No.</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.orderNumber : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Revision</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.revision : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Order Date</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.orderDate != null 
                                ? formatDateInputString(new Date(currentPrap.headerLine.orderDate)) : '' : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Created By</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.createdy : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Revision Date</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.headerRowField15 : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Updated By</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.headerRowField16 : ''}}</td>
                            </tr>
                        
                        </tbody>
                    </table>
                    <table class="prap-table">
                        <thead>
                            <tr>
                                <td colspan="2"><b>Contract</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-left">Description</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.contractDescription : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Supplier No.</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.supplierNumber : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Contract No.</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.contractNumber : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Opportunity No.</td>
                                <td class="col-right">{{currentPrap != null ?  currentPrap.headerLine.opportunityNumber : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Balance of Time</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.balanceofTime : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">JCP Advisor Initials</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.jcpAdvisorInitial : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">JCP Advisor Forename</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.jcpAdvisorForename : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">JCP Advisor Surname</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.jcpAdvisorSurname : ''}}</td>
                            </tr><tr>
                                <td class="col-left">JCP Advisor Office Code</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.headerLine.jcpAdvisorOfficeCode : ''}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="prap-table">
                        <thead>
                            <tr>
                                <td colspan="2"><b>Extra Referral Data</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-left">Title</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.title : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Forename</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.forename : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Surname</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.surname : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">NINO/Reference No.</td>
                                <td class="col-right">{{currentPrap != null ?  currentPrap.extraInfoLine.ninoReferenceNo : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Client Number</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.clientNumber : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Telephone No.</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.customerTelephoneNo : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Other Telephone No.</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.customerOtherTelephoneNo : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Address</td>
                                <td class="col-right">
                                    {{currentPrap != null ? currentPrap.extraInfoLine.customerAddress1 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.extraInfoLine.customerAddress2 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.extraInfoLine.customerTown : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.extraInfoLine.customerCounty : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.extraInfoLine.customerPostcode : ''}}<br/>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer DP/Disability Status</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.customerDPDisabilityStatus : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Signing Day</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.customerSigningDay : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Claim Cycle</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.customerClaimCycle : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Claim Pattern</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.customerClaimPattern : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Date of Referral</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.dateofReferral != null 
                                ? formatDateInputString(new Date(currentPrap.extraInfoLine.dateofReferral)) : '' : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Fast Track Status</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.fastTrackStatus : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Voluntary Early Entry Category</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.voluntaryEarlyEntryCategory : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Incident Marker</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.incidentMarker : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Child Care Requirements</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.childCareRequirements : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Welsh Language – Spoken</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.welshLanguageSpoken : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Welsh Language – Written</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.welshLanguageWritten : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Mandatory Provision</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.mandatoryProvision : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Mandatory Work Related Activity</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.mandatoryWorkRelatedActivity : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Safeguard Measures</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.safeguardMeasures : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Joint Claimant Customer's Forename</td>
                                <td class="col-right"></td>
                            </tr>
                            <tr>
                                <td class="col-left">Joint Claimant Customer's Surname</td>
                                <td class="col-right"></td>
                            </tr>
                            <tr>
                                <td class="col-left">Joint Claimant Customer's Title</td>
                                <td class="col-right"></td>
                            </tr>
                            <tr>
                                <td class="col-left">Joint Claimant Customer's NINO</td>
                                <td class="col-right"></td>
                            </tr>
                            <tr>
                                <td class="col-left">Disadvantaged Group, Early Entry Reason, Barriers to Work</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.extraInfoLine.disadvantagedGroup : ''}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="prap-table">
                        <thead>
                            <tr>
                                <td colspan="2"><b>Action Plan Items</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-left">Aims</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.aims : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Job Preferences</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.customerJobPreferences : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Preferred Hours</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.customerPreferredHours : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Employment History</td>
                                <td class="col-right">{{currentPrap != null ?  currentPrap.actionPlanLine.customerEmploymentHistory : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Previous Job 1 Dates</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.customerPreviousJob1Dates : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Previous Job 2</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.customerPreviousJob2 : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Previous Job 2 Dates</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.customerPreviousJob2Dates : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Driving Licence</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.customerDrivingLicence : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Licence Endorsements</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.customerLicenceEndorsements : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Customer Additional Information</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.customerAdditionalInformation : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Other Activities</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.otherActivities : ''}}</td>
                            </tr>
                            <tr>
                                <td class="col-left">Agreed Restriction</td>
                                <td class="col-right">{{currentPrap != null ? currentPrap.actionPlanLine.agreedRestriction : ''}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="prap-table">
                        <thead>
                            <tr>
                                <td colspan="2"><b>JCP Office Address</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-left">Address</td>
                                <td class="col-right">
                                    {{currentPrap != null ? currentPrap.headerLine.jcpOfficeAddressLine1 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.jcpOfficeAddressLine2 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.jcpOfficeAddressLine3 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.jcpOfficeTown : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.jcpOfficeArea : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.jcpPostcode : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.jcpCountry : ''}}<br/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="prap-table">
                        <thead>
                            <tr>
                                <td colspan="2"><b>Purchase Order Address</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-left">Address</td>
                                <td class="col-right">
                                    {{currentPrap != null ? currentPrap.headerLine.customerAddressLine1 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.customerAddressLine2 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.customerAddressLine3 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.customerTown : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.customerPostcode : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.customerCountry : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.customerPhone : ''}}<br/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="prap-table">
                        <thead>
                            <tr>
                                <td colspan="2"><b>Supplier Address</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-left">Address</td>
                                <td class="col-right">
                                    {{currentPrap != null ? currentPrap.headerLine.supplierAddressLine1 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.supplierAddressLine2 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.supplierAddressLine3 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.supplierAddressLine4 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.supplierAddressLine5 : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.supplierTown : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.supplierPostcode : ''}}<br/>
                                    {{currentPrap != null ? currentPrap.headerLine.supplierCountry : ''}}<br/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="small-overflow">
                    <table class="prap-table">
                        <thead>
                            <tr class="prap-multi-tr">
                                <td><b>Supplier Address</b></td>
                                <td><b>Part Number/ Description</b></td>
                                <td><b>Quantity</b></td>
                                <td><b>UoM</b></td>
                                <td><b>Unit Price (GBP)</b></td>
                                <td><b>Tax</b></td>
                                <td><b>Amount (GBP)</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(orderline, index) in orderLines" :key="index">
                                <td class="border-right">{{orderline.orderLineNumber}}</td>
                                <td class="border-right">{{orderline.partNumber}}</td>
                                <td class="border-right">{{orderline.quantity}}</td>
                                <td class="border-right">{{orderline.unitofmeasure}}</td>
                                <td class="border-right">{{orderline.unitprice}}</td>
                                <td class="border-right">{{orderline.tax}}</td>
                                <td>{{orderline.amount}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="prap-multi-tr">
                                <td colspan="6">Total:</td>
                                <td>{{currentPrap != null ? currentPrap.totalLine.totalOrderLine : ''}}</td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
                <div v-else class="card prap-info-body">
                    <table class="prap-table">
                        <thead>
                            <tr>
                                <td colspan="2"><b>No Results Found</b></td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <FooterVue/>
</div>
</template>
<script>
import navBarVue from '@/components/navBar.vue';
import FooterVue from '@/components/Footer.vue';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/store/commonStore';
import { NavBackwards } from "@/router/index";
export default {
    name: "contract-view",
    components:{
        navBarVue,
        FooterVue
    },
    computed:{
        ...mapState(useCommonStore,["getPRaPOrder"]),
        currentPrap(){
            return this.getPRaPOrder != null ? this.getPRaPOrder.documents[this.getPRaPOrder.documents.length-1] : null;
        },
        orderLines(){
            return this.currentPrap != null ? this.currentPrap.orderLine : [];
        }
    },
    methods:{
        ...mapActions(useCommonStore,["GetCustomer", "SetCustomerId", "GetPRaPOrder"]),
        formatDateInputString(date){
            return [
                    date.getDate() < 10 
                    ? '0' + (date.getDate()).toString() 
                    :date.getDate(),
                    date.getMonth()+1 < 10 
                    ? '0' + (date.getMonth()+1).toString() 
                    : date.getMonth()+1,
                    date.getFullYear(),].join('/');
        },
        navBack(){
            NavBackwards();
        }
    },
    mounted(){
        this.SetCustomerId(this.$route.params.id)
        Promise.all([
            this.GetCustomer(),
            this.GetPRaPOrder()]);
    }
}
</script>
<style scoped>
.prap-main{
    width: 100%;
    background-image: url('../assets/img/wave2.svg');
    background-repeat:no-repeat;
    justify-content: center;
    display: flex;
    font-size: 0.9rem;
}

.prap-cont{
    padding: 5%;
    padding-top:  calc(5%-1rem);
}

.prap-info{
    background-color: rgb(0 40 58 / 83%);
    padding: 2.3rem;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 75rem;
    width: 70vw;
    margin: 0 auto;
}

.prap-info-body{
    width: 100%;
    padding: 2rem 3rem;
}

.prap-table{
    width: 100%;
    margin-bottom: 1.5rem;
}

.small-overflow .prap-table{
    margin-bottom: 0;
}

.prap-table thead tr{
    background-color:#242e38;
    color: #fff;
}

.prap-table tbody tr:nth-child(even){
    background-color: #26313b;
    color: #fff;
}

.prap-table tbody tr:nth-child(odd){
    background-color: #293541;
    color: #fff;
}

.prap-table tr{
    /* border-top: 1px solid grey; */
    height: 3rem;
}
.prap-table tr td{
    padding: 0 1rem;
}

.col-left{
    width: 40%;
    border-right: 1px solid #232c36;
}

.col-right{
    width: 60%;
}

.border-right{
    border-right: 1px solid #232c36;
}

.prap-multi-tr td{
    border-right: 1px solid #575d64;
}

.prap-multi-tr td:last-child{
    border-right: 0px;
}

.prap-table tfoot tr{
    background-color:#242e38;
    color: #fff;
}

@media(max-width: 900px){
    .prap-info{
        width: 100%;
    }

    .prap-table{
        word-break: break-all;
    }

    .small-overflow{
        width: 100%;
        overflow-x: auto;
    }

    .small-overflow .prap-table{
        width: 60rem !important;
    }
}

.leave-bar{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 0.3rem 0.3rem 0 0;
    padding-right: 0.6rem;
}

.leave-bar svg{
    width: 2rem;
    height: 2rem;
    z-index: 1;
    cursor: pointer;
}

.leave-bar svg:hover{
    fill: #243147;
}
</style>