<template>
  <div v-if="configInformation">
    <div class="tenant-card" v-if="currentTenant">
      <div class="filter">
        <label for="tenant-select">Select Tenant:</label>
        <select id="tenant-select" v-model="selectedTenant" @change="updateSelectedTenant">
          <option v-for="tenant in configInformation.tenants" :key="tenant.tenantId" :value="tenant.tenantId">{{ tenant.displayName }}</option>
        </select>
        <div class="header">
          <button @click="showAddTenantModal = true">Add Tenant</button>
          <button @click="showAddLifecycleModal = true">Add Lifecycle</button>
        </div>
      </div>
      <h2>{{ currentTenant.displayName }}</h2>
      <div class="form-group">
        <label for="tenantId">Tenant ID:</label>
        <input type="text" id="tenantId" v-model="currentTenant.tenantId" readonly class="readonly-field">
      </div>
      <div class="form-group">
        <label for="displayName">Display Name:</label>
        <input type="text" id="displayName" v-model="currentTenant.displayName">
      </div>
      <div class="form-group">
        <label for="shortCode">Short Code:</label>
        <input type="text" id="shortCode" v-model="currentTenant.shortCode" readonly class="readonly-field">
      </div>
      <div class="form-group checkbox-group">
        <label for="isCaresEnabled">Is Cares Enabled:</label>
        <input type="checkbox" id="isCaresEnabled" v-model="currentTenant.isCaresEnabled">
      </div>
      <div class="form-group">
        <label for="actionCatalogueId">Action Catalogue ID:</label>
        <input type="text" id="actionCatalogueId" v-model="currentTenant.actionCatalogueId">
      </div>
      
      <!-- Third Party Identities -->
      <div class="third-party-identities">
        <h3>Third Party Identities</h3>
        <div class="form-group" v-for="(value, key) in currentTenant.thirdPartyIdentities" :key="key">
          <label :for="'thirdPartyIdentity-' + key">{{ key }}:</label>
          <input type="text" :id="'thirdPartyIdentity-' + key" v-model="currentTenant.thirdPartyIdentities[key]">
        </div>
      </div>

      <!-- Services -->
      <div class="services">
        <h3>Services</h3>
        <div v-for="(service, serviceName) in currentTenant.services" :key="serviceName" class="service">
          <h4>{{ serviceName }}</h4>
          <div class="form-group">
            <label :for="'serviceInstance-' + serviceName">Instance:</label>
            <input type="text" :id="'serviceInstance-' + serviceName" v-model="service.instance">
          </div>
          <div class="form-group">
            <label :for="'serviceProfile-' + serviceName">Profile:</label>
            <input type="text" :id="'serviceProfile-' + serviceName" v-model="service.profile">
          </div>
        </div>
      </div>

      <div class="feature-flags">
        <h3>Feature Flags</h3>
        <div class="form-group checkbox-group">
          <label for="mandationEnabled">Mandation Enabled:</label>
          <input type="checkbox" id="mandationEnabled" v-model="currentTenant.featureFlags.mandationEnabled">
        </div>
        <div class="form-group checkbox-group">
          <label for="customerFiles">Customer Files:</label>
          <input type="checkbox" id="customerFiles" v-model="currentTenant.featureFlags.customerFiles">
        </div>
        <div class="form-group checkbox-group">
          <label for="customerVoice">Customer Voice:</label>
          <input type="checkbox" id="customerVoice" v-model="currentTenant.featureFlags.customerVoice">
        </div>
        <div class="form-group checkbox-group">
          <label for="strandEnabled">Strand Enabled:</label>
          <input type="checkbox" id="strandEnabled" v-model="currentTenant.featureFlags.strandEnabled">
        </div>
        <div class="form-group checkbox-group">
          <label for="rightToWorkEnabled">Right To Work Enabled:</label>
          <input type="checkbox" id="rightToWorkEnabled" v-model="currentTenant.featureFlags.rightToWorkEnabled">
        </div>
      </div>

      <div class="themed-assessment">
        <h3>Themed Assessment Settings</h3>
        <div class="form-group">
          <label for="catalogueId">Catalogue ID:</label>
          <input type="text" id="catalogueId" v-model="currentTenant.themedAssessmentSettings.catalogueId">
        </div>
        <div class="form-group">
          <label for="actionId">Action ID:</label>
          <input type="text" id="actionId" v-model="currentTenant.themedAssessmentSettings.actionId">
        </div>
        <div class="form-group">
          <label for="lifecycles">Lifecycles:</label>
          <div v-for="(lifecycle, index) in currentTenant.themedAssessmentSettings.lifecycles" :key="index" class="lifecycle">
            <h4 @click="toggleLifecycle(index)">
              {{ lifecycle.name }}
              <span :class="{'arrow': true, 'expanded': lifecycle.isOpen}">&#9662;</span>
            </h4>
            <div v-show="lifecycle.isOpen">
              <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" :id="'name-' + index" v-model="lifecycle.name">
              </div>
              <div class="form-group">
                <label for="startDate">Start Date:</label>
                <input type="date" :id="'startDate-' + index" v-model="lifecycle.startDate">
              </div>
              <div class="form-group">
                <label for="endDate">End Date:</label>
                <input type="date" :id="'endDate-' + index" v-model="lifecycle.endDate">
              </div>
              <div class="form-group">
                <label for="catalogueId">Catalogue ID:</label>
                <input type="text" :id="'catalogueId-' + index" v-model="lifecycle.catalogueId">
              </div>
              <div class="form-group">
                <label for="actionId">Action ID:</label>
                <input type="text" :id="'actionId-' + index" v-model="lifecycle.actionId">
              </div>
              <div class="form-group">
                <label for="reassessmentDays">Reassessment Days:</label>
                <input type="text" :id="'reassessmentDays-' + index" v-model="lifecycle.reassessmentDays">
              </div>
              <div class="form-group">
                <label for="reassessmentActionId">Reassessment Action ID:</label>
                <input type="text" :id="'reassessmentActionId-' + index" v-model="lifecycle.reassessmentActionId">
              </div>
              <div class="form-group">
                <label for="otherAssessments">Other Assessments:</label>
                <div v-for="(assessment, i) in lifecycle.otherAssessments" :key="i" class="form-group">
                  <label for="actionId">Action ID:</label>
                  <input type="text" :id="'otherAssessment-actionId-' + index + '-' + i" v-model="assessment.actionId">
                  <label for="actionType">Action Type:</label>
                  <input type="text" :id="'otherAssessment-actionType-' + index + '-' + i" v-model="assessment.actionType">
                </div>
              </div>
              <div class="form-group checkbox-group" v-if="lifecycle.rightToWorkSettings">
                <label for="rightToWorkEnabled">Right To Work Enabled:</label>
                <input type="checkbox" :id="'rightToWorkEnabled-' + index" v-model="lifecycle.rightToWorkSettings.rightToWorkEnabled">
              </div>
              <div class="form-group">
                <label for="pendingActionId">Pending Action ID:</label>
                <input type="text" :id="'pendingActionId-' + index" v-model="lifecycle.rightToWorkSettings.pendingActionId">
              </div>

              <!-- Lifecycle Feature Flags -->
              <div class="form-group checkbox-group" v-if="lifecycle.featureFlags">
                <label for="pathwayEnabled">Pathway Enabled:</label>
                <input type="checkbox" :id="'pathwayEnabled-' + index" v-model="lifecycle.featureFlags.pathwayEnabled">
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="mindsetUrl">Mindset URL:</label>
          <input type="text" id="mindsetUrl" v-model="currentTenant.mindsetUrl">
        </div>
      </div>
      
      <!-- Contact Options -->
      <div class="contact-options">
      <h3>Contact Options</h3>
      <div class="form-group">
        <label for="strandOptions">Strand Options:</label>
        <div id="strandOptions">
          <div v-for="(strand, index) in currentTenant.contactOptions.strandOptions" :key="index" class="strand-item">
            <span>{{ strand }}</span>
            <button @click="removeStrandOption(index)">Remove</button>
          </div>
          <div class="add-strand">
            <input type="text" v-model="newStrand" placeholder="Add new strand option" />
            <button @click="addStrandOption">Add Strand</button>
          </div>
        </div>
      </div>
    </div>

      <button @click="saveTenant">Save</button>
    </div>

    <div v-if="showAddTenantModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Add New Tenant</h2>
          <span class="close-button" @click="showAddTenantModal = false">&times;</span>
        </div>
        <div class="form-group">
          <label for="newTenantId">Tenant ID:</label>
          <input type="text" id="newTenantId" v-model="newTenant.tenantId">
          <div v-if="!newTenant.tenantId && showValidationErrors" class="error-message">Tenant ID is required.</div>
        </div>
        <div class="form-group">
          <label for="newTenantDisplayName">Display Name:</label>
          <input type="text" id="newTenantDisplayName" v-model="newTenant.displayName">
          <div v-if="!newTenant.displayName && showValidationErrors" class="error-message">Display Name is required.</div>
        </div>
        <div class="form-group">
          <label for="newTenantShortCode">Short Code:</label>
          <input type="text" id="newTenantShortCode" v-model="newTenant.shortCode">
          <div v-if="!newTenant.shortCode && showValidationErrors" class="error-message">Short Code is required.</div>
        </div>
        <div class="form-group checkbox-group">
          <label for="newTenantIsCaresEnabled">Is Cares Enabled:</label>
          <input type="checkbox" id="newTenantIsCaresEnabled" v-model="newTenant.isCaresEnabled">
        </div>
        <div class="form-group">
          <label for="newTenantActionCatalogueId">Action Catalogue ID:</label>
          <input type="text" id="newTenantActionCatalogueId" v-model="newTenant.actionCatalogueId">
          <div v-if="!newTenant.actionCatalogueId && showValidationErrors" class="error-message">Action Catalogue ID is required.</div>
        </div>
        
        <!-- Third Party Identities -->
        <div class="third-party-identities">
          <h3>Third Party Identities</h3>
          <div class="form-group">
            <label for="newTenantKey1">Key1:</label>
            <input type="text" id="newTenantKey1" v-model="newTenant.thirdPartyIdentities.Key1">
          </div>
          <div class="form-group">
            <label for="newTenantKey2">Key2:</label>
            <input type="text" id="newTenantKey2" v-model="newTenant.thirdPartyIdentities.Key2">
          </div>
        </div>

        <!-- Services -->
        <div class="services">
          <h3>Services</h3>
          <div class="form-group">
            <label for="newTenantService1Instance">Service1 Instance:</label>
            <input type="text" id="newTenantService1Instance" v-model="newTenant.services.Service1.instance">
          </div>
          <div class="form-group">
            <label for="newTenantService1Profile">Service1 Profile:</label>
            <input type="text" id="newTenantService1Profile" v-model="newTenant.services.Service1.profile">
          </div>
          <div class="form-group">
            <label for="newTenantService2Instance">Service2 Instance:</label>
            <input type="text" id="newTenantService2Instance" v-model="newTenant.services.Service2.instance">
          </div>
          <div class="form-group">
            <label for="newTenantService2Profile">Service2 Profile:</label>
            <input type="text" id="newTenantService2Profile" v-model="newTenant.services.Service2.profile">
          </div>
        </div>

        <div class="feature-flags">
          <h3>Feature Flags</h3>
          <div class="form-group checkbox-group">
            <label for="newTenantMandationEnabled">Mandation Enabled:</label>
            <input type="checkbox" id="newTenantMandationEnabled" v-model="newTenant.featureFlags.mandationEnabled">
          </div>
          <div class="form-group checkbox-group">
            <label for="newTenantCustomerFiles">Customer Files:</label>
            <input type="checkbox" id="newTenantCustomerFiles" v-model="newTenant.featureFlags.customerFiles">
          </div>
          <div class="form-group checkbox-group">
            <label for="newTenantCustomerVoice">Customer Voice:</label>
            <input type="checkbox" id="newTenantCustomerVoice" v-model="newTenant.featureFlags.customerVoice">
          </div>
          <div class="form-group checkbox-group">
            <label for="newTenantStrandEnabled">Strand Enabled:</label>
            <input type="checkbox" id="newTenantStrandEnabled" v-model="newTenant.featureFlags.strandEnabled">
          </div>
          <div class="form-group checkbox-group">
            <label for="newTenantRightToWorkEnabled">Right To Work Enabled:</label>
            <input type="checkbox" id="newTenantRightToWorkEnabled" v-model="newTenant.featureFlags.rightToWorkEnabled">
          </div>
        </div>

        <div class="themed-assessment">
          <h3>Themed Assessment Settings</h3>
          <div class="form-group">
            <label for="newTenantCatalogueId">Catalogue ID:</label>
            <input type="text" id="newTenantCatalogueId" v-model="newTenant.themedAssessmentSettings.catalogueId">
            <div v-if="!newTenant.themedAssessmentSettings.catalogueId && showValidationErrors" class="error-message"> Themed Action Catalogue ID is required.</div>
          </div>
          <div class="form-group">
            <label for="newTenantActionId">Action ID:</label>
            <input type="text" id="newTenantActionId" v-model="newTenant.themedAssessmentSettings.actionId">
            <div v-if="!newTenant.themedAssessmentSettings.actionId && showValidationErrors" class="error-message">Themed Action ID is required.</div>
          </div>
        </div>

        <div class="form-group">
          <label for="newTenantMindsetUrl">Mindset URL:</label>
          <input type="text" id="newTenantMindsetUrl" v-model="newTenant.mindsetUrl">
          <div v-if="!newTenant.mindsetUrl && showValidationErrors" class="error-message">Mindset Url is required.</div>
        </div>
        <button @click="addNewTenant">Add Tenant</button>
      </div>
    </div>

    <div v-if="showAddLifecycleModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Add New Lifecycle</h2>
          <span class="close-button" @click="showAddLifecycleModal = false">&times;</span>
        </div>
        <div class="form-group">
          <label for="newLifecycleName">Name:</label>
          <input type="text" id="newLifecycleName" v-model="newLifecycle.name">
          <div v-if="!newLifecycle.name && showValidationErrors" class="error-message">Name is required.</div>
        </div>
        <div class="form-group">
          <label for="newLifecycleStartDate">Start Date:</label>
          <input type="date" id="newLifecycleStartDate" v-model="newLifecycle.startDate">
          <div v-if="!newLifecycle.startDate && showValidationErrors" class="error-message">Start Date is required.</div>
        </div>
        <div class="form-group">
          <label for="newLifecycleEndDate">End Date:</label>
          <input type="date" id="newLifecycleEndDate" v-model="newLifecycle.endDate">
        </div>
        <div class="form-group">
          <label for="newLifecycleCatalogueId">Catalogue ID:</label>
          <input type="text" id="newLifecycleCatalogueId" v-model="newLifecycle.catalogueId">
          <div v-if="!newLifecycle.catalogueId && showValidationErrors" class="error-message">Catalogue ID is required.</div>
        </div>
        <div class="form-group">
          <label for="newLifecycleActionId">Action ID:</label>
          <input type="text" id="newLifecycleActionId" v-model="newLifecycle.actionId">
          <div v-if="!newLifecycle.actionId && showValidationErrors" class="error-message">Action ID is required.</div>
        </div>
        <div class="form-group">
          <label for="newLifecycleReassessmentDays">Reassessment Days:</label>
          <input type="text" id="newLifecycleReassessmentDays" v-model="newLifecycle.reassessmentDays">
          <div v-if="!newLifecycle.reassessmentDays && showValidationErrors" class="error-message">Reassessment Days are required.</div>
        </div>
        <div class="form-group">
          <label for="newLifecycleReassessmentActionId">Reassessment Action ID:</label>
          <input type="text" id="newLifecycleReassessmentActionId" v-model="newLifecycle.reassessmentActionId">
          <div v-if="!newLifecycle.reassessmentActionId && showValidationErrors" class="error-message">Reassessment Action ID is required.</div>
        </div>
        <div class="form-group">
          <label for="newLifecycleOtherAssessments">Other Assessments:</label>
          <div v-for="(assessment, index) in newLifecycle.otherAssessments" :key="index" class="form-group">
            <label for="actionId">Action ID:</label>
            <input type="text" :id="'newOtherAssessment-actionId-' + index" v-model="assessment.actionId">
            <label for="actionType">Action Type:</label>
            <input type="text" :id="'newOtherAssessment-actionType-' + index" v-model="assessment.actionType">
          </div>
        </div>
        <div class="form-group checkbox-group">
          <label for="newLifecycleRightToWorkEnabled">Right To Work Enabled:</label>
          <input type="checkbox" id="newLifecycleRightToWorkEnabled" v-model="newLifecycle.rightToWorkEnabled">
        </div>
        <div class="form-group">
          <label for="newLifecyclePendingActionId">Pending Action ID:</label>
          <input type="text" id="newLifecyclePendingActionId" v-model="newLifecycle.pendingActionId">
          <div v-if="!newLifecycle.pendingActionId && showValidationErrors" class="error-message">Pending Action ID is required.</div>
        </div>
        <div class="form-group checkbox-group">
          <label for="newLifecyclePathwayEnabled">Pathway Enabled:</label>
          <input type="checkbox" id="newLifecyclePathwayEnabled" v-model="newLifecycle.featureFlags.pathwayEnabled">
        </div>

        <button @click="addNewLifecycle">Add Lifecycle</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useCommonStore } from "@/store/commonStore";

export default {
  data() {
    return {
      selectedTenant: null,
      currentTenant: null,
      showAddTenantModal: false,
      showAddLifecycleModal: false,
      showValidationErrors: false,
      newTenant: {
        tenantId: "",
        displayName: "",
        shortCode: "",
        isCaresEnabled: false,
        actionCatalogueId: "",
        thirdPartyIdentities: {
          Key1: "",
          Key2: ""
        },
        services: {
          Service1: {
            instance: "",
            profile: ""
          },
          Service2: {
            instance: "",
            profile: ""
          }
        },
        featureFlags: {
          mandationEnabled: false,
          customerFiles: false,
          customerVoice: false,
          strandEnabled: false,
          rightToWorkEnabled: false
        },
        themedAssessmentSettings: {
          catalogueId: "",
          actionId: "",
          lifecycles: []
        },
        contactOptions: {
          strandOptions: []
        },
        mindsetUrl: ""
      },
      newLifecycle: {
        name: "",
        startDate: "",
        endDate: "",
        catalogueId: "",
        actionId: "",
        reassessmentDays: "",
        reassessmentActionId: "",
        rightToWorkEnabled: false,
        pendingActionId: "",
        featureFlags: {
          pathwayEnabled: false
        },
        otherAssessments: [
          {
            actionId: "",
            actionType: ""
          }
        ]
      },
      newStrand: "",
      availableStrandOptions: []
    };
  },
  mounted() {
    this.GetConfigInformation().then(() => {
      const tenant = this.configInformation.tenants.find(
        (tenant) => tenant.displayName === "RestartSouthCentral"
      );
      if (tenant) {
        this.selectedTenant = tenant.tenantId;
        this.updateSelectedTenant();
      }
    });
  },
  computed: {
    ...mapState(useCommonStore, ["configInformation"]),

    isTenantFormValid() {
      return this.newTenant.tenantId && this.newTenant.displayName && this.newTenant.shortCode 
      && this.newTenant.actionCatalogueId && this.newTenant.themedAssessmentSettings.catalogueId 
      && this.newTenant.themedAssessmentSettings.actionId && this.newTenant.mindsetUrl;
    },

    isLifecycleFormValid() {
      return this.newLifecycle.name && this.newLifecycle.startDate && this.newLifecycle.catalogueId 
      && this.newLifecycle.actionId && this.newLifecycle.reassessmentDays 
      && this.newLifecycle.reassessmentActionId && this.newLifecycle.pendingActionId;
    },
  },
  methods: {
    ...mapActions(useCommonStore, ["GetConfigInformation", "PutConfigInformation"]),

    updateSelectedTenant() {
      this.currentTenant = this.configInformation.tenants.find(
        (tenant) => tenant.tenantId === this.selectedTenant
      );

      if (this.currentTenant && this.currentTenant.themedAssessmentSettings) {
        this.currentTenant.themedAssessmentSettings.lifecycles = this.currentTenant.themedAssessmentSettings.lifecycles.map(lifecycle => ({
          ...lifecycle,
          featureFlags: lifecycle.featureFlags || { pathwayEnabled: false },
          rightToWorkSettings: lifecycle.rightToWorkSettings || { rightToWorkEnabled: false, pendingActionId: '' },
          startDate: lifecycle.startDate ? lifecycle.startDate.split("T")[0] : '',
          endDate: lifecycle.endDate ? lifecycle.endDate.split("T")[0] : '',
          isOpen: false
        }));
      }
    },

    convertDateToUTC(date) {
      return date ? new Date(date).toISOString() : null;
    },

    async saveTenant() {
      const updatedConfigInformation = {
        ...this.configInformation,
        tenants: this.configInformation.tenants.map(tenant => {
          if (tenant.tenantId === this.currentTenant.tenantId) {
            return {
              ...this.currentTenant,
              themedAssessmentSettings: {
                ...this.currentTenant.themedAssessmentSettings,
                lifecycles: this.currentTenant.themedAssessmentSettings.lifecycles.map(lifecycle => ({
                  ...lifecycle,
                  startDate: this.convertDateToUTC(lifecycle.startDate),
                  endDate: this.convertDateToUTC(lifecycle.endDate)
                }))
              }
            };
          }
          return tenant;
        })
      };

      console.log("Sending data to API:", updatedConfigInformation);

      try {
        const response = await this.PutConfigInformation({ data: updatedConfigInformation });
        console.log("Configuration saved successfully:", response);
      } catch (error) {
        console.error("Error saving configuration:", error);
      }
    },

    toggleLifecycle(index) {
      this.currentTenant.themedAssessmentSettings.lifecycles[index].isOpen = !this.currentTenant.themedAssessmentSettings.lifecycles[index].isOpen;
    },

    addNewTenant() {
      this.showValidationErrors = true;

      if (!this.isTenantFormValid) {
        return;
      }

      this.configInformation.tenants.push({ ...this.newTenant });
      this.showAddTenantModal = false;
      this.resetNewTenantForm();
      this.showValidationErrors = false;
    },

    resetNewTenantForm() {
      this.newTenant = {
        tenantId: "",
        displayName: "",
        shortCode: "",
        isCaresEnabled: false,
        actionCatalogueId: "",
        thirdPartyIdentities: {
          Key1: "",
          Key2: ""
        },
        services: {
          Service1: {
            instance: "",
            profile: ""
          },
          Service2: {
            instance: "",
            profile: ""
          }
        },
        featureFlags: {
          mandationEnabled: false,
          customerFiles: false,
          customerVoice: false,
          strandEnabled: false,
          rightToWorkEnabled: false
        },
        themedAssessmentSettings: {
          catalogueId: "",
          actionId: "",
          lifecycles: []
        },
        contactOptions: {
          strandOptions: []
        },
        mindsetUrl: ""
      };
    },

    addNewLifecycle() {
      this.showValidationErrors = true;

      if (!this.isLifecycleFormValid) {
        return;
      }

      if (this.currentTenant && this.currentTenant.themedAssessmentSettings) {
        this.currentTenant.themedAssessmentSettings.lifecycles.push({
          ...this.newLifecycle,
          otherAssessments: this.newLifecycle.otherAssessments.map(assessment => ({
            actionId: assessment.actionId,
            actionType: assessment.actionType
          })),
          rightToWorkSettings: this.newLifecycle.rightToWorkEnabled ? {
            rightToWorkEnabled: true,
            pendingActionId: this.newLifecycle.pendingActionId
          } : null,
          featureFlags: {
            pathwayEnabled: this.newLifecycle.featureFlags.pathwayEnabled
          },
          isOpen: false
        });
        this.showAddLifecycleModal = false;
        this.resetNewLifecycleForm();
        this.showValidationErrors = false;
      }
    },

    resetNewLifecycleForm() {
      this.newLifecycle = {
        name: "",
        startDate: "",
        endDate: "",
        catalogueId: "",
        actionId: "",
        reassessmentDays: "",
        reassessmentActionId: "",
        rightToWorkEnabled: false,
        pendingActionId: "",
        featureFlags: {
          pathwayEnabled: false
        },
        otherAssessments: [
          {
            actionId: "",
            actionType: ""
          }
        ]
      };
    },

    addStrandOption() {
      if (this.newStrand) {
        this.currentTenant.contactOptions.strandOptions.push(this.newStrand);
        this.newStrand = "";
      }
    },

    removeStrandOption(index) {
      this.currentTenant.contactOptions.strandOptions.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 15px;
}

.filter label {
  margin-right: 10px;
  font-size: 15px;
}

.filter select {
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

.tenant-card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="date"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group.checkbox-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-inline {
  display: flex;
  align-items: center;
}

button {
  padding: 5px 20px;
  background-color: #a6bf37;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #8ca32d;
}

.lifecycle {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.lifecycle h4 {
  margin-top: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  transition: transform 0.2s;
}

.expanded {
  transform: rotate(180deg);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.readonly-field {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.modal button {
  padding: 10px 20px;
  background-color: #a6bf37;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #8ca32d; 
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.contact-options {
  margin-top: 20px;
}

.contact-options h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #2c3e50;
}

.contact-options .form-group {
  margin-bottom: 15px;
}

.contact-options label {
  font-size: 16px;
  color: #34495e;
  margin-bottom: 5px;
  display: block;
}

.checkbox-item {
  margin-bottom: 5px;
}

.checkbox-item label {
  display: flex;
  align-items: center;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
}

.strand-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.strand-item span {
  margin-right: 10px;
  font-weight: bold;
}

.strand-item button {
  padding: 5px 10px;
  background-color: #a6bf37; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.strand-item button:hover {
  background-color: #8ca32d;
}

.add-strand {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.add-strand input {
  flex-grow: 1;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.add-strand button {
  padding: 2px 5px;
  background-color: #a6bf37;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.add-strand button:hover {
  background-color: #8ca32d;
}
</style>
