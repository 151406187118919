<template>
  <div class="p-3 bg-white card border-radius-xl js-active" data-animation="FadeIn">
    <div class="text-center row">
      <div class="mx-auto col-12">
        <h5 class="font-weight-normal">
          Please check the details entered are correct.
        </h5>
        <p>
          If you want to make changes you can go back.
          If everything is correct then please approve and the file will save.
          You will be able to access it through a link in the mandated action.
        </p>
      </div>
    </div>
    <div
      v-if="documentId != null"
      style="width: 95%; margin: 0 auto; aspect-ratio: 1.7/1"
    >
      <iframe
        id="preview-iframe"
        style="width: 100%; height: 100%"
        :src="getPreviewMandationFormLink"
        width="476px"
        height="288px"
        frameborder="0"
        >This is an embedded
        <a target="_blank" href="https://office.com">Microsoft Office</a>
        document, powered by
        <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
    </div>
    <div class="multisteps-form__content">
      <div class="mt-4 button-row d-flex" style="justify-content: flex-end">
        <button class="mb-0 btn bg-gradient-dark ms-4 js-btn-next" type="button" title="Next" @click="goBack()">
          Go Back
        </button>
        <button class="mb-0 btn bg-gradient-dark ms-4 js-btn-next" type="button" title="Next" @click="onConfirm()">
          Approve and Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useCommonStore } from "@/store/commonStore";
import { mapState, mapActions } from "pinia";
import { showLoadingAlert, showWarningAlert, showSuccessAlert, showConfirmationAlert } from "@/utils/SwalUtil";

// const ByPost = "By Post"
// const FaceToFace = "Face to Face"

export default {
  props: {
    activityProvider: {
      type: String,
      default: "Fedcap",
    },
    cscNumber: {
      type: String,
      default: "0800 917 9262",
    },
  },

  data() {
    return {
      iFrameSrc: null,
      blobDestination: "",
      documentLink: "",
      showMandatedPopup: false,
      docName: "",
      documentId: null,
      iframeVisible: false
    };
  },

  computed: {
    ...mapState(useCommonStore, [
      "getCustomer",
      "getAllAdvisors",
      "setUcMandationFormVisability",
      "getActionToMandate",
      "getDueDate",
      "getManFormData",
      "getMandationEmbedGuid",
      "getIsEditingManForm",
      "getPRaPOrder",
    ]),

    getPreviewMandationFormLink(){
      return process.env.VUE_APP_PREVIEW_MANDATION_FORM.replace('{documentId}',this.documentId)
    },

    filteredAdvisors() {
      let advisors = this.getAllAdvisors.filter((a) => {
        return this.getContactDisplay(a) != "";
      });

      return advisors.sort(this.contactSort);
    },
    storeChoices() {
      return {
        complianceCondition: this.option,
        complianceDetails: [
          {
            name: "none",
            value: this.inputValue1,
          },
        ],
        isIssuedByPost: this.isByPost,
        isIssuedFaceToFace: this.isF2F,
        requiredEvidence: this.text,
      };
    },

    formAsDTO() {
      const methodOfIssue = "By Post"

      return {
        owningUser:
          // Advisor priority
          // (1) Selected advisor
          // (2) Logged in advisor
          // (3) Customer's advisor (may be different to logged in advisor)
          this.selectedAdvistorId == null
            ? this.loggedInAdvisor == null
              ? this.getCustomer.advisor.id
              : this.loggedInAdvisor.id
            : this.selectedAdvistorId,
        mandatableType: "Action",
        customerName: this.getCustomer.fullName, 
        title: this.getManFormData.Title,
        nino: "",
        mandationSubject: this.getActionToMandate.subject,
        includeMap: this.includeMap,
        providerName: this.getManFormData.activityContactName,
        providerPhone: this.cscNumber,
        duedate: this.getManFormData.Duedate,
        locationOverride: "no location",
        startTimeOverride: "0:0:0.0000000",
        isPhoneAppointment: false,
        isGroupAppointment: this.getManFormData.isGroupAppointment,
        complianceCondition: this.getManFormData.ComplianceCondition,
        complianceDetails: [
          {
            name: "none",
            value: this.getManFormData.inputValue,
          },
        ],
        // [Deprecated] Use methodOfIssue instead
        // isIssuedByPost: this.isByPost,
        // isIssuedFaceToFace: this.isF2f,
        isJobSeeker: false,
        requiredEvidence: this.getManFormData.Evidence,
        addressLine1: this.getCustomer.addressLine1,
        addressLine2: this.getCustomer.addressLine2,
        addressLine3: this.getCustomer.addressLine3,
        city: this.getCustomer.city,
        stateOrProvince: this.getCustomer.stateOrProvince,
        county: this.getCustomer.county,
        country: this.getCustomer.country,
        postalCode: this.getCustomer.postalCode,
        methodOfIssue: methodOfIssue
      };
    },
  },

  methods: {
    ...mapActions(useCommonStore, [
      "GetAllAdvisors",
      "PostMandationForm",
      "setPreviewData",
      "GetMandationEmbedGuid",
      "getManFormData",
      "postMandationFormToSharepoint",
      "GetMandationEmbedGuid",
      "setIsEditingManForm",
      "setUcManDirectFormData",
      "getPRaPOrder",
      "createMANPreviewDocument",
      "createMANRecord",
      "HandleUIError"
    ]),

    /**
     * Load the preview of the document.
     */
    loadPreview() {
      showLoadingAlert(this.createPreview(), { title: "Generating UC-MAN Preview" })
        // Code to run on failure...
        .catch((err) => {
          this.HandleUIError(err)
          this.goBack()
        })
    },

    /**
     * Generate and save the final version of the document.
     */
    onConfirm() {
      showConfirmationAlert({ 
        title: "Proceed with Generating UC-MAN Form?", 
        text: "This information cannot be changed afterwards"
      }).then((result) => {
        if (result.isConfirmed) {  
          // Show the loading alert
          showLoadingAlert(this.createFinal(), { title: "Generating UC-MAN Document" })
            // Code to run on success...
            .then(() => {
              showSuccessAlert({ title : "Document Generated"})
              this.closeForm()
            })
            // Code to run on failure...
            .catch((err) => {
              const store = useCommonStore()
              store.HandleUIError(err)
            })
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          showWarningAlert({ title: "UC-MAN Form has not been submitted"})         
        }
      })
    },

    goBack() {
      this.$parent.prevStep();
      this.setIsEditingManForm(true);
    },

    capitaliseAddress(address) {
      if (address === null) {
        return null;
      }

      const regex = /(?<=^|\s|-)[a-z]/g;

      // convert the address to lowercase and replace matched letters with uppercase
      const capitalisedAddress = address
        .toLowerCase()
        .replace(regex, (match) => match.toUpperCase());

      return capitalisedAddress;
    },

    contactSort(a, b) {
      let aDisplay = this.getContactDisplay(a).toLowerCase();
      let bDisplay = this.getContactDisplay(b).toLowerCase();

      if (aDisplay == bDisplay) {
        return 0;
      }

      return aDisplay < bDisplay ? -1 : 1;
    },
    getContactDisplay(contact) {
      let firstName = contact.firstName != null ? contact.firstName.trim() : "";
      let lastName = contact.lastName != null ? contact.lastName.trim() : "";

      let fullName = contact.fullName != null ? contact.fullName.trim() : "";
      if (fullName == null || fullName == "") {
        fullName = firstName + " " + lastName;
      }

      return fullName.trim();
    },

    setAdvisorDetails(id) {
      var contact = this.getAllAdvisors.find((c) => c.contactId == id);
      if (contact == undefined) {
        console.error("EA could not be found.");
        return;
      }
      this.customerData.activityContactName = this.getContactDisplay(contact);
      this.selectedAdvisorId = id;
    },
    setup() {
      this.loggedInAdvisor = this.getLoggedInAdvisor;

      if (this.loggedInAdvisor != null) {
        this.setAdvisorDetails(this.loggedInAdvisor.contactId);
      }

      if (
        this.getCustomer.salutation != null &&
        this.getCustomer.salutation != ""
      ) {
        let salutation = this.getCustomer.salutation + ".";
        if (this.SALUTATIONS.includes(salutation)) {
          this.customerData.hasSalutation = true;
          this.salutation = salutation;
        }
      }
    },
    reset() {
      // Reset form state
      Object.assign(this.$data, this.$options.data.apply(this));

      this.$refs["f2f"].checked = this.isF2F;
      this.$refs["by-post"].checked = this.isByPost;

      this.setup();
    },

    /**
    * Creates a preview of the MAN document
    */
    async createPreview() {
      var resp = await this.createMANPreviewDocument(this.getActionToMandate.id, this.formAsDTO);

      this.documentId = await this.GetMandationEmbedGuid(
        resp.documentId
      );
    },

    /**
    * Creates the final version of the MAN, once the user selects save
    */
    async createFinal() {
      await this.createMANRecord(this.getActionToMandate.id, this.formAsDTO); 
    },

    async closeForm() {
      this.setUcMandationFormVisability(false);
      if (this.showMandatedPopup === true) {
        this.showMandatedPopup === false;
      }
      this.setUcManDirectFormData(null);
      this.setIsEditingManForm(false);
    },

    openLink() {
      window.open(this.documentLink, "_blank");
    },
  },

  mounted() {
    this.loadPreview()
  },
};
</script>

<style>
.mandation-confirmation {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  padding: 12px;
  border: 1px solid #2dce89;
  border-radius: 5px;
  background-color: rgba(#fff, 0.4);
  z-index: 20;
  opacity: 1;
  transition: 0.6s;
}

.button-5 {
  align-items: center;
  background-clip: padding-box;
  background-color: #57bce4;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-5:hover,
.button-5:focus {
  background-color: #1a6d86;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  transform: translateY(-1px);
}

.button-5:active {
  background-color: #0b4b71;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
</style>
