<template>
<div style="padding: 0.5rem 1.5rem;">
    <navBarVue style="background-color: #6F9600;"/>
     <div class="assessment-results-main card" style="margin: 1rem 0 0.75rem 0;">
        <div class="leave-bar">
            <svg @click="navBack()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
        </div>
        <div class="assessment-results-cont">
            <div class="results-table" style="width: 100%;">
                <div class="results-header results-tr" style="width: 100%; padding: 0.3rem 0.8rem;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm64 192c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V160zM320 288c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32z"/></svg>
                    Responses
                </div>
                <div style="width: 100%; display: flex; flex-direction: row;">
                    <div style="width: 50%; border-right: 1px solid rgb(190, 196, 216);">
                        <div style="display: flex;flex-direction: column;padding: 0.6rem 0.8rem;" class="results-td td-first td-no-border">
                            <div><b>Scores [1 = Strongly Disagree, 5 = Strongly Agree]</b></div>
                            <div style="margin-top: auto;">Current: SOC <b>{{getAssessmentResults.length > 0 ? getAssessmentResults[getAssessmentResults.length-1].soc : ''}}</b></div>
                        </div>
                        <div :style="index==0 ? 'border-top: 1px solid rgb(190, 196, 216);' : ''" class="results-td question-td" style="padding: 0.6rem 0.8rem;" v-for="(question, index) in getQuestions" :key="question">
                            {{question.question}}
                        </div>
                        <div style="width: 100%; padding: 0.6rem 0.8rem; height: 3rem;"><b>Coaching Guides >></b></div>
                    </div>
                    <div style="width: 50%; overflow-x: auto;">
                        <div style="display: flex; flex-direction: row;" class="td-first results-td td-no-border">
                            <div v-for="assessment in getAssessmentResults" :key="assessment" class="assessment-header">
                                <div><b>{{assessment.assessmentDisplay}}</b></div>
                                <div>{{formatDate(new Date(assessment.completedAt))}}</div>
                                <div><b>SOC ({{assessment.soc}})</b></div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row;">
                            <div class="answer-column" style="display: flex; flex-direction: column;" v-for="assessment in getAssessmentResults" :key="assessment">
                                <div class="assessment-answer-cont results-td" v-for="answer in assessment.answers" :key="answer">
                                    <div class="assessment-anwser" :style="`background-color: hsl(${answer.hue},100%,75%)`">{{answer.score}}</div>
                                </div>
                                <div class="coaching-guide-btn-td">
                                    <button class="btn" @click="OpenCoachingGuide(assessment.assessmentId)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterVue/>
</div>
</template>
<script>
import navBarVue from '@/components/navBar.vue';
import FooterVue from '@/components/Footer.vue';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/store/commonStore';
import { NavBackwards } from "@/router/index";
export default {
    name: "assessment-result",
    components:{
        navBarVue,
        FooterVue
    },
    computed:{
        ...mapState(useCommonStore,["getCustomer", "getAssessmentResults"]),
        getQuestions(){
            var questions = [];
            for(var i =0; i < this.getAssessmentResults.length; i++){
                if(this.getAssessmentResults[i].answers.length > questions.length){
                    questions = this.getAssessmentResults[i].answers;
                }
            }
            return questions;
        }
    },
    methods:{
        ...mapActions(useCommonStore,["GetCustomer", "GetAssessmentResults", "SetCustomerId", "GetCoachingGuideRedirect"]),
        formatDate(date){
            return [
                    date.getDate() < 10 
                    ? '0' + (date.getDate()).toString() 
                    :date.getDate(),
                    date.getMonth()+1 < 10 
                    ? '0' + (date.getMonth()+1).toString() 
                    : date.getMonth()+1,
                    date.getFullYear(),].join('/');
        },
        navBack(){
            NavBackwards();
        },
        async OpenCoachingGuide(assessmentId){
            var path = await this.GetCoachingGuideRedirect(assessmentId);
            window.open(path, '_blank');
        }
    },
    mounted(){
        this.SetCustomerId(this.$route.params.id)
        Promise.all([
            this.GetCustomer(),
            this.GetAssessmentResults()
        ]);
    }
}
</script>
<style scoped>
.coaching-guide-btn-td{
    width: 100%; 
    padding: 0.6rem 0.8rem; 
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coaching-guide-btn-td button{
    margin: 0;
    padding: 0;
    padding: 0.4rem 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.results-table{
    background-color: rgb(248, 248, 248);
    border: 1px solid rgb(190, 196, 216);
    border-radius: 1rem;
}

.coaching-guide-btn-td button svg{
    width: 1rem;
    height: 1rem;
}

.results-header{
    font-size: 2rem;
    border-radius: 1rem 1rem 0 0;
    background-color:rgb(238, 238, 238);
    display: flex;
    justify-content: left;
    align-items: center;
}

.results-header svg{
    width: 2rem;
    height: 2rem;
    margin-right: 0.3rem;
}

.assessment-anwser{
    padding: 0.2rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    color: rgb(82, 82, 82);
}

.results-tr{
    border-bottom: 1px solid rgb(190, 196, 216);
}

.results-tr:last-of-type{
    border-bottom: 0;
}

.results-td{
    border-bottom: 1px solid rgb(190, 196, 216);
}

.results-td:last-child{
    border-bottom: 0;
}

.question-td, .assessment-answer-cont{
    height: 3rem;
}

.td-first{
    height: 5.5rem;
}

.answer-column{
    border-top: 1px solid rgb(190, 196, 216)
}

.assessment-header, .assessment-answer-cont{
    padding: 0.3rem 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 10rem;
    max-width: 10rem;
}


.assessment-header div{
    width: fit-content;
}

.td-no-border{
    border-bottom: 0;
}

.assessment-results-main{
    width: 100%;
    background-image: url('../assets/img/wave2.svg');
    background-repeat:no-repeat;
    justify-content: center;
    display: flex;
    font-size: 0.9rem;
}

.assessment-results-cont{
    padding: 5%;
    padding-top:  calc(5%-1rem);
}

.leave-bar{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 0.3rem 0.3rem 0 0;
    padding-right: 0.6rem;
}

.leave-bar svg{
    width: 2rem;
    height: 2rem;
    z-index: 1;
    cursor: pointer;
}

.leave-bar svg:hover{
    fill: #243147;
}
</style>