<template>
  <div class="backgroundColour"></div>
</template>
<script>
export default {
  name: "error-view",
};
</script>
<style scoped>
.backgroundColour {
  height: 100%;
  width: 100%;
  background: rgb(254, 254, 254);
  background: linear-gradient(
    302deg,
    rgba(254, 254, 254, 1) 0%,
    rgba(20, 126, 194, 1) 100%
  );
}
</style>
