import axios from "axios";
import { TryGetCachedToken } from "../router/TokenManger";
const baseUrl = process.env.VUE_APP_ACTIONS_BASE_URL;
const envVars = process.env;
export default{
    async GetConfigValues(){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:baseUrl
                + envVars.VUE_APP_CUSTOMER_SPEND_CONFIG,
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
              }); 
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async GetCustomerSpend(customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:baseUrl
                + envVars.VUE_APP_CUSTOMER_SPEND_ALL
                .replace("{customerId}", customerId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
              }); 
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async GetCustomerSpendBySpendId(customerId, spendId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:baseUrl
                + envVars.VUE_APP_CUSTOMER_SPEND_SINGLE
                .replace("{customerId}", customerId)
                .replace("{spendId}", spendId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
              }); 
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async CreateCustomerSpendRecord(record, customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            var resp = await axios({ 
                method: 'POST',
                headers: { 
                    'content-type':'application/json',
                },
                data: record,
                url:baseUrl
                + envVars.VUE_APP_CUSTOMER_SPEND_ALL
                .replace("{customerId}", customerId)});
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async UpdateCustomerSpendRecord(record, spendId, customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            var resp = await axios({ 
                method: 'PUT',
                headers: { 
                    'content-type':'application/json',
                },
                data: record,
                url:baseUrl
                + envVars.VUE_APP_CUSTOMER_SPEND_SINGLE
                .replace("{customerId}", customerId)
                .replace("{spendId}", spendId)});
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async DeleteCustomerSpendRecord(spendId, customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            var resp = await axios({ 
                method: 'DELETE',
                headers: { 
                    'content-type':'application/json',
                },
                url:baseUrl
                + envVars.VUE_APP_CUSTOMER_SPEND_SINGLE
                .replace("{customerId}", customerId)
                .replace("{spendId}", spendId)});
            return resp;
        }
        catch(error){
            return error;
        }
    }
}