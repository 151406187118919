<template>
    <div>
        <!-- Desktop View -->
        <div>
            <h4><b>Customer Spend History</b></h4>
            <a
                href="#cus-spend-table"
                style="
                  margin: 0 1rem 1rem auto;
                  background-color: white;
                  padding: 0.23rem 0.8rem;
                  font-size: 0.9rem;
                "
                class="btn"
                @click="loadCreationForm()"
              >
            <svg
                style="width: 1rem; margin-right: 0.2rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
            >
                <path
                d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                />
            </svg>
            Add New
            </a>
            <table 
                class="table table-flush" 
                style="table-layout: fixed; width: 100%">
                <thead>
                    <tr>
                        <th style="padding: 8px" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            <div 
                                :title="'Description'" 
                                style="cursor:pointer;">
                                Description
                            </div>
                            
                        </th>
                        <th style="padding: 8px" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            <div 
                                :title="'Amount'" 
                                style="cursor:pointer;">
                                Amount
                            </div>
                        </th>
                        <th style="padding: 8px" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            <div 
                                :title="'Spend Date'" 
                                style="cursor:pointer;">
                                Spend Date
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(record, index) in getCustomerSpendHistory" :key="index">
                        <td
                            class="text-sm font-weight-normal"
                            >
                            <div :title="record.spend.description" style="cursor:pointer;">
                                {{ record.spend.description }}
                            </div>
                            
                        </td>
                        <td
                            class="text-sm font-weight-normal"
                            >
                            <div :title="parseInt(record.spend.value.value) != undefined ? this.getCurrencyTableDisplay(parseInt(record.spend.value.value)/100) : ''" style="cursor:pointer;">
                                {{ parseInt(record.spend.value.value) != undefined ? this.getCurrencyTableDisplay(parseInt(record.spend.value.value)/100) : '' }}
                            </div>
                        </td>
                        <td
                            class="text-sm font-weight-normal"
                            >
                            <div :title="record.spend.spendOn != null && record.spend.spendOn != '' ? formatDate(new Date(record.spend.spendOn)) : ''" style="cursor:pointer;">
                                {{record.spend.spendOn != null && record.spend.spendOn != "" ? formatDate(new Date(record.spend.spendOn)) : ""}}
                            </div>
                            
                        </td>
                        <td
                            class="text-sm font-weight-normal">
                            <div style="display: flex; justify-content: right; flex-wrap: wrap">
                                <svg 
                                    @click="fireConfirmDeleteCustomerSpendAlert(record.identity.id, true)" 
                                    style="cursor: pointer; margin-left: 0.7rem; width: 20px; height: 20px; min-width: 16px; min-height: 16px"
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                                </svg>

                                <a href="#cus-spend-table" style="display: flex;">
                                <svg 
                                    @click="loadEditForm(record)" 
                                    style="cursor: pointer; margin-left: 0.7rem; width: 20px; height: 20px; min-width: 16px; min-height: 16px" 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
                                </svg>
                                </a>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>


        <!-- Spend Form -->
        <div ref="spend-form" class="card" style="margin-top: 1.1rem; padding: 0.8rem 1.1rem; background-color:rgb(236, 236, 236)" v-show="showCreateNew">
            <table id="cus-spend-table">
                <thead>
                    <tr>
                        <td colspan="2">
                            <div style="width: 100%; height: 100%; display: flex; justify-content: space-between; align-items: center;">
                                <h4 style="margin:0;" v-show="recordToEdit == null"><b>Create Customer Spend Record</b></h4>
                                <h4 style="margin:0;" v-show="recordToEdit != null"><b>Edit Customer Spend Record</b></h4>
                                <svg style="height: 2rem; cursor:pointer;" @click="showCreateNew = false; clearValues()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="label-cell">Description of Spend</td>
                        <td class="input-cell" v-show="recordToEdit == null">
                            <input v-model="description"/>
                            <div v-show="errors.description != ''" class="text-danger">{{errors.description}}</div>
                        </td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <input :value="recordToEdit != null ? recordToEdit.spend.description : ''" @input="recordToEdit.spend.description = $event.target.value"/>
                            <div v-show="errors.description != ''" class="text-danger">{{errors.description}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Amount</td>
                        <td class="input-cell" v-show="recordToEdit == null">
                            <input 
                                class="amount-input"
                                type="number" 
                                value="0" 
                                min="0"
                                @input="onAmountInput($event)"
                                @change="onAmountInput($event)"
                                @keypress="validateCurrencyKeyPress($event)" />
                            <!-- <span style="margin: 0 0.3rem;">
                                <b>.</b>
                            </span>
                            <input class="amount-input" value="0" type="number" @input="changeAmountDec($event.target, $event.target.value)"/> -->
                            <div v-show="errors.amount != ''" class="text-danger">{{errors.amount}}</div>
                        </td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <input 
                                class="amount-input"
                                type="number" 
                                :value="recordToEdit != null ? this.recordAmount : '0'" 
                                min="0"
                                @input="onRecordAmountInput($event)"
                                @change="onRecordAmountInput($event)"
                                @keypress="validateCurrencyKeyPress($event)" />
                            <!-- <span style="margin: 0 0.3rem;">
                                <b>.</b>
                            </span>
                            <input class="amount-input" type="number" :value="recordToEdit != null ? getRecordAmountDec() : '0'" @input="changeRecordAmountDec($event.target, $event.target.value)"/> -->
                            <div v-show="errors.amount != ''" class="text-danger">{{errors.amount}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Spend Date</td>
                        <td class="input-cell" v-show="recordToEdit == null"><input type="date" v-model="spendOn"/><div v-show="errors.spendOn != ''" class="text-danger">{{errors.spendOn}}</div></td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <input type="date" :value="recordToEdit != null ? recordToEdit.spend.spendOn != null && recordToEdit.spend.spendOn != '' ? formatDateInputString(new Date(recordToEdit.spend.spendOn)): null : null" @input="recordToEdit.spend.spendOn = $event.target.value"/>
                            <div v-show="errors.spendOn != ''" class="text-danger">{{errors.spendOn}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Expected Date</td>
                        <td class="input-cell" v-show="recordToEdit == null"><input type="date" v-model="expectedOn"/><div v-show="errors.expectedOn != ''" class="text-danger">{{errors.expectedOn}}</div></td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <input type="date" :value="recordToEdit != null ? recordToEdit.spend.expectedOn != null && recordToEdit.spend.expectedOn != '' ? formatDateInputString(new Date(recordToEdit.spend.expectedOn)): null :null" @input="recordToEdit.spend.expectedOn = $event.target.value"/>
                            <div v-show="errors.expectedOn != ''" class="text-danger">{{errors.expectedOn}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Transaction Date</td>
                        <td class="input-cell" v-show="recordToEdit == null"><input type="date" v-model="transactionOn"/><div v-show="errors.transactionOn != ''" class="text-danger">{{errors.transactionOn}}</div></td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <input type="date" :value="recordToEdit != null ? recordToEdit.spend.transactionOn != null && recordToEdit.spend.transactionOn != '' ? formatDateInputString(new Date(recordToEdit.spend.transactionOn)): null : null" @input="recordToEdit.spend.transactionOn = $event.target.value"/>
                            <div v-show="errors.transactionOn != ''" class="text-danger">{{errors.transactionOn}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Posted Date</td>
                        <td class="input-cell" v-show="recordToEdit == null"><input type="date" v-model="postedOn"/><div v-show="errors.postedOn != ''" class="text-danger">{{errors.postedOn}}</div></td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <input type="date" :value="recordToEdit != null ? recordToEdit.spend.postedOn != null && recordToEdit.spend.postedOn != '' ? formatDateInputString(new Date(recordToEdit.spend.postedOn)): null : null" @input="recordToEdit.spend.postedOn = $event.target.value"/>
                            <div v-show="errors.postedOn != ''" class="text-danger">{{errors.postedOn}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Status</td>
                        <td class="input-cell" v-show="recordToEdit == null">
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: row">
                                <div class="selector" style="overflow: hidden;">{{status}}</div>
                                <svg
                                    v-show="!showStatusDrpDwn"
                                    @click="()=>{showStatusDrpDwn = true; showPaymentTypeDrpDwn = false; showPaymentMethodDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                                    />
                                </svg>
                                <svg
                                    v-show="showStatusDrpDwn"
                                    @click="()=>{showStatusDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                                    />
                                </svg>
                                </div>
                                <div class="selector-options" v-show="showStatusDrpDwn">
                                    <div
                                        v-for="item in getCustomerSpendConfig.paymentStatuses"
                                        :key="item.id"
                                        @click="
                                        () => {
                                            status = item.name;
                                            showStatusDrpDwn = false;
                                        }
                                        "
                                    >
                                        {{ statusDisplayName(item) }}
                                    </div>
                                </div>
                                <div v-show="errors.status != ''" class="text-danger">{{errors.status}}</div>
                            </div>
                        </td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: row">
                                <div class="selector" style="overflow: hidden;">{{recordToEdit != null ? recordToEdit.spend.status : ''}}</div>
                                <svg
                                    v-show="!showStatusDrpDwn"
                                    @click="()=>{showStatusDrpDwn = true; showPaymentTypeDrpDwn = false; showPaymentMethodDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                                    />
                                </svg>
                                <svg
                                    v-show="showStatusDrpDwn"
                                    @click="()=>{showStatusDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                                    />
                                </svg>
                                </div>
                                <div class="selector-options" v-show="showStatusDrpDwn">
                                <div
                                    v-for="item in getCustomerSpendConfig.paymentStatuses"
                                    :key="item.id"
                                    @click="
                                    () => {
                                        recordToEdit.spend.status = item.name;
                                        showStatusDrpDwn = false;
                                    }
                                    "
                                >
                                    {{ statusDisplayName(item) }}
                                </div>
                                </div>
                                <div v-show="errors.status != ''" class="text-danger">{{errors.status}}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Payment Type</td>
                        <td class="input-cell" v-show="recordToEdit == null">
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: row">
                                <div class="selector" style="overflow: hidden;">{{paymentType}}</div>
                                <svg
                                    v-show="!showPaymentTypeDrpDwn"
                                    @click="()=>{showPaymentTypeDrpDwn = true; showStatusDrpDwn = false; showPaymentMethodDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                                    />
                                </svg>
                                <svg
                                    v-show="showPaymentTypeDrpDwn"
                                    @click="()=>{showPaymentTypeDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                                    />
                                </svg>
                                </div>
                                <div class="selector-options" v-show="showPaymentTypeDrpDwn">
                                <div
                                    v-for="item in getCustomerSpendConfig.paymentTypes"
                                    :key="item.id"
                                    @click="
                                    () => {
                                        paymentType = item.name;
                                        showPaymentTypeDrpDwn = false;
                                    }
                                    "
                                >
                                    {{ item.name }}
                                </div>
                                </div>
                                <div v-show="errors.paymentType != ''" class="text-danger">{{errors.paymentType}}</div>
                            </div>
                        </td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: row">
                                <div class="selector" style="overflow: hidden;">{{recordToEdit != null ? recordToEdit.spend.paymentType : ''}}</div>
                                <svg
                                    v-show="!showPaymentTypeDrpDwn"
                                    @click="()=>{showPaymentTypeDrpDwn = true; showStatusDrpDwn = false; showPaymentMethodDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                                    />
                                </svg>
                                <svg
                                    v-show="showPaymentTypeDrpDwn"
                                    @click="()=>{showPaymentTypeDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                                    />
                                </svg>
                                </div>
                                <div class="selector-options" v-show="showPaymentTypeDrpDwn">
                                <div
                                    v-for="item in getCustomerSpendConfig.paymentTypes"
                                    :key="item.id"
                                    @click="
                                    () => {
                                        recordToEdit.spend.paymentType = item.name;
                                        showPaymentTypeDrpDwn = false;
                                    }
                                    "
                                >
                                    {{ item.name }}
                                </div>
                                </div>
                                <div v-show="errors.paymentType != ''" class="text-danger">{{errors.paymentType}}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Payment Method</td>
                        <td class="input-cell" v-show="recordToEdit == null">
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: row">
                                <div class="selector" style="overflow: hidden;">{{paymentMethod}}</div>
                                <svg
                                    v-show="!showPaymentMethodDrpDwn"
                                    @click="()=>{showPaymentMethodDrpDwn = true; showPaymentTypeDrpDwn = false; showStatusDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                                    />
                                </svg>
                                <svg
                                    v-show="showPaymentMethodDrpDwn"
                                    @click="()=>{showPaymentMethodDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                                    />
                                </svg>
                                </div>
                                <div class="selector-options" v-show="showPaymentMethodDrpDwn">
                                <div
                                    v-for="item in getCustomerSpendConfig.paymentMethods"
                                    :key="item.id"
                                    @click="
                                    () => {
                                        paymentMethod = item.name;
                                        showPaymentMethodDrpDwn = false;
                                    }
                                    "
                                >
                                    {{ item.name }}
                                </div>
                                </div>
                                <div v-show="errors.paymentMethod != ''" class="text-danger">{{errors.paymentMethod}}</div>
                            </div>
                        </td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: row">
                                <div class="selector" style="overflow: hidden;">{{recordToEdit != null ? recordToEdit.spend.paymentMethod : ''}}</div>
                                <svg
                                    v-show="!showPaymentMethodDrpDwn"
                                    @click="()=>{showPaymentMethodDrpDwn = true; showPaymentTypeDrpDwn = false; showStatusDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                                    />
                                </svg>
                                <svg
                                    v-show="showPaymentMethodDrpDwn"
                                    @click="()=>{showPaymentMethodDrpDwn = false;}"
                                    class="tgl-page-dropdown"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                                    />
                                </svg>
                                </div>
                                <div class="selector-options" v-show="showPaymentMethodDrpDwn">
                                <div
                                    v-for="item in getCustomerSpendConfig.paymentMethods"
                                    :key="item.id"
                                    @click="
                                    () => {
                                        recordToEdit.spend.paymentMethod = item.name;
                                        showPaymentMethodDrpDwn = false;
                                    }
                                    "
                                >
                                    {{ item.name }}
                                </div>
                                </div>
                                <div v-show="errors.paymentMethod != ''" class="text-danger">{{errors.paymentMethod}}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="label-cell">Notes</td>
                        <td class="input-cell" v-show="recordToEdit == null"><textarea v-model="notes"/><div v-show="errors.notes != ''" class="text-danger">{{errors.notes}}</div></td>
                        <td class="input-cell" v-show="recordToEdit != null">
                            <textarea :value="recordToEdit != null ? recordToEdit.spend.memo : ''" @input="recordToEdit.spend.memo = $event.target.value"/>
                            <div v-show="errors.notes != ''" class="text-danger">{{errors.notes}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <button class="btn" @click="showCreateNew = false; clearValues();" style="margin-right: 1rem; background-color:rgb(241, 241, 241);">Cancel</button>
                            <button class="btn" v-show="recordToEdit == null" @click="saveSpend()" style="background-color:rgb(241, 241, 241);">Save</button>
                            <button class="btn" :disabled="!recordToEditChanged" v-show="recordToEdit != null" @click="editSpend()" style="background-color:rgb(241, 241, 241);">Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>   
</template>
<script>
import { NavBackwards } from "@/router/index";
import { useCommonStore } from "@/store/commonStore";
import { toNumber } from "@vue/shared";
import { mapActions, mapState } from "pinia";

const MaxTotalSpend = process.env.VUE_APP_CUSTOMER_SPEND_LIMIT_TOTAL
const MaxSingleSpend = process.env.VUE_APP_CUSTOMER_SPEND_LIMIT_SINGLE

export default {
    name: "customer-spend-form",
    data(){
        return{
            extendedRecords: [],

            loadSuccess: false,
            showCreateNew: false,
            showEdit: false,
            errors:{
                description:"",
                amount: "",
                spendOn: "",
                expectedOn: "",
                transactionOn: "",
                postedOn: "",
                status: "",
                paymentType: "",
                paymentMethod: "",
                notes:""
            },
            showStatusDrpDwn: false,
            showPaymentTypeDrpDwn: false,
            showPaymentMethodDrpDwn: false,
            statusOptions:["Expected", "Paid", "Cancelled", "Withhold"],
            paymentTypeOptions: ["Childcare", "Clothing and Protective Gear", "Mobile Phone and Top Up Card", "Personal Appearance", "Personal Development Courses", "Personal ID", "Stationary (including CV Prep)", "Subsistence", "Tools & Equipment", "Training", "Transport", "Travel Costs"],
            paymentMethodOptions: ["Credit Card", "General Requisition", "Petty Cash"],
            description:"",
            amount: "0",
            spendOn: "",
            expectedOn: "",
            transactionOn: "",
            postedOn: "",
            status: "",
            paymentType: "",
            paymentMethod: "",
            notes:"",
            recordToEdit: null,
            recordToEditOriginal: null,
            recordAmount: "0",

            // Only these keys can be entered into currency input boxes
            currencyKeyCodeWhitelist:[
                "Digit0",
                "Digit1",
                "Digit2",
                "Digit3",
                "Digit4",
                "Digit5",
                "Digit6",
                "Digit7",
                "Digit8",
                "Digit9",
                "Period",

                "Numpad0",
                "Numpad1",
                "Numpad2",
                "Numpad3",
                "Numpad4",
                "Numpad5",
                "Numpad6",
                "Numpad7",
                "Numpad8",
                "Numpad9",
                "NumpadDecimal"
            ],
            currencyMaxDigits: 9
        }
    },
    computed:{
        ...mapState(useCommonStore,["getCustomerSpendHistory", "getCustomerSpendConfig"]),

        normalizedTotalSpend() {
            let spendHistory = this.getCustomerSpendHistory
            let total = 0

            for (let i = 0; i < spendHistory.length; i++) {
                let spend = spendHistory[i].spend
                if (!isNaN(spend.value.value)) {
                    total += this.getActualSpendValue(spend.value.value, spend.status)
                }
            }

            return total
        },

        normalizedSpendCap() {
            let totalSpend = this.normalizedTotalSpend

            if (MaxTotalSpend - totalSpend < MaxSingleSpend) {
                return Math.max(0, MaxTotalSpend - totalSpend)
            }

            return Math.max(0, MaxSingleSpend)
        },

        recordToEditChanged() {
            if (this.recordToEdit == null || this.recordToEditOriginal == null) {
                return false
            }

            const originalSpend = this.recordToEditOriginal.spend
            const editingSpend = this.recordToEdit.spend

            let parsedCurrency = this.tryParseCurrency(this.recordAmount)
            if (!parsedCurrency.success) {
                return false
            }

            return originalSpend.description != editingSpend.description
                || originalSpend.value.value != parsedCurrency.normalized
                || this.getUTCDateString(new Date(originalSpend.spendOn)) != this.getUTCDateString(new Date(editingSpend.spendOn))
                || this.getUTCDateString(new Date(originalSpend.expectedOn)) != this.getUTCDateString(new Date(editingSpend.expectedOn))
                || this.getUTCDateString(new Date(originalSpend.transactionOn)) != this.getUTCDateString(new Date(editingSpend.transactionOn))
                || this.getUTCDateString(new Date(originalSpend.postedOn)) != this.getUTCDateString(new Date(editingSpend.postedOn))
                || originalSpend.status != editingSpend.status
                || originalSpend.paymentType != editingSpend.paymentType
                || originalSpend.paymentMethod != editingSpend.paymentMethod
                || originalSpend.memo != editingSpend.memo
        }
    },
    methods:{
        ...mapActions(useCommonStore,[ "getUTCDateString", "GetCustomerSpendConfig", "SetCustomerId", "CreateCustomerSpend", "GetCustomerSpend", "UpdateCustomerSpend", "fireConfirmDeleteCustomerSpendAlert", "fireErrorAlert" ]),
        
        onRecordSelect(spendId) {
            console.log(spendId)
            this.extendedRecords.push(spendId)
        },

        onRecordDeselect(spendId) {
            this.extendedRecords = this.extendedRecords.filter(s => s != spendId)
        },
             
        formatDate(date){
            return [
                date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
                (date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1),
                date.getFullYear() 
                ].join('/');
        },
        getRecord(spendId) {
            return this.getCustomerSpendHistory.filter(r => r.identity.id == spendId)[0]
        },
        statusDisplayName(config) {
            return config.name + (!config.includedInTotal ? " (Excluded from total)" : "")
        },
        getCurrencyTableDisplay(value){
            let parsed = this.tryParseCurrency(value, true)
            if (!parsed.success) {
                return value
            }

            return parsed.fullDisplay
        },
        resetErrors(){
            this.errors = {
                description:"",
                amount: "",
                spendOn: "",
                expectedOn: "",
                transactionOn: "",
                postedOn: "",
                status: "",
                paymentType: "",
                paymentMethod: "",
                notes:""
            }
        },
        evaluateErrors() {
            this.resetErrors()

            let error = false
            if(this.description == ""){
                this.errors.description = "*Required";
                error = true;
            }

            let validatedSpendAmount = this.validateSpendAmount(this.amount, this.status)
            if (!validatedSpendAmount.success) {
                this.errors.amount = validatedSpendAmount.error
                error = true;
            }
            if(this.spendOn == ""){
                this.errors.spendOn = "*Required";
                error = true;
            }
            if(this.expectedOn == ""){
                this.errors.expectedOn = "*Required";
                error = true;
            }
            if(this.transactionOn == ""){
                this.errors.transactionOn = "*Required";
                error = true;
            }
            if(this.postedOn == ""){
                this.errors.postedOn = "*Required";
                error = true;
            }
            if(this.status == ""){
                this.errors.status = "*Required";
                error = true;
            } else {
                if (!this.validatePaymentStatus(this.status)) {
                    this.errors.status = "*Invalid payment status";
                    error = true;
                }
            }
            if(this.paymentType == ""){
                this.errors.paymentType = "*Required";
                error = true;
            } else {
                if (!this.validatePaymentType(this.paymentType)) {
                    this.errors.paymentType = "*Invalid payment type";
                    error = true;
                }
            }
            if(this.paymentMethod == ""){
                this.errors.paymentMethod = "*Required";
                error = true;
            } else {
                if (!this.validatePaymentMethod(this.paymentMethod)) {
                    this.errors.paymentMethod = "*Invalid payment method"
                }
            }
            if(this.notes == ""){
                this.errors.notes = "*Required";
                error = true;
            }

            return !error
        },
        evaluateEditErrors(){
            this.resetErrors()

            let error = false
            if(this.recordToEdit.spend.description == ""){
                this.errors.description = "*Required";
                error = true;
            }

            let validatedSpendAmount = this.validateEditedSpendAmount(this.recordAmount, this.recordToEdit)
            if (!validatedSpendAmount.success) {
                this.errors.amount = validatedSpendAmount.error
                error = true;
            }
            if(this.recordToEdit.spend.spendOn == ""){
                this.errors.spendOn = "*Required";
                error = true;
            }
            if(this.recordToEdit.spend.expectedOn == ""){
                this.errors.expectedOn = "*Required";
                error = true;
            }
            if(this.recordToEdit.spend.transactionOn == ""){
                this.errors.transactionOn = "*Required";
                error = true;
            }
            if(this.recordToEdit.spend.postedOn == ""){
                this.errors.postedOn = "*Required";
                error = true;
            }
            if(this.recordToEdit.spend.status == ""){
                this.errors.status = "*Required";
                error = true;
            } else {
                if (!this.validatePaymentStatus(this.recordToEdit.spend.status)) {
                    this.errors.status = "*Invalid payment status";
                    error = true;
                }
            }
            if(this.recordToEdit.spend.paymentType == ""){
                this.errors.paymentType = "*Required";
                error = true;
            } else {
                if (!this.validatePaymentType(this.recordToEdit.spend.paymentType)) {
                    this.errors.paymentType = "*Invalid payment type";
                    error = true;
                }
            }
            if(this.recordToEdit.spend.paymentMethod == ""){
                this.errors.paymentMethod = "*Required";
                error = true;
            } else {
                if (!this.validatePaymentMethod(this.recordToEdit.spend.paymentMethod)) {
                    this.errors.paymentMethod = "*Invalid payment method"
                }
            }
            if(this.recordToEdit.spend.memo == ""){
                this.errors.notes = "*Required";
                error = true;
            }
            
            return !error
        },
        formatDateInputString(date){
            return [
                date.getFullYear(),
                (date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1),
                date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
                ].join('-');
        },
        navBack(){
            this.clearValues();
            NavBackwards();
        },
        validatePaymentStatus(status) {        
            return this.getCustomerSpendConfig.paymentStatuses
                .filter(s => s.name == status)
                .length > 0
        },
        validatePaymentType(type) {
            return this.getCustomerSpendConfig.paymentTypes
                .filter(t => t.name == type)
                .length > 0
        },
        validatePaymentMethod(method) {
            return this.getCustomerSpendConfig.paymentMethods
                .filter(m => m.name == method)
                .length > 0
        },
        async loadCreationForm() {
            if (!this.loadSuccess) {
                this.fireErrorAlert("Try reloading the page.")
                return
            }


            this.showCreateNew = true; 
            this.recordToEdit = null;
            this.recordToEditOriginal = null;
        },
        async loadEditForm(record){
            if (!this.loadSuccess) {
                this.fireErrorAlert("Try reloading the page.")
                return
            }

            this.recordToEdit = JSON.parse(JSON.stringify(record)); 
            this.recordToEditOriginal = JSON.parse(JSON.stringify(record)); 
            this.setRecordAmount(record.spend.value.value); 
            this.showCreateNew = true;        
            this.resetErrors()
        },
        async saveSpend(){
            // console.log(this.amount);
            // console.log(parseFloat(this.amount));
            // console.log(Math.round(parseFloat(this.amount)*100));
            if (!this.evaluateErrors()) {
                return
            }
                    
            // This has already been validated above
            let parsedCurrency = this.tryParseCurrency(this.amount)
            let success = await this.CreateCustomerSpend(
                {
                    spendOn: this.spendOn != "" ? this.getUTCDateString(new Date(this.spendOn)) : null,
                    expectedOn: this.expectedOn != "" ? this.getUTCDateString(new Date(this.expectedOn)) : null,
                    transactionOn: this.transactionOn != "" ? this.getUTCDateString(new Date(this.transactionOn)) : null,
                    postedOn: this.postedOn != "" ? this.getUTCDateString(new Date(this.postedOn)) : null,
                    value: {
                        value: parsedCurrency.normalized,
                        code: "GBP"
                    },
                    description: this.description,
                    status: this.status,
                    paymentType: this.paymentType,
                    paymentMethod: this.paymentMethod,
                    memo: this.notes
                }, true);

            if (success) {
                this.clearValues();
                this.showCreateNew = false;
            }
        },
        async editSpend(){
            if (!this.evaluateEditErrors()) {
                return
            }

            // This has already been validated above
            let parsedCurrency = this.tryParseCurrency(this.recordAmount)
            let success = await this.UpdateCustomerSpend(
            {
                spendOn: this.recordToEdit.spend.spendOn != "" ? this.getUTCDateString(new Date(this.recordToEdit.spend.spendOn)) : null,
                expectedOn: this.recordToEdit.spend.expectedOn != "" ? this.getUTCDateString(new Date(this.recordToEdit.spend.expectedOn)) : null,
                transactionOn: this.recordToEdit.spend.transactionOn != "" ? this.getUTCDateString(new Date(this.recordToEdit.spend.transactionOn)) : null,
                postedOn: this.recordToEdit.spend.postedOn != "" ? this.getUTCDateString(new Date(this.recordToEdit.spend.postedOn)) : null,
                value: {
                    value: parsedCurrency.normalized,
                    code: "GBP"
                },
                description: this.recordToEdit.spend.description,
                status: this.recordToEdit.spend.status,
                paymentType: this.recordToEdit.spend.paymentType,
                paymentMethod: this.recordToEdit.spend.paymentMethod,
                memo: this.recordToEdit.spend.memo
            }
            , this.recordToEdit.identity.id, true);

            if (success) {
                this.clearValues();
                this.showCreateNew = false;
            }         
        },
        clearValues(){
            this.resetErrors();
            this.showStatusDrpDwn= false;
            this.showPaymentTypeDrpDwn= false;
            this.showPaymentMethodDrpDwn= false;
            this.description="";
            this.amount= "0";
            this.spendOn= "";
            this.expectedOn= "";
            this.transactionOn= "";
            this.postedOn= "";
            this.status= "";
            this.paymentType= "";
            this.paymentMethod= "";
            this.notes="";
            this.recordToEdit = null;
            this.recordToEditOriginal = null;
        },
        changeAmountDec(target, value){
            if((isNaN(parseInt(value)) || parseFloat(value) - Math.floor(parseFloat(value)) > 0) && value != ''){
                target.value = this.amount.substring(this.amount.indexOf(".")+1);
                return;
            }
            this.amount = this.amount.substring(0, this.amount.indexOf(".")+1) + value;
        },
        validateEditedSpendAmount(input, editingRecord) {
            let originalRecord = this.getRecord(editingRecord.identity.id)

            let originalInput = originalRecord.spend.value.value
            let originalStatus = originalRecord.spend.status

            // There must be an inputted value before it can be processed
            if (input == undefined || input == null || input == "") {
                return { success: false, error: "*Required"}
            }


            let parsedInput = this.tryParseCurrency(input)
            let parsedOriginal = this.tryParseNormalizedCurrency(originalInput)
            let parsedSpendCap = this.tryParseNormalizedCurrency(this.normalizedSpendCap)

            // The input must be a valid currency before it can be processed
            if (!parsedInput.success) {
                return { success: false, error: "*Must be a valid currency" }
            }

            // console.log("Original status: " + originalStatus)
            
            let actualValue = this.getActualSpendValue(parsedInput.normalized, editingRecord.spend.status)
            let originalSafe = 0
            if (parsedOriginal.success) {
                originalSafe = this.getActualSpendValue(parsedOriginal.normalized, originalStatus)
            }

            // console.log("Actual: " + actualValue)
            // console.log("Original: " + originalSafe)

            // console.log("Total: " + this.normalizedTotalSpend)

            // Spend cap that does not include this edit's original value
            let editSpendCap = Math.max(0, (MaxTotalSpend - this.normalizedTotalSpend) + originalSafe)
            let parsedEditSpendCap = this.tryParseNormalizedCurrency(editSpendCap)

            // console.log("Cap: " + editSpendCap)
            
            // If the spending amount is greater than the remaining total customer spending limit across all spends
            if (actualValue > editSpendCap) {
                return { 
                    success: false, 
                    error: `*Value exceeds maximum edit allowance (${parsedEditSpendCap.fullDisplay}) for remaining total customer spending limit (${parsedSpendCap.fullDisplay})`
                }
            }

            return this.validateSingleSpendValue(parsedInput.normalized)
        },
        validateSpendAmount(input, status) {
            // There must be an inputted value before it can be processed
            if (input == undefined || input == null || input == "") {
                return { success: false, error: "*Required"}
            }

            let parsedInput = this.tryParseCurrency(input)
            let parsedSpendCap = this.tryParseNormalizedCurrency(this.normalizedSpendCap)

            // The input must be a valid currency before it can be processed
            if (!parsedInput.success) {
                return { success: false, error: "*Must be a valid currency" }
            }


            // Value based on status code (some status codes do not contribute towards customer total expenditure)
            let actualValue = this.getActualSpendValue(parsedInput.normalized, status)

            // If the spending amount is greater than the remaining total customer spending limit across all spends
            if (actualValue > this.normalizedSpendCap) {
                return { success: false, error: `*Value exceeds remaining total customer spending limit (${parsedSpendCap.fullDisplay})` }
            }

            return this.validateSingleSpendValue(parsedInput.normalized)
        },
        // Helper function for validation
        validateSingleSpendValue(value) {
            let parsedMaxSingleSpend = this.tryParseNormalizedCurrency(MaxSingleSpend)

            // If the spending amount is greater than the spending limit for a single spend
            if (value > MaxSingleSpend) {
                return { success: false, error: `*Value exceeds spending limit of a single spend (${parsedMaxSingleSpend.fullDisplay})`}
            }

            return { success: true }
        },
        getActualSpendValue(amount, status) {
            for (let i = 0; i < this.getCustomerSpendConfig.paymentStatuses.length; i++) {
                let statusConfig = this.getCustomerSpendConfig.paymentStatuses[i]
                if (statusConfig.name == status) {
                    if (statusConfig.includedInTotal) {
                        return amount
                    } else {
                        return 0
                    }
                }
            }

            console.warn("Unrecognised status code: " + status)
            return amount
        },
        tryParseNormalizedCurrency(input) {
            if (input == null || input == undefined) {
                return { success: false }
            } 

            return this.tryParseCurrency(parseFloat(input) / 100)
        },
        tryParseCurrency(input, allowNegative = false) {
            if (input == null || input == undefined) {
                return { success: false }
            } 

            try {
                input = input.toString()
            } catch {
                return { success: false } 
            }

            let parts = input.split(".")
            let hasPart1 = input.length > 0
            let hasPart2 = parts.length > 1

            // Cannot have negative values or more than 1 decimal point
            if ((!allowNegative && input.indexOf("-") != -1) || parts.length > 2) {
                return { success: false }
            }
                
            // Pence/minor values should be limited to 2 numbers (e.g '05')
            let majorString = hasPart1 ? parts[0] : "0"
            let minorString = hasPart2 ? parts[1] : "0"

            // Values entered must be numbers
            // Cannot have pence/minor values with more than 2 digits
            if (isNaN(majorString) || isNaN(minorString) || majorString.length > this.currencyMaxDigits || minorString.length > 2) {
                return { success: false }
            }

            let majorNum = toNumber(majorString)
            let minorNum = toNumber(minorString) * (minorString.length == 1 ? 10 : 1)

            let inputDisplayMajorString = majorNum.toString()
            let inputDisplayMinorString = minorString

            let fullDisplayMajorString = inputDisplayMajorString
            let fullDisplayMinorString = inputDisplayMinorString

            if (inputDisplayMinorString.length == 1) {
                fullDisplayMinorString += "0"

                // Don't append '0' for input boxes
                if (!hasPart2) {
                    inputDisplayMinorString += "0"
                }             
            }

            let inputDisplay = (hasPart1 ? inputDisplayMajorString : "") + (hasPart2 ? "." + inputDisplayMinorString : "")
            let fullDisplay = fullDisplayMajorString + "." + fullDisplayMinorString

            let normalized = minorNum + (majorNum * 100)

            return {
                success: true,
                input: input, 
                inputDisplay: inputDisplay,
                fullDisplay: fullDisplay,
                major: majorNum,
                minor: minorNum,
                normalized: normalized
            }
        },
        processCurrencyInput(event, original) {
            let value = event.target.value
            let parsed = this.tryParseCurrency(value)

            // In case a value is loaded that has a length larger than what is allowed
            // This allows for pressing backspace
            if (value.split(".")[0].length < original.split(".")[0].length) {
                return true
            }

            // An invalid input has been entered... revert to previous value
            if (!parsed.success) {
                event.target.value = original
                return false
            }


            // Single digit values will be faked as 2 digits ('3', '03')
            var maxTotalSpendLength = Math.max(2, MaxTotalSpend.toString().length)
            var maxAmountLength = maxTotalSpendLength + (maxTotalSpendLength > 2 ? 1 : 2)

            // The currency amount should be less than the spending cap
            if (parsed.fullDisplay.length > maxAmountLength) {
                event.target.value = original
                return false
            }

            // This will format the text if needed
            if (value != parsed.inputDisplay) {
                event.target.value = parsed.inputDisplay
            }

            return true
        },
        onAmountInput(event){
            if (!this.processCurrencyInput(event, this.amount))
                return

            this.amount = event.target.value
        },
        validateCurrencyKeyPress(event) {
            if (this.currencyKeyCodeWhitelist.indexOf(event.code) == -1) {
                event.preventDefault();
                return true
            }
            
            return false
        },
        setRecordAmount(value){
            if(!isNaN(parseInt(value))){
                this.recordAmount = (parseInt(value)/100).toString();
                return;
            }
            this.recordAmount = "0.0";
        },
        getRecordAmountInt(){
            return this.recordAmount.substring(0, this.recordAmount.indexOf("."))
        },
        getRecordAmountDec(){
            return this.recordAmount.substring(this.recordAmount.indexOf(".")+1)
        },
        onRecordAmountInput(event){
            if (!this.processCurrencyInput(event, this.recordAmount))
                return

            this.recordAmount = event.target.value
        },
        changeRecordAmountDec(target, value){
            //console.log("start "+this.recordAmount);
            if((isNaN(parseInt(value))|| parseFloat(value) - Math.floor(parseFloat(value)) > 0) && value != ''){
                target.value = this.recordAmount.substring(this.recordAmount.indexOf(".")+1);
                return;
            }
            this.recordAmount = this.recordAmount.substring(0, this.recordAmount.indexOf(".")+1) + value;
            //console.log("end "+this.recordAmount);
        }
    },
    async mounted(){
        this.SetCustomerId(this.$route.params.id);
        let success1 = await this.GetCustomerSpendConfig();
        let success2 = await this.GetCustomerSpend();

        this.loadSuccess = success1 && success2
    }
}
</script>
<style scoped>
    #cus-spend-table td, #cus-spend-table-edit td{
        padding-bottom: 0.8rem;
    }

    #cus-spend-table tr:last-child td, #cus-spend-table-edit tr:last-child td{
        padding-bottom: 0;
    }

    input{
        width: 18rem;
        height: 1.8rem;
        border: none;
        border-radius: 0.2rem;
        background-color: rgb(241, 241, 241);
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        padding: 0 0.7rem;
        color: #67748e;
    }

    input[type="date"]{
        width: fit-content;
    }


    textarea{
        width: 18rem;
        height: 7rem;
        border: none;
        border-radius: 0.2rem;
        background-color: rgb(241, 241, 241);
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        padding: 0 0.7rem;
        color: #67748e;
    }

    .selector{
        width: 18rem;
        height: 1.8rem;
        border: none;
        border-radius: 0.2rem;
        background-color: rgb(241, 241, 241);
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        padding: 0 0.7rem;
        color: #67748e;
    }

    .selector-options{
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        width: 18rem;
        max-width: 18rem;
        z-index: 20;
        display: flex;
        flex-direction: column;
        position: absolute;
        margin-top: 2rem;
        max-height: 15rem;
        overflow-y: scroll;
    }

    .selector-options div{
        background-color: rgb(241, 241, 241);
        padding: 0.2rem 0.7rem;
    }


    .selector-options div:first-child{
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
    }

    .selector-options div:last-child{
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
    }

    .selector-options div:hover{
        cursor: pointer;
        background-color: rgb(233, 233, 233);
    }

    #cus-spend-results{
        background-color: rgb(236, 236, 236);
        overflow-x: auto;
    }

    #cus-spend-results tbody tr{
        border-bottom:#67748e 1px solid;
    }

    #cus-spend-results tbody tr:last-of-type{
        border-bottom:none;
    }

    #cus-spend-results tbody tr td{
        padding: 0.4rem 1rem;
        border-right:#67748e 1px solid;
        max-width: 14rem;
        min-width: 14rem;
    }

    #cus-spend-results tbody tr td:first-child{
        background-color: rgb(228, 228, 228);
    }

    #cus-spend-results tbody tr:first-of-type td:first-child{
        border-top-left-radius: 1rem;
    }

    #cus-spend-results tbody tr:last-of-type td:first-child{
        border-bottom-left-radius: 1rem;
    }

    #cus-spend-results svg{
        height: 1rem;
    }

    .btn-span{
        display:flex; 
        justify-content: center; 
        align-items:center; 
        text-decoration: underline;
        cursor: pointer;
    }

    .btn-span:hover{
        color: #2e333f;
    }

    .btn-span:hover svg{
        fill: #2e333f;
    }

    .btn-div{
        display:flex; 
        justify-content: left; 
        align-items: center;
        height: 100%; 
        width:100%;
    }

    .btn-div svg:hover{
        fill: #2e333f;
        cursor: pointer;
    }

    @media(max-width:550px){
        input, .selector, textarea, .selector-options {
            width: 9rem;
        }

        #cus-spend-table tr{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    .table td div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
    }

    .table th div {
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }
</style>
