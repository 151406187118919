<template>
  <div class="overflow-hidden card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <div
          class="text-center shadow icon icon-shape border-radius-md bg-gradient-success"
        >
          <i
            class="text-lg ni opacity-10"
            :class="`ni-${icon}`"
            aria-hidden="true"
          ></i>
        </div>
        <div :class="getIsRTL ? 'me-3' : 'ms-3'">
          <p class="mb-0 text-sm text-capitalize font-weight-bold">
            {{ title }}
          </p>
          <h5 class="mb-0 font-weight-bolder">{{ this.monthlyCompletedDataDateRange }}</h5>
        </div>
        <!-- <div
          class="progress-wrapper w-25"
          :class="getIsRTL ? 'me-auto' : 'ms-auto'"
        >
          <div class="progress-info">
            <div class="progress-j">
              <span class="text-xs font-weight-bold">{{ progress }}%</span>
            </div>
          </div>
          <div class="progress">
            <div
              class="progress-bar bg-success"
              :class="`w-${progress}`"
              role="progressbar"
              :aria-valuenow="progress"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="p-0 mt-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { mapState } from "pinia";
import { useMainStore } from "@/store/mainStore";
import { useCommonStore } from "@/store/commonStore";
export default {
  name: "ProgressLineChart",
  props: {
    id: {
      type: String,
      default: "progress-line-chart",
    },
    height: {
      type: String,
      default: "100",
    },
    icon: {
      type: String,
      default: "calendar-grid-58",
    },
    title: {
      type: String,
      default: "Tasks",
    },
    count: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      data: Array,
    },
  },
  computed: {
    ...mapState(useMainStore,["getIsRTL"]),
    ...mapState(useCommonStore,["getMonthlyCompletedData","monthlyCompletedDataDateRange"])
  },
  mounted() {
    let chartStatus = Chart.getChart(this.id);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    
  },
};
</script>
