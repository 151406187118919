import axios from 'axios';
import { TryGetCachedToken } from "../router/TokenManger";
import { EncodeBase64 } from "../utils/EncodingUtil"
const BaseUrl = process.env.VUE_APP_CONTACT_BASE_URL;
const envVars = process.env;
export default {
    async GetContact(contactId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CONTACT
                        .replace("{contactId}", contactId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetAllAdvisors() {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'GET',
                url: BaseUrl
                    + envVars.VUE_APP_ALL_ADVISORS,
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetRelatedActivities(contactId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_RELATED_ACTIVITIES
                        .replace("{contactId}", contactId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async SearchForContact(searchType, searchValue) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }

            // Encode the search value to be URL-safe
            searchValue = EncodeBase64(searchValue)

            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    //url:"https://localhost:5005"
                    + envVars.VUE_APP_CONTACT_SEARCH
                        .replace("{searchType}", searchType)
                        .replace("{searchValue}", searchValue),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetAssessmentGraphData(contactId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_ASSESSMENT_GRAPH_DATA
                        .replace("{contactId}", contactId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetAssessmentResults(contactId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    //url: "https://localhost:5005"
                    + envVars.VUE_APP_ASSESSMENT_RESULTS
                        .replace("{contactId}", contactId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetAssessmentHistoryData(contactId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    //url: "https://localhost:5005"
                    + envVars.VUE_APP_ASSESSMENT_HISTORY_DATA
                        .replace("{contactId}", contactId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetCoachingGuideRedirectUrl(assessmentId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    //url: "https://localhost:5005"
                    + envVars.VUE_APP_COACHING_GUIDE_REDIRECT
                        .replace("{assessmentId}", assessmentId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetNextAssessmentDue(contactId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_NEXT_ASSESSMENT_DUE
                        .replace("{contactId}", contactId),
                responseType: 'text/plain',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetConfigInformation() {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CONFIG_INFORMATION,
                responseType: 'text/plain',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async PutConfigInformation(payload) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'PUT',
                url: BaseUrl
                    + envVars.VUE_APP_POST_CONFIG_INFORMATION,
                data: payload,
                responseType: 'text/plain',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    async GetCustomerStrandOptions(contactId) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_CUSTOMER_STRAND_OPTIONS
                    .replace('{contactId}',contactId),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    async GetReportingCustomers(advisorEmail) {
        try {
            if (envVars.VUE_APP_AUTH_ON == "true") {
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl
                    + envVars.VUE_APP_GET_REPORTING_CUSTOMERS
                    .replace("{advisorEmail}", advisorEmail),
                responseType: 'json',
                Headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },

    async UpdateCustomerAsync(customerId, customerUpdateDto, sentBy) {
        try {
            var resp = await axios({
                method: 'Put',
                headers: {
                    'content-type': 'application/json',
                },
                data:customerUpdateDto,
                url: BaseUrl
                    + envVars.VUE_APP_GET_CONTACT
                        .replace("{contactId}", customerId)
                    + `?modifiedBy=${sentBy}`
            });
            return resp;
        }
        catch (error) {
            return error;
        }
    },
    
}