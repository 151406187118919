<template>
  <div
    class="column-12 pd-lr-l600"
    style="height: fit-content; padding: 0.5rem 1.5rem 0 1.5rem"
  >
    <navBarVue class="column-12" style="background-color: #6f9600" />
    <main
      style="height: fit-content"
      class="main-content position-relative column-12"
    >
      <div class="column-12 pd-lr-21-9">
        <div style="margin: 0.75rem 0">
          <UpcomingAuditsTableVue />
        </div>
        <div style="margin: 0.75rem 0">
          <SignedAuditsTableVue />
        </div>
        <div style="margin: 0.75rem 0">
          <GeneratedAuditsTableVue />
        </div>
      </div>
    </main>
    <FooterVue />
  </div>
</template>
<script>
import navBarVue from "@/components/navBar.vue";
import FooterVue from "@/components/Footer.vue";

import UpcomingAuditsTableVue from "@/components/UpcomingAuditsTable.vue";
import SignedAuditsTableVue from "@/components/SignedAuditsTable.vue";
import GeneratedAuditsTableVue from "@/components/GeneratedAuditsTable.vue";

export default {
  name: "advisor-dash-search",
  components: {
    navBarVue,
    FooterVue,
    UpcomingAuditsTableVue,
    SignedAuditsTableVue,
    GeneratedAuditsTableVue,
  },
  methods: {},
  mounted() {},
};
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100vh;
}
</style>
<style scoped>
#footer {
  width: calc(100% - 3rem);
  position: absolute;
}

#main {
  height: calc(100% - 0.5rem);
  margin-top: 0.5rem;
}
</style>
