<template>
<div :id="id" class="overflow-y-scroll-create" style="max-height: 70vh; padding: 1rem;">
    <div hidden><button class="clear-values-btn" @click="clearValues()"></button></div>
    <div style="margin-top: 1rem;" class="crte-act-datetime">
        <div style="margin-bottom: 1rem;">
            <div><b>End Date*:</b></div>
            <input type="date" class="crte-act-input"
            :value="getDateInputString(getActivityEnd)"
            @input="actualEnd = $event.target.value"
            :min="getDateInputString(new Date(new Date().toUTCString()))" disabled/>
            <div style="margin-top: 0.3rem;" v-show="errors.end != ''" 
            class="text-danger">{{errors.end}}</div>
        </div>
    </div>
    <div class="crte-act-section">
        <div><b>Action*:</b></div>
        <input style="width: 22rem;" class="crte-act-input" 
        @input="newAction.subject = $event.target.value" :value="getActivitySubject"/>
        <div style="margin-top: 0.3rem;" v-show="errors.subject != ''" 
        class="text-danger">{{errors.subject}}</div>
    </div>
    <div class="crte-act-section" hidden>
        <div><b>Url:</b></div>
        <input style="width: 22rem;" class="crte-act-input act-url-input" 
        v-model="newAction.actionUrl"/>
        <div class="text-danger" v-show="errors.url != ''">{{errors.url}}</div>
    </div>
    <div class="crte-act-section">
        <div><b>What will I get from doing this?*:</b></div>
        <textarea style="width: 22rem; height: 7.5rem;" class="crte-act-input" 
        v-model="newAction.motivation"/>
        <div class="text-danger" v-show="errors.motivation != ''">{{errors.motivation}}</div>
    </div>
    <div class="crte-act-section">
        <div><b>Description*:</b></div>
        <textarea style="width: 22rem; height: 7.5rem;" class="crte-act-input" 
        @input="newAction.description = $event.target.value" :value="getActivityDescription"/>
        <div class="text-danger" v-show="errors.description != ''">{{errors.description}}</div>
    </div>
    <button style="margin: 0; margin-right: 0.75rem; background-color: #ededed;" 
        class="btn" @click="clearValues(), toggleModal()">Cancel</button>
    <button style="margin: 0; margin-right: 0.75rem; background-color: #ededed;" 
    class="btn crte-act-input" @click="createAction()">Save</button>
</div>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import { useAdvisorDashboardStore } from '@/store/advisorDashboard';
import { useCommonStore } from '@/store/commonStore';

export default {
    name:"convert-activity",
    data(){
        return{
            newAction:{
                actionUrl: "",
                subject: "",
                description: "",
                motivation: "",
                isMandated: false,
                priorityCode: 0,
                progress: 0,
                estimatedEffort: 1,
                statusCode: "",
                stateCode: "Complete",
                scheduledStart: null,
                scheduledEnd: null,
            },
            actualEnd:null,
            errors:{
                start: "",
                end: "",
                subject: "",
                url: "",
                motivation: "",
                description: "",
                effort: ""
            }
        }
    },
    props:{
        id:{
            type: String,
            default: "convert-activity"
        }
    },
    computed:{
        ...mapState(useCommonStore,["getRecommendedActionTemplates", "getActivityToConvert"]),
        ...mapState(useAdvisorDashboardStore,["getTargetedActivities", "getCurrentActionToAddTask"]),
        getActivityEnd(){
            if(this.getActivityToConvert != null){
                if(this.getActivityToConvert.timeline.actualEnd != null){
                    return new Date(this.getActivityToConvert.timeline.actualEnd);
                }
            }
            return new Date(new Date().toUTCString());
        },
        getActivitySubject(){
            if(this.getActivityToConvert != null){
                return this.getActivityToConvert.classification.activityTypeCode;
            }
            return '';
        },
        getActivityDescription(){
            if(this.getActivityToConvert != null){
                return this.getActivityToConvert.description;
            }
            return '';
        }
    },
    mounted(){
        //  document.getElementById("modal-close").addEventListener("click", ()=>{
        //    this.clearValues();
        // })
    },
    methods:{
        ...mapActions(useCommonStore,["CreateNewAction", "CreateNewTask", "getUTCDateString", "UpdateActionCompletedDate"]),
        ...mapActions(useAdvisorDashboardStore, ["toggleModal", "setCurrentActionToAddTask"]),
        clearValues(){
            this.newAction={
                actionUrl: "",
                subject: "",
                description: "",
                motivation: "",
                isMandated: false,
                priorityCode: 0,
                progress: 0,
                estimatedEffort: 1,
                statusCode: "",
                stateCode: "Complete",
                scheduledStart: this.getUTCDateString(new Date(new Date())),
                scheduledEnd: null,
            },
            this.actualEnd = null;
            this.errors={
                start: "",
                end: "",
                subject: "",
                url: "",
                motivation: "",
                description: "",
                effort: ""
            }
        },
        getDateInputString(date){
            return [date.getFullYear(),
                    date.getMonth()+1 < 10 
                    ? '0' + (date.getMonth()+1).toString() 
                    : date.getMonth()+1,
                    date.getDate() < 10 
                    ? '0' + (date.getDate()).toString() 
                    :date.getDate()].join('-');
        },
        getDateFormatString(date){
            return [[date.getFullYear(),
                    date.getMonth()+1 < 10 
                    ? '0' + (date.getMonth()+1).toString() 
                    : date.getMonth()+1,
                    date.getDate() < 10 
                    ? '0' + (date.getDate()).toString() 
                    :date.getDate()].join('-'),
                    [date.getHours()+1 < 10 
                    ? '0' + (date.getHours()+1).toString() 
                    : date.getHours()+1,
                    date.getMinutes()+1 < 10 
                    ? '0' + (date.getMinutes()+1).toString() 
                    : date.getMinutes()+1].join(':')].join('T');
        },
        async createAction(){
            var isValid = true;
            this.errors = {
                start: "",
                end: "",
                subject: "",
                url: "",
                motivation: "",
                description: "",
                effort: ""
            };
            // if(this.newAction.scheduledStart == null){
            //     this.errors.start = "*Required";
            //     isValid = false;
            // }
            if(this.newAction.subject == ""){
                this.newAction.subject = this.getActivitySubject;
            }
            if(this.newAction.description == ""){
                this.newAction.description = this.getActivityDescription;
            }
            if(this.actualEnd == null){
                this.actualEnd = this.getActivityEnd;
            }
            if(this.actualEnd == null){
                this.errors.end = "*Required";
                isValid = false;
            }
            // else if(this.newAction.scheduledStart != null){
            //     if(new Date(this.newAction.scheduledEnd) < new Date(this.newAction.scheduledStart)){
            //         this.errors.end = "End date should be later than start date";
            //         isValid = false;
            //     }
            // }
            if(this.newAction.subject == "" || this.newAction.subject == null){
                this.errors.subject = "*Required";
                isValid = false;
            }
            if(this.newAction.motivation == "" || this.newAction.motivation == null){
                this.errors.motivation = "*Required";
                isValid = false;
            }
            if(this.newAction.description == "" || this.newAction.description == null){
                this.errors.description = "*Required";
                isValid = false;
            }
            if(isValid){
                this.newAction.scheduledEnd = this.getUTCDateString(new Date(this.getActivityToConvert.timeline.scheduledEnd != null ? this.getActivityToConvert.timeline.scheduledEnd: this.actualEnd));
                this.newAction.scheduledStart = this.getUTCDateString(new Date(this.getActivityToConvert.timeline.scheduledStart != null ? this.getActivityToConvert.timeline.scheduledStart: this.actualEnd));
                var action = await this.CreateNewAction(this.newAction, false);
                this.UpdateActionCompletedDate(this.getUTCDateString(new Date(this.actualEnd)), action.id, true);
                this.newAction = {
                    actionUrl: "",
                    subject: "",
                    description: "",
                    motivation: "",
                    isMandated: false,
                    priorityCode: 0,
                    progress: 0,
                    estimatedEffort: 1,
                    statusCode: "",
                    stateCode: "Complete",
                    scheduledStart: this.getUTCDateString(new Date(new Date())),
                    scheduledEnd: null,};
                this.actualEnd = null;
                this.setCurrentActionToAddTask(null);
                this.toggleModal();
            }
        }
    }
}
</script>
<style>
    .overflow-y-scroll-create{
        overflow-y: scroll; 
    }

    .overflow-y-hidden-create{
        overflow-y: hidden; 
    }

    .crte-act-section{
        margin-bottom: 1rem;
    }

    .crte-act-section div{
        font-size: medium;
    }

    .crte-act-section input,.crte-act-section textarea, .crte-act-datetime input{
        border: none;
        border-radius: 0.2rem;
        background-color: rgb(243 243 243);
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        color: #67748e;
        font-size: medium;
        padding: 0.2rem 0.5rem;
    }

    .crte-act-datetime b{
        font-size: medium;
        margin-right: 0.4rem;
    }

    .crte-act-datetime input{
        font-size: medium;
    }

    .activity-type-selector, .status-selector{
        width: 18rem;
        height: 1.7rem;
        border: none;
        border-radius: 0.2rem;
        background-color: rgb(243 243 243);
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        padding: 0 0.7rem;
        color: #67748e;
    }

    .activity-type-search input{
        width: 18rem;
        height: 1.7rem;
        border: none;
        border-radius: 0.2rem;
        background-color: rgb(243 243 243);
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        padding: 0 0.7rem;
        color: #67748e;
    }

    .tgl-activity-type-dropdown{
        width: 0.78rem;
        margin-left: -1.3rem;
    }

    .tgl-activity-type-dropdown:hover{
        cursor: pointer;
    }

    .activity-type-options, .status-options{
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        width: 18rem;
        max-width: 18rem;
        z-index: 20;
        display: flex;
        flex-direction: column;
        position: absolute;
        margin-top: 2rem;
        max-height: 15rem;
        overflow-y: scroll;
    }

    .activity-type-options div, .status-options div{
        background-color: rgb(243 243 243);
        padding: 0.2rem 0.7rem;
    }


    .activity-type-options div:first-child, .status-options div:first-child{
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
    }

    .activity-type-options div:last-child, status-options div:last-child{
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
    }

    .activity-type-options div:hover, .status-options div:hover{
        cursor: pointer;
        background-color: rgb(235, 235, 235);
    }
</style>