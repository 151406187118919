<template>
    <div id="main-survey">
        <div id="insert-creator" hidden></div>
        <!-- <EmployabilitySurveyCreator v-if="surveyType == 'Employability'" hidden/>
        <SkillsSurveyCreator v-if="surveyType == 'Skills'" hidden/>
        <DigitalSkillsSurveyCreator v-if="surveyType == 'DigitalSkills'" hidden/>
        <LifeSkillsSurveyCreator v-if="surveyType == 'LifeSkills'" hidden/> -->
        <div id="insert-survey"></div>
        <CompletedAssessment hidden/>
    </div>
</template>
<script>

import 'survey-core/modern.min.css';
import SurveyCreator from "./SurveyCreator.vue";
// import EmployabilitySurveyCreator from './EmployabilitySurveyCreator.vue';
// import SkillsSurveyCreator from './SkillsSurveyCreator.vue';
// import DigitalSkillsSurveyCreator from './DigitalSkillsSurveyCreator.vue';
// import LifeSkillsSurveyCreator from './LifeSkillsSurveyCreator.vue';
import { mapActions } from 'pinia';
import { useAdvisorDashboardStore } from '../store/advisorDashboard';
import { useCommonStore } from '@/store/commonStore';
import CompletedAssessment from '@/components/CompletedAssessment.vue';
import { createVNode, render } from "vue";
import { waitForElementsByClassName } from "../utils/DomUtil"

let completeBtn;
let result;
export default {
    name: "survey-vue",
    components: {
        CompletedAssessment
        // EmployabilitySurveyCreator,
        // SkillsSurveyCreator,
        // DigitalSkillsSurveyCreator,
        // LifeSkillsSurveyCreator
    },
    props:{
        // surveyType:{
        //     type: String,
        //     required: true
        // }
    },
    methods:{
        ...mapActions(useAdvisorDashboardStore,["setSurvey", "setModalTitle"]),
        ...mapActions(useCommonStore,["SendThemedAssessmentAnswers", "fireErrorAlert"]),
        tryGetResults(){
            var header = document.getElementsByClassName("svd-test-results__header-types")[0];
            var count = 0;
            while(header == undefined && count < 20){
                header = document.getElementsByClassName("svd-test-results__header-types")[0];
                count++;
            }
            for(var i = 0; i < header.children.length; i++){
                if(header.children[i].innerText == "As JSON"){
                    header.children[i].addEventListener("click",()=>{
                        var count2 = 0;
                        var resultsDiv = document.getElementsByClassName("svd-test-results__text")[0];
                        while(resultsDiv == undefined && count2 < 20){
                            resultsDiv = document.getElementsByClassName("svd-test-results__text")[0];
                            count2++;
                        }
                        if(resultsDiv != undefined){
                            result = resultsDiv.children[0].innerHTML;
                        }
                        document.getElementsByClassName("svd-simulator-main")[0].hidden = true;
                        document.getElementById("completed-assessment").hidden = false;
                        var currentUrl = new URL(window.location);
                        var relatedActionId = currentUrl.searchParams.get("relatedActionId");
                        this.SendThemedAssessmentAnswers(result, relatedActionId);
                       setTimeout(()=>{}, 100); 
                    });
                    header.children[i].click();
                    document.getElementsByClassName("svd-simulator-main")[0].hidden = true;
                    document.getElementsByClassName("svc-preview__test-again")[0].hidden = true;
                    document.getElementsByTagName("survey-results")[0].hidden = true;
                    document.getElementById("completed-assessment").hidden = false;
                }
            }
        },
    },
    async mounted(){
        var insertCreator = document.getElementById("insert-creator");
        render(createVNode(SurveyCreator), insertCreator);

        // Temporary solution to fix blank blue screen when loading
        await new Promise(res => setTimeout(res, 2000));

        let container = document.getElementById("insert-survey");
        let existing = container.getElementsByClassName("svc-plugin-tab__content")
        
        // Remove any existing surveys
        for (let node of existing) {
            container.removeChild(node)
        }

        var currentUrl = new URL(window.location);
        var relatedActionId = currentUrl.searchParams.get("relatedActionId");
        if(relatedActionId == null){
            this.fireErrorAlert("No related action Id found.", "surveyView");
        }

        console.debug("Getting survey content (phase 1)")
        await waitForElementsByClassName("svc-tabbed-menu-item__text", 1, document, 10, 500)
            .then(async (content) => {
                for(var i = 0; i < content.length; i++){
                    if(content[i].innerHTML == "Preview"){
                        content[i].parentNode.click();
                    }
                }

                console.debug("Getting survey content (phase 2)")
                await waitForElementsByClassName("svc-plugin-tab__content", 1, document, 10, 200)
                    .then((elements) => {
                        let survey = elements[0]

                        container.appendChild(survey);                 
                        container.addEventListener("mouseover", ()=> {
                        if (completeBtn == undefined){
                            var checkForButton = document.getElementsByClassName("sd-navigation__complete-btn")[0];
                            if(checkForButton != undefined){
                                completeBtn = checkForButton;
                                completeBtn.addEventListener("click", this.tryGetResults);
                            }
                        }
                    });               
                })
            })
    },
    beforeUnmount(){
        completeBtn = undefined;
    }
}
</script>

<style>

</style>
