// stores/employabilityActions.js
import { defineStore } from "pinia";

export const useEmployabilityActionsStore = defineStore(
  "employabilityActions",
  {
    state: () => ({
      pathwayA: {
        targetSubjects: [
          "Attend 'How to Navigate Fedcap Hub' information session",
          "Attend 'Job Networking Session' ",
          "Better off calculation",
          "Confirm clear, realistic job goals",
          "Create / update a stand out CV",
          "Right to Work Verification",
        ],
        actions: [
          {
            actionUrl: "",
            category: "Activity",
            subject: "Mini Bite - SMART goals for jobsearch",
            description:
              "Devise your jobsearch plan making it much more likely to adhere to",
            motivation:
              "Devise your jobsearch plan making it much more likely to adhere to",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
          {
            actionUrl: "",
            category: "Activity",
            subject: "Attend a Mock Interview",
            description:
              "We'll conduct a mock interview, provide feedback on your responses, and identify areas for further practice.",
            motivation:
              "We'll conduct a mock interview, provide feedback on your responses, and identify areas for further practice.",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
        ],
      },
      pathwayB: {
        targetSubjects: [
          "Attend 'How to Navigate Fedcap Hub' information session",
          "Attend 'Job Networking Session' ",
          "Better off calculation",
          "Confirm clear, realistic job goals",
          "Create / update a stand out CV",
          "Right to Work Verification",
          "Mini Bite - Recognising unique value",
          "Mini Bite - Money Matters Insights",
          "Mini Bite - Dream Job DNA",
        ],
        actions: [
          {
            actionUrl: "",
            category: "Activity",
            subject: "Mini Bite - SMART goals for jobsearch",
            description:
              "Devise your jobsearch plan making it much more likely to adhere to",
            motivation:
              "Devise your jobsearch plan making it much more likely to adhere to",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
          {
            actionUrl: "",
            category: "Activity",
            subject: "Attend a Mock Interview",
            description:
              "We'll conduct a mock interview, provide feedback on your responses, and identify areas for further practice.",
            motivation:
              "We'll conduct a mock interview, provide feedback on your responses, and identify areas for further practice.",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
          {
            actionUrl: "",
            category: "Activity",
            subject: "Attend 'Bouncing Back from Challenge' group session",
            description:
              "Explore challenges in job seeking and resilience-building strategies to overcome them, gauging your resilience along the way.",
            motivation:
              "Explore challenges in job seeking and resilience-building strategies to overcome them, gauging your resilience along the way.",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
          {
            actionUrl: "",
            category: "Activity",
            subject: "Attend 'Gearing up for Work' group session",
            description:
              "Prepare for your new job by organising essentials, anticipating routine changes, understanding employer expectations, and accessing post-employment support.",
            motivation:
              "Prepare for your new job by organising essentials, anticipating routine changes, understanding employer expectations, and accessing post-employment support.",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
        ],
      },
      pathwayC: {
        targetSubjects: [
          "Attend 'How to Navigate Fedcap Hub' information session",
          "Attend 'Job Networking Session' ",
          "Better off calculation",
          "Career Canvas",
          "Confirm clear, realistic job goals",
          "Create / update a stand out CV",
          "Mini Bite - Job Exploration",
          "Mini Bite - Money Matters Insights",
          "Mini Bite - Pros and cons of work",
          "Right to Work Verification",
          "Mini Bite - The worst that can happen?"
        ],
        actions: [
          {
            actionUrl: "",
            category: "Activity",
            subject: "Mini Bite - SMART goals for jobsearch",
            description:
              "Devise your jobsearch plan making it much more likely to adhere to",
            motivation:
              "Devise your jobsearch plan making it much more likely to adhere to",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
          {
            actionUrl: "",
            category: "Activity",
            subject: "Attend a Mock Interview",
            description:
              "We'll conduct a mock interview, provide feedback on your responses, and identify areas for further practice.",
            motivation:
              "We'll conduct a mock interview, provide feedback on your responses, and identify areas for further practice.",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
          {
            actionUrl: "",
            category: "Activity",
            subject: "Attend 'Changing Mindset' group session",
            description:
              "Explore overcoming fear of failure, understanding mindsets, transitioning from fixed to growth mindset, and navigating the five stages of change.",
            motivation:
              "Explore overcoming fear of failure, understanding mindsets, transitioning from fixed to growth mindset, and navigating the five stages of change.",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
          {
            actionUrl: "",
            category: "Activity",
            subject: "Mini Bite - SMART goals for personal growth",
            description:
              "Learn the benefits of working with SMART goals and how these transfer to jobsearch",
            motivation:
              "Learn the benefits of working with SMART goals and how these transfer to jobsearch",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
          {
            actionUrl: "",
            category: "Activity",
            subject: "Attend 'Gearing up for Work' group session",
            description:
              "Prepare for your new job by organising essentials, anticipating routine changes, understanding employer expectations, and accessing post-employment support.",
            motivation:
              "Prepare for your new job by organising essentials, anticipating routine changes, understanding employer expectations, and accessing post-employment support.",
            isMandated: false,
            priorityCode: 0,
            progress: 0,
            estimatedEffort: 1,
            statusCode: "",
            stateCode: "Open",
            scheduledStart: "",
            scheduledEnd: "",
            recommendedActionCatalog: "",
            recommendedAction: "",
          },
        ],
      },
    }),

    getters: {
      getPathwayA: (state) => state.pathwayA,
      getPathwayB: (state) => state.pathwayB,
      getPathwayC: (state) => state.pathwayC,
    },
  }
);
