import axios from "axios";
import { TryGetCachedToken } from "../router/TokenManger";
const BaseUrl = process.env.VUE_APP_CATALOG_BASE_URL;
const MainApiBaseUrl = process.env.VUE_APP_ACTIONS_BASE_URL;
const envVars = process.env;
export default{
    async GetRecommendedActions(customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:BaseUrl
                + envVars.VUE_APP_GET_RECOMMENDED_ACTIONS
                .replace("{customerId}", customerId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
              });
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async GetCurrentThemedAssessment(customerId, relatedActionId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:MainApiBaseUrl
                + envVars.VUE_APP_CURRENT_ASSESSMENT
                .replace("{customerId}", customerId)
                .replace("{relatedActionPlanItemId}", relatedActionId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async GetThemedAssessmentByVersion(customerId, version){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:MainApiBaseUrl
                + envVars.VUE_APP_ASSESSMENT_BY_VERSION
                .replace("{customerId}", customerId)
                .replace("{versionNumber}", version),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async PostThemedAssessment(customerId, assessmentId, version, results, relatedItemId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            var resp = await axios({ 
                method: 'POST',
                headers: { 
                    'content-type':'application/json',
                },
                data: results,
                //url: "https://localhost:5005"
                url:MainApiBaseUrl
                + envVars.VUE_APP_POST_ASSESSMENT_ANSWERS
                .replace("{customerId}", customerId)
                .replace("{assessmentId}", assessmentId)
                .replace("{versionNumber}", version)
                +`?relatedActionPlanId=${relatedItemId}`});
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async GetThemedAssessmentResultsForCustomer(customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:MainApiBaseUrl
                + envVars.VUE_APP_GET_RESULTS_FOR_CUSTOMER
                .replace("{customerId}", customerId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch(error){
            return error;
        }
    },

    async GetUnfilteredAssessmentResultsForCustomer(customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:MainApiBaseUrl
                + envVars.VUE_APP_GET_UNFILTERED_RESULTS_FOR_CUSTOMER
                .replace("{customerId}", customerId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch(error){
            return error;
        }
    },

    async GetAssessmentSubjectById(actionId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            console.debug("Getting subject name")
            const resp = await axios({
                method: 'get',
                url:MainApiBaseUrl
                + envVars.VUE_APP_GET_ASSESSMENT_SUBJECT_BY_ID
                .replace("{actionId}", actionId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
            });
            console.debug(resp)
            return resp;
        }
        catch(error){
            return error;
        }
    },

    async GetActionAssessmentResultsFromId(customerId, relatedActionPlanItemId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:MainApiBaseUrl
                + envVars.VUE_APP_GET_RESULTS_FOR_CUSTOMER_SPECIFIC_ASSESSMENT
                .replace("{customerId}", customerId)
                .replace("{relatedActionPlanId}", relatedActionPlanItemId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
            });
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async ResolveURL(url, customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            var resp = await axios({ 
                method: 'POST',
                headers: { 
                    'content-type':'application/json',
                },
                url:
                //BaseUrl+
                url.replace("dynamic://", "https://")
                .replace("localhost", "")
                + `?customerId=${customerId}`});
            return resp;
        }
        catch(error){
            return error;
        }
    }
}