<template>
  <body class="preview-form">
    <section class="header image-header p-3">
      <div style="max-width: auto">
        <!-- Experimented with image widths for equal sizing; it looks about right -->
        <img style="width: 47%; height: auto" src="../assets/img/restartScheme.png" alt="" />
        <img style="width: 53%; height: auto" src="../assets/img/FedcapLogo.png" alt="" />
      </div>
    </section>
    <section class="details header">
      <div class="customer-information py-4 px-5">
        <div>
          <div>{{ salutations }}{{ getCustomer.fullName }}</div>
          <div v-if="getSavedManData.addressLine1 != null">
            {{ getSavedManData.addressLine1 }},&nbsp;
          </div>
          <div v-if="getSavedManData.addressLine2 != null">
            {{ getSavedManData.addressLine2 }},&nbsp;
          </div>
          <div v-if="getSavedManData.addressLine3 != null">
            {{ getSavedManData.addressLine3 }},&nbsp;
          </div>
          <div v-if="getSavedManData.postalCode != null">
            {{ getSavedManData.postalCode }}
          </div>
        </div>
      </div>
      <div class="fedcap-information py-4 px-5">
        <div>
          <div>Fedcap Employment</div>
          <div>Cleveland Business Centre</div>
          <div>Oak Street</div>
          <div>Middlesbrough</div>
          <div>TS1 2RQ</div>
          <div>0800 917 9262</div>
          <div>{{ formatDate(new Date(currentDate)) }}</div>
        </div>
      </div>
    </section>
    <section class="body px-5">
      <div>
        <p>Dear {{ salutations }} {{ getCustomer.fullName }}</p>
      </div>
      <div>
        <h4>
          You must take part in a mandatory Restart Scheme appointment /
          Activity
        </h4>
      </div>
      <div>
        <p>
          I am writing to tell you about an appointment / activity which you
          must take part in. <br />
          This appointment / activity will help you prepare for, or move into,
          work. <br />
          If you want to find out more about this appointment / activity, please
          contact me using the details at the top of the letter.
        </p>
        <ul>
          What you'll have to do is shown below:
          <li class="pt-2">
            Your appointment / activity is to complete
            <strong>
              {{ getActionToMandate != null ? getActionToMandate.subject : "" }}
            </strong>
          </li>
          <li>
            On:
            <strong>
              {{ longDataFormatter(getDueDate)}}
            </strong>
          </li>
          <li>
            Your contact at Fedcap is:
            <strong> {{ getSavedManData.providerName }}. </strong>
            They can be contacted on
            <strong>{{ getSavedManData.providerPhone }}.</strong>
          </li>
        </ul>
        <!-- <p>
          If the appointment is by telephone, we will contact you on
          <strong>{{ getCustomer.mobile }}</strong
          >. You must be available to take the call. <br />
          (If the telephone number shown is not your correct telephone number,
          contact me on the number at the top of this letter to let me know. You
          will also need to update your Universal Credit journal or let your
          work coach know your correct telephone number.)
        </p> -->
        <p>
          You will be required to show evidence that you have completed the
          mandatory activity by providing:
          <strong>{{ getSavedManData.requiredEvidence }}</strong>
        </p>
        <p>
          Please contact me at the number on this letter to tell me if you need
          an approved child carer, an interpreter, help with travel costs, or
          any other service to be able to attend. We may be able to arrange this
          and cover your costs.
        </p>
      </div>
      <div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <h4>
          If you do not take part in your Restart Scheme appointment / activity
        </h4>
      </div>
      <div>
        <p>
          If you are unable to take part in your appointment / activity
          <strong>you must contact me straight away and tell me why</strong>. My
          contact details are at the top of this letter.
        </p>
      </div>
      <div>
        <p>
          If DWP decides that you have failed to attend or fully taken part in
          this activity without good reason, a low level sanction will be
          applied to your Universal Credit payment.
        </p>
      </div>
      <div>
        <ul>
          The Sanction will continue until:
          <li>
            The day before you
            <strong>{{ getSavedManData.complianceCondition }} {{ getSavedManData.complianceDetails[0].value }}</strong>
          </li>
          <div>OR</div>
          <li>
            The day before you are told that you no longer need to take part in
            the appointment / activity
          </li>
        </ul>
      </div>
      <div>
        <p>
          plus, an additional period of time (7, 14 or 28 days) depending on if
          you have had other sanctions in the past year.
        </p>
        <p>
          For 16/17 year olds, the second part of the sanction is a fixed period
          of 7 days if you have had other sanctions in the past year.
        </p>
        <p>
          <strong
            >For more information about sanctions please read your
            commitments.</strong
          >
        </p>
      </div>
      <div>
        <p>
          This tells you about sanction levels and explains how much of your
          Universal Credit payment you could lose. You can also contact your
          work coach using your online journal.
        </p>
        <p>
          If your Universal Credit is stopped or reduced, you may be entitled to
          recoverable hardship payments.
        </p>
        <br />
        <p>
          Yours Sincerely, <br /><strong style="text-decoration: underline">{{
            sender
          }}</strong>
        </p>
      </div>
      <br />
    </section>
  </body>
</template>

<script>
import { useCommonStore } from "@/store/commonStore";
import { mapState, mapActions } from "pinia";
import { loggedIn } from "@/router/Guard";
export default {
  name: "UC-Preview",

  data() {
    return {
      currentDate: new Date(),
      advisorNumber: "0800 917 9262",
      sender: loggedIn.name,
    };
  },
  computed: {
    ...mapState(useCommonStore, [
      "getCustomer",
      "getAllAdvisors",
      "setUcMandationFormVisability",
      "getActionToMandate",
      "getDueDate",
      "getPreviewData",
      "getSavedManData",
    ]),
  },

  methods: {
    ...mapActions(useCommonStore, ["GetAllAdvisors", "PostMandationForm"]),
    formatDate(date) {
      return [
        date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : date.getMonth() + 1,
        date.getFullYear(),
      ].join("/");
    },

    longDataFormatter(date){
      //gets todays date as written text

      var dateAsText;
      var objToday = new Date(date),
      weekday = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'),
      dayOfWeek = weekday[objToday.getDay()],
      domEnder = function() { var a = objToday; if (/1/.test(parseInt((a + "").charAt(0)))) return "th"; a = parseInt((a + "").charAt(1)); return 1 == a ? "st" : 2 == a ? "nd" : 3 == a ? "rd" : "th" }(),
      dayOfMonth = dateAsText + ( objToday.getDate() < 10) ? '0' + objToday.getDate() + domEnder : objToday.getDate() + domEnder,
      months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'),
      curMonth = months[objToday.getMonth()],
      curYear = objToday.getFullYear();
      return dayOfWeek + " " + dayOfMonth + " of " + curMonth + ", " + curYear;
       
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}

.header {
  display: flex;
  margin-right: 0;
  width: 100%;
  justify-content: space-between;
}
.image-header {
  display: flex; 
  justify-content: center; 
  align-content: center
}
.header img {
  height: 5rem;
}
.preview-form {
  width: 40%;
  height: fit-content;
  position: absolute;
  margin-left: 25%;
  margin-right: 25%;
  }
</style>
