<template>
  <div
    class="multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="man-title">{{ getCustomer.fullName }}, JSA603 Form</h5>
    <p class="man-sub-title">
      The following information is details of the mandatory activity the
      participant failed to undertake
    </p>
    <div class="multisteps-form__content">
      <form class="card-body">
        <div class="rad-input-text-inline">
          <label class="man-text"
            >Please select the advisor who will be completing this form</label
          >
          <select
            style="-webkit-appearance: auto"
            class="w-auto form-control"
            v-model="store.selectedAdvisor"
            required
          >
            <option
              :value="advisor.contactId"
              v-for="advisor in filteredAdvisors"
              :key="advisor.contactId"
            >
              {{ getContactDisplay(advisor) }}
            </option>
          </select>
        </div>

        <div class="rad-input-text-inline">
          <label class="man-text"
            >Did the activity the participant was required to undertake satisfy
            any restrictions notified to you by JCP?</label
          >

          <div class="radio-group">
            <label class="rad-label">
              <input
                class="rad-input"
                type="radio"
                value="yes"
                name="satisfy"
                v-model="store.satisfyRestrictionOnJSA"
              />
              <div class="rad-design"></div>
              <div class="rad-text" for="invalidCheck">Yes</div>
            </label>

            <label class="rad-label">
              <input
                class="rad-input"
                type="radio"
                value="no"
                name="satisfy"
                v-model="store.satisfyRestrictionOnJSA"
              />
              <div class="rad-design"></div>
              <div class="rad-text" for="invalidCheck">No</div>
            </label>
          </div>

          <p class="required-colour">
            {{ store.errors.satisfyRestrictionOnJSA }}
          </p>
        </div>

        <div class="rad-input-text-inline">
          <label class="man-text"
            >Is Participation on the Restart Scheme Mandatory?</label
          >
          <div class="radio-group">
            <label class="rad-label">
              <input
                class="rad-input"
                type="radio"
                value="yes"
                name="restart"
                v-model="store.restartSchemeMandatory"
              />
              <div class="rad-design"></div>
              <div class="rad-text" for="invalidCheck">Yes</div>
            </label>
            <label class="rad-label">
              <input
                class="rad-input"
                type="radio"
                value="no"
                name="restart"
                v-model="store.restartSchemeMandatory"
              />
              <div class="rad-design"></div>
              <div class="rad-text" for="invalidCheck">No</div>
            </label>
          </div>

          <p class="required-colour">
            {{ store.errors.restartSchemeMandatory }}
          </p>
        </div>
        <div class="rad-input-text-inline">
          <label class="man-text"
            >Was the required Activity reasonable taking into consideration the
            participants circumstances?</label
          >
          <div class="radio-group">
            <label class="rad-label">
              <input
                class="rad-input"
                type="radio"
                value="yes"
                name="reasonable"
                v-model="store.reasonableConsideration"
              />
              <div class="rad-design"></div>
              <div class="rad-text" for="invalidCheck">Yes</div>
            </label>
            <label class="rad-label">
              <input
                class="rad-input"
                type="radio"
                value="no"
                name="reasonable"
                v-model="store.reasonableConsideration"
              />
              <div class="rad-design"></div>
              <div class="rad-text" for="invalidCheck">No</div>
            </label>
          </div>
          <p class="required-colour">
            {{ store.errors.reasonableConsideration }}
          </p>
        </div>

        <div class="rad-input-text-inline">
          <label class="man-text"
            >Have you made other non-mandatory attempts to set this activity
            before mandating the Participant to undertake this activity?</label
          >
          <div class="rad-container">
            <div class="radio-group">
              <label class="rad-label">
                <input
                  class="rad-input"
                  type="radio"
                  value="yes"
                  name="attempts"
                  v-model="store.attemptsMade"
                />
                <div class="rad-design"></div>
                <div class="rad-text" for="invalidCheck">Yes</div>
              </label>
              <label class="rad-label">
                <input
                  class="rad-input"
                  type="radio"
                  value="no"
                  name="attempts"
                  v-model="store.attemptsMade"
                />
                <div class="rad-design"></div>
                <div class="rad-text" for="invalidCheck">No</div>
              </label>
            </div>
            <p class="required-colour">{{ store.errors.attemptsMade }}</p>
          </div>
        </div>
        <div
          v-if="store.attemptsMade == 'yes'"
          class="col-12 col-sm-10 mt-sm-2 text-start"
        >
          <label class="man-text"
            >If you have tried, but failed to see the participant face to face,
            please explain in detail what actions you have taken (for example a
            timeline of methods of attempted contact, name of advocate or
            representative contacted etc.)</label
          >
          <textarea
            class="form-control form-control-colour ps-3"
            rows="3"
            placeholder="Enter Details here ..."
            for="face2FaceContact"
            v-model="store.face2Face"
          ></textarea>
          <p class="required-colour">{{ store.errors.face2Face }}</p>
        </div>
      </form>
      <div class="mt-4 button-row d-flex" style="justify-content: flex-end">
        <button
          class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
          type="button"
          title="Close without saving"
          @click="resetData()"
        >
          Close without saving
        </button>
        <button
          class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
          :disabled="!store.meta.valid"
          type="button"
          title="Next"
          @click="this.$parent.nextStep"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { jsa603FormStore } from "../../../store/JSA603FormStore";
import { useCommonStore } from "@/store/commonStore";
import { jsa603FormStore2 } from "../../../store/JSA603FormStore2";
const store = jsa603FormStore();
const store2 = jsa603FormStore2();
const commonStore = useCommonStore();
const { getAllAdvisors, getCustomer, setJsa603Visibility } = useCommonStore();

function resetData() {
  store.resetData();
  store2.resetData();
  setJsa603Visibility(false);
}

function getLatest603() {
  const dwp603Records =
    commonStore.getActionToMandate.mandationRecord.dwp603Records;
  if (dwp603Records.length > 0) {
    return dwp603Records[dwp603Records.length - 1];
  }
}

function getDefaultAdvisorId() {
  const found = getAllAdvisors.filter(a => a.email == loggedIn.email)[0]

  console.log(found)
  if (found == null) {
    return getCustomer.advisor.id
  }

  return found.contactId
}

onMounted(() => {
  // It is possible that there isn't a saved 603 at this point
  const latest = getLatest603();

  // Load saved form data if not already loaded
  if (latest != null && !store.loadedSavedForm) {
    store.loadFromSavedRecord(latest); 
  } 
  
  // Default the selected advisor to the logged in user
  if (store.selectedAdvisor == null) {
    store.selectedAdvisor = getDefaultAdvisorId()
  }
});
</script>

<script>
import { mapState } from "pinia";
import { onMounted } from "vue";
import { loggedIn } from '../../../router/Guard';

export default {
  computed: {
    ...mapState(useCommonStore, [
      "getAllAdvisors",
      "getActionToMandate",
      "getSavedManData",
    ]),
    filteredAdvisors() {
      let advisors = this.getAllAdvisors.filter((a) => {
        return this.getContactDisplay(a) != "";
      });

      return advisors.sort(this.contactSort);
    },
  },
  methods: {
    contactSort(a, b) {
      let aDisplay = this.getContactDisplay(a).toLowerCase();
      let bDisplay = this.getContactDisplay(b).toLowerCase();

      if (aDisplay == bDisplay) {
        return 0;
      }

      return aDisplay < bDisplay ? -1 : 1;
    },

    getContactDisplay(contact) {
      let firstName = contact.firstName != null ? contact.firstName.trim() : "";
      let lastName = contact.lastName != null ? contact.lastName.trim() : "";

      let fullName = contact.fullName != null ? contact.fullName.trim() : "";
      if (fullName == null || fullName == "") {
        fullName = firstName + " " + lastName;
      }

      return fullName.trim();
    },
  },
};
</script>

<style scoped>
.required-colour {
  color: red;
}
.man-title {
  color: #595959;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: inherit;
}

.man-sub-title {
  color: #595959;
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;
}

.man-text {
  font-size: 0.9rem;
  font-weight: 700;
  color: #595959;
}

.rad-text {
  color: #595959;
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 900;
  transition: 0.3s;
}
.rad-text-condition {
  color: #595959;
  margin-left: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 900;
  transition: 0.3s;
}

.rad-design {
  width: 20px;
  height: 20px;
  border-radius: 100px;

  background-color: #344767;
  position: relative;
}
.rad-design::before {
  content: "";

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: 0.3s;
}

.rad-container {
  display: flex;
  flex-direction: column;
}

.rad-container-condition {
  display: flex;
  flex-direction: row;
}

.rad-input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-input:checked ~ .rad-text {
  color: #344767;
}

.rad-input-text-inline {
  /* display: flex; */
  text-align: left;
  flex-direction: row;
  margin-bottom: 5px;
}

.rad-input:checked + .rad-design::before {
  transform: scale(0);
}

.rad-label {
  display: flex;
  padding-right: 40px;
}

.radio-group {
  display: flex;
}
</style>
