<template>
<div id="sa-parent">
    <svg v-show="isAscending" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM385 231c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V193.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 119c9.4-9.4 24.6-9.4 33.9 0L385 231z"/></svg>
    <svg v-show="isDescending" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM127 281c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l71 71L232 136c0-13.3 10.7-24 24-24s24 10.7 24 24l0 182.1 71-71c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 393c-9.4 9.4-24.6 9.4-33.9 0L127 281z"/></svg>
</div>
</template>
<script>
export default {
    name:"sorting-arrows",
    data(){
        return{
            currentState : "none"
        }
    },
    props:{
        ascending:{
            required: true
        },
        descending:{
            required: true
        },
        //can be 'ascending', 'descending' or 'none'
        startingState:{
            default: "none"
        },
        currentlyActive:{
            required: true
        },
        id:{
            required:true
        },
        triggerElementId:{
            required:true
        }
    },
    computed:{
        isAscending(){
            return this.isActive && this.currentState == "ascending";
        },
        isDescending(){
            return this.isActive && this.currentState == "descending";
        },
        isActive(){
            return this.currentlyActive == this.id;
        },
    },
    watch:{
        isActive(){
            if(!this.currentlyActive == this.id){
                this.resetCurrentState();
            }
        }
    },
    methods:{
        resetCurrentState(){
            this.currentState = 'none';
        },
        sortAscending(){
            this.currentState = "ascending";
            this.$emit("update:currentlyActive", this.id)
            this.ascending();
        },
        sortDescending(){
            this.currentState = "descending";
            this.$emit("update:currentlyActive", this.id)
            this.descending();
        },
        sort(){
            if(this.currentState == 'none' || this.currentState == 'descending'){
                this.sortAscending();
                return;
            }
            this.sortDescending();           
        },
        initialSort(){
            if(this.isActive){
                if(this.isAscending){
                    this.sortAscending();
                    return;
                }
                this.sortDescending();
            }
        }
    },
    mounted(){
        this.currentState = this.startingState;
        this.initialSort();
        document.getElementById(this.triggerElementId).addEventListener("click",this.sort)
    }
}
</script>