<template>
  <div class="multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-normal">{{ getCustomer.fullName }}, UC604 Form</h5>
    <h6>Request to lift a sanction - details of a compliance dispute</h6>
    <div class="multisteps-form__content card-header pb-0 p-3">
      <div class="mt-2 row" style="display: flex; justify-content: center; min-width: 900px;">
        <div>
          <form class="card-body">
            <div class="form-check">
              <div class="mt-2 col-12 col-sm-10 mt-sm-2 text-start">
                <span>What was the participant told to do and when</span>
                <textarea class="form-control form-control-colour ps-3" rows="2"
                  placeholder="Enter N/A if not Applicable  ..." v-model="store.activityToldDoTo"></textarea>
                <span class="required-colour">{{ store.errors.activityToldDoTo}}</span>
              </div>
            </div>
            <div class="form-check">
              <div class="mt-2 col-12 col-sm-10 mt-sm-2 text-start">
                <span>What did the participant fail to do and when</span>
                <textarea class="form-control form-control-colour ps-3" rows="2"
                  placeholder="Enter N/A if not Applicable  ..." v-model="store.activityFailedToDo"></textarea>
                <span class="required-colour">{{ store.errors.activityFailedToDo}}</span>
              </div>
            </div>
            <div class="form-check">
              <div class="mt-2 col-12 col-sm-10 mt-sm-2 text-start">
                <span>Why do you consider the paricipant failed to complete the activity</span>
                <textarea class="form-control form-control-colour ps-3" rows="2"
                  placeholder="Enter N/A if not Applicable  ..." v-model="store.activityConsiderFailedReason"></textarea>
                <span class="required-colour">{{ store.errors.activityConsiderFailedReason}}</span>
              </div>
            </div>
            <div class="form-check">
              <div class="mt-2 col-12 col-sm-10 mt-sm-2 text-start">
                <span>Why does the participant consider that they have completed the activity</span>
                <textarea class="form-control form-control-colour ps-3" rows="2"
                  placeholder="Enter N/A if not Applicable  ..." v-model="store.activityConsiderCompleteReason"></textarea>
                <span class="required-colour">{{ store.errors.activityConsiderCompleteReason}}</span>
              </div>
            </div>
              <div class="form-check">
                <div class="mt-1 col-12 col-sm-10 mt-sm-2 text-start">
                  <div>
                    <span>I confirm that the above is a full and accurate
                      statement</span>
                    <div class="form-check-inline ps-5">
                      <input id="confirm-604-form-checkbox-part-2" class="form-check-input" type="checkbox" v-model="store.uC604FinalConfirmStatement" />
                      <label class="form-check-label"> Yes </label>
                    </div>
                  </div>
                  <span class="required-colour">{{
                    store.errors.uC604FinalConfirmStatement
                  }}</span>
                </div>
              </div>
          </form>
        </div>
      </div>
      <div class="form-check">
        <div class="d-flex">
          <ArgonButton type="button" color="light" variant="gradient" class="js-btn-prev" @click="goBack()">Back</ArgonButton>
          <ArgonButton type="button" title="Save and Close" class="mb-0 btn btn-btn-success ms-auto js-btn-next"
            :disabled="!store.meta.valid" @click="showPopup()">Save and Send</ArgonButton>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import ArgonButton from "@/components/Argoncomponents/ArgonButton.vue";
import { uc604FormStore2 } from "@/store/UC604FormStore2";
import { useCommonStore } from "@/store/commonStore";
const store = uc604FormStore2();
const commonStore = useCommonStore()
const { getCustomer} = useCommonStore();

function getLatest604() {
  const dwp604Records = commonStore.getActionToMandate.mandationRecord.dwp604Records
  if (dwp604Records.length > 0) {
    return dwp604Records[dwp604Records.length - 1]
  }
}

onMounted(() => {
  // It is possible that there isn't a saved 604 at this point
  const latest = getLatest604()
  const recordId = latest?.recordId ?? null

  if (store.recordId != null && store.recordId != recordId) {
    store.resetData()
  }

  if (latest != null && !store.loadedSavedForm) {
    store.loadFromSavedRecord(latest)
  }
})
</script>
  
<script>
import { onMounted } from 'vue';
export default {
  data() {
    return {
      showSavePopup: false,
    };
  },

  methods: {
    toggleShowSavePopup() {
      this.showSavePopup = !this.showSavePopup;
    },
    goBack() {
      this.$parent.prevStep();
    },

  // Tahomi Load Alert
  // showSwal() {
  //     return new Promise((resolve) => {
  //       this.$swal({
  //         title: "Loading Preview Please Wait...",
  //         showConfirmButton: false,
  //         allowOutsideClick: false,
  //         icon: "info",
  //         timer: 5000,
  //         didOpen: () => {
  //           this.$swal.showLoading();
  //           setTimeout(() => {
  //             resolve();
  //           }, 5000);
  //         },
  //       });
  //     });
  //   },

    showPopup() {
      //this.showSwal().then(() => {
        this.$parent.nextStep();
      //});
    },
}
}

</script>
  
<style scoped>
.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #999;
  animation: spin 1s infinite linear;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.required-colour {
  color: red;
}
.save-and-close {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 70%;
  height: fit-content;
  padding: 12px;
  border: 1px solid #2dce89;
  border-radius: 5px;
  background-color: rgba(#fff, 0.4);
  z-index: 20;
  opacity: 1;
  transition: 0.6s;
}
</style>
  