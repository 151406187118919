//import { AxiosError } from 'axios';
import { showErrorAlert } from './SwalUtil';

// Function to handle Axios errors
export default function handleAxiosError(error) {
    let errorTitle = "Error Encountered"
    let errorMessage = 'Please try again later.'

    // Customize error messages based on status codes
    if (error.response) {
        const resp = error.response
        const statusCode = resp.status;

        // Customize error messages for specific status codes
        switch (statusCode) {
            case 401:
                errorTitle = 'Unauthorized'
                errorMessage = 'Please try logging in again.'
                break;
            case 403:
                errorTitle = 'Forbidden'
                break;
            // Add more cases for other status codes as needed
            default:
                // Use default error message
                errorMessage = 'We encountered an unexpected issue. Please try again later.'
                break;
        }

        errorMessage = error.message + ' - ' + errorMessage
    } else {
        errorTitle = 'Network Error'
        errorMessage = 'Please check your connection and try again.'
    }

    // Show Swal error alert
    showErrorAlert({ title: errorTitle, text: errorMessage })
}
