import axios from 'axios';
import { TryGetCachedToken } from "../router/TokenManger";


const baseUrl = process.env.VUE_APP_ACTIONS_BASE_URL;
const envVars = process.env;
export default {
    async UploadContract(customerId, auditVersion, contract){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            var resp = await axios({ 
                method: 'POST',
                headers: { 
                    'content-type':'application/pdf',
                },
                data: contract,
                url:baseUrl
                + envVars.VUE_APP_CONTRACT
                .replace("{customerId}", customerId)
                .replace("{auditVersion}", auditVersion)});
            return resp;
        }
        catch(error){
            console.log(error);
            return error;
        }
    },
    async GetContract(customerId, auditVersion){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:baseUrl
                + envVars.VUE_APP_CONTRACT
                .replace("{customerId}", customerId)
                .replace("{auditVersion}", auditVersion),
                responseType: 'octet-stream',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
              });
            //var blob = new Blob(resp.data.split(""), { type: 'application/pdf' });
            //console.log(blob.size);
            // let bytes = new Uint8Array(resp.data.length);
            // for (let i = 0; i < bytes.length; i++) {
            //     bytes[i] = resp.data.charCodeAt(i);
            // }
            // var byteArray= [];
            // for(var i =0; i< resp.data.length; i++){
            //     byteArray.push(resp.data[i]);
            // }
            // console.log(byteArray);
            //const base64 = await fetch(resp.data);
            const base64Response = await fetch(`data:application/pdf;base64,${resp.data}`);
            const blob = await base64Response.blob();
            //let blob = new Blob(byteArray, { type:'application/pdf' });
            //console.log(resp.data.split(""));
            var url  = window.URL.createObjectURL(blob);
            window.open(url, '_blank').focus();
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async GetByteArrayFromBlob(blob){
        var bytes = await new Response(blob).arrayBuffer();
        return bytes;

        // var arrayBuffer;
        // var fileReader = new FileReader();
        // fileReader.onload = function(event) {
        //     arrayBuffer = event.target.result;
        // };
        // fileReader.readAsArrayBuffer(blob);
        // return arrayBuffer;
    }
}
