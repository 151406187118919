import { ref } from 'vue';
import { defineStore } from "pinia";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import { jsa603FormStore } from "@/store/JSA603FormStore";
import CreateMandationDocJSA from "../components/JSA603Wizard/Components/CreateMandationDocJSA";
import { useCommonStore } from "@/store/commonStore";

export const jsa603FormStore2Schema = Yup.object({
  rearrangeMandateActivity: Yup.string().required("This field is required*"),
  agreetoRearrange: Yup.string().when("rearrangeMandateActivity", {
    is: "yes",
    then: Yup.string().required("This field is required*"),
  }),
  originalMandatedAction: Yup.string().when("rearrangeMandateActivity", {
    is: "yes",
    then: Yup.string().required("This field is required*"),
  }),
  rearrangementDenial: Yup.string().when("rearrangeMandateActivity", {
    is: "no",
    then: Yup.string().required("This field is required*"),
  }),
  failActivity: Yup.string().required("This field is required*"),
  fullDetails: Yup.string().required("This field is required*"),
  volunteerInfo: Yup.string(),
  otherInfo: Yup.string(),
  confirmStatement: Yup.boolean()
    .required("Field must be checked")
    .oneOf([true], "Field must be checked"),
});

export const jsa603FormStore2 = defineStore("jsa603FormStore2", () => {
  const { errors, useFieldModel, handleSubmit, meta } = useForm({
    validationSchema: jsa603FormStore2Schema,
  });

  // Prevents overwriting changed form data when changing pages
  const loadedSavedForm = ref(false)

  const rearrangeMandateActivity = useFieldModel("rearrangeMandateActivity");
  const agreetoRearrange = useFieldModel("agreetoRearrange");
  const originalMandatedAction = useFieldModel("originalMandatedAction");
  const rearrangementDenial = useFieldModel("rearrangementDenial");
  const failActivity = useFieldModel("failActivity");
  const fullDetails = useFieldModel("fullDetails");
  const volunteerInfo = useFieldModel("volunteerInfo");
  const otherInfo = useFieldModel("otherInfo");
  const confirmStatement = useFieldModel("confirmStatement");

  const submitForm = handleSubmit(async () => {
    console.log("submitted");
  });

  const preview = handleSubmit(async (values) => {
    const partOneStore = jsa603FormStore();
    const partTwoStore = jsa603FormStore2();
    const commmonStore = useCommonStore();
    await CreateMandationDocJSA(partOneStore, values, commmonStore);
    commmonStore.setJsa603Visibility(false)

    partOneStore.resetData()
    partTwoStore.resetData()
  });


  const loadFromSavedRecord = function(dwp603Record) {
    const nonParticipationDetails = dwp603Record.nonParticipationDetails
    const rearrangementDetails = dwp603Record.rearrangementDetails
  
    rearrangeMandateActivity.value = convertBool(rearrangementDetails.attemptToRearrangeMade)
    
    if (rearrangementDetails.attemptToRearrangeMade) {
      originalMandatedAction.value = rearrangementDetails.rearrangmentDecisionDetails
      agreetoRearrange.value = convertBool(rearrangementDetails.rearrangmentAgreed)
    } else {
      rearrangementDenial.value = rearrangementDetails.rearrangmentDecisionDetails
    }
    
    failActivity.value = nonParticipationDetails.failureReason
    fullDetails.value = nonParticipationDetails.failureReasonDetails
  
    volunteerInfo.value = dwp603Record.participantFailureReason
    otherInfo.value = dwp603Record.additionalInformation
  
    loadedSavedForm.value = true
  }


  const resetData = function() {
    rearrangeMandateActivity.value = "";
    originalMandatedAction.value = "";
    agreetoRearrange.value = "";
    rearrangementDenial.value = "";
    failActivity.value = "";
    fullDetails.value = "";
    volunteerInfo.value = "";
    otherInfo.value = "";
    confirmStatement.value = false;
    loadedSavedForm.value = false
  }

  function convertBool(value) {
    return value == true ? "yes" : value == false ? "no" : ""
  }
  
  return {
    loadedSavedForm,
    rearrangeMandateActivity,
    agreetoRearrange,
    originalMandatedAction,
    rearrangementDenial,
    failActivity,
    fullDetails,
    volunteerInfo,
    otherInfo,
    confirmStatement,
    errors,
    submitForm,
    preview,
    meta,
    loadFromSavedRecord,
    resetData
  };
});
