<template>
  <div
    class="p-3 bg-white card border-radius-xl js-active"
    data-animation="FadeIn"
  >
    <div class="text-center row">
      <div class="mx-auto col-12">
        <h5 class="font-weight-normal">
          Please check the details entered are correct.
        </h5>
        <p>If correct please download and finish or go back to make changes.</p>
      </div>
    </div>
    <div
      v-if="documentId != null"
      style="width: 95%; margin: 0 auto; aspect-ratio: 1.7/1"
    >
      <iframe
        id="preview-iframe"
        style="width: 100%; height: 100%"
        :src="getPreviewMandationFormLink"
        width="500px"
        height="350px"
        frameborder="0"
        >This is an embedded
        <a target="_blank" href="https://office.com">Microsoft Office</a>
        document, powered by
        <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe
      >
    </div>
    <div class="multisteps-form__content">
      <div class="mt-4 button-row d-flex" style="justify-content: flex-end">
        <button
          class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
          type="button"
          title="Next"
          @click="goBack()"
        >
          Go Back
        </button>
        <button
          class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
          type="button"
          title="Next"
          @click="onConfirm()"
        >
          Save and Send
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { uc604FormStore } from "@/store/UC604FormStore";
import { uc604FormStore2 } from "@/store/UC604FormStore2";
import { useCommonStore } from "@/store/commonStore";
import { mapState, mapActions } from "pinia";
import { showLoadingAlert, showSuccessAlert, showWarningAlert, showConfirmationAlert } from "../../../../src/utils/SwalUtil"


</script>

<script>
export default {
  data() {
    return {
      showSavePopup: false,
      iFrameSrc: null,
      blobDestination: "",
      documentLink: "",
      docName: "",
      documentId: null
    };
  },

  computed: {
    ...mapState(useCommonStore, [
      "getCustomer",
      "getActionToMandate",
      "getMandationEmbedGuid",
      "getIsEditingManForm",
      "getPRaPOrder",
    ]),
    getPreviewMandationFormLink(){
      return process.env.VUE_APP_PREVIEW_MANDATION_FORM.replace('{documentId}',this.documentId)
    },
  },

  methods: {
    ...mapActions(useCommonStore, [
      "PostMandationForm",
      "setPreviewData",
      "GetMandationEmbedGuid",
      "postMandationFormToSharepoint",
      "GetMandationEmbedGuid",
      "setUc604Visibility"
    ]),

    resetData() {
      const store1 = uc604FormStore()
      const store2 = uc604FormStore2()

      store1.resetData();
      store2.resetData();
      this.setUc604Visibility(false);
    },

    toggleShowSavePopup() {
      this.showSavePopup = !this.showSavePopup;
    },
    goBack() {
      this.$parent.prevStep();
    },

    async createPreview() {
      const actionToMandate = this.getActionToMandate;
      const customer = this.getCustomer;
      const store = uc604FormStore2();

      await store.createPreview()

      const documentId = store.previewDocumentId
      if (actionToMandate && customer && documentId != null) {
        const id = await this.GetMandationEmbedGuid(
          documentId
        );

        if (id) {
          this.documentId = id;
        }
      }
    },

    // Upload the mandation document
    async uploadDocument() {
        const store = uc604FormStore2();
        await store.createFinal();
    },

    /**
     * Load the preview of the document.
     */
    loadPreview() {
      showLoadingAlert(this.createPreview(), { title: "Generating UC-604 Preview" })
        // Code to run on failure...
        .catch((err) => {
          const store = useCommonStore()
          store.HandleUIError(err)
          this.goBack()
        })
    },

    /**
     * Generate and save the final version of the document.
     */
    onConfirm() {
      showConfirmationAlert({ 
        title: "Proceed with Generating UC-604 Form?", 
        text: "This information cannot be changed afterwards"
      }).then((result) => {
        if (result.isConfirmed) {  
          // Show the loading alert
          showLoadingAlert(this.uploadDocument(), { title: "Generating UC-604 Document" })
            // Code to run on success...
            .then(() => {
              showSuccessAlert({ title : "Document Generated"})
              this.resetData()      
            })
            // Code to run on failure...
            .catch((err) => {
              const store = useCommonStore()
              store.HandleUIError(err)
            })
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          showWarningAlert({ title: "UC-604 Form has not been submitted"})         
        }
      })
    }
  },

  mounted() {
    this.loadPreview()
  }
};
</script>

<style scoped>
.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #999;
  animation: spin 1s infinite linear;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.required-colour {
  color: red;
}

.save-and-close-cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-and-close {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin-bottom: 15%;
}

.popup-content {
  margin-bottom: 20px;
}

.popup-text {
  text-align: center;
}

.popup-actions {
  text-align: center;
}

/* Example button styles (adjust as needed) */
.popup-actions button {
  margin-top: 10px;
  width: 100%;
}
</style>
