<template>
  <div
    class="multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="man-title">{{ getCustomer.fullName }}, JSA603 Form</h5>
    <div class="multisteps-form__content">
      <div class="mt-2 row" style="display: flex; justify-content: center">
        <form class="card-body">
          <div class="rad-input-text-inline">
            <h4 class="man-heading">Re-arrangement Details</h4>
            <label class="man-text"
              >Did the participant try to re-arrange the mandated activity
              before the date/time of the original mandatory activity?</label
            >
            <div class="radio-group">
              <label class="rad-label">
                <input
                  class="rad-input"
                  type="radio"
                  name="radio"
                  value="yes"
                  v-model="store.rearrangeMandateActivity"
                />
                <div class="rad-design"></div>
                <div class="rad-text" for="invalidCheck">Yes</div>
              </label>
              <label class="rad-label">
                <input
                  class="rad-input"
                  type="radio"
                  name="radio"
                  value="no"
                  v-model="store.rearrangeMandateActivity"
                />
                <div class="rad-design"></div>
                <div class="rad-text" for="invalidCheck">No</div>
              </label>
            </div>
            <p class="required-colour">
              {{ store.errors.rearrangeMandateActivity }}
            </p>

            <div v-if="store.rearrangeMandateActivity == 'yes'">
              <label class="man-text">Did you agree to Rearrange?</label>
              <div class="radio-group">
                <label class="rad-label">
                  <input
                    class="rad-input"
                    type="radio"
                    name="radio2"
                    value="yes"
                    v-model="store.agreetoRearrange"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text" for="invalidCheck">Yes</div>
                </label>
                <label class="rad-label">
                  <input
                    class="rad-input"
                    type="radio"
                    name="radio2"
                    value="no"
                    v-model="store.agreetoRearrange"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text" for="invalidCheck">No</div>
                </label>
              </div>
              <p class="required-colour">
                {{ store.errors.agreetoRearrange }}
              </p>
            </div>

            <div
              v-if="store.rearrangeMandateActivity == 'yes'"
              class="col-12 col-sm-10 mt-sm-2 text-start"
            >
              <label class="man-text"
                >Provide full details of the original mandatory activity.</label
              >
              <textarea
                class="form-control form-control-colour ps-3"
                rows="3"
                placeholder="Enter Details here ..."
                v-model="store.originalMandatedAction"
              ></textarea>
              <p class="required-colour">
                {{ store.errors.originalMandatedAction }}
              </p>
            </div>

            <div
              v-if="store.rearrangeMandateActivity == 'no'"
              class="col-12 col-sm-10 mt-sm-2 text-start"
            >
              <label class="man-text"
                >Explain why you did not allow the participant to re-arrange the
                original mandated activity.</label
              >
              <textarea
                class="form-control form-control-colour ps-3"
                rows="3"
                placeholder="Enter Details here ..."
                v-model="store.rearrangementDenial"
              ></textarea>
              <p class="required-colour">
                {{ store.errors.rearrangementDenial }}
              </p>
            </div>
          </div>

          <div class="mt-1 col-12 col-sm-10 mt-sm-2 text-start">
            <h4 class="man-heading">Non-participation details</h4>
          </div>
          <div class="mt-2 col-12 col-sm-10 mt-sm-2 text-start">
            <label class="man-text"
              >How did the participant fail to undertake the mandatory
              activity.</label
            >
            <textarea
              class="form-control form-control-colour ps-3"
              rows="3"
              placeholder="Enter Details here ..."
              v-model="store.failActivity"
              required
            ></textarea>
            <p class="required-colour">{{ store.errors.failActivity }}</p>
          </div>

          <div class="mt-2 col-12 col-sm-10 mt-sm-2 text-start">
            <label class="man-text">Provide full details.</label>
            <textarea
              class="form-control form-control-colour ps-3"
              rows="3"
              placeholder="Enter Details here ..."
              v-model="store.fullDetails"
              required
            ></textarea>
            <p class="required-colour">{{ store.errors.fullDetails }}</p>
          </div>

          <div class="mt-1 col-12 col-sm-10 mt-sm-2 text-start">
            <h4 class="man-heading">Extra Information</h4>
          </div>
          <div class="mt-2 col-12 col-sm-10 mt-sm-2 text-start">
            <label class="man-text"
              >If participant volunteered information as to why they failed the
              mandatory activity provide the details below:</label
            >
            <textarea
              class="form-control form-control-colour ps-3"
              rows="3"
              placeholder="Enter N/A if not Applicable ..."
              v-model="store.volunteerInfo"
            ></textarea>
            <p class="required-colour">{{ store.errors.volunteerInfo }}</p>
          </div>

          <div class="mt-2 col-12 col-sm-10 mt-sm-2 text-start">
            <label class="man-text">Any other information:</label>
            <textarea
              class="form-control form-control-colour ps-3"
              rows="3"
              placeholder="Enter N/A if not Applicable  ..."
              v-model="store.otherInfo"
            ></textarea>
            <p class="required-colour">{{ store.errors.otherInfo }}</p>
          </div>

          <div class="mt-1 col-12 col-sm-10 mt-sm-2 text-start">
            <div>
              <label class="mt-4 man-text"
                >I confirm that the above is a full and accurate
                statement</label
              >
              <div class="radio-group">
                <label class="rad-label">
                  <input
                    class="rad-input"
                    type="checkbox"
                    v-model="store.confirmStatement"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Yes</div>
                </label>
              </div>
            </div>
            <p class="required-colour">{{ store.errors.confirmStatement }}</p>
          </div>
        </form>
      </div>
    </div>
    <div class="mt-4 button-row d-flex" style="justify-content: flex-end">
      <button
        class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
        type="button"
        title="Close without saving"
        @click="resetData()"
      >
        Close without saving
      </button>
      <button
        class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
        type="button"
        title="Save and Close"
        :disabled="!store.meta.valid"
        @click="onConfirm()"
      >
        Save and Close
      </button>
    </div>
  </div>
</template>

<script setup>
import { jsa603FormStore } from "@/store/JSA603FormStore";
import { jsa603FormStore2 } from "@/store/JSA603FormStore2";
import { useCommonStore } from "@/store/commonStore";
import { showLoadingAlert, showWarningAlert, showSuccessAlert, showConfirmationAlert } from "@/utils/SwalUtil";
const store = jsa603FormStore2();
const commonStore = useCommonStore();
const { getCustomer } = useCommonStore();

function getLatest603() {
  const dwp603Records =
    commonStore.getActionToMandate.mandationRecord.dwp603Records;
  if (dwp603Records.length > 0) {
    return dwp603Records[dwp603Records.length - 1];
  }
}

onMounted(() => {
  // It is possible that there isn't a saved 603 at this point
  const latest = getLatest603();

  if (latest != null && !store.loadedSavedForm) {
    store.loadFromSavedRecord(latest);
  }
});
</script>

<script>
import { onMounted } from "vue";
import { mapActions } from "pinia";
export default {
  data() {
    return {
      showSavePopup: false,
    };
  },
  methods: {
    ...mapActions(useCommonStore, [
      "setJsa603Visibility",
    ]),

    // Upload the mandation document
    async uploadDocument() {
      const store = jsa603FormStore2()
      await store.preview()
    },

    resetData() {
      const store1 = jsa603FormStore()
      const store2 = jsa603FormStore2()

      store1.resetData();
      store2.resetData();
      this.setJsa603Visibility(false);
    },

    onConfirm() {
      showConfirmationAlert({ 
        title: "Proceed with Generating JSA-603 Form?", 
        text: "This information cannot be changed afterwards"
      }).then((result) => {
        if (result.isConfirmed) {  
          // Show the loading alert
          showLoadingAlert(this.uploadDocument(), { title: "Generating JSA-603 Document" })
            // Code to run on success...
            .then(() => {
              showSuccessAlert({ title : "Document Generated"})
              this.resetData()      
            })
            // Code to run on failure...
            .catch((err) => {
              const store = useCommonStore()
              store.HandleUIError(err)
            })
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          showWarningAlert({ title: "JSA-603 Form has not been submitted"})         
        }
      })
    }
  },
};
</script>

<style scoped>
.required-colour {
  color: red;
}

.man-heading {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.man-title {
  color: #595959;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: inherit;
}
.man-sub-title {
  color: #595959;
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;
}
.man-text {
  font-size: 0.9rem;
  font-weight: 700;
  color: #595959;
}
.rad-text {
  color: #595959;
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 900;
  transition: 0.3s;
}
.rad-text-condition {
  color: #595959;
  margin-left: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 900;
  transition: 0.3s;
}
.rad-design {
  width: 20px;
  height: 20px;
  border-radius: 100px;

  background-color: #344767;
  position: relative;
}
.rad-design::before {
  content: "";

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: 0.3s;
}
.rad-container {
  display: flex;
  flex-direction: column;
}
.rad-container-condition {
  display: flex;
  flex-direction: row;
}
.rad-input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}
.rad-input:checked ~ .rad-text {
  color: #344767;
}
.rad-input-text-inline {
  /* display: flex; */
  text-align: left;
  flex-direction: row;
  margin-bottom: 5px;
}
.rad-input:checked + .rad-design::before {
  transform: scale(0);
}
.rad-label {
  display: flex;
  padding-right: 40px;
}
.radio-group {
  display: flex;
}
.man-title {
  color: #595959;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: inherit;
}
.rad-input-text-inline {
  /* display: flex; */
  text-align: left;
  flex-direction: row;
  margin-bottom: 5px;
}

.save-and-close {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 70%;
  height: fit-content;
  padding: 12px;
  border: 1px solid #2dce89;
  border-radius: 5px;
  background-color: rgba(#fff, 0.4);
  z-index: 20;
  opacity: 1;
  transition: 0.6s;
}
</style>
