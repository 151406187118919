<template>
  <div id="surveyCreator" />
</template>

<script>
import { SurveyCreator } from "survey-creator-knockout";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};

const json = {
 "pages": [
  {
   "name": "page1",
   "elements": [
    {
     "type": "signaturepad",
     "name": "declarationSignatureCustomer",
     "title": "By signing below, as a customer I certify all information is true and correct to the best of my knowledge",
     "isRequired": true,
     "signatureWidth": 500,
     "allowClear": false,
     "penColor": "#000000"
    },
     {
     "type": "signaturepad",
     "name": "declarationSignatureAdvisor",
     "title": "By signing below, as an Advisor I certify all information is true and correct to the best of my knowledge",
     "isRequired": true,
     "signatureWidth": 500,
     "allowClear": false,
     "penColor": "#000000"
    }
   ]
  }
 ]
};

export default {
  name: "survey-creator",
  async mounted() {
    const creator = new SurveyCreator(creatorOptions);
    creator.text =
      window.localStorage.getItem("survey-json") || JSON.stringify(json);
    creator.saveSurveyFunc = (saveNo, callback) => {
      window.localStorage.setItem("survey-json", creator.text);
      callback(saveNo, true);
      // saveSurveyJson(
      //     "https://your-web-service.com/",
      //     creator.JSON,
      //     saveNo,
      //     callback
      // );
    };
    creator.render("surveyCreator");
  },
};

// function saveSurveyJson(url, json, saveNo, callback) {
//   const request = new XMLHttpRequest();
//   request.open('POST', url);
//   request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
//   request.addEventListener('load', () => {
//       callback(saveNo, true);
//   });
//   request.addEventListener('error', () => {
//       callback(saveNo, false);
//   });
//   request.send(JSON.stringify(json));
// }
</script>
<style scoped>
#surveyCreator {
  height: 90vh;
  width: 100vw;
}
</style>