<template>
    <div v-show="getShowModal" style="position: fixed; z-index: 10; left: 0; top: 0;
     width: 100%; height: 100%;  background-color: rgba(0, 0, 0, 0.4); overflow: auto;
     display: flex; justify-content: center; align-items: center;">
        <div style="max-width: 90vw; max-height: 90vh; height: fit-content; width: fit-content;" class="card">
            <div style="display: flex; flex-direction: row; 
            justify-content: space-between; align-items: center; padding: 0.4rem 1.4rem;
             border-bottom: 1px solid #ebebeb;">
                <h4 style="margin: 0;">{{getModalTitle}}</h4>
                <svg @click="()=>{toggleModal(); callClearValues()}" id="modal-close" style="width: 1.2rem;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
            </div>
            <div style="padding: 1.5vw 3vw;" :id="id"></div>
        </div>
    </div>
</template>
<script>
import { useAdvisorDashboardStore } from '@/store/advisorDashboard';
import { mapActions, mapState } from 'pinia';
export default {
  name: "Modal-Vue",
  props: {
    id:{
        type: String,
        default: "modal-content",
        required: false,
    }
  },
  computed:{
    ...mapState(useAdvisorDashboardStore, ["getShowModal", "getModalContent", "getModalTitle"])
  },
  methods:{
    ...mapActions(useAdvisorDashboardStore, ["toggleModal", "setModalContent", "resetCachedModalViews"]),
    callClearValues(){
      var el = document.getElementById(this.id);
      var btns = el.getElementsByClassName("clear-values-btn");
      for(var i = 0; i < btns.length; i ++){
        if(el.contains(btns[i])){
          btns[i].click();
        }
      }
    },
    changeContent(){
      var parent = document.getElementById(this.id);
      var childCount = parent.children.length;

      for(var i = 0; i < childCount; i++){
        parent.removeChild(parent.children[0]);
      }

      if (this.getModalContent == null) {
        return
      }

      if(typeof(this.getModalContent) == "string"){
        var el = document.createElement("DIV");
        el.innerHTML = this.getModalContent;
        parent.appendChild(el);
      }
      else{
        this.getModalContent.hidden = false;
        parent.appendChild(this.getModalContent);
      }
      
    },
  },
  mounted(){
    this.resetCachedModalViews()
    this.setModalContent(null) 
  },
  watch: {
    getModalContent() {
      this.changeContent();
    }
  }
};
</script>
<style>

  #modal-close{
    width: 1.2vw;
  }

  #modal-close:hover{
    cursor: pointer;
  }
</style>
