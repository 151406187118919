<template>
  <div
    :id="id"
    class="overflow-y-scroll-update"
    style="max-height: 70vh; padding: 1rem"
  >
    <div hidden>
      <button class="clear-values-btn" @click="clearValues()"></button>
    </div>
    <div
      v-if="getIsActionUpdating"
      style="
        width: 100%;
        display: flex;
        margin-bottom: 0.2rem;
        justify-content: right;
      "
    >
      <button
        v-if="getOriginalActionState == 'Recommended'"
        @click="assignToCustomer()"
        style="
          margin: 0px;
          background-color: white;
          padding: 0.5vh 1.5vh;
          margin-bottom: 1vh;
        "
        class="btn"
      >
        <span style="margin-right: 0.2rem">Assign to customer</span>
      </button>
    </div>
    <div style="margin-bottom: 1rem" v-if="!getOriginalActionMandated">
      <div><b>Status*:</b></div>
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; flex-direction: row">
          <div class="status-selector-upt">
            {{ getOriginalActionStateDisplay }}
          </div>
          <svg
            v-show="!showStateCodeDrpDwn"
            @click="
              () => {
                showStateCodeDrpDwn = true;
                hideScroll();
              }
            "
            class="tgl-page-dropdown"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
            />
          </svg>
          <svg
            v-show="showStateCodeDrpDwn"
            @click="
              () => {
                showStateCodeDrpDwn = false;
                showScroll();
              }
            "
            class="tgl-page-dropdown"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
            />
          </svg>
        </div>
        <div class="status-options" v-show="showStateCodeDrpDwn">
          <div
            v-for="item in statusCodes.filter((status) =>
              validateStateCode(
                status,
                getOriginalActionState,
                getOriginalActionSystemGenerated,
                getOriginalActionRequired
              )
            )"
            :key="item"
            @click="
              () => {
                selectStatusCode(item);
                showStateCodeDrpDwn = false;
              }
            "
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 1rem" v-if="getOriginalActionMandated">
      <div><b>Status*:</b></div>
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; flex-direction: row">
          <div class="status-selector-upt">
            {{ getOriginalActionStateDisplay }}
          </div>
          <svg
            v-show="!showStateCodeDrpDwn"
            @click="
              () => {
                showStateCodeDrpDwn = true;
                hideScroll();
              }
            "
            class="tgl-page-dropdown"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
            />
          </svg>
          <svg
            v-show="showStateCodeDrpDwn"
            @click="
              () => {
                showStateCodeDrpDwn = false;
                showScroll();
              }
            "
            class="tgl-page-dropdown"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
            />
          </svg>
        </div>
        <div class="status-options" v-show="showStateCodeDrpDwn">
          <div
            v-for="item in mandatedStatusCodes.filter((status) =>
              validateStateCode(
                status,
                getOriginalActionState,
                getOriginalActionSystemGenerated,
                getOriginalActionRequired
              )
            )"
            :key="item"
            @click="
              () => {
                selectStatusCode(item);
                showStateCodeDrpDwn = false;
              }
            "
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="progress-updt-cancelled" v-show="cancelSelected">
      <div><b>Cancellation Reason*:</b></div>
      <textarea
        style="width: 22rem; height: 7.5rem"
        class="crte-act-input"
        v-model="cancellationReason"
      ></textarea>
      <div class="text-danger" v-show="errors.cancellationReason != ''">
        {{ errors.cancellationReason }}
      </div>
    </div>
    <div class="upt-act-datetime" v-if="!getOriginalActionMandated">
      <div style="margin-bottom: 1rem">
        <div><b>Scheduled Start*:</b></div>
        <input
          :type="this.getDateInputType"
          class="crte-act-input"
          :min="getDateFormatString(new Date())"
          :value="
            getNewActionStart == null
              ? getOriginalActionStart
              : getNewActionStart
          "
          v-show="!error"
          @input="newAction.scheduledStart = $event.target.value"
        />
        <input
          :type="this.getDateInputType"
          class="crte-act-input"
          :min="getDateFormatString(new Date())"
          :value="getNewActionStart"
          v-show="error"
          @input="newAction.scheduledStart = $event.target.value"
        />
        <div
          style="margin-top: 0.3rem"
          v-show="errors.start != ''"
          class="text-danger"
        >
          {{ errors.start }}
        </div>
      </div>
    </div>
    <div class="upt-act-datetime" v-if="!getOriginalActionMandated">
      <div style="margin-bottom: 1rem">
        <div><b>Due Date*:</b></div>
        <input
          :type="this.getDateInputType"
          class="crte-act-input"
          :min="getDateFormatString(new Date())"
          :value="
            getNewActionEnd == null ? getOriginalActionEnd : getNewActionEnd
          "
          v-show="!error"
          @input="newAction.scheduledEnd = $event.target.value"
        />
        <input
          :type="this.getDateInputType"
          class="crte-act-input"
          :min="getDateFormatString(new Date())"
          :value="getNewActionEnd"
          v-show="error"
          @input="newAction.scheduledEnd = $event.target.value"
        />
        <div
          style="margin-top: 0.3rem"
          v-show="errors.end != ''"
          class="text-danger"
        >
          {{ errors.end }}
        </div>
      </div>
    </div>

    <div class="upt-act-datetime" v-if="!getOriginalActionMandated">
      <div v-show="this.isOriginalActionAppointment" class="upd-act-section">
        <div><b>Location*:</b></div>
        <input
          style="width: 22rem"
          class="crte-act-input"
          :class="{ disabled: this.getOriginalActionSystemGenerated }"
          :value="
            getNewActionLocation == null
              ? getOriginalActionLocation
              : getNewActionLocation
          "
          v-show="!error"
          @input="newAction.location.locationName = $event.target.value"
          :disabled="this.getOriginalActionSystemGenerated"
          readonly
        />
        <input
          style="width: 22rem"
          class="crte-act-input"
          :value="getNewActionSubject"
          v-show="error"
          @input="newAction.location.locationName = $event.target.value"
        />
        <div
          style="margin-top: 0.3rem"
          v-show="errors.subject != ''"
          class="text-danger"
        >
          {{ errors.subject }}
        </div>
      </div>
    </div>

    <div class="upd-act-section" v-if="!getOriginalActionMandated">
      <div><b>Action*:</b></div>
      <input
        style="width: 22rem"
        class="crte-act-input"
        :class="{ disabled: this.getOriginalActionSystemGenerated }"
        :value="
          getNewActionSubject == null
            ? getOriginalActionSubject
            : getNewActionSubject
        "
        v-show="!error"
        @input="newAction.subject = $event.target.value"
        :disabled="this.getOriginalActionSystemGenerated"
        readonly
      />
      <input
        style="width: 22rem"
        class="crte-act-input"
        :value="getNewActionSubject"
        v-show="error"
        @input="newAction.subject = $event.target.value"
        readonly
      />
      <div
        style="margin-top: 0.3rem"
        v-show="errors.subject != ''"
        class="text-danger"
      >
        {{ errors.subject }}
      </div>
    </div>
    <div class="upd-act-section" v-if="!getOriginalActionMandated">
      <div><b>What will I get from doing this?*:</b></div>
      <textarea
        style="width: 22rem; height: 7.5rem"
        class="crte-act-input"
        :class="{ disabled: this.getOriginalActionSystemGenerated }"
        :value="
          getNewActionMotivation == null
            ? getOriginalActionMotivation
            : getNewActionMotivation
        "
        v-show="!error"
        @input="newAction.motivation = $event.target.value"
        :disabled="this.getOriginalActionSystemGenerated"
        readonly
      ></textarea>
      <textarea
        style="width: 22rem; height: 7.5rem"
        class="crte-act-input"
        :value="getNewActionMotivation"
        v-show="error"
        @input="newAction.motivation = $event.target.value"
        readonly
      ></textarea>
      <div class="text-danger" v-show="errors.motivation != ''">
        {{ errors.motivation }}
      </div>
    </div>
    <div class="text-danger" v-show="errors.effort != ''">
      {{ errors.effort }}
    </div>
    <div
      class="upd-act-section"
      style="display: flex; align-items: center"
      v-if="false"
    >
      <div><b>Is Mandated:</b></div>
      <input
        type="checkbox"
        style="height: 1rem; width: 1rem; margin-left: 0.6rem"
        class="crte-act-input"
        :checked="
          getNewActionMandated == null
            ? getOriginalActionMandated
            : getNewActionMandated
        "
        v-show="!error"
        @change="newAction.isMandated = $event.target.checked"
      />
      <input
        type="checkbox"
        style="height: 1rem; width: 1rem; margin-left: 0.6rem"
        class="crte-act-input"
        :value="getNewActionMandated"
        v-show="error"
        @change="newAction.isMandated = $event.target.checked"
      />
    </div>
    <button
      style="margin: 0; margin-right: 0.75rem; background-color: #ededed"
      class="btn crte-act-input"
      @click="onContinue()"
    >
      Save
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
import { useCommonStore } from "@/store/commonStore";
import { useMainStore } from "@/store/mainStore";
import { useEmployabilityActionsStore } from "@/store/employabilityActionsData";

export default {
  name: "update-action",
  data() {
    return {
      error: false,
      showStateCodeDrpDwn: false,
      statusCodes: [
        "Recommended",
        "Open",
        "Completed",
        "Cancelled",
        "Failed to Complete",
      ],
      mandatedStatusCodes: [
        "Open",
        "Completed",
        "Cancelled",
        "Failed to Complete",
      ],
      newAction: {
        actionUrl: null,
        subject: null,
        description: null,
        motivation: null,
        isMandated: null,
        isRequired: null,
        priorityCode: 0,
        progress: 0,
        estimatedEffort: 1,
        statusCode: "",
        stateCode: null,
        scheduledStart: null,
        scheduledEnd: null,
        systemGenerated: null,
      },
      errors: {
        start: "",
        end: "",
        subject: "",
        url: "",
        motivation: "",
        description: "",
        effort: "",
        cancellationReason: "",
      },
      cancellationReason: "",
      cancelSelected: false,
    };
  },
  props: {
    id: {
      type: String,
      default: "update-action",
    },
  },
  computed: {
    ...mapState(useAdvisorDashboardStore, [
      "getCurrentActionToUpdate",
      "getCreateActionView",
      "getCurrentTaskToUpdate",
      "getIsActionUpdating",
      "getUpdateAssessmentItemDueView",
    ]),

    ...mapState(useCommonStore, [
      "getActions",
      "getUnfilteredAssessmentResults",
      "getPathway",
    ]),

    ...mapState(useEmployabilityActionsStore, [
      "getPathwayA",
      "getPathwayB",
      "getPathwayC",
    ]),

    ...mapState(useMainStore, ["getIsCompletedByCustomerEnabled"]),
    isActionAnAssessment() {
      if (this.getIsActionUpdating) {
        if (this.getCurrentActionToUpdate) {
          return (
            this.getCurrentActionToUpdate.creationContext.action ==
              process.env.VUE_APP_REASSESSMENT_CREATION_ACTION_ID ||
            this.getCurrentActionToUpdate.creationContext.action ==
              process.env.VUE_APP_INITIAL_ASSESSMENT_CREATION_ACTION_ID
          );
        }
      } else {
        if (this.getCurrentTaskToUpdate) {
          return (
            this.getCurrentTaskToUpdate.creationContext.action ==
              process.env.VUE_APP_REASSESSMENT_CREATION_ACTION_ID ||
            this.getCurrentActionToUpdate.creationContext.action ==
              process.env.VUE_APP_INITIAL_ASSESSMENT_CREATION_ACTION_ID
          );
        }
      }
      return false;
    },
    getCurrentItemToUpdate() {
      if (this.getIsActionUpdating) {
        return this.getCurrentActionToUpdate;
      } else {
        return this.getCurrentTaskToUpdate;
      }
    },
    getNewActionStart() {
      if (this.newAction.scheduledStart != null) {
        return this.getDateFormatString(
          new Date(this.newAction.scheduledStart)
        );
      } else {
        return this.getOriginalActionStart;
      }
    },
    getNewActionEnd() {
      if (this.newAction.scheduledEnd != null) {
        return this.getDateFormatString(new Date(this.newAction.scheduledEnd));
      } else {
        return this.getOriginalActionEnd;
      }
    },
    getNewActionLocation() {
      if (this.newAction.location != null) {
        return this.newAction.location;
      } else {
        return this.getOriginalActionLocation;
      }
    },
    getNewActionSubject() {
      if (this.newAction.subject != null) {
        return this.newAction.subject;
      } else {
        return this.getOriginalActionSubject;
      }
    },
    getNewActionUrl() {
      if (this.newAction.actionUrl != null) {
        return this.newAction.actionUrl;
      } else {
        return this.getOriginalActionUrl;
      }
    },
    getNewActionDescription() {
      if (this.newAction.description != null) {
        return this.newAction.description;
      } else {
        return this.getOriginalActionDescription;
      }
    },
    getNewActionMotivation() {
      if (this.newAction.motivation != null) {
        return this.newAction.motivation;
      } else {
        return this.getOriginalActionMotivation;
      }
    },
    getNewActionEffort() {
      if (this.newAction.estimatedEffort != null) {
        return this.newAction.estimatedEffort;
      } else {
        return this.getOriginalActionEffort;
      }
    },
    getNewActionMandated() {
      if (this.newAction.isMandated != null) {
        return this.newAction.isMandated;
      } else {
        return this.getOriginalActionMandated;
      }
    },
    getNewActionState() {
      if (this.newAction.stateCode != null) {
        return this.newAction.stateCode;
      } else {
        return this.getOriginalActionState;
      }
    },
    isOriginalActionAppointment() {
      return (
        this.getCurrentItemToUpdate != null &&
        this.getCurrentItemToUpdate.category == "Appointment"
      );
    },
    getOriginalActionStart() {
      if (this.getCurrentItemToUpdate == null) {
        return null;
      } else {
        return this.getDateFormatString(
          new Date(this.getCurrentItemToUpdate.timeline.scheduledStart)
        );
      }
    },
    getOriginalActionEnd() {
      if (this.getCurrentItemToUpdate == null) {
        return null;
      } else {
        return this.getDateFormatString(
          new Date(this.getCurrentItemToUpdate.timeline.scheduledEnd)
        );
      }
    },
    getOriginalActionLocation() {
      if (this.getCurrentItemToUpdate == null) {
        return null;
      } else {
        return this.getCurrentItemToUpdate.location.locationName;
      }
    },
    getDateInputType() {
      return this.isOriginalActionAppointment ? "datetime-local" : "date";
    },
    getOriginalActionSubject() {
      if (this.getCurrentItemToUpdate == null) {
        return "";
      } else {
        return this.getCurrentItemToUpdate.subject;
      }
    },
    getOriginalActionUrl() {
      if (this.getCurrentItemToUpdate == null) {
        return "";
      } else {
        return this.getCurrentItemToUpdate.actionUrl;
      }
    },
    getOriginalActionDescription() {
      if (this.getCurrentItemToUpdate == null) {
        return "";
      } else {
        return this.getCurrentItemToUpdate.description;
      }
    },
    getOriginalActionMotivation() {
      if (this.getCurrentItemToUpdate == null) {
        return "";
      } else {
        return this.getCurrentItemToUpdate.motivation;
      }
    },
    getOriginalActionEffort() {
      if (this.getCurrentItemToUpdate == null) {
        return 1;
      } else {
        return this.getCurrentItemToUpdate.estimatedEffort;
      }
    },
    getOriginalActionMandated() {
      if (this.getCurrentItemToUpdate == null) {
        return false;
      } else {
        return this.getCurrentItemToUpdate.isMandated;
      }
    },
    getOriginalActionStateDisplay() {
      let state = this.getOriginalActionState;
      if (state == "Pending complete") {
        state = "Open";
      }

      return state;
    },
    getOriginalActionState() {
      if (this.getCurrentItemToUpdate == null) {
        return "";
      } else {
        var val;
        switch (this.getCurrentItemToUpdate.state.stateCode) {
          case "Available":
            val = "Recommended";
            break;
          case "Open":
            val = "Open";
            break;
          case "PendingComplete":
            val = "Pending complete";
            break;
          case "Complete":
            val = "Completed";
            break;
          case "Deferred":
            val = "Cancelled";
            break;
          case "Canceled":
            val = "Failed to Complete";
            break;
          // return this.getCurrentItemToUpdate.stateCode;
        }
        return val;
      }
    },
    getOriginalActionSystemGenerated() {
      return this.getCurrentItemToUpdate == null
        ? false
        : this.getCurrentItemToUpdate.creationContext.systemGenerated;
    },
    getOriginalActionRequired() {
      return this.getCurrentItemToUpdate == null
        ? false
        : this.getCurrentItemToUpdate.isRequired;
    },

    getUpdateRequest() {
      if (this.getCurrentItemToUpdate.isMandated) {
        return { stateCode: this.getNewActionState };
      } else {
        return this.newAction;
      }
    },

    // areRequiredSubjectsCompleted() {
    //   const completedSubjects = new Set(
    //     this.getActions.completed?.map((action) => action.subject) || []
    //   );

    //   return this.getPathwayA.targetSubjects.every((subject) =>
    //     completedSubjects.has(subject)
    //   );
    // },

    areRequiredSubjectsCompleted() {
      const completedSubjects = new Set(
        this.getActions.completed?.map((action) => action.subject) || []
      );

      // Determine which pathway data to use
      let targetSubjects;
      if (!this.getPathway) {
        console.warn("getPathway is null, using default pathway (Pathway A)");
        targetSubjects = this.getPathwayA.targetSubjects; // Use Pathway A as default
      } else {
        switch (this.getPathway) {
          case "Pathway A":
            targetSubjects = this.getPathwayA.targetSubjects;
            break;
          case "Pathway B":
            targetSubjects = this.getPathwayB.targetSubjects;
            break;
          case "Pathway C":
            targetSubjects = this.getPathwayC.targetSubjects;
            break;
          default:
            console.error("Invalid pathway value:", this.getPathway);
            return false; // Or throw an error
        }
      }

      // Check if all required subjects are completed
      return targetSubjects.every((subject) => completedSubjects.has(subject));
    },
  },

  watch: {
    areRequiredSubjectsCompleted(newVal) {
      if (newVal) {
        this.setPerformPassportCheck(true);
      }
    },

    // getUnfilteredAssessmentResults: {
    //   immediate: true, // Run the handler immediately when the component is created
    //   handler(newAssessmentResults) {
    //     this.assessmentResults = newAssessmentResults;

    //     if (newAssessmentResults) {
    //       // Check if results are actually available
    //       this.findPathway();
    //     } else {
    //       this.pathway = null; // Reset pathway if results become empty
    //     }
    //   },
    // },
  },

  methods: {
    ...mapActions(useAdvisorDashboardStore, [
      "toggleModal",
      "setModalTitle",
      "setModalContent",
      "setCreateActionView",
      "setCurrentActionToAddTask",
      "setCurrentActionToUpdate",
      "setCurrentTaskToUpdate",
      "setUpdateAssessmentItemDueView",
    ]),
    ...mapActions(useCommonStore, [
      "UpdateAction",
      "UpdateTask",
      "findTasksParentAction",
      "getUTCDateString",
      "UpdateActionCompletedDate",
      "UpdateActionStateCode",
      "CreateActionPlanItemMemo",
      "CreateTaskItemMemo",
      "setUpdateAssessmentItemDueCallback",
      "setPerformPassportCheck",
      "GetUnfilteredAssessmentResults",
    ]),


    getDateInputString(date) {
      return [
        date.getFullYear(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : date.getMonth() + 1,
        date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate(),
      ].join("-");
    },
    getDateFormatString(date) {
      if (this.getDateInputType == "datetime-local") {
        return [
          [
            date.getFullYear(),
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1).toString()
              : date.getMonth() + 1,
            date.getDate() < 10
              ? "0" + date.getDate().toString()
              : date.getDate(),
          ].join("-"),
          [
            date.getHours() < 10
              ? "0" + date.getHours().toString()
              : date.getHours(),
            date.getMinutes() < 10
              ? "0" + date.getMinutes().toString()
              : date.getMinutes(),
          ].join(":"),
        ].join("T");
      }
      return this.getDateInputString(date);
    },
    setValues() {
      this.newAction.scheduledStart = this.getUTCDateString(
        new Date(this.getNewActionStart)
      );
      this.newAction.scheduledEnd = this.getUTCDateString(
        new Date(this.getNewActionEnd)
      );
      this.newAction.subject = this.getNewActionSubject;
      this.newAction.motivation = this.getNewActionMotivation;
      this.newAction.description = this.getNewActionDescription;
      this.newAction.estimatedEffort = this.getNewActionEffort;
      this.newAction.isMandated = this.getNewActionMandated;
      this.newAction.actionUrl = this.getNewActionUrl;
      this.newAction.stateCode = this.getNewActionState;
    },

    clearValues() {
      this.newAction = {
        actionUrl: null,
        subject: null,
        description: null,
        motivation: null,
        isMandated: null,
        priorityCode: 0,
        progress: 0,
        estimatedEffort: 1,
        scheduledStart: null,
        scheduledEnd: null,
        location: null,
        stateCode: null,
      };
      this.error = false;
      this.cancelSelected = false;
      this.cancellationReason = "";
      this.showStateCodeDrpDwn = false;
      this.setCurrentActionToUpdate(null);
      this.setCurrentTaskToUpdate(null);
    },
    onContinue() {
      this.validateNewAction();
      if (!this.error) {
        if (this.isActionAnAssessment && this.newAction.scheduledEnd != null) {
          this.openUpdateAssessmentActionDueDate();
        } else {
          this.updateAction();
        }
      }
    },
    validateNewAction() {
      this.error = false;
      this.setValues();
      this.errors = {
        start: "",
        end: "",
        subject: "",
        url: "",
        motivation: "",
        description: "",
        effort: "",
        cancellationReason: "",
      };
      if (
        this.newAction.stateCode == "Current" &&
        this.newAction.scheduledStart == null
      ) {
        this.errors.start = "*Required";
        this.error = true;
      }
      if (this.newAction.scheduledEnd == null) {
        this.errors.end = "*Required";
        this.error = true;
      } else if (this.newAction.scheduledStart != null) {
        if (
          new Date(this.newAction.scheduledEnd) <
          new Date(this.newAction.scheduledStart)
        ) {
          this.errors.end = "End date should be later than start date";
          this.error = true;
        }
      }
      if (this.newAction.subject == "") {
        this.errors.subject = "*Required";
        this.error = true;
      }
      if (this.newAction.motivation == "") {
        this.errors.motivation = "*Required";
        this.error = true;
      }
      if (this.cancelSelected) {
        if (this.cancellationReason == null || this.cancellationReason == "") {
          this.errors.cancellationReason = "*Required";
          this.error = true;
        }
      }
      // if(this.newAction.description == ""){
      //     this.errors.description = "*Required";
      //     this.error = true;
      // }
      if (
        parseInt(this.newAction.estimatedEffort) < 1 ||
        parseInt(this.newAction.estimatedEffort) > 10
      ) {
        this.errors.effort = "Must be between 1 and 10";
        this.error = true;
      }
    },
    async updateAction() {
      if (!this.error) {
        switch (this.newAction.stateCode) {
          case "Recommended":
            this.newAction.stateCode = "Available";
            break;
          case "Open":
            // Because Open can be used for both "Open" and "PendingComplete" codes, a check is needed on the original state
            if (this.getOriginalActionState == "Pending complete") {
              this.newAction.stateCode = "PendingComplete";
            } else {
              this.newAction.stateCode = "Open";
            }

            break;
          case "Pending complete":
            this.newAction.stateCode = "PendingComplete";
            break;
          case "Completed":
            this.newAction.stateCode = "Complete";
            break;
          case "Cancelled":
            this.newAction.stateCode = "Deferred";
            break;
          case "Failed to Complete":
            this.newAction.stateCode = "Canceled";
            break;
        }
        if (
          this.getOriginalActionState != "Completed" &&
          this.newAction.stateCode == "Complete"
        ) {
          await this.UpdateActionCompletedDate(
            this.getUTCDateString(new Date()),
            this.getCurrentItemToUpdate.id,
            false
          );
        } else if (
          this.getOriginalActionState != "Completed" &&
          this.newAction.stateCode == "Deferred"
        ) {
          await this.UpdateActionCompletedDate(
            this.getUTCDateString(new Date()),
            this.getCurrentItemToUpdate.id,
            false
          );
        } else if (
          this.getOriginalActionState != "Completed" &&
          this.newAction.stateCode == "Canceled"
        ) {
          await this.UpdateActionCompletedDate(
            this.getUTCDateString(new Date()),
            this.getCurrentItemToUpdate.id,
            false
          );
        }
        this.newAction.estimatedEffort = parseInt(
          this.newAction.estimatedEffort
        );
        this.newAction.actionUrl = this.getOriginalActionUrl;
        if (this.getIsActionUpdating) {
          if (this.getOriginalActionMandated) {
            this.UpdateActionStateCode(
              this.getCurrentItemToUpdate.id,
              this.newAction.stateCode,
              true
            );
          } else {
            this.UpdateAction(
              this.getCurrentItemToUpdate.id,
              this.getUpdateRequest,
              true
            );
            if (this.cancelSelected) {
              this.CreateActionPlanItemMemo(
                this.getCurrentItemToUpdate.id,
                this.cancellationReason,
                "EA",
                "Archived"
              );
            }
          }
        }
      } else {
        this.UpdateTask(
          this.findTasksParentAction(this.getCurrentItemToUpdate.id).id,
          this.getCurrentItemToUpdate.id,
          this.getUpdateRequest,
          true
        );
        if (this.cancelSelected) {
          this.CreateTaskItemMemo(
            this.getCurrentItemToUpdate.id,
            this.findTasksParentAction(this.getCurrentItemToUpdate.id).id,
            this.cancellationReason,
            "EA",
            "Archived"
          );
        }
      }
      this.newAction = {
        actionUrl: null,
        subject: null,
        description: null,
        motivation: null,
        isMandated: null,
        priorityCode: 0,
        progress: 0,
        estimatedEffort: 1,
        scheduledStart: null,
        scheduledEnd: null,
        stateCode: null,
      };
      this.error = false;
      this.cancelSelected = false;
      this.cancellationReason = "";
      this.showStateCodeDrpDwn = false;
      this.setCurrentActionToUpdate(null);
      this.setCurrentTaskToUpdate(null);
      this.toggleModal();

      // if (this.areRequiredSubjectsCompleted) {
      //   this.setPerformPassportCheck(true);
      // }
    },

    hideScroll() {
      if (
        document.getElementsByClassName("overflow-y-scroll-update").length > 0
      ) {
        document
          .getElementsByClassName("overflow-y-scroll-update")[0]
          .classList.add("overflow-y-hidden-update");
        document
          .getElementsByClassName("overflow-y-scroll-update")[0]
          .classList.remove("overflow-y-scroll-update");
      }
    },
    showScroll() {
      if (
        document.getElementsByClassName("overflow-y-hidden-update").length > 0
      ) {
        document
          .getElementsByClassName("overflow-y-hidden-update")[0]
          .classList.add("overflow-y-scroll-update");
        document
          .getElementsByClassName("overflow-y-hidden-update")[0]
          .classList.remove("overflow-y-hidden-update");
      }
    },
    selectStatusCode(selected) {
      if (selected == "Cancelled") {
        this.cancelSelected = true;
      } else {
        this.cancelSelected = false;
        this.cancellationReason = "";
      }
      document.getElementsByClassName("status-selector-upt")[0].innerHTML =
        selected;
      this.newAction.stateCode = selected;
      this.showScroll();
    },
    goToCreateTask() {
      if (this.getCreateActionView == null) {
        this.setCreateActionView(document.getElementById("create-action"));
      }
      this.setModalContent(this.getCreateActionView);
      this.setModalTitle("Create New Task");
      this.setCurrentActionToAddTask(this.getCurrentItemToUpdate.id);
    },
    openUpdateAssessmentActionDueDate() {
      this.validateNewAction();
      if (!this.error) {
        if (this.getUpdateAssessmentItemDueView == null) {
          this.setUpdateAssessmentItemDueView(
            document.getElementById("updt-assessment-item-due")
          );
        }
        this.setModalContent(this.getUpdateAssessmentItemDueView);
        this.setModalTitle("Update assessment action due date");
        this.setUpdateAssessmentItemDueCallback(async (updateDuedateMethod) => {
          await updateDuedateMethod(this.getNewActionEnd);
          this.updateAction();
        });
      }
    },
    assignToCustomer() {
      this.UpdateActionStateCode(this.getCurrentItemToUpdate.id, "Open", true);
      this.newAction = {
        actionUrl: null,
        subject: null,
        description: null,
        motivation: null,
        isMandated: null,
        priorityCode: 0,
        progress: 0,
        estimatedEffort: 1,
        scheduledStart: null,
        scheduledEnd: null,
        stateCode: null,
      };
      this.error = false;
      this.showStateCodeDrpDwn = false;
      this.setCurrentActionToUpdate(null);
      this.setCurrentTaskToUpdate(null);
      this.toggleModal();
    },
    validateStateCode(code, current, systemGenerated, isRequired) {
      let customerComplete =
        current == "Pending complete" || current == "Completed";

      console.debug(current);
      // Actions with tasks cant be moved to completed unless they are pending complete
      if (this.getCurrentItemToUpdate != null) {
        if (
          this.getCurrentItemToUpdate.tasks.length > 0 &&
          current != "Pending complete" &&
          code == "Completed"
        ) {
          return false;
        }
      }

      // System generated actions cannot be moved to Recommended
      if (systemGenerated && code == "Recommended") {
        return false;
      }

      // Actions that a customer has not completed cannot be moved to Complete
      if (
        !customerComplete &&
        isRequired &&
        this.getIsCompletedByCustomerEnabled
      ) {
        return code != "Completed";
      }

      return true;
    },
  },

  mounted() {
    // this.GetUnfilteredAssessmentResults()
  },
};
</script>

<style>
.overflow-y-scroll-update {
  overflow-y: scroll;
}

.overflow-y-hidden-update {
  overflow-y: hidden;
}

.upd-act-section,
.progress-updt-cancelled {
  margin-bottom: 1rem;
}

.upd-act-section div {
  font-size: medium;
}

.upd-act-section input,
.upd-act-section textarea,
.upt-act-datetime input,
.progress-updt-cancelled textarea {
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  color: #67748e;
  font-size: medium;
  padding: 0.2rem 0.5rem;
}

.upt-act-datetime b {
  font-size: medium;
  margin-right: 0.4rem;
}

.upt-act-datetime input {
  font-size: medium;
}

.status-selector-upt {
  width: 18rem;
  height: 1.7rem;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 0 0.7rem;
  color: #67748e;
}

.status-options {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  width: 18rem;
  max-width: 18rem;
  z-index: 20;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 2rem;
  max-height: 15rem;
  overflow-y: scroll;
}

input.disabled {
  background-color: #ebebeb;
}

textarea.disabled {
  background-color: #ebebeb;
}
</style>
