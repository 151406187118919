import * as yup from "yup";
import type { jsa603FormStoreSchema } from "../../../store/JSA603FormStore";
import moment from "moment";
import { loggedIn } from "../../../router/Guard";
import { toRaw } from "vue";

interface MandationDocPartOne
  extends yup.InferType<typeof jsa603FormStoreSchema> {}

function convertRecordToMandationDocPartOne(
  request: Record<string, any>
): MandationDocPartOne {
  return {
    selectedAdvisor: toRaw(request["selectedAdvisor"]),
    satisfyRestrictionOnJSA: request["satisfyRestrictionOnJSA"],
    restartSchemeMandatory: request["restartSchemeMandatory"],
    reasonableConsideration: request["reasonableConsideration"],
    attemptsMade: request["attemptsMade"],
    face2Face: request["face2Face"],
  } as MandationDocPartOne;
}

export const jsa603FormStore2Schema = yup.object({
  rearrangeMandateActivity: yup.string().required("This field is required*"),
  agreetoRearrange: yup.string().when("rearrangeMandateActivity", {
    is: "yes",
    then: yup.string().required("This field is required*"),
  }),
  originalMandatedAction: yup.string().when("rearrangeMandateActivity", {
    is: "yes",
    then: yup.string().required("This field is required*"),
  }),
  rearrangementDenial: yup.string().when("rearrangeMandateActivity", {
    is: "no",
    then: yup.string().required("This field is required*"),
  }),
  failActivity: yup.string().required("This field is required*"),
  fullDetails: yup.string().required("This field is required*"),
  volunteerInfo: yup.string().required("This field is required*"),
  otherInfo: yup.string().required("This field is required*"),
  confirmStatement: yup
    .boolean()
    .required("Field must be checked")
    .oneOf([true], "Field must be checked"),
});

interface MandationDocPartTwo
  extends yup.InferType<typeof jsa603FormStore2Schema> {}

function convertRecordToMandationDocPartTwo(
  request: Record<string, any>
): MandationDocPartTwo {
  return {
    rearrangeMandateActivity: request["rearrangeMandateActivity"],
    agreetoRearrange: request["agreetoRearrange"],
    originalMandatedAction: request["originalMandatedAction"],
    rearrangementDenial: request["rearrangementDenial"],
    failActivity: request["failActivity"],
    fullDetails: request["fullDetails"],
    volunteerInfo: request["volunteerInfo"],
    otherInfo: request["otherInfo"],
    confirmStatement: request["confirmStatement"],
  } as MandationDocPartTwo;
}

jsa603FormStore2Schema.cast({
  agreetoRearrange: "rearrangeMandateActivity",
});

export default async function createMandationDoc(
  partOne: Record<string, any>,
  partTwo: Record<string, any>,
  commonStore: any
): Promise<boolean> {
  const mandationPartOne = convertRecordToMandationDocPartOne(partOne);
  const mandationPartTwo = convertRecordToMandationDocPartTwo(partTwo);

  const {
    getCustomer,
    previewData,
    savedManData,
    manFormData,
    getPRaPOrder,
    postMandationFormToSharepoint,
    getActionToMandate,
    dueDate,
    post603ToLogicApp,
    postMandation603,
  } = commonStore;

  function DataToSave() {
    const rearrangementDetails =
      formatYesNo(mandationPartTwo.rearrangeMandateActivity) == true
        ? mandationPartTwo.originalMandatedAction
        : mandationPartTwo.rearrangementDenial;

    const face2FaceActions = 
      formatYesNo(mandationPartOne.attemptsMade)
        ? mandationPartOne.face2Face
        : ""

    const agreetoRearrange = 
      mandationPartTwo.agreetoRearrange == undefined
        ? null
        : formatYesNo(mandationPartTwo.agreetoRearrange)

    return {
      destinationAddress: FormatCustomerAddress(),
      status: "",
      documentTypeVersion: "",
      completedBy: mandationPartOne.selectedAdvisor,

      mandatableDetails: {
        jcpRestrictions: formatYesNo(mandationPartOne.satisfyRestrictionOnJSA),
        restartSchemeParticipation: formatYesNo(
          mandationPartOne.restartSchemeMandatory
        ),
        reasonable: formatYesNo(mandationPartOne.reasonableConsideration),
        otherNonManAttemptsMade: formatYesNo(mandationPartOne.attemptsMade),
        faceToFaceActionsTaken: face2FaceActions
      },

      rearrangementDetails: {
        attemptToRearrangeMade: formatYesNo(
          mandationPartTwo.rearrangeMandateActivity
        ) ?? "",
        rearrangmentAgreed: agreetoRearrange,
        rearrangmentDecisionDetails: rearrangementDetails,
      },

      nonParticipationDetails: {
        failureReason: mandationPartTwo.failActivity,
        failureReasonDetails: mandationPartTwo.fullDetails,
      },

      participantFailureReason: mandationPartTwo.volunteerInfo,
      additionalInformation: mandationPartTwo.otherInfo,
      customerName: getCustomer.fullName, 
      title: "Mr",
      nino: getPRaPOrder.id ? getPRaPOrder.id : "N/A",
      mandationSubject: getActionToMandate.subject,

      uc603Section: {
        dateComplianceConditionIssued: savedManData.createdOn,
        methodOfIssue: savedManData.methodOfIssue,
        complianceConditionDetails:
        savedManData.complianceCondition == null && savedManData.complianceDetails[0].value == null 
          ? null 
          : savedManData.complianceCondition == null
            ? savedManData.complianceDetails[0].value 
            : savedManData.complianceDetails[0].value == null 
              ? savedManData.complianceDetails[0].value 
              : savedManData.complianceCondition + savedManData.complianceDetails[0].value
      },
    };
  }

  function formatYesNo(input: string) {
    if (input.toLowerCase() == "yes") {
      return true;
    } else if (input.toLowerCase() == "no") {
      return false;
    }
  }

  function FormatCustomerAddress() {
    const postalCode = savedManData.postalCode;
    const addressLine1 = savedManData.addressLine1;
    const addressLine2 = savedManData.addressLine2;
    const addressLine3 = savedManData.addressLine3;

    const address = [addressLine1, addressLine2, addressLine3, postalCode];

    return address.filter((i) => i != null && i != "").join(", ");
  }


  const data603 = DataToSave();

  await postMandation603(
    getActionToMandate.id,
    loggedIn.email,
    data603)

  return true;
}

const getDateFormat = (date: Date): String => {
  return Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(date);
};
