<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="getIsRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }} - Advisor Portal (Version {{ version }}), made by
            <a
              href="https://xaura.io/"
              class="font-weight-bold"
              target="_blank"
              >Xaura.io</a
            >
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="https://www.fedcapemployment.org/about-us"
                class="nav-link text-muted"
                target="_blank"
                >Fedcap</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-muted"
                >Call: 0800 917 9262</a
              >
            </li>
            <li class="nav-item">
              <a
                href="mailto:hello@fedcapemployment.org"
                class="nav-link text-muted"
                target="_blank"
                >Email</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.facebook.com/fedcapemployment"
                class="nav-link pe-0 text-muted"
                target="_blank"
                >Facebook</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://twitter.com/FedcapE"
                class="nav-link pe-0 text-muted"
                target="_blank"
                >Twitter</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'pinia';
import { useMainStore } from '@/store/mainStore';
export default {
  name: "AppFooter",
  data(){
    return{
      version:process.env.VUE_APP_VERSION
    }
  },
  computed:{
    ...mapState(useMainStore,[
    "getIsRTL"
    ])
  }
};
</script>
