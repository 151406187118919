<template>
  <nav aria-label="breadcrumb">
    <ol
      :class="`px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb ${
        !getIsRTL ? 'me-sm-6' : ' '
      }`"
    >
      <li :class="`text-sm breadcrumb-item ${getIsRTL ? 'ps-2' : ''}`">
        <a :class="getIsNavFixed ? 'text-dark' : 'text-white'">
          <i v-if="getNavigationHistory.length == 1" class="ni ni-box-2"></i>
          <svg @click="navBack()" v-if="getNavigationHistory.length > 1" class="nav-back" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480h64C177.7 480 192 465.7 192 448S177.7 416 160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64C177.7 96 192 81.67 192 64S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256C0 437 42.98 480 96 480zM504.8 238.5l-144.1-136c-6.975-6.578-17.2-8.375-26-4.594c-8.803 3.797-14.51 12.47-14.51 22.05l-.0918 72l-128-.001c-17.69 0-32.02 14.33-32.02 32v64c0 17.67 14.34 32 32.02 32l128 .001l.0918 71.1c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C514.4 264.4 514.4 247.6 504.8 238.5z"/></svg>
        </a>
      </li>
      <li
        :class="`text-sm breadcrumb-item  ${
          getIsNavFixed ? 'text-dark' : 'text-white'
        }`"
      >
        <a
          v-if="getIsRTL"
          :class="`opacity-8 ps-2 ${getIsNavFixed ? 'text-dark' : 'text-white'}`"
          href="#"
          >لوحات القيادة</a
        >
        <router-link :to="getFirstPath"
          v-else
          :class="`${getIsNavFixed ? 'text-dark' : 'text-white'}`"
          >Home</router-link
        >
      </li>
      <li
        v-for="route in getRoutes" :key="route.path"
        :class="`text-sm breadcrumb-item active ${
          getIsNavFixed ? 'text-dark' : 'text-white'
        }`"
        aria-current="page"
        @mouseover="()=>{currentHoverEl = route.name; showMin = true;}"
        @mouseleave="()=>{showMin = false;}"
        style="display: flex; align-items: center;"
      >
       <router-link :to="route.path"
          v-show="showMin == false || (showMin == true && currentHoverEl != route.name)"
          :class="`${getIsNavFixed ? 'text-dark' : 'text-white'}`"
          >{{ route.name }}</router-link
        >
        <div class="hover-nav" v-show="showMin == true && currentHoverEl == route.name">
         <router-link :to="route.path"
          :class="`${getIsNavFixed ? 'text-dark' : 'text-white'}`"
          >{{ route.name }}</router-link
        >
        <svg @click="removeNav(route.name)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
        </div>
      </li>
    </ol>
    <h6
      :class="`mb-0 font-weight-bolder ${
        getIsNavFixed ? 'text-dark' : 'text-white '
      }`"
    >
      {{ getLastName }}
    </h6>
  </nav>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/mainStore";
import { NavBackwards, RemoveNavItem } from "@/router/index";
//import { navigationHistory } from "../router/index";

export default {
  name: "bread-crumbs",
  data(){
      return{
        forceUpdate: false,
        showMin: false,
        currentHoverEl: ''
      }
  },
  computed: {
    ...mapState(useMainStore, ["getIsRTL", "getIsNavFixed"]),
    getNavigationHistory(){
      if(this.forceUpdate){
        return JSON.parse(sessionStorage.getItem("navigationHistory") ?? JSON.stringify({value:[]})).value;
      }
      return JSON.parse(sessionStorage.getItem("navigationHistory") ?? JSON.stringify({value:[]})).value;
      //return navigationHistory;
    },
    getRoutes(){
      return this.getNavigationHistory.slice(1);
    },
    getFirstName(){
      if(this.getNavigationHistory.length > 0){
        return this.getNavigationHistory[0].name;
      }
      else{
        return "";
      }
    },
     getFirstPath(){
      if(this.getNavigationHistory.length > 0){
        return this.getNavigationHistory[0].path;
      }
      else{
        return "";
      }
    },
    getLastName(){
      if(this.getNavigationHistory.length > 0){
        return this.getNavigationHistory[this.getNavigationHistory.length-1].name;
      }
      else{
        return "";
      }
    }
  },
  methods:{
    navBack(){
      NavBackwards();
      this.forceUpdate = !this.forceUpdate;
    },
    removeNav(name){
      RemoveNavItem(name);
      this.forceUpdate = !this.forceUpdate;
    }
  }
};
</script>
<style scoped>
.nav-back{
  width: 1rem;
  margin-bottom: auot;
  fill: #fff;
  rotate: 180deg;
  cursor: pointer;
}

.nav-back:hover{
  fill: rgb(230, 230, 230);
}

.hover-nav{
  font-size: 0.7rem !important;
  display: flex;
}

.hover-nav svg{
  width: 0.65rem;
  margin-left: 0.4em;
  fill: white;
  cursor:pointer;
}

.hover-nav svg{
  fill: rgb(240, 240, 240);
}
</style>
